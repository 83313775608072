/* eslint-disable import/no-cycle */
import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { sha256 } from 'js-sha256';

import {
  requestLogin,
  requestLoginV2,
  requestRefreshToken,
  requestCheckForUpdates,
} from '~/services/request';
import history from '~/services/history';
import { Types } from '../types';

import {
  loginSuccess,
  loginFailure,
  validateEmailResponse,
  forgotPasswordResponse,
  resetPasswordResponse,
  refreshTokenSuccess,
  refreshTokenFailure,
  firstLogin,
} from './actions';

export function* login({ payload }) {
  let encryptedPassword = sha256(payload.password);
  encryptedPassword = encryptedPassword.toUpperCase();

  const firebaseToken = localStorage.getItem('firebaseToken');
  const uuid = localStorage.getItem('uuid');

  const data = {
    EmpresaId: payload.campanyId,
    Email: payload.username,
    Senha: encryptedPassword,
    grantType: 'password',
    encryptedPassword: true,
  };

  if (firebaseToken && firebaseToken !== 'null' && uuid && uuid !== 'null') {
    data.registrationId = firebaseToken;
    data.deviceId = uuid;
  }

  const base64 = btoa(JSON.stringify(data));

  const body = { data: base64 };
  const params = {
    url: '/login',
    method: 'POST',
    body,
    authorization: false,
    header: {
      'g-recaptcha-response': payload.token,
    },
  };

  try {
    const response = yield call(requestLogin, params);
    if (response.status === 200) {
      yield localStorage.setItem('lastSignIn', Date.now());
      yield localStorage.setItem('user/id', response.data.Usuario.Id);

      const loggedIn = response.data;
      const token = response.data.Token;
      localStorage.setItem('lastLoggedUser', payload.username);

      if (!loggedIn.Usuario.CadastroConfirmado) {
        history.push('/login/primeiro-acesso');
        yield put(firstLogin(loggedIn, token));
      } else {
        yield put(loginSuccess(loggedIn, token));

        const isAdmin = loggedIn.MenuAdmin.length > 0;
        const isManager =
          loggedIn &&
          loggedIn.Usuario &&
          loggedIn.Usuario.Configuracao &&
          loggedIn.Usuario.Configuracao.UsarMenuGestor &&
          loggedIn.MenuGestores.length > 0;

        if (isAdmin || isManager) {
          history.push('/home');
        } else if (localStorage.getItem('iscomprovante')) {
          history.push('/comprovantes-marcacao-ponto/detalhes');
          localStorage.removeItem('iscomprovante');
        } else if (loggedIn.Usuario.Configuracao.HabilitarMarcacaoPelaWeb) {
          history.push('/#/dashboard/minhas-rotinas/web-ponto');
        } else {
          history.push('/#/dashboard/minhas-rotinas/meu-painel');
        }
      }
    } else if (localStorage.getItem('iscomprovante')) {
      localStorage.removeItem('iscomprovante');
      localStorage.setItem('incorrectPassword', true);

      toast.error(response.data.Mensagem);
      yield put(loginFailure());
      history.push('/comprovantes-marcacao-ponto');
    } else {
      toast.error(response.data.Mensagem);
      yield put(loginFailure());
    }
  } catch (error) {
    toast.error('Falha na autenticação');
    yield put(loginFailure());
  }
}

export function* loginExterno({ payload }) {
  const body = { token: payload.token, empresaId: payload.empresaId };

  const params = {
    url: '/external-sign-in',
    method: 'POST',
    body,
    authorization: false,
  };

  try {
    const response = yield call(requestLogin, params);
    if (response.status === 200) {
      yield localStorage.setItem('lastSignIn', Date.now());
      yield localStorage.setItem('user/id', response.data.Usuario.Id);

      const loggedIn = response.data;
      const token = response.data.Token;
      const isAdmin = loggedIn.MenuAdmin.length > 0;
      const isManager =
        loggedIn &&
        loggedIn.Usuario &&
        loggedIn.Usuario.Configuracao &&
        loggedIn.Usuario.Configuracao.UsarMenuGestor &&
        loggedIn.MenuGestores.length > 0;

      yield put(loginSuccess(loggedIn, token));
      if (isAdmin || isManager) {
        history.push('/home');
      } else if (localStorage.getItem('iscomprovante')) {
        history.push('/comprovantes-marcacao-ponto/detalhes');
        localStorage.removeItem('iscomprovante');
      } else if (loggedIn.Usuario.Configuracao.HabilitarMarcacaoPelaWeb)
        history.push('/#/dashboard/minhas-rotinas/web-ponto');
      else history.push('/#/dashboard/minhas-rotinas/meu-painel');
    } else {
      toast.error(response.data.Mensagem);
      yield put(loginFailure());
    }
  } catch (error) {
    toast.error('Falha na autenticação');
    yield put(loginFailure());
  }
}

export function* refreshToken({ payload }) {
  const base64 = btoa(
    `"email":"${payload.email}","grantType":"refreshToken","refreshToken":"${payload.refreshToken}","senha":"sdfsdf"`,
  );
  const body = { data: base64 };
  const params = { url: '/sign-in', method: 'POST', body };

  try {
    const response = yield call(requestRefreshToken, params);

    if (response.status === 200) {
      yield localStorage.setItem('lastSignIn', Date.now());

      const token = response.data.Token;

      yield put(refreshTokenSuccess(token));
    } else {
      yield put(refreshTokenFailure());
      history.push('/');
    }
  } catch (error) {
    yield put(refreshTokenFailure());
    history.push('/');
  }
}

export function* validateEmail({ username, callback, token }) {
  const params = {
    url: `/validate`,
    method: 'POST',
    body: { email: username.trim() },
    queryParams: null,
    authorization: false,
    header: {
      'g-recaptcha-response': token,
    },
  };
  try {
    const response = yield call(requestLoginV2, params);
    if (response.status === 200) {
      yield put(validateEmailResponse(response.data, callback));
    } else {
      yield put(validateEmailResponse(response.data, callback));
      toast.error(response.data.Mensagem);
    }
  } catch (error) {
    toast.error('Falha na autenticação');
  }
}

export function* forgotPassword({ email, callback }) {
  const params = {
    url: `/forgot-password`,
    method: 'POST',
    body: { email: email.trim() },
    authorization: false,
  };
  try {
    const response = yield call(requestLogin, params);
    if (response.status === 200) {
      yield put(forgotPasswordResponse(response.data, callback));
    } else {
      toast.error(
        response.data.Mensagem
          ? response.data.Mensagem
          : 'Falha ao recuperar senha',
      );
    }
  } catch (error) {
    toast.error('Falha ao recuperar senha');
  }
}

export function* setNewPassword({ body, callback }) {
  const params = {
    url: `/change-password`,
    method: 'POST',
    body,
  };
  try {
    const response = yield call(requestLoginV2, params);
    if (response.status === 200) {
      yield put(resetPasswordResponse(response.data, callback));
    } else {
      toast.error(response.data.Mensagem || 'Falha ao estabelecer nova senha');
    }
  } catch (error) {
    toast.error('Falha ao estabelecer nova senha');
  }
}

export function* resetPassword({ body, callback }) {
  const params = {
    url: `/reset-password`,
    method: 'POST',
    body,
    authorization: false,
  };
  try {
    const response = yield call(requestLogin, params);
    if (response.status === 200) {
      yield put(resetPasswordResponse(response.data, callback));
    } else {
      toast.error(response.data.Mensagem || 'Falha ao estabelecer nova senha');
    }
  } catch (error) {
    toast.error('Falha ao estabelecer nova senha');
  }
}

export function* checkForUpdates() {
  const params = { url: '/recurso/update', method: 'GET' };

  try {
    const response = yield call(requestCheckForUpdates, params);
    if (response.status === 200) {
      yield localStorage.setItem('lastSignIn', Date.now());

      const loggedIn = response.data.Data;
      const token = response.data.Data.Token;

      yield put(loginSuccess(loggedIn, token));
      window.location.reload();
    } else {
      toast.error(response.data.Mensagem);
      yield put(loginFailure());
    }
  } catch (error) {
    toast.error('Falha na autenticação');
    yield put(loginFailure());
  }
}

export function signOut() {
  history.push('/');
  const lastLoggedUser = localStorage.getItem('lastLoggedUser');
  const uuid = localStorage.getItem('uuid');
  localStorage.clear();
  localStorage.setItem('lastLoggedUser', lastLoggedUser);
  localStorage.setItem('uuid', uuid);
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });
}

export default all([
  takeLatest(Types.LOGIN_REQUEST, login),
  takeLatest(Types.LOGIN_EXTERNO_REQUEST, loginExterno),
  takeLatest(Types.REFRESH_TOKEN, refreshToken),
  takeLatest(Types.VALIDATE_EMAIL_REQUEST, validateEmail),
  takeLatest(Types.FORGOT_PASSWORD_REQUEST, forgotPassword),
  takeLatest(Types.RESET_PASSWORD_REQUEST, resetPassword),
  takeLatest(Types.SET_NEW_PASSWORD_REQUEST, setNewPassword),
  takeLatest(Types.CHECK_FOR_UPDATES, checkForUpdates),
  takeLatest(Types.LOGOUT, signOut),
]);
