import { Types } from '../types';

export function novaConvocacaoRequest({ params }, callback) {
  return {
    type: Types.NOVA_CONVOCACAO_REQUEST,
    payload: params,
    callback,
  };
}

export function novaConvocacaoSuccess(data, callback) {
  callback(data);
  return {
    type: Types.NOVA_CONVOCACAO_SUCCESS,
    payload: data,
  };
}

export function novaConvocacaoFailure(data, callback) {
  callback(data);
  return {
    type: Types.NOVA_CONVOCACAO_FAILURE,
    payload: data,
  };
}

export function convocacaoListaRequest(params) {
  return {
    type: Types.CONVOCACAO_LISTA_REQUEST,
    payload: params,
  };
}

export function convocacaoListaSuccess(data) {
  return {
    type: Types.CONVOCACAO_LISTA_SUCCESS,
    payload: { data },
  };
}

export function convocacaoListaFailure() {
  return {
    type: Types.CONVOCACAO_LISTA_FAILURE,
  };
}

export function convocacaoResumoByIdRequest(data) {
  return {
    type: Types.CONTRATANTES_RESUMO_ID_REQUEST,
    payload: data,
  };
}

export function convocacaoResumoByIdSuccess(data) {
  return {
    type: Types.CONTRATANTES_RESUMO_ID_SUCCESS,
    payload: data,
  };
}

export function convocacaoResumoByIdFailure() {
  return {
    type: Types.CONTRATANTES_RESUMO_ID_FAILURE,
  };
}

export function convocacaoInterromperRequest(params) {
  return {
    type: Types.CONVOCACAO_INTERROMPER_REQUEST,
    payload: params,
  };
}

export function convocacaoInterromperSuccess(data) {
  return {
    type: Types.CONVOCACAO_INTERROMPER_SUCCESS,
    payload: { data },
  };
}

export function convocacaoInterromperFailure(data) {
  return {
    type: Types.CONVOCACAO_INTERROMPER_FAILURE,
    payload: { data },
  };
}

export function convocacaoVencendoRequest(dataInicio, dataFim, status) {
  return {
    type: Types.CONVOCACAO_VENCENDO_REQUEST,
    payload: { inicio: dataInicio, fim: dataFim },
    status,
  };
}

export function convocacaoVencendoResponse(data) {
  return {
    type: Types.CONVOCACAO_VENCENDO_RESPONSE,
    payload: data,
  };
}

export function novaConvocacaoClearData() {
  return {
    type: Types.NOVA_CONVOCACAO_CLEAR_DATA,
  };
}
