import { Types } from '../types';

// Carregar Ferias Lancamentos
export function carregarFeriasLancamentosRequest() {
  return {
    type: Types.CARREGAR_FERIAS_LANCAMENTOS_REQUEST,
  };
}

export function carregarFeriasLancamentosSuccess(data) {
  return {
    type: Types.CARREGAR_FERIAS_LANCAMENTOS_SUCCESS,
    payload: { data },
  };
}

export function carregarFeriasLancamentosFailure() {
  return {
    type: Types.CARREGAR_FERIAS_LANCAMENTOS_FAILURE,
  };
}

export function salvarFeriasLancamentoPut({ params }, callback) {
  return {
    type: Types.SALVAR_FERIAS_LANCAMENTO_REQUEST,
    method: 'POST',
    payload: params,
    callback,
  };
}

export function salvarFeriasLancamentoResponse(data, callback) {
  callback(data);
  return {
    type: Types.SALVAR_FERIAS_LANCAMENTO_RESPONSE,
    payload: { data },
  };
}

// Carregar DayOff Lancamentos
export function carregarDayOffLancamentosRequest(usuarioId) {
  return {
    type: Types.CARREGAR_DAYOFF_LANCAMENTOS_REQUEST,
    usuarioId,
  };
}

export function carregarDayOffLancamentosSuccess(data) {
  return {
    type: Types.CARREGAR_DAYOFF_LANCAMENTOS_SUCCESS,
    payload: { data },
  };
}

export function carregarDayOffLancamentosFailure() {
  return {
    type: Types.CARREGAR_DAYOFF_LANCAMENTOS_FAILURE,
  };
}

// Salvar DayOff lancamentos
export function salvarDayOffLancamentoPut({ params }, callback) {
  return {
    type: Types.SALVAR_DAYOFF_LANCAMENTO_REQUEST,
    method: 'POST',
    payload: params,
    callback,
  };
}

export function salvarDayOffLancamentoResponse(data, callback) {
  callback(data);
  return {
    type: Types.SALVAR_DAYOFF_LANCAMENTO_RESPONSE,
    payload: { data },
  };
}

// Carregar Recesso Lancamentos
export function carregarRecessoLancamentosRequest(usuarioId) {
  return {
    type: Types.CARREGAR_RECESSO_LANCAMENTOS_REQUEST,
    usuarioId,
  };
}

export function carregarRecessoLancamentosSuccess(data) {
  return {
    type: Types.CARREGAR_RECESSO_LANCAMENTOS_SUCCESS,
    payload: { data },
  };
}

export function carregarRecessoLancamentosFailure() {
  return {
    type: Types.CARREGAR_RECESSO_LANCAMENTOS_FAILURE,
  };
}

// Salvar Recesso Lancamentos
export function salvarRecessoLancamentoPut({ params }, callback) {
  return {
    type: Types.SALVAR_RECESSO_LANCAMENTO_REQUEST,
    method: 'POST',
    payload: params,
    callback,
  };
}

export function salvarRecessoLancamentoResponse(data, callback) {
  callback(data);
  return {
    type: Types.SALVAR_RECESSO_LANCAMENTO_RESPONSE,
    payload: { data },
  };
}

// Carregar Lancamentos Gestor
export function carregarLancamentosRequest(params) {
  return {
    type: Types.CARREGAR_LANCAMENTOS_REQUEST,
    method: 'GET',
    payload: params,
  };
}

export function carregarLancamentosSuccess(data) {
  return {
    type: Types.CARREGAR_LANCAMENTOS_SUCCESS,
    payload: { data },
  };
}

export function carregarLancamentosFailure() {
  return {
    type: Types.CARREGAR_LANCAMENTOS_FAILURE,
  };
}

// Carregar Lancamentos Gestor
export function totalPontosPorFuncionarioRequest(params) {
  return {
    type: Types.TOTAL_PONTOS_POR_FUNCIONARIO_REQUEST,
    method: 'GET',
    payload: params,
  };
}

export function totalPontosPorFuncionarioSuccess(data) {
  return {
    type: Types.TOTAL_PONTOS_POR_FUNCIONARIO_SUCCESS,
    payload: { data },
  };
}

export function totalPontosPorFuncionarioFailure() {
  return {
    type: Types.TOTAL_PONTOS_POR_FUNCIONARIO_FAILURE,
  };
}

// Carregar Lancamentos Gestor
export function CarregarDayOffLancamento(params) {
  return {
    type: Types.DAYOFF_LANCAMENTO_POR_ID_REQUEST,
    method: 'POST',
    payload: params,
  };
}

export function CarregarDayOffLancamentoResponse(data) {
  return {
    type: Types.DAYOFF_LANCAMENTO_POR_ID_RESPONSE,
    payload: { data },
  };
}

// Carregar Recesso Gestor
export function CarregarRecessoPorId(params) {
  return {
    type: Types.RECESSO_POR_ID_REQUEST,
    method: 'POST',
    payload: params,
  };
}

export function CarregarRecessoPorIdResponse(data) {
  return {
    type: Types.RECESSO_POR_ID_RESPONSE,
    payload: { data },
  };
}

export function CarregarFeriasPorId(params) {
  return {
    type: Types.FERIAS_POR_ID_REQUEST,
    method: 'GET',
    payload: params,
  };
}

export function CarregarFeriasPorIdResponse(data) {
  return {
    type: Types.FERIAS_POR_ID_RESPONSE,
    payload: { data },
  };
}

export function clearAllLancamento() {
  return {
    type: Types.CLEAR_ALL_LANCAMENTOS,
  };
}
