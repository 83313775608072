import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

export const Container = styled(animated.div)`
  position: relative;
  width: 632px;
  height: 538px;
  background: var(--white);
  padding: 35px;
  box-shadow: 3px 3px 20px #00000029;
  border-radius: 5px;
`;
export const Background = styled.div`
  position: fixed;
  top: 0;
  overflow: hidden;

  z-index: 4001;
  display: flex;
  flex: 1;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s;
  opacity: 1;
  ${({ open }) =>
    !open &&
    css`
      opacity: 0;
    `}
`;
