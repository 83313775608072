import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import InputMask from 'react-input-mask';

import AccessTime from '~/assets/images/accessTime.png';

const Container = styled.input`
  width: ${(props) => (props.width ? props.width : '100%')};
  padding: 8px;
  padding-left: 1px
  resize: none;
  border: none;
  border-bottom: ${(props) => !props.withoutBorder && '1px solid #d9e5eb'};
  outline: none;
  max-length: 30;
  color: ${({ color }) => color && color};

  &::placeholder {
    color: ${({ color }) => color && color};
}

&:-ms-input-placeholder {
  color: ${({ color }) => color && color};
}

&::-ms-input-placeholder {
  color: ${({ color }) => color && color};
}

  ${({ transparente }) =>
    transparente &&
    css`
      background: transparent;
    `}
`;

const InputTime = styled(Container)`
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}

  &::-webkit-calendar-picker-indicator {
    background-image: url(${({ url }) => url && url});
  }

  &::-webkit-datetime-edit-hour-field {
    padding: 10px;
  }

  &::-webkit-datetime-edit-minute-field {
    padding: 10px;
  }

  ${({ transparente }) =>
    transparente &&
    css`
      background: transparent;
    `}
`;

const InputMaskTime = styled(InputMask)`
  width: ${(props) => (props.width ? props.width : '100%')};
  padding: 8px;
  padding-left: 1px
  resize: none;
  border: none;
  border-bottom: ${(props) => !props.withoutBorder && '1px solid #d9e5eb'};
  outline: none;
  max-length: 30;
  color: ${({ color }) => color && color};

&::placeholder {
    color: ${({ color }) => color && color};
  opacity: 1;
}

&:-ms-input-placeholder {
  color: ${({ color }) => color && color};
}

&::-ms-input-placeholder {
  color: ${({ color }) => color && color};
}

&::-webkit-calendar-picker-indicator {
    background-image: url(${({ url }) => url && url});
  }

&::-webkit-datetime-edit-hour-field {
    padding: 10px;
}

&::-webkit-datetime-edit-minute-field {
  padding: 10px;
}

  ${({ transparente }) =>
    transparente &&
    css`
      background: transparent;
    `}

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
    
`;

class Input extends React.Component {
  onlyNumbers = (value) => {
    return value.replace(/[^0-9]/g, '');
  };

  splitNumbers = (value) => {
    return value.split(':');
  };

  onChangeTime = (event) => {
    const { onChange } = this.props;
    const value = this.onlyNumbers(event.target.value);
    if (value > 2359) {
      event.target.value = '23:59';
    }
    const arrayValue = this.splitNumbers(event.target.value);
    if (arrayValue[1] > 59) {
      event.target.value = `${arrayValue[0]}:59`;
    }
    if (value.length !== 1) {
      onChange(event.target.value);
    } else {
      onChange(`${value}0:00`);
    }
  };

  onChange = (event) => {
    const { onChange } = this.props;
    onChange(event.target.value);
  };

  render() {
    const {
      disabled,
      placeholder,
      value,
      type,
      style,
      onKeyPress,
      withoutBorder,
      className,
      maxlength,
      name,
      width,
      transparente,
      min,
      max,
      color,
    } = this.props;

    return (
      <>
        {type ? (
          <>
            {type === 'time' ? (
              <>
                <InputMaskTime
                  mask="99:99"
                  maxLength={maxlength && maxlength}
                  className={className}
                  withoutBorder={withoutBorder}
                  onChange={this.onChangeTime}
                  value={value}
                  name={name && name}
                  disabled={disabled}
                  style={style}
                  onKeyPress={(e) => e.key === 'Enter' && onKeyPress(value)}
                  url={AccessTime}
                  width={width}
                  transparente={transparente}
                  min={min && min}
                  max={max && max}
                />
              </>
            ) : (
              <InputTime
                maxLength={maxlength && maxlength}
                className={className}
                withoutBorder={withoutBorder}
                placeholder={placeholder}
                onChange={this.onChange}
                value={value}
                name={name && name}
                disabled={disabled}
                type={type && type}
                style={style}
                onKeyPress={(e) => e.key === 'Enter' && onKeyPress(value)}
                url={AccessTime}
                width={width}
                transparente={transparente}
                min={min && min}
                max={max && max}
              />
            )}
          </>
        ) : (
          <Container
            width={width}
            maxLength={maxlength && maxlength}
            className={className}
            withoutBorder={withoutBorder}
            placeholder={placeholder}
            onChange={this.onChange}
            value={value}
            name={name && name}
            disabled={disabled}
            color={color}
            transparente={transparente}
            type={type && type}
            style={style}
            onKeyPress={(e) => e.key === 'Enter' && onKeyPress(value)}
            min={min && min}
            max={max && max}
          />
        )}
      </>
    );
  }
}

Input.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
  value: PropTypes.string,
  disabled: PropTypes.bool,
  password: PropTypes.bool,
  onKeyDown: PropTypes.func.isRequired,
};

Input.defaultProps = {
  value: '',
  placeholder: 'Digite aqui',
  disabled: false,
  password: false,
  style: {},
};

export default Input;
