import React, { useEffect } from 'react';

import { Conteiner } from './styles';
import insucessoMsg from '~/assets/utils/images/imagensFolhaCerta/insucessoMsg.svg';
import { useModal } from '~/hooks/modal';

export default function Insucesso({ dados }) {
  const { ocultarModal } = useModal();
  useEffect(() => {
    setTimeout(() => ocultarModal(), 3000);
  }, []);
  return (
    <Conteiner>
      <div />
      <img src={insucessoMsg} alt="fail" />
      <p>{dados.menssagem}</p>
    </Conteiner>
  );
}
