import { Types } from '../../../types';

export function bancoHorasConfiguracaoFaixaVerbaRequest({
  params,
  method,
  id,
}) {
  return {
    type: Types.BANCO_HORAS_CONFIGURACAO_FAIXA_VERBA_REQUEST,
    payload: params,
    method: method,
    id: id,
  };
}

export function getBancoHorasConfiguracaoFaixaVerbaResponse(data) {
  return {
    type: Types.GET_BANCO_HORAS_CONFIGURACAO_FAIXA_VERBA_RESPONSE,
    payload: { data },
  };
}

export function postBancoHorasConfiguracaoFaixaVerbaResponse(data) {
  return {
    type: Types.POST_BANCO_HORAS_CONFIGURACAO_FAIXA_VERBA_RESPONSE,
    payload: { data },
  };
}

export function putBancoHorasConfiguracaoFaixaVerbaResponse(data) {
  return {
    type: Types.PUT_BANCO_HORAS_CONFIGURACAO_FAIXA_VERBA_RESPONSE,
    payload: { data },
  };
}
