import React from 'react';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

export default function Firebase() {
  const firebaseConfig = {
    apiKey: 'AIzaSyBzObNWyS-CoG2n-mvl90tk_IIy8gXp6RQ',
    projectId: 'folhacerta-mobile-service',
    messagingSenderId: '281234801731',
    appId: '1:281234801731:web:2275492e88f4d08ecf00f6',
  };

  const firebaseApp = initializeApp(firebaseConfig);
  const messaging = getMessaging(firebaseApp);
  const uuid = uuidv4();

  const getFirebaseToken = () => {
    return getToken(messaging, {
      vapidKey:
        'BBn2swTN7KDf2sobBJh5WIRv47sSAfBLWuanwcRw3_BEJnUKFoHTTONN0XCGcxYvb66-xrrus10xa2xDVPAa9og',
    })
      .then((currentToken) => {
        if (currentToken) {
          localStorage.setItem('firebaseToken', currentToken);
          const lastUuid = localStorage.getItem('uuid');
          if (!lastUuid || lastUuid === 'null') {
            localStorage.setItem('uuid', uuid);
          }
        } else {
          toast.info(
            'Para podermos te notificar, ative a opção de receber notificações no seu navegador.',
          );
        }
      })
      .catch(() => {});
  };

  const onMessageListener = () =>
    new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        console.log('notification', payload.notification.body);
        toast.warning(payload.notification.body);
        resolve(payload);
      });
    });

  getFirebaseToken();
  onMessageListener();

  return <></>;
}
