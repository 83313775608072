import { Types } from '~/store/modules/types';

const url = '/turno';

// LISTA

function responseGetTurno(data) {
  return {
    type: Types.RESPONSE_TURNO,
    payload: { data },
  };
}

export function getTurno(params) {
  return {
    type: Types.REQUEST_TURNO,
    url,
    method: 'GET',
    queryParams: params,
    responseAction: responseGetTurno,
  };
}

function responsePostAndPutTurno(data, callback) {
  if (callback) {
    callback(data);
  }

  return {
    type: Types.RESPONSE_POST_AND_PUT_TURNO,
  };
}

export function PostTurno(params, callback) {
  return {
    type: Types.REQUEST_POST_AND_PUT_TURNO,
    url,
    method: 'POST',
    body: params,
    responseAction: responsePostAndPutTurno,
    callback,
  };
}

export function PutTurno(params, callback) {
  return {
    type: Types.REQUEST_POST_AND_PUT_TURNO,
    url,
    method: 'PUT',
    body: params,
    responseAction: responsePostAndPutTurno,
    callback,
  };
}

export function ClearTurno() {
  return { type: Types.CLEAR_TURNO };
}
