import { Types } from '../types';

export function loginRequest(campanyId, username, password, token) {
  return {
    type: Types.LOGIN_REQUEST,
    payload: { campanyId, username, password, token },
  };
}

export function loginExternoRequest(token, empresaId) {
  return {
    type: Types.LOGIN_EXTERNO_REQUEST,
    payload: { token, empresaId },
  };
}

export function loginSuccess(loggedIn, token) {
  return {
    type: Types.LOGIN_SUCCESS,
    payload: { loggedIn, token },
  };
}

export function loginFailure() {
  return {
    type: Types.LOGIN_FAILURE,
  };
}

export function firstLogin(loggedIn, token) {
  return {
    type: Types.FIRST_LOGIN,
    payload: { loggedIn, token },
  };
}

export function refreshTokenRequest(email, refreshToken) {
  return { type: Types.REFRESH_TOKEN, payload: { email, refreshToken } };
}

export function refreshTokenSuccess(token) {
  return { type: Types.REFRESH_TOKEN_SUCCESS, payload: { token } };
}

export function refreshTokenFailure() {
  return { type: Types.REFRESH_TOKEN_FAILURE };
}

export function validateEmailRequest(username, callback, token) {
  return {
    type: Types.VALIDATE_EMAIL_REQUEST,
    username,
    callback,
    token,
  };
}

export function checkForUpdatesRequest() {
  return { type: Types.CHECK_FOR_UPDATES };
}

export function validateEmailResponse(data, callback) {
  callback(data);
  return {
    type: Types.VALIDATE_EMAIL_RESPONSE,
    payload: { data },
  };
}

export function forgotPasswordRequest(email, callback) {
  return {
    type: Types.FORGOT_PASSWORD_REQUEST,
    email,
    callback,
  };
}

export function forgotPasswordResponse(data, callback) {
  callback(data);
  return {
    type: Types.FORGOT_PASSWORD_RESPONSE,
    payload: { data },
  };
}

export function setNewPasswordRequest(body, callback) {
  return {
    type: Types.SET_NEW_PASSWORD_REQUEST,
    body,
    callback,
  };
}

export function setNewPasswordResponse(message, callback) {
  callback(message);
  return {
    type: Types.SET_NEW_PASSWORD_RESPONSE,
  };
}

export function resetPasswordRequest(body, callback) {
  return {
    type: Types.RESET_PASSWORD_REQUEST,
    body,
    callback,
  };
}

export function resetPasswordResponse(message, callback) {
  callback(message);
  return {
    type: Types.RESET_PASSWORD_RESPONSE,
  };
}

export function resetLogin() {
  return {
    type: Types.RESET_LOGIN,
  };
}

export function registerFirebaseToken(token) {
  return {
    type: Types.REGISTER_FIREBASE_TOKEN,
    payload: { token },
  };
}

export function logout() {
  return {
    type: Types.LOGOUT,
  };
}
