import { Types } from '../../types';

export function filtrarCentroCustosRequest(params) {
  return {
    type: Types.FILTRAR_CENTRO_CUSTOS_REQUEST,
    payload: params,
  };
}

export function filtrarCentroCustosResponse(data) {
  return {
    type: Types.FILTRAR_CENTRO_CUSTOS_RESPONSE,
    payload: { data },
  };
}
