import produce from 'immer';

import { Types } from '../types';

// Reducers
const INITIAL_STATE = {
  logMotorCalculo: null,
  verbaTotais: null,
  verbasADPGlobalView: null,
  bancoHorasPorDia: null,
  bancoHorasPorPeriodo: null,

  relatorioVerbaDia: null,
  relatorioBancoHoras: null,

  loading: false,
};

export default function relatorioApi(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.RELATORIO_API_REQUEST: {
        draft.loading = true;
        draft.verbasADPGlobalView = null;
        break;
      }

      case Types.RELATORIO_LOG_CALCULO_RESPONSE: {
        draft.logMotorCalculo = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.RELATORIO_EXPORTACAO_VERBAS_RESPONSE: {
        draft.verbaTotais = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.RELATORIO_EXPORTACAO_VERBAS_ADP_GLOBALVIEW: {
        draft.verbasADPGlobalView = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.RELATORIO_COMPLETO_VERBAS_DIA_RESPONSE: {
        draft.relatorioVerbaDia = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.RELATORIO_COMPLETO_BANCO_DIA_HORAS_RESPONSE: {
        draft.relatorioBancoHoras = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.RELATORIO_BANCO_HORAS_DIA_RESPONSE: {
        draft.bancoHorasPorDia = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.RELATORIO_COMPLETO_BANCO_PERIODO_HORAS_RESPONSE: {
        draft.relatorioBancoHoras = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.RELATORIO_BANCO_HORAS_PERIODO_RESPONSE: {
        draft.bancoHorasPorPeriodo = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.RELATORIO_RH_EVOLUTION_RESPONSE: {
        // draft.bancoHorasPorPeriodo = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.CLEAR_RELATORIO: {
        draft.verbaTotais = null;
        draft.logMotorCalculo = null;
        draft.relatorioVerbaDia = null;
        draft.relatorioBancoHoras = null;
        draft.verbasADPGlobalView = null;
        draft.bancoHorasPorDia = null;
        draft.bancoHorasPorPeriodo = null;

        draft.loading = false;
        break;
      }

      case Types.LOGOUT: {
        draft.verbaTotais = null;
        draft.logMotorCalculo = null;
        draft.relatorioVerbaDia = null;
        draft.relatorioBancoHoras = null;
        draft.verbasADPGlobalView = null;
        draft.bancoHorasPorDia = null;
        draft.bancoHorasPorPeriodo = null;

        draft.loading = false;
        break;
      }
      default:
    }
  });
}
