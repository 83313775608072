import { Types } from '../types';

export function carregarDia3Request() {
  return {
    type: Types.CARREGAR_DIA_3_REQUEST,
  };
}

export function carregarDia3Success(data) {
  return {
    type: Types.CARREGAR_DIA_3_SUCCESS,
    payload: { data },
  };
}

export function carregarDia3Failure() {
  return {
    type: Types.CARREGAR_DIA_3_FAILURE,
  };
}
