import { takeLatest, call, put, all } from 'redux-saga/effects';
import { Types } from '../../types';
import { requestMotorNovo } from '~/services/request';

import {
  getHoraPositivaResponse,
  postHoraPositivaResponse,
  putHoraPositivaResponse
} from './actions';

export function* horaPositiva({ payload, method, id }) {
  let params = {
    url: id ? '/api/v1/horaPositiva/' + id : '/api/v1/horaPositiva',
    method: method,
    body: payload
  };

  switch (method) {
    case "GET":
      try {
        const response = yield call(requestMotorNovo, params);
        yield put(getHoraPositivaResponse(response.data));
      } catch (error) {
        yield put(getHoraPositivaResponse({ success: false }));
      }
      break;
    case "POST":
      try {
        const response = yield call(requestMotorNovo, params);
        let aux = {
          id: response.headers.location,
          data: response.data
        }
        yield put(postHoraPositivaResponse(aux));
      } catch (error) {
        yield put(postHoraPositivaResponse({ success: false }));
      }
      break;
    case "PUT":
      try {
        const response = yield call(requestMotorNovo, params);
        yield put(putHoraPositivaResponse(response.data));
        yield put(postHoraPositivaResponse({ id: id }));
      } catch (error) {
        yield put(putHoraPositivaResponse({ success: false }));
      }
      break;

    default:
      break;
  }
}


export default all([
  takeLatest(Types.HORA_POSITIVA_REQUEST, horaPositiva),
]);