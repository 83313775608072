import { takeLatest, call, put, all } from 'redux-saga/effects';
import { Types } from '../../../types';
// eslint-disable-next-line import/no-cycle
import { requestMotorNovoBancoHoras } from '~/services/request';

import {
  getBancoHorasConfiguracaoCicloVerbaResponse,
  postBancoHorasConfiguracaoCicloVerbaResponse,
  putBancoHorasConfiguracaoCicloVerbaResponse,
} from './actions';

export function* bancoHorasConfiguracaoCicloVerba({ payload, method, id }) {
  const params = {
    url: id
      ? `/api/v1/bancoHora/configuracao/ciclo/verba/${id}`
      : '/api/v1/bancoHora/configuracao/ciclo/verba',
    method,
    body: payload,
  };

  switch (method) {
    case 'GET':
      try {
        const response = yield call(requestMotorNovoBancoHoras, params);
        yield put(getBancoHorasConfiguracaoCicloVerbaResponse(response.data));
      } catch (error) {
        yield put(
          getBancoHorasConfiguracaoCicloVerbaResponse({ success: false }),
        );
      }
      break;
    case 'POST':
      try {
        const response = yield call(requestMotorNovoBancoHoras, params);

        const aux = {
          id: response.headers.location,
          data: response.data,
        };
        yield put(postBancoHorasConfiguracaoCicloVerbaResponse(aux));
      } catch (error) {
        yield put(
          postBancoHorasConfiguracaoCicloVerbaResponse({ success: false }),
        );
      }
      break;
    case 'PUT':
      try {
        const response = yield call(requestMotorNovoBancoHoras, params);
        yield put(putBancoHorasConfiguracaoCicloVerbaResponse(response.data));
        yield put(postBancoHorasConfiguracaoCicloVerbaResponse({ id }));
      } catch (error) {
        yield put(
          putBancoHorasConfiguracaoCicloVerbaResponse({ success: false }),
        );
      }
      break;

    default:
      break;
  }
}

export default all([
  takeLatest(
    Types.BANCO_HORAS_CONFIGURACAO_CICLO_VERBA_REQUEST,
    bancoHorasConfiguracaoCicloVerba,
  ),
]);
