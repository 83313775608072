import { takeLatest, call, put, all, delay } from 'redux-saga/effects';

import { Types } from '../types';

import { toast } from 'react-toastify';

import { requestIntermitentes } from '~/services/request';

import { cargosGetSuccess, cargosGetFailure } from './actions';

export function* cargosGet() {
  let params = {
    url: '/Cargo',
    method: 'GET',
    body: null
  };

  try {
    const response = yield call(requestIntermitentes, params);
    if (response.data.success) {
      const data = response.data.data;
      yield put(cargosGetSuccess(data));
      yield delay(2000);
    } else {
      toast.error(response.data.Error);
      yield put(cargosGetFailure());
    }
  } catch (error) {
    toast.error("Falha na autenticação");
    yield put(cargosGetFailure());
  }
}

export default all([
  takeLatest(Types.CARGOS_GET_REQUEST, cargosGet)
]);