import produce from 'immer';

import { Types } from '../types';

// Reducers
const INITIAL_STATE = {
  erro: null,

  lista: null,
  relatorio: null,
  historico: null,

  loading: false,
}

export default function mensageria(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.MENSAGERIA_REQUEST: {
        draft.loading = true;
        draft.erro = null;
        break;
      }

      // Lista Mensagens
      case Types.MENSAGERIA_LISTA_SUCCESS: {
        draft.loading = false;
        draft.erro = null;
        draft.lista = action.payload.data;
        break;
      }

      case Types.MENSAGERIA_LISTA_FAILURE: {
        draft.loading = false;
        draft.erro = action.payload.data;
        break;
      }

      // Histórico(detalhe) de uma mensagem
      case Types.MENSAGERIA_HISTORICO_SUCCESS: {
        draft.loading = false;
        draft.erro = null;
        draft.historico = action.payload.data;
        break;
      }

      case Types.MENSAGERIA_HISTORICO_FAILURE: {
        draft.loading = false;
        draft.erro = action.payload.data;
        break;
      }

      // Relatorio
      case Types.MENSAGERIA_RELATORIO_SUCCESS: {
        draft.loading = false;
        draft.erro = null;
        draft.relatorio = action.payload.data;
        break;
      }

      case Types.MENSAGERIA_RELATORIO_FAILURE: {
        draft.loading = false;
        draft.erro = action.payload.data;
        break;
      }

      // Clear e Logout
      case Types.CLEAR_MENSAGERIA: {
        draft.erro = null;
        draft.lista = null;
        draft.relatorio = null;
        draft.historico = null;

        draft.loading = false;
        break;
      }

      case Types.LOGOUT: {
        draft.erro = null;
        draft.lista = null;
        draft.relatorio = null;
        draft.historico = null;

        draft.loading = false;
        break;
      }

      default:
    }
  });
}