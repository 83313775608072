import React from 'react';
import { ProvedorAcao, useAcao } from './acao';
import { ProvedorCalendario, useCalendario } from './calendario';
import { ProvedorTag, useTag } from './tag';

export default function ProvedorEscalas({ children }) {
  return (
    <>
      <ProvedorAcao>
        <ProvedorTag>
          <ProvedorCalendario>{children}</ProvedorCalendario>
        </ProvedorTag>
      </ProvedorAcao>
    </>
  );
}

export { useCalendario, useTag, useAcao };
