import produce from 'immer';

import { Types } from '../../types';

// Reducers
const INITIAL_STATE = {
  loading: false,
  get_payload: null,
  post_payload: null,
  put_payload: null,

  get_defualt_payload: null,
  get_regras_by_empresa_payload: null,
};

export default function regraPonto(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.REGRA_PONTO_REQUEST: {
        draft.loading = true;
        draft.get_payload = null;
        draft.post_payload = null;
        draft.put_payload = null;
        draft.get_defualt_payload = null;
        draft.get_regras_by_empresa_payload = null;
        break;
      }

      case Types.GET_REGRA_DEFAULT_PONTO_REQUEST: {
        draft.loading = true;
        draft.get_defualt_payload = null;
        break;
      }

      case Types.REGRA_PONTO_BY_EMPRESA_REQUEST: {
        draft.loading = true;
        draft.get_regras_by_empresa_payload = null;
        break;
      }

      case Types.GET_REGRA_PONTO_RESPONSE: {
        draft.get_payload = action.payload.data;
        draft.loading = false;
        break;
      }
      case Types.POST_REGRA_PONTO_RESPONSE: {
        draft.post_payload = action.payload.data;
        draft.loading = false;
        break;
      }
      case Types.PUT_REGRA_PONTO_RESPONSE: {
        draft.put_payload = action.payload.data;
        draft.loading = false;
        break;
      }
      case Types.GET_REGRA_DEFAULT_PONTO_RESPONSE: {
        draft.get_defualt_payload = action.payload.data;
        draft.loading = false;
        break;
      }
      case Types.REGRA_PONTO_BY_EMPRESA_RESPONSE: {
        draft.get_regras_by_empresa_payload = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.LOGOUT: {
        draft.get_payload = null;
        draft.post_payload = null;
        draft.put_payload = null;
        draft.get_defualt_payload = null;
        draft.get_regras_by_empresa_payload = null;
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
