/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-cycle */
import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import loginPayload from './loggedIn/sagas';
import themeOptions from './themeOptions/sagas';
import locale from './locale/sagas';
import carregarDia3 from './carregarDia3/sagas';
import marcarPonto3 from './marcarPonto3/sagas';
import carregarBancoHorasAtual from './carregarBancoHorasAtual/sagas';
import carregarMotivoMarcacao from './carregarMotivoMarcacao/sagas';
import alterarMarcacao from './marcacaoPonto/alterar/sagas';
import restaurarMarcacao from './marcacaoPonto/restaurar/sagas';
import desconsiderarMarcacao from './marcacaoPonto/desconsiderar/sagas';
import alterarStatusLancamento from './marcacaoPonto/alterarStatusLancamento/sagas';
import carregarLancamentos from './carregarLancamentos/sagas';
import carregarMes from './carregarMes/sagas';
import carregarPeriodoAtual from './carregarPeriodoAtual/sagas';
import carregarHistoricoMarcacao from './carregarHistoricoMarcacao/sagas';
import carregarAvisoAusenciaLancamentos from './carregarAvisoAusenciaLancamentos/sagas';
import cargosGet from './cargosIntermitentes/sagas';
import locaisDeTrabalho from './locaisDeTrabalho/sagas';
import usuariosConvocacao from './usuariosConvocacao/sagas';
import regrasConvocacao from './regrasConvocacao/sagas';
import departamentos from './departamentos/sagas';
import carregarDownloads from './download/sagas';
import grupo from './grupo/sagas';
import grupoAtivos from './grupo/ativos/sagas';
import grupoArquivados from './grupo/arquivados/sagas';
import grupoTodos from './grupo/todos/sagas';
import contratantes from './contratantes/sagas';
import convocacao from './convocacao/sagas';
import motivosInterrupcao from './motivosInterrupcao/sagas';
import relatorioSap from './relatorio/sagas';
import regrasApuracao from './motorNovo/regrasApuracao/sagas';
import formaApuracao from './formaApuracao/sagas';
import carregarMotivosAusencia from './carregarMotivosAusencia/sagas';
import carregarAusencias from './carregarAusencias/sagas';
import carregarTotaisLancamentos from './carregarTotaisLancamentos/sagas';
import salvarAvisoAusenciaLancamento from './salvarAvisoAusenciaLancamento/sagas';
import dashboardAprovacoes from './dashboardAprovacoes/sagas';
import motivoSobreaviso from './motivoSobreaviso/sagas';
import regraSobreaviso from './regraSobreaviso/sagas';
import alocacaoSobreaviso from './alocacaoSobreaviso/sagas';
import folhacertabase from './folhacertabase/sagas';
import lancamentoSobreaviso from './lancamentoSobreaviso/sagas';
import horaNoturna from './motorNovo/horaNoturna/sagas';
import tolerancia from './motorNovo/tolerancia/sagas';
import horaPositiva from './motorNovo/horaPositiva/sagas';
import horaPositivaFaixas from './motorNovo/horaPositivaFaixas/sagas';
import horaNegativa from './motorNovo/horaNegativa/sagas';
import faltas from './motorNovo/faltas/sagas';
import ausencias from './motorNovo/ausencias/sagas';
import descansoObrigatorio from './motorNovo/descansoObrigatorio/sagas';
import domingoDsr from './motorNovo/domingoDsr/sagas';
import inconsistencia from './motorNovo/Inconsistencia/sagas';
import tratamento from './motorNovo/tratamento/sagas';
import intervalo from './motorNovo/intervalo/sagas';
import regraPonto from './motorNovo/regraPonto/sagas';
import alocacaoRegraApuracao from './motorNovo/alocacaoRegraApuracao/sagas';
import relatorioApi from './apiRelatorios/sagas';
import configDePara from './configuracaoDePara/sagas';
import mensageria from './mensageria/sagas';
import marcacoesRelatorio from './marcacoesRelatorio/sagas';
import bancoHorasConfiguracao from './motorNovo/BancoHoras/bancoHorasConfiguracao/sagas';
import bancoHorasConfiguracaoFaixa from './motorNovo/BancoHoras/bancoHorasConfiguracaoFaixa/sagas';
import bancoHorasConfiguracaoFaixaVerba from './motorNovo/BancoHoras/bancoHorasConfiguracaoFaixaVerba/sagas';
import bancoHorasConfiguracaoCiclo from './motorNovo/BancoHoras/bancoHorasConfiguracaoCiclo/sagas';
import bancoHorasConfiguracaoCicloVerba from './motorNovo/BancoHoras/bancoHorasConfiguracaoCicloVerba/sagas';
import bancoHorasEstagio from './motorNovo/BancoHoras/bancoHorasEstagio/sagas';
import bancoHorasEstagioVerba from './motorNovo/BancoHoras/bancoHorasEstagioVerba/sagas';
import bancoHora from './motorNovo/BancoHoras/bancoHora/sagas';
import ciclo from './motorNovo/BancoHoras/ciclo/sagas';
import reprocessamento from './reprocessamento/sagas';
import indicadores from './indicadores/sagas';
import processosInternos from './processosInternos/sagas';
import gerenciarProcessosInternos from './gerenciarProcessosInternos/sagas';
import carregarUsuariosGestores from './carregarUsuariosGestores/sagas';
import filtrarHoleritesPorFiliais from './holerite/filiais/sagas';
import filtrarHoleritesPorDepartamentos from './holerite/departamentos/sagas';
import filtrarHoleritesPorCentroCustos from './holerite/centroCustos/sagas';
import filtrarHoleritesPorGrupos from './holerite/grupos/sagas';
import filtrarHoleritesPorSindicatos from './holerite/sindicatos/sagas';
import filtrarHoleritesPorPessoas from './holerite/pessoas/sagas';
import listarTodosHolerites from './holerite/listagem/sagas';
import importacao from './holerite/importacao/sagas';
import deletaRegistroPorId from './holerite/delete/sagas';
import relatorioApiBancoHoras from './apiBancoHoras/sagas';
import bancohoraPgtoAbonoExtraordinario from './bancoHoras/pagamentoAbonoExtraordinario/sagas';
import saldoAnterior from './bancoHoras/saldoAnterior/sagas';
import fechamento from './bancoHoras/fechamentoAntecipado/sagas';
import layoutExportacao from './layoutExportacao/sagas';
import regrasCondicoesTrabalho from './escalas/configuracoes/regrasCondicoesTrabalho/sagas';
import regrasGestaoEscala from './escalas/configuracoes/regrasGestaoEscala/sagas';
import alocacaoEscalas from './escalas/configuracoes/alocacao/sagas';
import espelhoPontoBancoHoras from './bancoHoras/espelhoDePonto/sagas';
import escalasTrabalho from './escalas/configuracoes/escalaTrabalho/sagas';
import calendarioEquipe from './escalas/equipe/sagas';
import tags from './escalas/tags/sagas';
import turno from './escalas/configuracoes/turno/sagas';
import escalasEmUso from './escalas/escalasEmUso/sagas';
import divergencia from './divergencia/sagas';
import acao from './escalas/acoes/sagas';
import listaTiposHolerites from './holerite/tipoHolerite/sagas';

export default function* rootSaga() {
  return yield all([
    auth,
    loginPayload,
    themeOptions,
    locale,
    carregarDia3,
    marcarPonto3,
    carregarBancoHorasAtual,
    carregarMotivoMarcacao,
    alterarMarcacao,
    restaurarMarcacao,
    desconsiderarMarcacao,
    alterarStatusLancamento,
    carregarLancamentos,
    carregarMes,
    carregarPeriodoAtual,
    carregarHistoricoMarcacao,
    carregarAvisoAusenciaLancamentos,
    cargosGet,
    carregarDownloads,
    locaisDeTrabalho,
    usuariosConvocacao,
    regrasConvocacao,
    departamentos,
    grupo,
    grupoAtivos,
    grupoArquivados,
    grupoTodos,
    contratantes,
    convocacao,
    relatorioSap,
    motivosInterrupcao,
    regrasApuracao,
    formaApuracao,
    carregarMotivosAusencia,
    carregarAusencias,
    carregarTotaisLancamentos,
    salvarAvisoAusenciaLancamento,
    dashboardAprovacoes,
    motivoSobreaviso,
    regraSobreaviso,
    alocacaoSobreaviso,
    folhacertabase,
    lancamentoSobreaviso,
    horaNoturna,
    tolerancia,
    horaPositiva,
    horaPositivaFaixas,
    horaNegativa,
    faltas,
    ausencias,
    descansoObrigatorio,
    domingoDsr,
    inconsistencia,
    tratamento,
    intervalo,
    regraPonto,
    alocacaoRegraApuracao,
    relatorioApi,
    configDePara,
    mensageria,
    marcacoesRelatorio,
    bancoHorasConfiguracao,
    bancoHorasConfiguracaoCiclo,
    bancoHorasConfiguracaoCicloVerba,
    bancoHorasConfiguracaoFaixa,
    bancoHorasConfiguracaoFaixaVerba,
    bancoHora,
    ciclo,
    reprocessamento,
    indicadores,
    processosInternos,
    gerenciarProcessosInternos,
    carregarUsuariosGestores,
    filtrarHoleritesPorFiliais,
    filtrarHoleritesPorDepartamentos,
    filtrarHoleritesPorCentroCustos,
    filtrarHoleritesPorGrupos,
    filtrarHoleritesPorSindicatos,
    filtrarHoleritesPorPessoas,
    listarTodosHolerites,
    importacao,
    deletaRegistroPorId,
    relatorioApiBancoHoras,
    bancohoraPgtoAbonoExtraordinario,
    saldoAnterior,
    fechamento,
    bancoHorasEstagio,
    bancoHorasEstagioVerba,
    layoutExportacao,
    regrasCondicoesTrabalho,
    regrasGestaoEscala,
    alocacaoEscalas,
    espelhoPontoBancoHoras,
    escalasTrabalho,
    calendarioEquipe,
    tags,
    turno,
    escalasEmUso,
    divergencia,
    acao,
    listaTiposHolerites,
  ]);
}
