import { Types } from '../../types';

export function filtrarFiliaisRequest(params) {
  return {
    type: Types.FILTRAR_FILIAIS_REQUEST,
    payload: params,
  };
}

export function filtrarFiliaisResponse(data) {
  return {
    type: Types.FILTRAR_FILIAIS_RESPONSE,
    payload: { data },
  };
}
