import { takeLatest, call, put, all } from 'redux-saga/effects';

import { Types } from '~/store/modules/types';
import { requestEspelhoPonto } from '~/services/request';

export function* request({
  url,
  method,
  queryParams,
  body,
  responseAction,
  callback,
}) {
  const params = {
    url,
    method,
    queryParams,
    body,
  };

  try {
    const response = yield call(requestEspelhoPonto, params);
    yield put(responseAction(response.data, callback));
  } catch (error) {
    yield put(responseAction({ success: false, message: 'Erro' }, callback));
  }
}

export default all([
  takeLatest(Types.ESPELHO_PONTO_BANCO_HORA_REQUEST, request),
]);
