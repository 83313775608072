/* eslint-disable import/no-cycle */
import { takeLatest, call, put, all, delay } from 'redux-saga/effects';

import { toast } from 'react-toastify';

import { requestIntermitentes } from '~/services/request';
import { Types } from '../types';

import { departamentosSuccess, departamentosFailure } from './actions';

export function* departamentos() {
  const params = {
    url: '/Departamento',
    method: 'GET',
    body: null,
  };

  try {
    const response = yield call(requestIntermitentes, params);
    if (response.data.success) {
      const { data } = response.data;

      yield put(departamentosSuccess(data));
      yield delay(2000);
    } else {
      toast.error(response.data.Error);
      yield put(departamentosFailure());
    }
  } catch (error) {
    toast.error('Falha na autenticação');
    yield put(departamentosFailure());
  }
}

export default all([takeLatest(Types.DEPARTAMENTOS_REQUEST, departamentos)]);
