import produce from 'immer';

import { Types } from '../types';

// Reducers
const INITIAL_STATE = {
  payload: null,
  loading: false,
}

export default function locaisDeTrabalho(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.LOCAIS_DE_TRABALHO_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.LOCAIS_DE_TRABALHO_SUCCESS: {
        draft.payload = action.payload.data;
        draft.loading = false;
        break;
      }
      case Types.LOCAIS_DE_TRABALHO_FAILURE: {
        draft.payload = null;
        draft.loading = false;
        break;
      }
      case Types.LOGOUT: {
        draft.payload = null;
        draft.loading = false;
        break;
      }
      default:
    }
  });
}