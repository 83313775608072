import { Types } from '../../types';

export function regraPontoGet({ params, method, id, queryParams }) {
  return {
    type: Types.REGRA_PONTO_REQUEST,
    body: params,
    method,
    id,
    queryParams,
  };
}

export function getRegrasByEmpresaId({ queryParams }) {
  return {
    type: Types.REGRA_PONTO_BY_EMPRESA_REQUEST,
    queryParams,
  };
}

export function regraPontoGetDefault() {
  return {
    type: Types.GET_REGRA_DEFAULT_PONTO_REQUEST,
    method: 'GET'
  };
}

export function getRegraPontoResponse(data) {
  return {
    type: Types.GET_REGRA_PONTO_RESPONSE,
    payload: { data },
  };
}

export function postRegraPontoResponse(data) {
  return {
    type: Types.POST_REGRA_PONTO_RESPONSE,
    payload: { data },
  };
}

export function putRegraPontoResponse(data) {
  return {
    type: Types.PUT_REGRA_PONTO_RESPONSE,
    payload: { data },
  };
}

export function getRegraPontoDefaultResponse(data) {
  return {
    type: Types.GET_REGRA_DEFAULT_PONTO_RESPONSE,
    payload: { data },
  };
}

export function regrasByEmpresaResponse(data) {
  return {
    type: Types.REGRA_PONTO_BY_EMPRESA_RESPONSE,
    payload: { data },
  };
}
