import produce from 'immer';

import { Types } from '../types';

// Reducers
const INITIAL_STATE = {
  lang: 'pt',
  flagCountry: 'BR'
}

export default function locale(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.CHOOSE_LOCALE:
        draft.lang = action.payload.language;
        draft.flagCountry = action.payload.flagCountry;
        break;
      case Types.LOGOUT: {
        draft.lang = 'pt';
        draft.flagCountry = 'BR';
        break;
      }
      default:
    }
  });
}