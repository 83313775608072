import { Types } from '../../../types';

export function bancoHorasEstagioVerbaRequest({ params, method }, callback) {
  return {
    type: Types.BANCO_HORAS_ESTAGIO_VERBA_REQUEST,
    payload: params,
    method,
    callback,
  };
}

export function getBancoHorasEstagioVerbaResponse(data, callback) {
  callback(data);
  return {
    type: Types.GET_BANCO_HORAS_ESTAGIO_VERBA_RESPONSE,
    payload: { data },
  };
}

export function putBancoHorasEstagioVerbaResponse(data, callback) {
  callback(data);
  return {
    type: Types.PUT_BANCO_HORAS_ESTAGIO_VERBA_RESPONSE,
    payload: { data },
  };
}
