import { takeLatest, call, put, all, delay } from 'redux-saga/effects';
import { Types } from '../types';
import { requestIntermitentes } from '~/services/request';

import {
  relatorioSapResponse
} from './actions';

export function* relatorioSap({ payload }) {
  let params = {
    url: '/Relatorios/relatorio-sap-v2',
    method: 'POST',
    body: payload
  };
  let fileName = 'export_Intermitentes_' + payload.busqueDe + '.csv';
  try {
    const response = yield call(requestIntermitentes, params);
    // fileDownload(response.data, 'texteResponse.csv');
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName); //or any other extension
    document.body.appendChild(link);
    link.click();

    yield put(relatorioSapResponse(response.data));
  } catch (error) {
    yield put(relatorioSapResponse({ success: false }));
  }
}


export default all([
  takeLatest(Types.RELATORIO_SAP_REQUEST, relatorioSap),
]);