import { Types } from '../types';

export function carregarTotaisLancamentosRequest(usuarioId) {
	return {
		type: Types.CARREGAR_TOTAIS_LANCAMENTOS_REQUEST,
		payload: { usuarioId },
	};
}

export function carregarTotaisLancamentosResponse(data) {
	return {
		type: Types.CARREGAR_TOTAIS_LANCAMENTOS_RESPONSE,
		payload: { data },
	};
}
