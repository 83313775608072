import { takeLatest, call, put, all } from 'redux-saga/effects';

import { Types } from '../../types';
import { requestFiltrarHolerites } from '~/services/request';
import { filtrarCentroCustosResponse } from './actions';

export function* filtrarHoleritesPorCentroCustos({ payload }) {
  const params = {
    url: '/centros-custos',
    method: 'GET',
    body: payload,
  };

  try {
    const response = yield call(requestFiltrarHolerites, params);
    yield put(filtrarCentroCustosResponse(response.data));
  } catch (error) {
    yield put(filtrarCentroCustosResponse({ success: false }));
  }
}

export default all([
  takeLatest(
    Types.FILTRAR_CENTRO_CUSTOS_REQUEST,
    filtrarHoleritesPorCentroCustos,
  ),
]);
