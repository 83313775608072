/* eslint-disable import/no-cycle */
import { takeLatest, call, put, all } from 'redux-saga/effects';
import { requestSobreaviso } from '~/services/request';
import { Types } from '../types';

import {
  lancamentoSobreavisoResponse,
  lancamentoSobreavisoResponsePut,
  lancamentoSobreavisoResponseGet,
  lancamentoSobreavisoCountResponse,
  lancamentoSobreavisoGetByIDResponse,
  lancamentoSobreavisoHistoricoResponse,
  lancamentoSobreavisoAcoesResponse,
  lancamentoSobreavisoRelatorioResponse,
  lancamentoSobreavisoRelatorioCsvResponse,
} from './actions';

export function* lancamentoSobreaviso({ payload, method }) {
  const params = {
    url: '/lancamento',
    method,
    body: payload,
  };

  try {
    const response = yield call(requestSobreaviso, params);

    yield put(lancamentoSobreavisoResponse(response.data));
  } catch (error) {
    yield put(lancamentoSobreavisoResponse({ success: false }));
  }
}

export function* lancamentoSobreavisoRelatorio({ payload, method }) {
  const params = {
    url: '/lancamento/report',
    method,
    queryParams: payload,
  };

  try {
    const response = yield call(requestSobreaviso, params);
    yield put(lancamentoSobreavisoRelatorioResponse(response.data));
  } catch (error) {
    yield put(lancamentoSobreavisoRelatorioResponse({ success: false }));
  }
}

export function* lancamentoSobreavisoRelatorioCsv({ payload, method }) {
  const params = {
    url: '/lancamento/report-csv',
    method,
    queryParams: payload,
  };

  try {
    const response = yield call(requestSobreaviso, params);
    yield put(lancamentoSobreavisoRelatorioCsvResponse(response.data));
  } catch (error) {
    yield put(lancamentoSobreavisoRelatorioCsvResponse({ success: false }));
  }
}

export function* lancamentoSobreavisoCount({ visao }) {
  const params = {
    url: '/lancamento/count',
    method: 'GET',
    queryParams: { visao },
  };

  try {
    const response = yield call(requestSobreaviso, params);

    yield put(lancamentoSobreavisoCountResponse(response.data));
  } catch (error) {
    yield put(lancamentoSobreavisoCountResponse({ success: false }));
  }
}

export function* lancamentoSobreavisoPut({ payload, method }) {
  const params = {
    url: `/lancamento/${payload.id}`,
    method,
    body: payload,
  };

  try {
    const response = yield call(requestSobreaviso, params);

    yield put(lancamentoSobreavisoResponsePut(response.data));
  } catch (error) {
    yield put(lancamentoSobreavisoResponsePut({ success: false }));
  }
}

export function* lancamentoSobreavisoGet({ payload }) {
  const params = {
    url: '/lancamento',
    method: 'GET',
    queryParams: payload,
    body: {},
  };

  try {
    const response = yield call(requestSobreaviso, params);

    yield put(lancamentoSobreavisoResponseGet(response.data));
  } catch (error) {
    yield put(lancamentoSobreavisoResponseGet({ success: false }));
  }
}

export function* lancamentoSobreavisoGetByID({ payload }) {
  const params = {
    url: `/lancamento/${payload}`,
    method: 'GET',
  };

  try {
    const response = yield call(requestSobreaviso, params);
    yield put(lancamentoSobreavisoGetByIDResponse(response.data));
  } catch (error) {
    yield put(lancamentoSobreavisoGetByIDResponse({ success: false }));
  }
}

export function* lancamentoSobreavisoHistorico({ id }) {
  const params = {
    url: `/lancamento/historico/${id}`,
    method: 'GET',
  };

  try {
    const response = yield call(requestSobreaviso, params);

    yield put(lancamentoSobreavisoHistoricoResponse(response.data));
  } catch (error) {
    yield put(lancamentoSobreavisoHistoricoResponse({ success: false }));
  }
}

export function* lancamentoSobreavisoAcoes({ payload }) {
  const params = {
    url: `/lancamento/${payload.acao}/${payload.id}`,
    method: 'PUT',
    body: {
      lancamentoId: payload.id,
    },
  };

  try {
    const response = yield call(requestSobreaviso, params);
    yield put(lancamentoSobreavisoAcoesResponse(response.data));
  } catch (error) {
    yield put(lancamentoSobreavisoAcoesResponse({ success: false }));
  }
}

export default all([
  takeLatest(Types.LANCAMENTO_SOBREAVISO_REQUEST, lancamentoSobreaviso),
  takeLatest(Types.LANCAMENTO_SOBREAVISO_REQUEST_PUT, lancamentoSobreavisoPut),
  takeLatest(Types.LANCAMENTO_SOBREAVISO_REQUEST_GET, lancamentoSobreavisoGet),
  takeLatest(
    Types.LANCAMENTO_SOBREAVISO_COUNT_REQUEST,
    lancamentoSobreavisoCount,
  ),
  takeLatest(
    Types.LANCAMENTO_SOBREAVISO_BY_ID_REQUEST_GET,
    lancamentoSobreavisoGetByID,
  ),
  takeLatest(
    Types.LANCAMENTO_SOBREAVISO_HISTORICO_REQUEST,
    lancamentoSobreavisoHistorico,
  ),
  takeLatest(
    Types.LANCAMENTO_SOBREAVISO_ACAO_REQUEST,
    lancamentoSobreavisoAcoes,
  ),
  takeLatest(
    Types.LANCAMENTO_SOBREAVISO_RELATORIO_REQUEST,
    lancamentoSobreavisoRelatorio,
  ),
  takeLatest(
    Types.LANCAMENTO_SOBREAVISO_RELATORIO_CSV_REQUEST,
    lancamentoSobreavisoRelatorioCsv,
  ),
]);
