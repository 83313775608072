import { takeLatest, call, put, all } from 'redux-saga/effects';

import { Types } from '../../types';
import { requestFiltrarHolerites } from '~/services/request';
import { filtrarSindicatosResponse } from './actions';

export function* filtrarHoleritesPorSindicatos({ payload }) {
  const params = {
    url: '/sindicatos',
    method: 'GET',
    body: payload,
  };

  try {
    const response = yield call(requestFiltrarHolerites, params);
    yield put(filtrarSindicatosResponse(response.data));
  } catch (error) {
    yield put(filtrarSindicatosResponse({ success: false }));
  }
}

export default all([
  takeLatest(Types.FILTRAR_SINDICATOS_REQUEST, filtrarHoleritesPorSindicatos),
]);
