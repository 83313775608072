import styled from 'styled-components';

export const Conteiner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  header {
    align-items: flex-end;
    border-bottom: 1px solid var(--cinzaDisabledLight);
    width: 112%;
    padding-left: 15px;
    padding-bottom: 15px;
    h1 {
      color: var(--primary);
      font-size: 15px;
    }
  }
  main {
    width: 100%;
    margin-top: 20%;
    label {
      color: var(--secondary);
      font-size: 15px;
    }
    p {
      margin-top: 20px;
    }
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        + button {
          margin-left: 20px;
        }
      }
    }
  }
`;
