import React, { useState, useCallback } from 'react';

import { useAcao } from '~/hooks/escalas';

import MessageModalList from '~/components/_layouts/modals/MessageModalList';
import ButtonFolhaCerta from '~/components/_layouts/forms/ButtonFolhaCerta';

import {
  JourneySpan,
  LabelCompensaSemana,
  DivBranca,
  DivTransparente,
} from './styles';

const FolgaCompensarDuranteSemana = ({ dados, onClose, callback }) => {
  const { FolgaCompensarDuranteSemana } = useAcao();

  const [message, setMessage] = useState('');
  const [openMessage, setOpenMessage] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState(false);

  const showMessageView = (data) => {
    setMessage(data.message);
    setOpenMessage(true);
    setMessageSuccess(data.success);
  };

  const buttonConfirm = () => {
    setOpenMessage(false);
    onClose();
    callback();
  };

  const sendRequest = useCallback(async () => {
    const params = {
      usuarioId: dados.usuario.Id || dados.usuario.usuarioId,
      data: dados.data,
    };

    try {
      const response = await FolgaCompensarDuranteSemana(params);
      showMessageView(response);
    } catch (err) {
      showMessageView({
        success: false,
        message: err.response.data.message
          ? err.response.data.message
          : 'Erro ao compensar folga',
      });
    }
  }, []);

  return (
    <>
      <DivBranca>
        <LabelCompensaSemana>
          Jornada de trabalho <JourneySpan>{}</JourneySpan>
          será compensada durante a semana
        </LabelCompensaSemana>
      </DivBranca>
      <DivTransparente>
        <ButtonFolhaCerta
          buttonName="Cancelar"
          branco
          onClickButton={onClose}
          small
        />
        <ButtonFolhaCerta
          buttonName="Confirmar"
          amarelo
          showIcon
          onClickButton={() => {
            if (dados && dados.alteracaoEscala) {
              showMessageView({
                message:
                  'Já existem alterações de escala para a data em questão. Para continuar, remova a ação existente.',
                success: false,
              });
            } else {
              sendRequest();
            }
          }}
          small
        />
      </DivTransparente>
      <MessageModalList
        message={message}
        open={openMessage}
        messageSuccess={messageSuccess}
        onClick={() => {
          buttonConfirm();
        }}
      />
    </>
  );
};

FolgaCompensarDuranteSemana.propTypes = {};

export default FolgaCompensarDuranteSemana;
