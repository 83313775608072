import { takeLatest, call, put, all } from 'redux-saga/effects';
import { Types } from '../../types';
import { requestAlocacaoRegra } from '~/services/request';

import {
  getRegraPontoResponse,
  postRegraPontoResponse,
  putRegraPontoResponse,
  getRegraPontoDefaultResponse,
  regrasByEmpresaResponse,
} from './actions';

export function* regraPonto({ body, method, id, queryParams }) {
  const params = {
    url: id ? `/regraponto/${id}` : '/regraponto',
    method,
    body,
    queryParams,
  };

  switch (method) {
    case 'GET':
      try {
        const response = yield call(requestAlocacaoRegra, params);
        yield put(getRegraPontoResponse(response.data));
      } catch (error) {
        yield put(getRegraPontoResponse({ success: false }));
      }
      break;
    case 'POST':
      try {
        const response = yield call(requestAlocacaoRegra, params);
        yield put(postRegraPontoResponse(response.data));
      } catch (error) {
        yield put(postRegraPontoResponse({ success: false }));
      }
      break;
    case 'PUT':
      try {
        const response = yield call(requestAlocacaoRegra, params);
        yield put(putRegraPontoResponse(response.data));
      } catch (error) {
        yield put(putRegraPontoResponse({ success: false }));
      }
      break;

    default:
      break;
  }
}

export function* getRegraPontoDefault({ method, queryParams }) {
  const params = {
    url: '/regraponto/default',
    method,
    queryParams,
  };

  try {
    const response = yield call(requestAlocacaoRegra, params);
    yield put(getRegraPontoDefaultResponse(response.data));
  } catch (error) {
    yield put(getRegraPontoDefaultResponse({ success: false }));
  }
}

export function* regraPontoByEmpresaId({ queryParams }) {
  const params = {
    url: '/regraponto/by-empresa-id',
    method: 'GET',
    queryParams,
  };

  try {
    const response = yield call(requestAlocacaoRegra, params);
    yield put(regrasByEmpresaResponse(response.data));
  } catch (error) {
    yield put(regrasByEmpresaResponse({ success: false }));
  }
}

export default all([
  takeLatest(Types.REGRA_PONTO_REQUEST, regraPonto),
  takeLatest(Types.REGRA_PONTO_BY_EMPRESA_REQUEST, regraPontoByEmpresaId),
  takeLatest(Types.GET_REGRA_DEFAULT_PONTO_REQUEST, getRegraPontoDefault),
]);
