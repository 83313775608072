import { Types } from '../../types';

export function grupoTodosRequest(params) {
  return {
    type: Types.GRUPO_TODOS_REQUEST,
    payload: params,
  };
}

export function grupoTodosSuccess(data) {
  return {
    type: Types.GRUPO_TODOS_SUCCESS,
    payload: { data },
  };
}

export function grupoTodosFailure(error) {
  return {
    type: Types.GRUPO_TODOS_FAILURE,
    error: error,
  };
}
