import produce from 'immer';

import { Types } from '../types';

// Reducers
const INITIAL_STATE = {
  payload: null,
  loading: false,

  payloadById: null,
  loadingById: false,

  ativas: null,
  arquivadas: null,
  utilizadas: null,
}

export default function regrasConvocacao(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.REGRAS_CONVOCACAO_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.REGRAS_CONVOCACAO_SUCCESS: {
        draft.payload = action.payload;
        draft.loading = false;
        break;
      }
      case Types.REGRAS_CONVOCACAO_FAILURE: {
        draft.payload = action.payload;
        draft.loading = false;
        break;
      }

      case Types.REGRAS_CONVOCACAO_GET_REQUEST: {
        draft.loadingById = true;
        break;
      }
      case Types.REGRAS_CONVOCACAO_GET_SUCCESS: {
        draft.payloadById = action.payload;
        draft.loadingById = false;
        break;
      }
      case Types.REGRAS_CONVOCACAO_GET_FAILURE: {
        draft.payloadById = null;
        draft.loadingById = false;
        break;
      }

      case Types.REGRAS_CONVOCACAO_PUT_REQUEST:{
        draft.loading = true;
        break;
      }

      case Types.REGRAS_CONVOCACAO_PUT_RESPONSE:{
        draft.payload = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.REGRAS_ATIVAS_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.REGRAS_ATIVAS_SUCCESS: {
        draft.ativas = action.payload.data;
        draft.loading = false;
        break;
      }
      case Types.REGRAS_ATIVAS_FAILURE: {
        draft.ativas = null;
        draft.loading = false;
        break;
      }

      case Types.REGRAS_ARQUIVADAS_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.REGRAS_ARQUIVADAS_SUCCESS: {
        draft.arquivadas = action.payload.data;
        draft.loading = false;
        break;
      }
      case Types.REGRAS_ARQUIVADAS_FAILURE: {
        draft.arquivadas = null;
        draft.loading = false;
        break;
      }

      case Types.REGRAS_UTILIZADAS_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.REGRAS_UTILIZADAS_SUCCESS: {
        draft.utilizadas = action.payload.data;
        draft.loading = false;
        break;
      }
      case Types.REGRAS_UTILIZADAS_FAILURE: {
        draft.utilizadas = null;
        draft.loading = false;
        break;
      }

      case Types.REGRAS_CLEAR_DATA: {
        draft.payload = null;
        draft.loading = false;
        break;
      }

      case Types.LOGOUT: {
        draft.payload = null;
        draft.ativas = null;
        draft.arquivadas = null;
        draft.utilizadas = null;
        draft.loading = false;
        draft.payloadById = null;
        draft.loadingById = false;
        break;
      }
      default:
    }
  });
}