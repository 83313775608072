import { takeLatest, call, put, all, delay } from 'redux-saga/effects';
import { Types } from '../types';
import { toast } from 'react-toastify';
import { requestBaseV3 } from '~/services/request';

import {
  carregarAvisoAusenciaLancamentosSuccess, carregarAvisoAusenciaLancamentosFailure,
  carregarAusenciaLancamentoPorIdSuccess, carregarAusenciaLancamentoPorIdFailure
} from './actions';

export function* carregarAvisoAusenciaLancamentos() {
  let params = {
    url: '/CarregarAvisoAusenciaLancamentos',
    method: 'GET',
  };

  try {
    const response = yield call(requestBaseV3, params);

    if (response.data.Success) {
      const data = response.data;

      yield put(carregarAvisoAusenciaLancamentosSuccess(data));
      yield delay(2000);
    } else {
      toast.error(response.data.Error);
      yield put(carregarAvisoAusenciaLancamentosFailure());
    }
  } catch (error) {
    toast.error("Falha na autenticação");
    yield put(carregarAvisoAusenciaLancamentosFailure());
  }
}

export function* carregarAvisoAusenciaLancamento({ payload }) {

  let params = {
    url: '/CarregarAvisoAusenciaLancamento',
    method: 'GET',
    queryParams: payload
  };

  try {
    const response = yield call(requestBaseV3, params);

    if (response.data.Success) {
      const data = response.data;

      yield put(carregarAusenciaLancamentoPorIdSuccess(data));
      yield delay(2000);
    } else {
      yield put(carregarAusenciaLancamentoPorIdFailure());
    }
  } catch (error) {
    yield put(carregarAusenciaLancamentoPorIdFailure({ success: false, message: 'Erro ao carregar ausência' }));
  }
}

export default all([
  takeLatest(Types.CARREGAR_AVISO_AUSENCIA_LANCAMENTOS_REQUEST, carregarAvisoAusenciaLancamentos),
  takeLatest(Types.CARREGAR_AUSENCIA_POR_ID_REQUEST, carregarAvisoAusenciaLancamento)
]);