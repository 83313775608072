import { Types } from '../types';

export function alocacaoSobreavisoPost(params) {
  return {
    type: Types.ALOCACAO_SOBREAVISO_REQUEST,
    method: 'POST',
    payload: params,
  };
}

export function alocacaoSobreavisoPut(params) {
  return {
    type: Types.ALOCACAO_SOBREAVISO_REQUEST_PUT,
    method: 'PUT',
    payload: params,
  };
}

export function alocacaoSobreavisoGet(params) {
  return {
    type: Types.ALOCACAO_SOBREAVISO_REQUEST_GET,
    payload: params,
  };
}

export function alocacaoSobreavisoGetByID(id) {
  return {
    type: Types.ALOCACAO_SOBREAVISO_BY_ID_REQUEST_GET,
    payload: id,
  };
}

export function alocacaoSobreavisoHistorico(id) {
  return {
    type: Types.ALOCACAO_SOBREAVISO_HISTORICO_REQUEST,
    id,
  };
}

export function alocacaoSobreavisoHistoricoResponse(data) {
  return {
    type: Types.ALOCACAO_SOBREAVISO_HISTORICO_RESPONSE,
    payload: { data },
  };
}

export function alocacaoSobreavisoResponse(data) {
  return {
    type: Types.ALOCACAO_SOBREAVISO_RESPONSE,
    payload: { data },
  };
}

export function alocacaoSobreavisoResponsePut(data) {
  return {
    type: Types.ALOCACAO_SOBREAVISO_RESPONSE_PUT,
    payload: { data },
  };
}

export function alocacaoSobreavisoResponseGet(data) {
  return {
    type: Types.ALOCACAO_SOBREAVISO_RESPONSE_GET,
    payload: { data },
  };
}

export function alocacaoSobreavisoGetByIDResponse(data) {
  return {
    type: Types.ALOCACAO_SOBREAVISO_BY_ID_RESPONSE_GET,
    payload: { data },
  };
}

export function alocacaoSobreavisoClear() {
  return {
    type: Types.ALOCACAO_SOBREAVISO_CLEAR_DATA,
  };
}
