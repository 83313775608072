import { takeLatest, call, put, all, delay } from 'redux-saga/effects';

import { Types } from '../types';

import { toast } from 'react-toastify';

import { requestIntermitentes } from '~/services/request';

import {
  contratantesSuccess, contratantesFailure,
  contratantesAtivasSuccess, contratantesAtivasFailure,
  contratantesArquivadasSuccess, contratantesArquivadasFailure,
  contratantesTodosSuccess, contratantesTodosFailure,
  contratantesDisponiveisSuccess, contratantesDisponiveisFailure,
  contratantesArquivarResponse,
  contratantesDesarquivarResponse,
  contratantesEditarResponse
} from './actions';

export function* contratantes({ payload }) {
  let params = {
    url: '/Contratantes',
    method: 'POST',
    body: payload
  };

  try {
    const response = yield call(requestIntermitentes, params);
    if (response.data.success) {
      const data = response.data;

      yield put(contratantesSuccess(data));
      yield delay(2000);
    } else {
      // toast.error(response.data.Error);
      yield put(contratantesFailure(response.data));
    }
  } catch (error) {
    // toast.error("Falha na autenticação");
    yield put(contratantesFailure({ success: false }));
  }
}

export function* contratantesEditar({ payload }) {
  let params = {
    url: '/Contratantes',
    method: 'PUT',
    body: payload
  };

  try {
    const response = yield call(requestIntermitentes, params);
    yield put(contratantesEditarResponse(response.data));
  } catch (error) {
    // toast.error("Falha na autenticação");
    yield put(contratantesEditarResponse({ success: false }));
  }
}

export function* contratantesAtivas() {
  let params = {
    url: '/Contratantes/por-status',
    method: 'GET',
    body: null,
    queryParams: { ativas: true }
  };

  try {
    const response = yield call(requestIntermitentes, params);
    if (response.data.success) {
      const data = response.data.data;

      let aux = [];
      data.map(element => {
        aux = [];
        element.contratantesDepartamentos && element.contratantesDepartamentos.map(item => {
          aux.push(item.departamento_Id);
        });
        element.contratantesDepartamentos = aux;
      })

      yield put(contratantesAtivasSuccess(data));
      yield delay(2000);
    } else {
      yield put(contratantesAtivasFailure());
    }
  } catch (error) {
    yield put(contratantesAtivasFailure());
  }
}

export function* contratantesArquivadas() {
  let params = {
    url: '/Contratantes/por-status',
    method: 'GET',
    body: null,
    queryParams: { arquivadas: true }
  };

  try {
    const response = yield call(requestIntermitentes, params);
    if (response.data.success) {
      const data = response.data.data;

      let aux = [];
      data.map(element => {
        aux = [];
        element.contratantesDepartamentos && element.contratantesDepartamentos.map(item => {
          aux.push(item.departamento_Id);
        });
        element.contratantesDepartamentos = aux;
      })
      
      yield put(contratantesArquivadasSuccess(data));
      yield delay(2000);
    } else {
      yield put(contratantesArquivadasFailure());
    }
  } catch (error) {
    yield put(contratantesArquivadasFailure());
  }
}

export function* contratantesTodos() {
  let params = {
    url: '/Contratantes/por-status',
    method: 'GET',
    body: null,
    queryParams: { todos: true }
  };

  try {
    const response = yield call(requestIntermitentes, params);
    if (response.data.success) {
      const data = response.data.data;

      let aux = [];
      data.map(element => {
        aux = [];
        element.contratantesDepartamentos && element.contratantesDepartamentos.map(item => {
          aux.push(item.departamento_Id);
        });
        element.contratantesDepartamentos = aux;
      })

      yield put(contratantesTodosSuccess(data));
      yield delay(2000);
    } else {
      yield put(contratantesTodosFailure());
    }
  } catch (error) {
    yield put(contratantesTodosFailure());
  }
}

export function* contratantesDisponiveis() {
  let params = {
    url: '/Contratantes/pesquisar-usuarios-virar-contratante',
    method: 'GET',
    body: null,
    queryParams: null
  };

  try {
    const response = yield call(requestIntermitentes, params);
    if (response.data.success) {
      const data = response.data.data;

      yield put(contratantesDisponiveisSuccess(data));
      yield delay(2000);
    } else {
      yield put(contratantesDisponiveisFailure());
    }
  } catch (error) {
    yield put(contratantesDisponiveisFailure());
  }
}

export function* contratantesArquivar({ payload }) {
  let params = {
    url: '/Contratantes/arquivar/' + payload,
    method: 'GET',
    body: null,
    queryParams: null
  };

  try {
    const response = yield call(requestIntermitentes, params);
    yield put(contratantesArquivarResponse(response.data));
  } catch (error) {
    yield put(contratantesArquivarResponse({ success: false }));
  }
}


export function* contratantesDesarquivar({ payload }) {
  let params = {
    url: '/Contratantes/desarquivar/' + payload,
    method: 'GET',
    body: null,
    queryParams: null
  };

  try {
    const response = yield call(requestIntermitentes, params);
    yield put(contratantesDesarquivarResponse(response.data));
  } catch (error) {
    yield put(contratantesDesarquivarResponse({ success: false }));
  }
}



export default all([
  takeLatest(Types.CONTRATANTES_REQUEST, contratantes),
  takeLatest(Types.CONTRATANTES_ATIVAS_REQUEST, contratantesAtivas),
  takeLatest(Types.CONTRATANTES_ARQUIVADAS_REQUEST, contratantesArquivadas),
  takeLatest(Types.CONTRATANTES_TODOS_REQUEST, contratantesTodos),
  takeLatest(Types.CONTRATANTES_DISPONIVEIS_REQUEST, contratantesDisponiveis),
  takeLatest(Types.CONTRATANTES_ARQUIVAR_REQUEST, contratantesArquivar),
  takeLatest(Types.CONTRATANTES_DESARQUIVAR_REQUEST, contratantesDesarquivar),
  takeLatest(Types.CONTRATANTES_EDITAR_REQUEST, contratantesEditar),
]);