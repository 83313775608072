import { takeLatest, call, put, all } from 'redux-saga/effects';
import { Types } from '../types';
import { requestAlocacaoRegra } from '~/services/request';

export function* configDeParaRequest({
  url,
  queryParams,
  method,
  body,
  onSuccess,
  onFailure,
  callback,
}) {
  const params = {
    url,
    method,
    body,
    queryParams,
  };

  try {
    const response = yield call(requestAlocacaoRegra, params);
    yield put(onSuccess(response.data, callback));
  } catch (error) {
    yield put(
      onFailure(
        { success: false, message: 'Erro ao realizar request' },
        callback,
      ),
    );
  }
}

export default all([
  takeLatest(Types.CONFIG_VERBAS_REQUEST, configDeParaRequest),
  takeLatest(Types.CADASTRO_VERBAS_REQUEST, configDeParaRequest),
  takeLatest(Types.CONFIG_VERBAS_DETALHES_REQUEST, configDeParaRequest),
  takeLatest(Types.GET_VERBAS, configDeParaRequest),
  takeLatest(Types.PUT_CONFIG_REQUEST, configDeParaRequest),
  takeLatest(Types.PUT_CONFIG_PORTAL_REQUEST, configDeParaRequest),
  takeLatest(Types.ALOCACAO_CONFIG_VERBAS_REGRA_REQUEST, configDeParaRequest),
]);
