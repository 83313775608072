import React from 'react';
import { useTransition } from 'react-spring';

import { Conteiner, Background } from './styles';
import Modal from './Modal';
import AceitarOuNegar from './AceitarOuNegar';

export default function ConteinerDetalheDoDia({ dados }) {
  const dadosComTransicao = useTransition(dados, (dados) => dados.id, {
    from: { top: '-120%', opacity: '0' },
    enter: { top: '0', opacity: '1' },
    leave: { top: '-120%', opacity: '0' },
  });
  const backgroundComTransicao = useTransition(dados, (dados) => dados.id, {
    from: { opacity: '0' },
    enter: { opacity: '1' },
    leave: { opacity: '0' },
  });
  if (dados.length === 0) return <></>;
  return (
    <Conteiner>
      {backgroundComTransicao.map(({ item, key, props }) => (
        <Background key={key} style={props}>
          {dadosComTransicao.map(({ item, key, props }) => (
            <Modal key={key} dados={item} style={props} />
          ))}
        </Background>
      ))}
    </Conteiner>
  );
}

export { AceitarOuNegar };
