import { takeLatest, call, put, all } from 'redux-saga/effects';
import { Types } from '../types';
import { requestVerba } from '~/services/request';

export function* reprocessamento({
  url,
  method,
  body,
  queryParams,
  onSuccess,
  onFailure,
}) {
  const params = {
    url,
    method,
    queryParams,
    body,
  };
  try {
    const response = yield call(requestVerba, params);
    yield put(onSuccess(response.data));
  } catch (error) {
    yield put(
      onFailure({
        success: false,
        message: 'Erro ao para realizar reprocessamento',
      }),
    );
  }
}

export function* reprocessamentoDetalhes({
  url,
  method,
  body,
  queryParams,
  onSuccess,
  onFailure,
}) {
  const params = {
    url,
    method,
    queryParams,
    body,
  };

  try {
    const response = yield call(requestVerba, params);
    yield put(onSuccess(response.data));
  } catch (error) {
    yield put(
      onFailure({
        success: false,
        message: 'Erro ao para realizar reprocessamento',
      }),
    );
  }
}

export default all([
  takeLatest(Types.PEDIDO_REPROCESSAMENTO_REQUEST, reprocessamento),
  takeLatest(
    Types.PEDIDO_REPROCESSAMENTO_DETALHE_REQUEST,
    reprocessamentoDetalhes,
  ),
  takeLatest(Types.PEDIDO_REPROCESSAMENTO_LOG_REQUEST, reprocessamentoDetalhes),
]);
