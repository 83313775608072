import { takeLatest, call, put, all } from 'redux-saga/effects';
import { Types } from '../types';
import { requestExportacao, requestBaseApiV1 } from '~/services/request';

export function* exportacaoLayoutRequest({
  url,
  queryParams,
  body,
  method,
  responseAction,
  callback = null,
}) {
  const params = {
    url,
    method,
    queryParams,
    body,
  };

  try {
    const response = yield call(requestExportacao, params);
    yield put(responseAction(response.data, callback));
  } catch (error) {
    yield put(
      responseAction(
        {
          success: false,
          message: 'Erro ao buscar nos dados',
        },
        callback,
      ),
    );
  }
}

export function* exportacaoLayoutRequestById({
  url,
  queryParams,
  body,
  method,
  responseAction,
  callback = null,
}) {
  const params = {
    url,
    method,
    queryParams,
    body,
  };

  try {
    const response = yield call(requestExportacao, params);
    yield put(responseAction(response.data, callback));
  } catch (error) {
    yield put(
      responseAction(
        {
          success: false,
          message: 'Erro ao buscar nos dados',
        },
        callback,
      ),
    );
  }
}

export function* getTipoAssinaturaEletronica({ url, responseAction, method }) {
  const params = {
    url,
    method,
  };

  try {
    const response = yield call(requestBaseApiV1, params);
    yield put(responseAction(response.data));
  } catch (error) {
    yield put(
      responseAction({
        success: false,
        message: 'Erro ao buscar tipos de assinaturas eletrônicas',
      }),
    );
  }
}

export default all([
  takeLatest(Types.REQUEST_EXPORTACAO_VERBAS, exportacaoLayoutRequest),
  takeLatest(
    Types.REQUEST_BY_ID_EXPORTACAO_VERBAS,
    exportacaoLayoutRequestById,
  ),
  takeLatest(
    Types.REQUEST_TIPO_ASSINATURA_ELETRONICA,
    getTipoAssinaturaEletronica,
  ),
]);
