import { takeLatest, call, put, all } from 'redux-saga/effects';
import { Types } from '../../../types';
// eslint-disable-next-line import/no-cycle
import { requestMotorNovoBancoHoras } from '~/services/request';

import {
  getBancoHorasConfiguracaoFaixaResponse,
  postBancoHorasConfiguracaoFaixaResponse,
  putBancoHorasConfiguracaoFaixaResponse,
} from './actions';

export function* bancoHorasConfiguracaoFaixa({ payload, method, id }) {
  const params = {
    url: id
      ? `/api/v1/bancoHora/configuracao/faixa/${id}`
      : '/api/v1/bancoHora/configuracao/faixa',
    method,
    body: payload,
  };

  switch (method) {
    case 'GET':
      try {
        const response = yield call(requestMotorNovoBancoHoras, params);
        yield put(getBancoHorasConfiguracaoFaixaResponse(response.data));
      } catch (error) {
        yield put(getBancoHorasConfiguracaoFaixaResponse({ success: false }));
      }
      break;
    case 'POST':
      try {
        const response = yield call(requestMotorNovoBancoHoras, params);

        const aux = {
          id: response.headers.location,
          data: response.data,
        };
        yield put(postBancoHorasConfiguracaoFaixaResponse(aux));
      } catch (error) {
        yield put(postBancoHorasConfiguracaoFaixaResponse({ success: false }));
      }
      break;
    case 'PUT':
      try {
        const response = yield call(requestMotorNovoBancoHoras, params);
        yield put(putBancoHorasConfiguracaoFaixaResponse(response.data));
        yield put(postBancoHorasConfiguracaoFaixaResponse({ id }));
      } catch (error) {
        yield put(putBancoHorasConfiguracaoFaixaResponse({ success: false }));
      }
      break;

    default:
      break;
  }
}

export default all([
  takeLatest(
    Types.BANCO_HORAS_CONFIGURACAO_FAIXA_REQUEST,
    bancoHorasConfiguracaoFaixa,
  ),
]);
