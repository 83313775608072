import React from 'react';
import { useCalendario } from './escalas/calendario';
import { useTag } from './escalas/tag';
import { ProvedorModal, useModal } from './modal';

import ProvedorEscalas from './escalas';

export default function Provedor({ children }) {
  return (
    <ProvedorModal>
      <ProvedorEscalas children={children} />
    </ProvedorModal>
  );
}

export { useCalendario, useTag, useModal };
