import React, { useState, useCallback, useEffect } from 'react';

import { requestEscalas } from '~/services/request';
import ButtonFolhaCerta from '~/components/_layouts/forms/ButtonFolhaCerta';
import { useAcao } from '~/hooks/escalas';

import MessageView from '~/components/_layouts/modals/MessageView';

import { getHourAndMinute } from '~/utils/dateFormat';

import { DivBranca } from './styles';

const getStatusColor = (status) => {
  switch (status) {
    case 'ParaAprovar':
      return 'var(--warning)';
    case 'Aprovada':
      return 'var(--success)';
    case 'Reprovada':
      return 'var(--danger)';
    case 'Removida':
      return 'var(--gray)';
    case 'ParaRemover':
      return 'var(--light-purple)';

    default:
      return null;
  }
};

const DescAcoes = {
  MudarParaFolga: 'Mudar escala de Trabalho para Folga',
  MudarParaTrabalho: 'Mudar escala de Folga para Trabalho',
  AlterarEntrada: 'Horário de entrada alterado para às',
  MoverFolgaSemanal: 'Mudar Folga semanal',
  FolgaCompensarDuranteSemana: 'Compensar folga durante a semana ',
  FolgaCompensarBancoHoras: 'Compensar folga no banco de horas',
  AlterarEscala: 'Alterar escala',
};
export default function VisualizarAcao({ dados, onClose, callback }) {
  const [dadosAcao, setDadosAcao] = useState(null);
  const [message, setMessage] = useState('');
  const [openMessage, setOpenMessage] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState(false);
  const { usuario } = useAcao();

  useEffect(() => {
    const asyncFunction = async () => {
      const res = await requestEscalas({
        url: `/acao/${dados.IdAcaoVisualizar}`,
      });
      setDadosAcao(res.data.data);
    };
    asyncFunction();
  }, [dados.IdAcaoVisualizar]);

  const showMessageView = (data) => {
    setMessage(data.message);
    setOpenMessage(true);
    setMessageSuccess(data.success);
    setTimeout(() => {
      setOpenMessage(false);
      onClose();
    }, 2000);
  };

  const alteraStatus = async () => {
    const { data } = await requestEscalas({
      url: `/acao/status`,
      body: {
        id: dados.IdAcaoVisualizar,
        status: 'Removida',
      },
      method: 'PUT',
    });
    showMessageView(data);

    setTimeout(() => {
      if (data.success) callback();
    }, 2000);
  };

  if (!dadosAcao) return <></>;
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          color: 'white',
          backgroundColor: getStatusColor(dadosAcao.status),
        }}
        className="pt-2 pb-2"
      >
        {dadosAcao.status}
      </div>
      <DivBranca>
        <div>
          {DescAcoes[dadosAcao.tipoAcao]}{' '}
          {dadosAcao.tipoAcao === 'AlterarEntrada'
            ? getHourAndMinute(dadosAcao.novoHorarioEntrada)
            : ''}
        </div>
        {dadosAcao.status === 'Aprovada' && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ButtonFolhaCerta
              className="ml-2 mt-5"
              buttonName="REMOVER"
              branco
              onClickButton={() => alteraStatus()}
              small
            />
          </div>
        )}
      </DivBranca>

      <MessageView
        message={message}
        open={openMessage}
        onClick={() => {
          return null;
        }}
        success={messageSuccess}
      />
    </>
  );
}
