import produce from 'immer';

import { Types } from '../types';

// Reducers
const INITIAL_STATE = {
  payloadFerias: null,
  payloadDayOFF: null,
  payloadRecesso: null,
  payloadLancamentos: null,
  payloadTotalPontos: null,

  dayOffLancamento: null,
  recessoLancamento: null,
  feriasLancamento: null,

  loading: false,
  loadingFerias: false,
  loadingDayOFF: false,
  loadingRecesso: false,
  loadingLancamentos: false,
  loadingTotalPontos: false,
}

export default function carregarLancamentos(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.CARREGAR_FERIAS_LANCAMENTOS_REQUEST: {
        draft.loadingFerias = true;
        break;
      }
      case Types.CARREGAR_FERIAS_LANCAMENTOS_SUCCESS: {
        draft.payloadFerias = action.payload.data;
        draft.loadingFerias = false;
        break;
      }
      case Types.CARREGAR_FERIAS_LANCAMENTOS_FAILURE: {
        draft.payloadFerias = null;
        draft.loadingFerias = false;
        break;
      }
      case Types.SALVAR_FERIAS_LANCAMENTO_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.SALVAR_FERIAS_LANCAMENTO_RESPONSE: {
        draft.payload = action.payload.data;
        draft.loading = false;
        break;
      }
      case Types.CARREGAR_DAYOFF_LANCAMENTOS_REQUEST: {
        draft.loadingDayOFF = true;
        break;
      }
      case Types.CARREGAR_DAYOFF_LANCAMENTOS_SUCCESS: {
        draft.payloadDayOFF = action.payload.data;
        draft.loadingDayOFF = false;
        break;
      }
      case Types.CARREGAR_DAYOFF_LANCAMENTOS_FAILURE: {
        draft.payloadDayOFF = null;
        draft.loadingDayOFF = false;
        break;
      }
      case Types.SALVAR_DAYOFF_LANCAMENTO_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.SALVAR_DAYOFF_LANCAMENTO_RESPONSE: {
        draft.payload = action.payload.data;
        draft.loading = false;
        break;
      }
      case Types.CARREGAR_RECESSO_LANCAMENTOS_REQUEST: {
        draft.loadingRecesso = true;
        break;
      }
      case Types.CARREGAR_RECESSO_LANCAMENTOS_SUCCESS: {
        draft.payloadRecesso = action.payload.data;
        draft.loadingRecesso = false;
        break;
      }
      case Types.CARREGAR_RECESSO_LANCAMENTOS_FAILURE: {
        draft.payloadRecesso = null;
        draft.loadingRecesso = false;
        break;
      }
      case Types.SALVAR_RECESSO_LANCAMENTO_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.SALVAR_RECESSO_LANCAMENTO_RESPONSE: {
        draft.payload = action.payload.data;
        draft.loading = false;
        break;
      }
      case Types.CARREGAR_LANCAMENTOS_REQUEST: {
        draft.loadingLancamentos = true;
        break;
      }
      case Types.CARREGAR_LANCAMENTOS_SUCCESS: {
        draft.payloadLancamentos = action.payload.data;
        draft.loadingLancamentos = false;
        break;
      }
      case Types.CARREGAR_LANCAMENTOS_FAILURE: {
        draft.payloadLancamentos = null;
        draft.loadingLancamentos = false;
        break;
      }
      case Types.TOTAL_PONTOS_POR_FUNCIONARIO_REQUEST: {
        draft.loadingTotalPontos = true;
        break;
      }
      case Types.TOTAL_PONTOS_POR_FUNCIONARIO_SUCCESS: {
        draft.payloadTotalPontos = action.payload.data;
        draft.loadingTotalPontos = false;
        break;
      }
      case Types.TOTAL_PONTOS_POR_FUNCIONARIO_FAILURE: {
        draft.payloadTotalPontos = null;
        draft.loadingTotalPontos = false;
        break;
      }

      case Types.DAYOFF_LANCAMENTO_POR_ID_REQUEST: {
        draft.dayOffLancamento = null;
        draft.loading = false;
        break;
      }
      case Types.DAYOFF_LANCAMENTO_POR_ID_RESPONSE: {
        draft.dayOffLancamento = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.RECESSO_POR_ID_REQUEST: {
        draft.recessoLancamento = null;
        draft.loading = false;
        break;
      }
      case Types.RECESSO_POR_ID_RESPONSE: {
        draft.recessoLancamento = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.FERIAS_POR_ID_REQUEST: {
        draft.feriasLancamento = null;
        draft.loading = false;
        break;
      }
      case Types.FERIAS_POR_ID_RESPONSE: {
        draft.feriasLancamento = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.CLEAR_ALL_LANCAMENTOS: {
        draft.payload = null;
        draft.loading = false;
        draft.payloadFerias = null;
        draft.payloadDayOFF = null;
        draft.payloadRecesso = null;
        draft.payloadLancamentos = null;
        draft.payloadTotalPontos = null;
        draft.dayOffLancamento = null;
        draft.recessoLancamento = null;
        draft.feriasLancamento = null;

        draft.loading = false;
        draft.loadingFerias = false;
        draft.loadingDayOFF = false;
        draft.loadingRecesso = false;
        draft.loadingLancamentos = false;
        draft.loadingTotalPontos = false;
        break;
      }

      case Types.LOGOUT: {
        draft.payload = null;
        draft.loading = false;
        draft.payloadFerias = null;
        draft.payloadDayOFF = null;
        draft.payloadRecesso = null;
        draft.payloadLancamentos = null;
        draft.payloadTotalPontos = null;
        draft.dayOffLancamento = null;
        draft.recessoLancamento = null;
        draft.feriasLancamento = null;

        draft.loading = false;
        draft.loadingFerias = false;
        draft.loadingDayOFF = false;
        draft.loadingRecesso = false;
        draft.loadingLancamentos = false;
        draft.loadingTotalPontos = false;
        break;
      }
      default:
    }
  });
}