import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  h1 {
    align-self: flex-start;
    font-size: 32px;
  }
  > div {
    text-align: center;
    width: 100%;
    p {
      font-size: 32px;
    }
  }
  div {
    &:last-child {
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        + button {
          margin-left: 20px;
        }
      }
    }
  }
`;
