import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { useSelector } from 'react-redux';

import { useTag } from './tag';

import ConteinerDeDetalheDoDia from '~/components/ConteinerDetalheDoDia';

import { requestEscalas } from '~/services/request';
import { requestEspelhoPonto } from '~/services/request';
import { getMonth, hoursAndMinutesByDate } from '~/utils/dateFormat';

const ContextoCalendario = createContext();

const meses = {
  JANEIRO: 1,
  FEVEREIRO: 2,
  MARCO: 3,
  ABRIL: 4,
  MAIO: 5,
  JUNHO: 6,
  JULHO: 7,
  AGOSTO: 8,
  SETEMBRO: 9,
  OUTUBRO: 10,
  NOVEMBRO: 11,
  DEZEMBRO: 12,
};

export function ProvedorCalendario({ children }) {
  const usuario = useSelector((state) => state.loginPayload.loggedIn.Usuario);
  const { resolverTags } = useTag();
  const [detalheDiaSelecionado, setDetalheDiaSelecionado] = useState({
    isEmployed: false,
    detalhe: [],
  });
  const [calendarioAtual, setCalendarioAtual] = useState({});
  const [mostrarHorarioEscala, setMostrarHorarioEscala] = useState(true);

  const ocultarDetalheDoDia = useCallback(async () => {
    await setDetalheDiaSelecionado({ isEmployed: false, detalhe: [] });
  }, []);

  const mostrarDetalheDoDia = useCallback(
    async (dia, usuario, isEmployed = false, gestaoId = null) => {
      const { data, naturezaDia, entrada, saida, index } = dia;
      if (detalheDiaSelecionado.detalhe.data === data) return;
      const detalhe = {
        ...dia,
        usuario,
      };
      if (detalheDiaSelecionado.detalhe.length > 0) await ocultarDetalheDoDia();
      await setDetalheDiaSelecionado({ isEmployed, detalhe: [detalhe] });
    },
    [setDetalheDiaSelecionado],
  );
  useEffect(() => {
    window.addEventListener('hashchange', () => ocultarDetalheDoDia());
    return () =>
      window.removeEventListener('hashchange', () => ocultarDetalheDoDia());
  }, []);
  const resolverCalendario = useCallback(
    async (params) => {
      let cleanUp = false;
      const tags = await resolverTags(params);
            
        const queryParams = {
          url: '/calendario/individual',
          method: 'GET',
          queryParams: {
            Ano: params.ano,
            Mes: params.mes,
          },
        };
    
        const response = await requestEscalas(queryParams);

      const responseEspelho = await requestEspelhoPonto({
        url: `/configuracao/em-vigor?usuarioId=${params.usuariosIds[0]}&empresaId=${params.empresaId}`,
        method: 'GET',
      });

      if (cleanUp) return;
      if (responseEspelho && responseEspelho.data) {
        setMostrarHorarioEscala(responseEspelho.data.data.mostrarHorarioEscala);
      }
      if (response.data && response.data.success) {
        const dias = response.data.data.dias.map((dia, index) => {
          const { horaEntrada, horaSaida, data, naturezaDia } = dia;
          const {
            ausencias,
            feriados,
            sobreavisos,
            feriadosIndenizados,
            folgasCompensadas,
            folgasBancoHoras,
            folgasBancoFolgas,
          } = tags;
          const entrada = hoursAndMinutesByDate(horaEntrada);
          const saida = hoursAndMinutesByDate(horaSaida);

          const mesAtual = getMonth(data) === response.data.data.periodo;
          const tipo = !mesAtual
            ? 'outro'
            : naturezaDia === 'Trabalho'
            ? 'trabalho'
            : 'folga';

          const tagsDoDia = {
            ausencias: ausencias.find((tag) => tag.data === data),
            feriados: feriados.find((tag) => tag.data === data),
            sobreavisos: sobreavisos.find((tag) => tag.data === data),
            feriadosIndenizados: feriadosIndenizados.find(
              (tag) => tag.data === data,
            ),
            folgasCompensadas: folgasCompensadas.find(
              (tag) => tag.data === data,
            ),
            folgasBancoHoras: folgasBancoHoras.find((tag) => tag.data === data),
            folgasBancoFolgas: folgasBancoFolgas.find(
              (tag) => tag.data === data,
            ),
          };
          const arrayTagsDoDia = [];
          let tipoTag = '';
          if (tagsDoDia.ausencias) {
            arrayTagsDoDia.push('ausencia');
            tipoTag = tagsDoDia.ausencias.tipoTag;
          }
          if (tagsDoDia.feriados) arrayTagsDoDia.push('feriado');
          if (tagsDoDia.sobreavisos) arrayTagsDoDia.push('sobreaviso');
          if (tagsDoDia.feriadosIndenizados) arrayTagsDoDia.push('indenizado');
          if (tagsDoDia.folgasCompensadas) arrayTagsDoDia.push('compensada');
          if (tagsDoDia.folgasBancoHoras) arrayTagsDoDia.push('banco');
          if (tagsDoDia.folgasBancoFolgas) arrayTagsDoDia.push('folgas');

          const d = {
            ...dia,
            entrada,
            saida,
            tipo,
            tipoTag,
            tags: arrayTagsDoDia,
            index,
          };
          return d;
        });

        let dados = {
          periodo: response.data.data.periodo,
          ano: response.data.data.ano,
          dias,
        };
        const ferias = dias.filter((d) => d.tipoTag === 'Ferias');
        if (ferias.length > 0) {
          const periodoFerias = {
            inicio: ferias[0].data,
            fim: ferias[ferias.length - 1].data,
          };

          dados = { ...dados, periodoFerias };
        }
        setCalendarioAtual(dados);
      }
      return () => (cleanUp = true);
    },
    [setCalendarioAtual, setMostrarHorarioEscala, requestEscalas, resolverTags],
  );

  const resolverBuscaCalendario = useCallback(
    (busca) => {
      const params = {
        ano:
          (calendarioAtual.periodo === 'JANEIRO' && busca < 0) ||
          (calendarioAtual.periodo === 'DEZEMBRO' && busca > 0)
            ? (calendarioAtual.ano += busca)
            : calendarioAtual.ano,
        mes:
          calendarioAtual.periodo === 'JANEIRO' && busca < 0
            ? 12
            : calendarioAtual.periodo === 'DEZEMBRO' && busca > 0
            ? 1
            : (meses[calendarioAtual.periodo] += busca),
        usuariosIds: [usuario.Id],
        empresaId: usuario.EmpresaId,
      };

      resolverCalendario(params);
    },
    [usuario, calendarioAtual, resolverCalendario, meses],
  );

  const carregarCalendarioIndividual = useCallback(() => {
    const atual = new Date();

    if (usuario) {
      const params = {
        ano: atual.getFullYear(),
        mes: atual.getMonth() + 1,
        usuariosIds: [usuario.Id],
        empresaId: usuario.EmpresaId,
      };

      resolverCalendario(params);
    }
  }, []);

  return (
    <ContextoCalendario.Provider
      value={{
        ocultarDetalheDoDia,
        mostrarDetalheDoDia,
        calendarioAtual,
        mostrarHorarioEscala,
        setCalendarioAtual,
        resolverBuscaCalendario,
        carregarCalendarioIndividual,
      }}
    >
      {children}
      <ConteinerDeDetalheDoDia
        dados={detalheDiaSelecionado.detalhe}
        isEmployed={detalheDiaSelecionado.isEmployed}
        callback={(isback = false) => {
          if (!isback) {
            carregarCalendarioIndividual();
            ocultarDetalheDoDia();
          }
        }}
      />
    </ContextoCalendario.Provider>
  );
}

export function useCalendario() {
  const contexto = useContext(ContextoCalendario);

  if (!contexto) {
    throw new Error(
      'useCalendario deve ser usado dentro do ProvedorCalendario',
    );
  }

  return contexto;
}
