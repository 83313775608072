import { Types } from '../../types';

export function horaNegativaRequest({ params, method, id }) {
  return {
    type: Types.HORA_NEGATIVA_REQUEST,
    payload: params,
    method: method,
    id: id
  };
}

export function getHoraNegativaResponse(data) {
  return {
    type: Types.GET_HORA_NEGATIVA_RESPONSE,
    payload: { data },
  };
}

export function postHoraNegativaResponse(data) {
  return {
    type: Types.POST_HORA_NEGATIVA_RESPONSE,
    payload: { data },
  };
}

export function putHoraNegativaResponse(data) {
  return {
    type: Types.PUT_HORA_NEGATIVA_RESPONSE,
    payload: { data },
  };
}
