import { Types } from '../types';

export function lancamentoSobreavisoPost(params) {
  return {
    type: Types.LANCAMENTO_SOBREAVISO_REQUEST,
    method: 'POST',
    payload: params,
  };
}

export function lancamentoSobreavisoPut(params) {
  return {
    type: Types.LANCAMENTO_SOBREAVISO_REQUEST_PUT,
    method: 'PUT',
    payload: params,
  };
}

export function lancamentoSobreavisoGet(params) {
  return {
    type: Types.LANCAMENTO_SOBREAVISO_REQUEST_GET,
    payload: params,
  };
}

export function lancamentoSobreavisoGetByID(id) {
  return {
    type: Types.LANCAMENTO_SOBREAVISO_BY_ID_REQUEST_GET,
    payload: id,
  };
}

export function lancamentoSobreavisoHistorico(id) {
  return {
    type: Types.LANCAMENTO_SOBREAVISO_HISTORICO_REQUEST,
    id,
  };
}

export function lancamentoSobreavisoGetCount() {
  return {
    type: Types.LANCAMENTO_SOBREAVISO_COUNT_REQUEST,
  };
}

export function lancamentoSobreavisoRelatorio(params) {
  return {
    type: Types.LANCAMENTO_SOBREAVISO_RELATORIO_REQUEST,
    method: 'GET',
    payload: params,
  };
}

export function lancamentoSobreavisoRelatorioResponse(data) {
  return {
    type: Types.LANCAMENTO_SOBREAVISO_RELATORIO_RESPONSE,
    payload: { data },
  };
}

export function lancamentoSobreavisoRelatorioCsv(params) {
  return {
    type: Types.LANCAMENTO_SOBREAVISO_RELATORIO_CSV_REQUEST,
    method: 'GET',
    payload: params,
  };
}

export function lancamentoSobreavisoRelatorioCsvResponse(data) {
  return {
    type: Types.LANCAMENTO_SOBREAVISO_RELATORIO_CSV_RESPONSE,
    payload: { data },
  };
}

export function lancamentoSobreavisoAcoes(params) {
  return {
    type: Types.LANCAMENTO_SOBREAVISO_ACAO_REQUEST,
    payload: params,
  };
}

export function lancamentoSobreavisoAcoesResponse(data) {
  return {
    type: Types.LANCAMENTO_SOBREAVISO_ACAO_RESPONSE,
    payload: { data },
  };
}

export function lancamentoSobreavisoCountResponse(data) {
  return {
    type: Types.LANCAMENTO_SOBREAVISO_COUNT_RESPONSE,
    payload: { data },
  };
}

export function lancamentoSobreavisoHistoricoResponse(data) {
  return {
    type: Types.LANCAMENTO_SOBREAVISO_HISTORICO_RESPONSE,
    payload: { data },
  };
}

export function lancamentoSobreavisoResponse(data) {
  return {
    type: Types.LANCAMENTO_SOBREAVISO_RESPONSE,
    payload: { data },
  };
}

export function lancamentoSobreavisoResponsePut(data) {
  return {
    type: Types.LANCAMENTO_SOBREAVISO_RESPONSE_PUT,
    payload: { data },
  };
}

export function lancamentoSobreavisoResponseGet(data) {
  return {
    type: Types.LANCAMENTO_SOBREAVISO_RESPONSE_GET,
    payload: { data },
  };
}

export function lancamentoSobreavisoGetByIDResponse(data) {
  return {
    type: Types.LANCAMENTO_SOBREAVISO_BY_ID_RESPONSE_GET,
    payload: { data },
  };
}

export function lancamentoSobreavisoClear() {
  return {
    type: Types.LANCAMENTO_SOBREAVISO_CLEAR_DATA,
  };
}

export function lancamentoSobreavisoClearCsv() {
  return {
    type: Types.LANCAMENTO_SOBREAVISO_CLEAR_CSV,
  };
}
