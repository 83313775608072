import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import { logout, refreshTokenRequest } from '~/store/modules/auth/actions';

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

class AuthProvider extends Component {
  constructor(props) {
    super(props);

    this.handleChange();

    props.history.listen(() => {
      this.handleChange();
    });
  }

  handleChange() {
    const { auth } = this.props;

    // Forçar o logout para todos os usuarios - 04/07/2022 14:00
    if (auth && auth.signed) {
      const lastSignIn = localStorage.getItem('lastSignIn');
      if (!lastSignIn || (lastSignIn && lastSignIn <= 1656954142276)) {
        this.props.logout();
      }
    }

    if (auth && auth.signed) {
      const date = new Date(auth.token.ExpiresIn);
      const now_utc = Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds(),
      );

      const date1 = new Date();
      const date2 = new Date(now_utc);
      const diffTime = Math.abs(date2 - date1);

      if (date1 >= date2) {
        this.props.logout();
      }

      if (diffTime >= 600000) {
        setTimeout(() => {
          toast.warning('Faltam 10 minutos para expirar a sua sessão');
        }, diffTime - 600000);
      }

      if (diffTime >= 300000) {
        setTimeout(() => {
          toast.warning('Faltam 5 minutos para expirar a sua sessão');
        }, diffTime - 300000);
      }

      if (diffTime >= 60000) {
        setTimeout(() => {
          toast.warning('Falta 1 minuto para expirar a sua sessão');
        }, diffTime - 60000);
      }

      setTimeout(() => {
        this.props.logout();
      }, diffTime);
    }
  }

  render = () => <></>;
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  usuario: state.loginPayload.loggedIn.Usuario,
});
const mapDispatchToProps = { logout, refreshTokenRequest };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuthProvider),
);
