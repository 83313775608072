/* eslint-disable import/extensions */
import React, { Suspense, lazy } from 'react';
import { Switch } from 'react-router-dom';

import BlockUi from 'react-block-ui';
import GlobalLoader from '~/components/_layouts/UI/GlobalLoader';
import Route from './Route';

const Dashboard = lazy(() => import('~/Screens/Dashboard'));
const LoginBoxed = lazy(() => import('~/Screens/LoginBoxed'));
const ForgotPasswordBoxed = lazy(() => import('~/Screens/ForgotPasswordBoxed'));
const AdBoxed = lazy(() => import('~/Screens/AdBoxed'));
const SsoIntegration = lazy(() => import('~/Screens/SsoIntegration'));
const ComprovantesMarcacao = lazy(() =>
  import('~/Screens/ComprovantesMarcacao'),
);
const ComprovantesMarcacaoDetalhes = lazy(() =>
  import('~/Screens/ComprovantesMarcacao/Comprovantes'),
);

export default function Routes() {
  return (
    <Suspense
      fallback={
        <BlockUi
          tag="div"
          blocking
          className="block-overlay-white"
          loader={<GlobalLoader />}
        />
      }
    >
      <Switch>
        <Route exact path="/login" component={LoginBoxed} />
        <Route exact path="/login/:code" component={LoginBoxed} />
        <Route exact path="/login/primeiro-acesso" component={LoginBoxed} />
        <Route path="/forgot-password" component={ForgotPasswordBoxed} />
        <Route path="/ad" component={AdBoxed} />
        <Route path="/sso/:empresaId" component={SsoIntegration} />
        <Route
          exact
          path="/comprovantes-marcacao-ponto"
          component={ComprovantesMarcacao}
        />
        <Route
          exact
          path="/comprovantes-marcacao-ponto/detalhes"
          component={ComprovantesMarcacaoDetalhes}
          isPrivate
        />

        <Route path="/" component={Dashboard} isPrivate />
      </Switch>
    </Suspense>
  );
}
