import { takeLatest, call, put, all, delay } from 'redux-saga/effects';
import { Types } from '../types';
import { toast } from 'react-toastify';
import { requestBaseV3 } from '~/services/request';
import { carregarBancoHorasAtualSuccess, carregarBancoHorasAtualFailure } from './actions';

export function* carregarBancoHorasAtual({ payload }) {
  let params = {
    url: '/CarregarBancoHorasAtual',
    method: 'GET',
    queryParams: payload
  };

  try {
    const response = yield call(requestBaseV3, params);

    if (response.data.Success) {
      const data = response.data;

      yield put(carregarBancoHorasAtualSuccess(data));
      yield delay(2000);
    } else {
      toast.error(response.data.Error);
      yield put(carregarBancoHorasAtualFailure());
    }
  } catch (error) {
    toast.error("Falha na autenticação");
    yield put(carregarBancoHorasAtualFailure());
  }
}

export default all([
  takeLatest(Types.CARREGAR_BANCO_HORAS_ATUAL_REQUEST, carregarBancoHorasAtual)
]);