import { takeLatest, call, put, all, delay } from 'redux-saga/effects';

import { Types } from '../types';

import { toast } from 'react-toastify';

import {
  requestIntermitentes,
  requestFolhacertaBase,
} from '~/services/request';

import {
  grupoGetSuccess,
  grupoGetFailure,
  grupoPostSuccess,
  grupoPostFailure,
  grupoArquivarResponse,
  grupoDesarquivarResponse,
  grupoEditarResponse,
} from './actions';

export function* grupoPost({ payload }) {
  let params = {
    url: '/Grupo',
    method: 'POST',
    body: payload,
  };

  try {
    const response = yield call(requestIntermitentes, params);
    if (response.data.success) {
      const data = response.data;

      yield put(grupoPostSuccess(data));
      yield delay(2000);
    } else {
      toast.error(response.data.Error);
      yield put(grupoPostFailure());
    }
  } catch (err) {
    toast.error('Falha ao criar grupo');
    yield put(grupoPostFailure());
  }
}

export function* grupoEditar({ payload }) {
  let params = {
    url: '/Grupo',
    method: 'PUT',
    body: payload,
  };

  try {
    const response = yield call(requestIntermitentes, params);
    yield put(grupoEditarResponse(response.data));
  } catch (err) {
    yield put(grupoEditarResponse({ success: false }));
  }
}

export function* grupoArquivar({ payload }) {
  let params = {
    url: '/Grupo/arquivar/' + payload,
    method: 'GET',
    body: null,
  };

  try {
    const response = yield call(requestIntermitentes, params);
    yield put(grupoArquivarResponse(response.data));
  } catch (error) {
    yield put(grupoArquivarResponse({ success: false }));
  }
}

export function* grupoDesarquivar({ payload }) {
  let params = {
    url: '/Grupo/desarquivar/' + payload,
    method: 'GET',
    body: null,
  };

  try {
    const response = yield call(requestIntermitentes, params);
    yield put(grupoDesarquivarResponse(response.data));
  } catch (error) {
    yield put(grupoDesarquivarResponse({ success: false }));
  }
}

export function* grupoGet({ payload }) {
  let params = {
    url: '/grupo/' + payload.grupoId,
    method: 'GET',
    queryParams: payload.params,
  };
  try {
    const response = yield call(requestFolhacertaBase, params);

    if (response.data.success) {
      const data = response.data;

      yield put(grupoGetSuccess(data));
      yield delay(2000);
    } else {
      yield put(grupoGetFailure());
    }
  } catch (err) {
    yield put(grupoGetFailure());
  }
}

export default all([
  takeLatest(Types.GRUPO_GET_REQUEST, grupoGet),
  takeLatest(Types.GRUPO_POST_REQUEST, grupoPost),
  takeLatest(Types.GRUPO_ARQUIVAR_REQUEST, grupoArquivar),
  takeLatest(Types.GRUPO_DESARQUIVAR_REQUEST, grupoDesarquivar),
  takeLatest(Types.GRUPO_EDITAR_REQUEST, grupoEditar),
]);
