import { Types } from '../types';

export function relatorioSapRequest(params) {
  return {
    type: Types.RELATORIO_SAP_REQUEST,
    payload: params
  };
}

export function relatorioSapResponse(data) {
  return {
    type: Types.RELATORIO_SAP_RESPONSE,
    payload: { data },
  };
}
