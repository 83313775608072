import { Types } from '../types';

// Processos internos
export function processosInternosRequest(params) {
  return {
    type: Types.PROCESSOS_INTERNOS_REQUEST,
    payload: params,
  };
}

export function processosInternosSuccess(data) {
  return {
    type: Types.PROCESSOS_INTERNOS_SUCCESS,
    payload: { data },
  };
}

export function processosInternosFailure() {
  return {
    type: Types.PROCESSOS_INTERNOS_FAILURE,
  };
}

export function processosInternosFuncionarioRequest(params) {
  return {
    type: Types.PROCESSOS_INTERNOS_FUNCIONARIO_REQUEST,
    payload: params,
  };
}

export function processosInternosFuncionarioResponse(data) {
  return {
    type: Types.PROCESSOS_INTERNOS_FUNCIONARIO_RESPONSE,
    payload: { data },
  };
}

export function listarProcessosInternosRequest() {
  return {
    type: Types.LISTAR_PROCESSOS_INTERNOS_REQUEST,
  };
}

export function listarProcessosInternosResponse(data) {
  return {
    type: Types.LISTAR_PROCESSOS_INTERNOS_RESPONSE,
    payload: { data },
  };
}

export function SolicitarProcessoInternoRequest({ params }, callback) {
  return {
    type: Types.SOLICITAR_PROCESSO_INTERNO_REQUEST,
    payload: params,
    callback,
  };
}

export function SolicitarProcessoInternoResponse(data, callback) {
  callback(data);
  return {
    type: Types.SOLICITAR_PROCESSO_INTERNO_RESPONSE,
    payload: { data },
  };
}

export function clearProcessosInternos() {
  return {
    type: Types.CLEAR_PROCESSOS_INTERNOS,
  };
}
