import { takeLatest, call, put, all } from 'redux-saga/effects';
import { Types } from '../../../types';
// eslint-disable-next-line import/no-cycle
import { requestMotorNovoBancoHoras } from '~/services/request';

import {
  getBancoCicloResponse,
  postBancoCicloResponse,
  putBancoCicloResponse,
  bancoCicloActivateResponse,
  bancoCicloInactivateResponse,
  bancoCicloBatchResponse,
} from './actions';

export function* bancoCiclo({ payload, method, id, callback }) {
  const params = {
    url: id ? `/api/v1/ciclo/${id}` : '/api/v1/ciclo',
    method,
    body: payload,
  };

  switch (method) {
    case 'GET':
      try {
        const response = yield call(requestMotorNovoBancoHoras, params);
        yield put(getBancoCicloResponse(response.data, callback));
      } catch (error) {
        yield put(getBancoCicloResponse({ success: false }, callback));
      }
      break;
    case 'POST':
      try {
        const response = yield call(requestMotorNovoBancoHoras, params);

        yield put(postBancoCicloResponse({
          id: response.headers.location,
          data: response.data,
          success: true,
        }, callback));
      } catch (error) {
        yield put(postBancoCicloResponse({ success: false }, callback));
      }
      break;
    case 'PUT':
      try {
        const response = yield call(requestMotorNovoBancoHoras, params);
        yield put(putBancoCicloResponse({ data: response.data, success: true }, callback));
        yield put(postBancoCicloResponse({ id, success: true }, callback));
      } catch (error) {
        yield put(putBancoCicloResponse({ success: false }, callback));
      }
      break;

    default:
      break;
  }
}

export function* bancoCicloActivate({ ids, callback }) {
  const params = {
    url: '/api/v1/ciclo/activate',
    method: 'PUT',
    body: {
      ids
    }
  };

  try {
    const response = yield call(requestMotorNovoBancoHoras, params);

    yield put(bancoCicloActivateResponse({ success: true, data: response.data }, callback));
  } catch (error) {
    yield put(bancoCicloActivateResponse({ success: false }, callback));
  }
}

export function* bancoCicloInactivate({ ids, callback }) {
  const params = {
    url: '/api/v1/ciclo/inactivate',
    method: 'PUT',
    body: {
      ids
    }
  };

  try {
    const response = yield call(requestMotorNovoBancoHoras, params);

    yield put(bancoCicloInactivateResponse({ success: true, data: response.data }, callback));
  } catch (error) {
    yield put(bancoCicloInactivateResponse({ success: false }, callback));
  }
}

export function* bancoCicloBatch({ payload, callback }) {
  const params = {
    url: '/api/v1/ciclo/batch',
    method: 'POST',
    body: payload,
  };

  try {
    const response = yield call(requestMotorNovoBancoHoras, params);

    if (response.isAxiosError) {
      yield put(bancoCicloBatchResponse({ success: false }, callback));
      return;
    }

    const success = response.status >= 200 && response.status < 300;
    yield put(
      bancoCicloBatchResponse({ success, data: response.data }, callback),
    );
  } catch (error) {
    yield put(bancoCicloBatchResponse({ success: false }, callback));
  }
}

export default all([
  takeLatest(Types.BANCO_CICLO_REQUEST, bancoCiclo),
  takeLatest(
    Types.BANCO_CICLO_ACTIVATE_REQUEST,
    bancoCicloActivate,
  ),
  takeLatest(
    Types.BANCO_CICLO_INACTIVATE_REQUEST,
    bancoCicloInactivate,
  ),
  takeLatest(
    Types.BANCO_CICLO_BATCH_REQUEST,
    bancoCicloBatch,
  ),
]);
