import { takeLatest, call, put, all, delay } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { Types } from '../../types';

import { requestBaseV3 } from '~/services/request';

export function* request({ url, body, responseAction, method }) {
  const formData = new FormData();
  Object.keys(body).forEach((key) => formData.append(key, body[key]));

  const parameters = {
    url,
    method,
    body: formData,
  };

  try {
    const response = yield call(requestBaseV3, parameters);

    if (response.data.Success) {
      yield put(responseAction(response.data));
      toast.info('Solicitação de restauração realizada');
      yield delay(2000);
    } else {
      toast.error(response.data.Error);
      yield put(responseAction(response.data));
    }
  } catch (error) {
    toast.error('Erro ao restaurar ponto');
    yield put(
      responseAction({
        success: false,
        message: 'Erro ao restaurar ponto',
      }),
    );
  }
}

export default all([takeLatest(Types.RESTAURAR_MARCACAO_REQUEST, request)]);
