import produce from 'immer';

import { Types } from '../types';

// Reducers
const INITIAL_STATE = {
  loggedIn: {},
  isManager: false,
};

export default function loginPayload(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.LOGIN_SUCCESS:
        draft.loggedIn = action.payload.loggedIn;
        if (
          action.payload.loggedIn &&
          action.payload.loggedIn.Usuario &&
          action.payload.loggedIn.Usuario.Configuracao &&
          action.payload.loggedIn.Usuario.Configuracao.UsarMenuGestor &&
          action.payload.loggedIn.MenuGestores.length > 0
        ) {
          draft.isManager = true;
        }
        break;
      case Types.FIRST_LOGIN:
        draft.loggedIn = action.payload.loggedIn;
        if (
          action.payload.loggedIn &&
          action.payload.loggedIn.Usuario &&
          action.payload.loggedIn.Usuario.Configuracao &&
          action.payload.loggedIn.Usuario.Configuracao.UsarMenuGestor &&
          action.payload.loggedIn.MenuGestores.length > 0
        ) {
          draft.isManager = true;
        }
        break;
      case Types.LOGOUT: {
        // draft.loggedIn = {};
        draft.isManager = false;
        break;
      }
      default:
    }
  });
}
