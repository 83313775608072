/* eslint-disable import/no-cycle */
/* eslint-disable import/named */
/* eslint-disable guard-for-in */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
import { takeLatest, call, put, all, delay } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import { requestBaseV3 } from '~/services/request';
import { Types } from '../../types';

import {
  alterarMarcacaoSuccess,
  alterarMarcacaoFailure,
  alterarDataMarcacaoSuccess,
  alterarDataMarcacaoFailure,
} from './actions';

// CarregarDia3Action
import { carregarDia3Request } from '../../carregarDia3/actions';

export function* alterarMarcacao({ params, callback }) {
  const parameters = {
    url: '/AlterarMarcacao',
    method: 'POST',
    body: params,
  };

  try {
    const response = yield call(requestBaseV3, parameters);
    const { data } = response;

    if (data.Success) {
      yield put(alterarMarcacaoSuccess(data, callback));
      yield put(carregarDia3Request());
      toast.success('Marcação Justificada');
      yield delay(2000);
    } else {
      toast.error(data.Error);
      yield put(alterarMarcacaoFailure(data, callback));
    }
  } catch (error) {
    toast.error('Falha na autenticação');
    yield put(alterarMarcacaoFailure({ Success: false, Error: error }, callback));
  }
}

export function* alterarDataMarcacao({ params }) {
  const formData = new FormData();
  for (const key in params) {
    formData.append(key, params[key]);
  }
  const parameters = {
    url: '/AlterarDataMarcacao',
    method: 'POST',
    body: formData,
  };

  try {
    const response = yield call(requestBaseV3, parameters);

    if (response.data.Success) {
      const { data } = response;
      yield put(alterarDataMarcacaoSuccess(data));
      yield put(carregarDia3Request());
      toast.success('Dia alterado com sucesso!');
      yield delay(2000);
    } else {
      toast.error(response.data.Error);
      yield put(alterarDataMarcacaoFailure());
    }
  } catch (error) {
    toast.error('Falha na autenticação');
    yield put(alterarDataMarcacaoFailure());
  }
}

export default all([
  takeLatest(Types.ALTERAR_MARCACAO_REQUEST, alterarMarcacao),
  takeLatest(Types.ALTERAR_DATA_MARCACAO_REQUEST, alterarDataMarcacao),
]);
