import { takeLatest, call, put, all, delay } from 'redux-saga/effects';

import { Types } from '../types';

import { toast } from 'react-toastify';

import { requestIntermitentes } from '~/services/request';

import {
  motivosInterrupcaoGetSuccess, motivosInterrupcaoGetFailure,
  motivosInterrupcaoPostSuccess, motivosInterrupcaoPostFailure,
  motivosInterrupcaoPutResponse
} from './actions';

export function* motivosInterrupcaoPost({ payload }) {
  let params = {
    url: '/MotivosInterrupcoes',
    method: 'POST',
    body: payload
  };

  try {
    const response = yield call(requestIntermitentes, params);
    if (response.data.success) {
      const data = response.data;

      yield put(motivosInterrupcaoPostSuccess(data));
      yield delay(2000);
    } else {
      toast.error(response.data.Error);
      yield put(motivosInterrupcaoPostFailure(response.data));
    }
  } catch (error) {
    toast.error("Falha na autenticação");
    yield put(motivosInterrupcaoPostFailure({ success: false }));
  }
}

export function* motivosInterrupcaoPut({ payload }) {
  let params = {
    url: '/MotivosInterrupcoes',
    method: 'PUT',
    body: payload
  };

  try {
    const response = yield call(requestIntermitentes, params);
    yield put(motivosInterrupcaoPutResponse(response.data));
  } catch (error) {
    yield put(motivosInterrupcaoPutResponse({ success: false }));
  }
}

export function* motivosInterrupcaoGet() {
  let params = {
    url: '/MotivosInterrupcoes/admin',
    method: 'GET',
    body: null
  };

  try {
    const response = yield call(requestIntermitentes, params);
    if (response.data.success) {
      const data = response.data;

      yield put(motivosInterrupcaoGetSuccess(data));
      yield delay(2000);
    } else {
      toast.error(response.data.Error);
      yield put(motivosInterrupcaoGetFailure(response.data));
    }
  } catch (error) {
    toast.error("Falha na autenticação");
    yield put(motivosInterrupcaoGetFailure({ success: false }));
  }
}

export default all([
  takeLatest(Types.MOTIVOS_INTERRUPCAO_POST_REQUEST, motivosInterrupcaoPost),
  takeLatest(Types.MOTIVOS_INTERRUPCAO_GET_REQUEST, motivosInterrupcaoGet),
  takeLatest(Types.MOTIVOS_INTERRUPCAO_PUT_REQUEST, motivosInterrupcaoPut),
]);