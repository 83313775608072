import React from 'react';
import PropTypes from 'prop-types';

import { Info, Photo, Wrapper, About, Column, Name } from './styles';

import userDefault from '~/assets/utils/images/avatars/user_default.png';

export const MudarParaTrabalho = ({ usuario, isEmployed, isOpen = false }) => (
  <>
    {isEmployed && usuario && (
      <Wrapper isOpen={isOpen}>
        <Info>
          <Photo
            src={usuario.foto || userDefault}
            alt={userDefault}
            className="rounded-circle"
          />
          <Column isOpen={isOpen}>
            <Name name>{usuario.nome}</Name>
            <About>{`${usuario.cargo} | ${usuario.turno}`}</About>
          </Column>
        </Info>
      </Wrapper>
    )}
  </>
);
MudarParaTrabalho.propTypes = {
  usuario: PropTypes.objectOf(PropTypes.any).isRequired,
  isEmployed: PropTypes.bool.isRequired,
};

export default MudarParaTrabalho;
