import React, { createContext, useContext, useCallback, useState } from 'react';

import Modal from '~/components/ConteinerModal';

const ContextoModal = createContext();

export function ProvedorModal({ children }) {
  const [dadosModal, setDadosModal] = useState([]);

  const ocultarModal = useCallback(async () => {
    await setDadosModal([]);
  }, []);

  const mostrarModal = useCallback(
    async (infos) => {
      const detalhe = {
        id: 1,
        ...infos,
      };
      if (dadosModal.length > 0) await ocultarModal();
      await setDadosModal([detalhe]);
    },
    [setDadosModal],
  );

  return (
    <ContextoModal.Provider
      value={{
        ocultarModal,
        mostrarModal,
      }}
    >
      {children}
      <Modal dados={dadosModal} />
    </ContextoModal.Provider>
  );
}

export function useModal() {
  const contexto = useContext(ContextoModal);

  if (!contexto) {
    throw new Error('useModal deve ser usado dentro do ProvedorModal');
  }

  return contexto;
}
