import produce from 'immer';

import { Types } from '~/store/modules/types';

const INITIAL_STATE = {
  loading: false,
  porId: null,
  lista: null,
};

export default function acao(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.REQUEST_ACOES: {
        draft.loading = true;
        break;
      }

      case Types.RESPONSE_ACAO_POR_ID: {
        draft.porId = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.RESPONSE_ACOES: {
        draft.lista = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.CLEAR_RELATORIO: {
        draft.loading = false;
        draft.lista = null;
        break;
      }

      case Types.LOGOUT: {
        draft.loading = false;
        draft.lista = null;
        break;
      }
      default:
    }
  });
}
