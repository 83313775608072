import produce from 'immer';

import { Types } from '../types';

// Reducers
const INITIAL_STATE = {
  payload: null,

  usuariosIntermitentes: null,

  loading: false,
}

export default function usuariosConvocacao(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.USUARIOS_CONVOCACAO_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.USUARIOS_CONVOCACAO_SUCCESS: {
        draft.payload = action.payload.data;
        draft.loading = false;
        break;
      }
      case Types.USUARIOS_CONVOCACAO_FAILURE: {
        draft.payload = null;
        draft.loading = false;
        break;
      }


      case Types.USUARIOS_INTERMITENTES_TODOS_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.USUARIOS_INTERMITENTES_TODOS_SUCCESS: {
        draft.usuariosIntermitentes = action.payload.data;
        draft.loading = false;
        break;
      }
      case Types.USUARIOS_INTERMITENTES_TODOS_FAILURE: {
        draft.usuariosIntermitentes = null;
        draft.loading = false;
        break;
      }

      case Types.LOGOUT: {
        draft.payload = null;
        draft.usuariosIntermitentes = null;
        draft.loading = false;
        break;
      }
      default:
    }
  });
}