import { Types } from '../types';

export function carregarPeriodoAtualRequest(usuarioId) {
    return {
        type: Types.CARREGAR_PERIODO_ATUAL_REQUEST,
        payload: { usuarioId },
    };
}

export function carregarPeriodoAtualSuccess(data) {
    return {
        type: Types.CARREGAR_PERIODO_ATUAL_SUCCESS,
        payload: { data },
    };
}

export function carregarPeriodoAtualFailure() {
    return {
        type: Types.CARREGAR_PERIODO_ATUAL_FAILURE
    };
}
