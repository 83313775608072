import { Types } from '../types';

export function motivoSobreavisoPost(params) {
  return {
    type: Types.MOTIVO_SOBREAVISO_REQUEST,
    method: 'POST',
    payload: params,
  };
}

export function motivoSobreavisoPut(params) {
  return {
    type: Types.MOTIVO_SOBREAVISO_REQUEST_PUT,
    method: 'PUT',
    payload: params,
  };
}

export function motivoSobreavisoGet(params) {
  return {
    type: Types.MOTIVO_SOBREAVISO_REQUEST_GET,
    payload: params,
  };
}

export function motivoSobreavisoGetByID(id) {
  return {
    type: Types.MOTIVO_SOBREAVISO_BY_ID_REQUEST_GET,
    payload: id,
  };
}

export function motivoSobreavisoHistorico(id) {
  return {
    type: Types.MOTIVO_SOBREAVISO_HISTORICO_REQUEST,
    id,
  };
}

export function motivoSobreavisoHistoricoResponse(data) {
  return {
    type: Types.MOTIVO_SOBREAVISO_HISTORICO_RESPONSE,
    payload: { data },
  };
}

export function motivoSobreavisoResponse(data) {
  return {
    type: Types.MOTIVO_SOBREAVISO_RESPONSE,
    payload: { data },
  };
}

export function motivoSobreavisoResponsePut(data) {
  return {
    type: Types.MOTIVO_SOBREAVISO_RESPONSE_PUT,
    payload: { data },
  };
}

export function motivoSobreavisoResponseGet(data) {
  return {
    type: Types.MOTIVO_SOBREAVISO_RESPONSE_GET,
    payload: { data },
  };
}

export function motivoSobreavisoGetByIDResponse(data) {
  return {
    type: Types.MOTIVO_SOBREAVISO_BY_ID_RESPONSE_GET,
    payload: { data },
  };
}

export function motivoSobreavisoClear() {
  return {
    type: Types.MOTIVO_SOBREAVISO_CLEAR_DATA,
  };
}
