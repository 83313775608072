// Theme material-ui
import { createMuiTheme } from '@material-ui/core';
import { ptBR } from '@material-ui/core/locale';

const primary = '#00AAE0';

export const theme = createMuiTheme(
  {
    palette: {
      primary: {
        main: primary,
        contrastText: '#fff',
        error: '#F73B62',
      },
    },
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: primary,
        },
      },
      MuiPickersClock: {
        pin: {
          backgroundColor: primary,
        },
      },
      MuiPickersClockPointer: {
        pointer: {
          backgroundColor: primary,
        },
        thumb: {
          borderColor: primary,
        },
      },
      MuiButton: {
        label: {
          color: primary,
        },
      },
      MuiInputLabel: {
        formControl: {
          width: 'max-content',
        },
      },
    },
  },
  ptBR,
);
