import produce from 'immer';

import { Types } from '../types';

// Reducers
const INITIAL_STATE = {
  payloadInterromper: null,
  payloadVencendo: null,
  payload: null,
  loading: false,

  abertas: null,
  emAndamento: null,
  encerradas: null,

  payloadConvocacao: null,
  aceitaram: null,
  recusaram: null,
  semResposta: null,
}

export default function convocacao(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.NOVA_CONVOCACAO_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.NOVA_CONVOCACAO_SUCCESS: {
        draft.loading = false;
        break;
      }
      case Types.NOVA_CONVOCACAO_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.CONVOCACAO_INTERROMPER_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.CONVOCACAO_INTERROMPER_SUCCESS: {
        draft.payloadInterromper = action.payload;
        draft.loading = false;
        break;
      }
      case Types.CONVOCACAO_INTERROMPER_FAILURE: {
        draft.payloadInterromper = action.payload;
        draft.loading = false;
        break;
      }

      case Types.CONVOCACAO_LISTA_REQUEST: {
        draft.loading = true;
        break;
      }
      
      case Types.CONVOCACAO_LISTA_SUCCESS: {
        draft.abertas = action.payload.data.ativas;
        draft.emAndamento = action.payload.data.emAndamento;
        draft.encerradas = action.payload.data.encerradas;
        draft.loading = false;
        break;
      }
      case Types.CONVOCACAO_LISTA_FAILURE: {
        draft.abertas = null;
        draft.emAndamento = null;
        draft.encerradas = null;
        draft.loading = false;
        break;
      }

      case Types.CONTRATANTES_RESUMO_ID_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.CONTRATANTES_RESUMO_ID_SUCCESS: {
        draft.payloadConvocacao = action.payload.data;
        draft.aceitaram = action.payload.data.colaboraresAcitaram;
        draft.recusaram = action.payload.data.colaboraresRecusaram;
        draft.semResposta = action.payload.data.semResposta;
        draft.interrompido = action.payload.data.interrompido;
        draft.loading = false;
        break;
      }
      case Types.CONTRATANTES_RESUMO_ID_FAILURE: {
        draft.payloadConvocacao = null;
        draft.aceitaram = null;
        draft.recusaram = null;
        draft.semResposta = null;
        draft.interrompido = null;
        draft.loading = false;
        break;
      }

      case Types.CONVOCACAO_VENCENDO_REQUEST: {
        draft.payloadVencendo = null;
        draft.loading = true;
        break;
      }

      case Types.CONVOCACAO_VENCENDO_RESPONSE: {
        draft.payloadVencendo = action.payload;
        draft.loading = false;
        break;
      }

      case Types.NOVA_CONVOCACAO_CLEAR_DATA: {
        draft.payload = null;
        draft.payloadInterromper = null;
        draft.loading = false;
        break;
      }

      case Types.LOGOUT: {
        draft.payload = null;
        draft.payloadVencendo = null;
        draft.payloadConvocacao = null;
        draft.payloadInterromper = null;
        draft.ativas = null;
        draft.arquivadas = null;
        draft.aceitaram = null;
        draft.recusaram = null;
        draft.semResposta = null;
        draft.interrompido = null;
        draft.todos = null;
        draft.loading = false;
        break;
      }
      default:
    }
  });
}