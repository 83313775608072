import { Types } from '../../types';

export function alocacaoRegraApuracaoPost(params) {
  return {
    type: Types.ALOCACAO_REGRA_APURACAO_REQUEST,
    method: 'POST',
    payload: params,
  };
}

export function alocacaoRegraApuracaoPut(params) {
  return {
    type: Types.ALOCACAO_REGRA_APURACAO_REQUEST_PUT,
    method: 'PUT',
    payload: params,
  };
}

export function alocacaoRegraApuracaoStatusPut(params) {
  return {
    type: Types.ALOCACAO_REGRA_APURACAO_REQUEST_STATUS_PUT,
    method: 'PUT',
    payload: params,
  };
}

export function alocacaoRegraApuracaoGet(params) {
  return {
    type: Types.ALOCACAO_REGRA_APURACAO_REQUEST_GET,
    payload: params,
  };
}

export function alocacaoRegraApuracaoGetByID(id) {
  return {
    type: Types.ALOCACAO_REGRA_APURACAO_BY_ID_REQUEST_GET,
    payload: id,
  };
}

export function alocacaoRegraApuracaoHistorico(id) {
  return {
    type: Types.ALOCACAO_REGRA_APURACAO_HISTORICO_REQUEST,
    id: id,
  };
}

export function alocacaoRegraApuracaoGetCount() {
  return {
    type: Types.ALOCACAO_REGRA_APURACAO_COUNT_REQUEST,
  };
}

export function alocacaoRegraApuracaoHistoricoResponse(data) {
  return {
    type: Types.ALOCACAO_REGRA_APURACAO_HISTORICO_RESPONSE,
    payload: { data },
  };
}

export function alocacaoRegraApuracaoCountResponse(data) {
  return {
    type: Types.ALOCACAO_REGRA_APURACAO_COUNT_RESPONSE,
    payload: { data },
  };
}

export function alocacaoRegraApuracaoResponse(data) {
  return {
    type: Types.ALOCACAO_REGRA_APURACAO_RESPONSE,
    payload: { data },
  };
}

export function alocacaoRegraApuracaoResponsePut(data) {
  return {
    type: Types.ALOCACAO_REGRA_APURACAO_RESPONSE_PUT,
    payload: { data },
  };
}

export function alocacaoRegraApuracaoResponseStatusPut(data) {
  return {
    type: Types.ALOCACAO_REGRA_APURACAO_RESPONSE_STATUS_PUT,
    payload: { data },
  };
}

export function alocacaoRegraApuracaoResponseGet(data) {
  return {
    type: Types.ALOCACAO_REGRA_APURACAO_RESPONSE_GET,
    payload: { data },
  };
}

export function alocacaoRegraApuracaoGetByIDResponse(data) {
  return {
    type: Types.ALOCACAO_REGRA_APURACAO_BY_ID_RESPONSE_GET,
    payload: { data },
  };
}

export function alocacaoRegraApuracaoClear() {
  return {
    type: Types.ALOCACAO_REGRA_APURACAO_CLEAR_DATA,
  };
}
