import { Types } from '../../types';

export function horaPositivaRequest({ params, method, id }) {
  return {
    type: Types.HORA_POSITIVA_REQUEST,
    payload: params,
    method: method,
    id: id
  };
}

export function getHoraPositivaResponse(data) {
  return {
    type: Types.GET_HORA_POSITIVA_RESPONSE,
    payload: { data },
  };
}

export function postHoraPositivaResponse(data) {
  return {
    type: Types.POST_HORA_POSITIVA_RESPONSE,
    payload: { data },
  };
}

export function putHoraPositivaResponse(data) {
  return {
    type: Types.PUT_HORA_POSITIVA_RESPONSE,
    payload: { data },
  };
}
