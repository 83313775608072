import React, { useState, useCallback, useEffect } from 'react';

import { Col, Row } from 'reactstrap';
import ButtonFolhaCerta from '~/components/_layouts/forms/ButtonFolhaCerta';

import MessageModalList from '~/components/_layouts/modals/MessageModalList';

import { Row as R, DivBranca, Circle, DivTransparente, Line } from './styles';

import DateSelect from '~/components/_layouts/DateSelect';

import { useAcao } from '~/hooks/escalas';

import { getDayDifferenceBewtweenTwoDates } from '~/utils/dateFormat';

export default function MudarPara({ dados, onClose, callback }) {
  const { usuario, naturezaDia, changeTo, data } = dados;

  const { requestAcao, listaDeAcaoDisponiveis } = useAcao();

  const [activeStep, setActiveStep] = useState(1);

  const [dataInicio, setDataInicio] = useState(new Date(data));
  const [dataFim, setDataFim] = useState(new Date(data));
  const [limiteDiaData, setLimiteDiaData] = useState(new Date(data));

  const [message, setMessage] = useState('');
  const [openMessage, setOpenMessage] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState(false);

  const limiteDiasNumber =
    listaDeAcaoDisponiveis[0].alterarDiaTrabalhoDiasConsecutivos;

  const showMessageView = (value) => {
    setMessage(value.message);
    setOpenMessage(true);
    setMessageSuccess(value.success);
  };

  const buttonConfirm = () => {
    setOpenMessage(false);
    onClose();
    callback();
  };

  const getLimiteDias = (initalDate) => {
    const LimiteData = initalDate;
    setLimiteDiaData(
      new Date(
        LimiteData.setDate(initalDate.getDate() + (limiteDiasNumber - 1)),
      ),
    );
  };

  useEffect(() => {
    getLimiteDias(new Date(data));
  }, []);

  const alterarPara = useCallback(async () => {
    const params = {
      usuarioId: usuario.Id || usuario.usuarioId,
      data: dataInicio,
      tipoAcao:
        changeTo === 'Trabalho' ? 'MudarParaTrabalho' : 'MudarParaFolga',
    };

    if (getDayDifferenceBewtweenTwoDates(dataInicio, dataFim) !== 0) {
      params.AlterarDiaDataFim = dataFim;
    }

    try {
      const response = await requestAcao(params);

      showMessageView(response);
    } catch (err) {
      showMessageView({
        success: false,
        message: err.response.data.message
          ? err.response.data.message
          : 'Erro ao realizar alteração',
      });
    }
  }, [requestAcao, changeTo, usuario, data, dataFim]);

  return (
    <>
      <DivBranca>
        <R
          style={{
            paddingTop: 0,
            width: '150px',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Circle active={activeStep === 1}>1</Circle>
          <Line />
          <Circle active={activeStep === 2}>2</Circle>
        </R>
        {activeStep === 1 ? (
          <div style={{ width: '16rem' }}>
            <h1>Data de Início?</h1>
            <DateSelect
              style={{ width: '16rem' }}
              key="dataInicio"
              label="Data"
              modalLabel="Selecione o prazo final para resposta"
              onDaySelect={(value) => {
                setDataInicio(value);
                setDataFim(value);
              }}
              selectedDay={dataInicio}
            />
          </div>
        ) : (
          <div style={{ width: '16rem' }}>
            <h1>Data fim?</h1>
            <DateSelect
              key="dataFim"
              label="Data"
              modalLabel="Selecione o prazo final para resposta"
              onDaySelect={(value) => setDataFim(value)}
              selectedDay={dataFim}
              min={dataInicio}
              max={limiteDiaData}
            />
            <label className="mt-1 text-danger">
              Limite de até {limiteDiasNumber}
              {limiteDiasNumber > 1 ? ' dias' : ' dia'}.
            </label>
          </div>
        )}
      </DivBranca>
      <Row>
        {activeStep === 1 ? (
          <Col>
            <DivTransparente className="d-flex justify-content-center">
              <ButtonFolhaCerta
                buttonName="Cancelar"
                branco
                onClickButton={onClose}
                small
              />
              <ButtonFolhaCerta
                buttonName="Próximo"
                amarelo
                showIcon
                disabled={!dataInicio}
                onClickButton={() => setActiveStep(2)}
                small
              />
            </DivTransparente>
          </Col>
        ) : (
          <Col>
            <DivTransparente className="d-flex justify-content-center">
              <ButtonFolhaCerta
                buttonName="Voltar"
                branco
                onClickButton={() => setActiveStep(1)}
                small
              />
              <ButtonFolhaCerta
                buttonName="Confirmar"
                amarelo
                showIcon
                disabled={!dataFim}
                onClickButton={() => {
                  if (dados && dados.alteracaoEscala) {
                    showMessageView({
                      message:
                        'Já existem alterações de escala para a data em questão. Para continuar, remova a ação existente.',
                      success: false,
                    });
                  } else {
                    alterarPara();
                  }
                }}
                small
              />
            </DivTransparente>
          </Col>
        )}
      </Row>
      <MessageModalList
        message={message}
        open={openMessage}
        messageSuccess={messageSuccess}
        onClick={() => {
          buttonConfirm();
        }}
      />
    </>
  );
}
