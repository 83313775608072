import produce from 'immer';

import { Types } from '../types';

// Reducers
const INITIAL_STATE = {
  payload: null,
  loading: false,
};

export default function carregarMotivosAusencia(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.CARREGAR_MOTIVOS_AUSENCIA_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.CARREGAR_MOTIVOS_AUSENCIA_RESPONSE: {
        draft.payload = action.payload.data;
        draft.loading = false;
        break;
      }
      case Types.LOGOUT: {
        draft.payload = null;
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
