import { takeLatest, call, put, all } from 'redux-saga/effects';
import { Types } from '../../types';
import { requestMotorNovo } from '~/services/request';

import {
  getDomingoDsrResponse,
  postDomingoDsrResponse,
  putDomingoDsrResponse
} from './actions';

export function* domingoDsr({ payload, method, id }) {
  let params = {
    url: id ? '/api/v1/domingo/' + id : '/api/v1/domingo',
    method: method,
    body: payload
  };

  switch (method) {
    case "GET":
      try {
        const response = yield call(requestMotorNovo, params);
        yield put(getDomingoDsrResponse(response.data));
      } catch (error) {
        yield put(getDomingoDsrResponse({ success: false }));
      }
      break;
    case "POST":
      try {
        const response = yield call(requestMotorNovo, params);
        let aux = {
          id: response.headers.location,
          data: response.data
        }
        yield put(postDomingoDsrResponse(aux));
      } catch (error) {
        yield put(postDomingoDsrResponse({ success: false }));
      }
      break;
    case "PUT":
      try {
        const response = yield call(requestMotorNovo, params);
        yield put(putDomingoDsrResponse(response.data));
        yield put(postDomingoDsrResponse({ id: id }));
      } catch (error) {
        yield put(putDomingoDsrResponse({ success: false }));
      }
      break;

    default:
      break;
  }
}


export default all([
  takeLatest(Types.DOMINGO_DSR_REQUEST, domingoDsr),
]);