import { Types } from '~/store/modules/types';

function responseEscalasEmUso(data, callback) {
  if (callback) {
    callback(data);
  }
  return {
    type: Types.RESPONSE_ESCALAS_EM_USO,
    payload: { data },
  };
}

export function getEscalasEmUso(params, callback) {
  return {
    type: Types.REQUEST_ESCALAS_EM_USO,
    url: '/escala/em-uso',
    method: 'GET',
    queryParams: params,
    responseAction: responseEscalasEmUso,
    callback,
  };
}

export function clearEscalasEmUso() {
  return { type: Types.CLEAR_RELATORIO };
}
