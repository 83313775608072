import { Types } from '../../types';

export function filtrarDepartamentosRequest(params) {
  return {
    type: Types.FILTRAR_DEPARTAMENTOS_REQUEST,
    payload: params,
  };
}

export function filtrarDepartamentosResponse(data) {
  return {
    type: Types.FILTRAR_DEPARTAMENTOS_RESPONSE,
    payload: { data },
  };
}
