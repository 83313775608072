import { takeLatest, call, put, all } from 'redux-saga/effects';
import { Types } from '../../types';
// eslint-disable-next-line import/no-cycle
import { requestMotorNovo } from '~/services/request';

import {
  postAusenciasResponse,
  getAusenciasResponse,
  putAusenciasResponse,
} from './actions';

export function* ausencias({ payload, method, id }) {
  const params = {
    url: id ? `/api/v1/ausencia/${id}` : '/api/v1/ausencia',
    method,
    body: payload,
  };

  switch (method) {
    case 'GET':
      try {
        const response = yield call(requestMotorNovo, params);
        yield put(getAusenciasResponse(response.data));
      } catch (error) {
        yield put(getAusenciasResponse({ success: false }));
      }
      break;
    case 'POST':
      try {
        const response = yield call(requestMotorNovo, params);
        const aux = {
          id: response.headers.location,
          data: response.data,
        };
        yield put(postAusenciasResponse(aux));
      } catch (error) {
        yield put(postAusenciasResponse({ success: false }));
      }
      break;
    case 'PUT':
      try {
        const response = yield call(requestMotorNovo, params);
        yield put(putAusenciasResponse(response.data));
        yield put(postAusenciasResponse({ id }));
      } catch (error) {
        yield put(putAusenciasResponse({ success: false }));
      }
      break;

    default:
      break;
  }
}

export default all([takeLatest(Types.AUSENCIAS_REQUEST, ausencias)]);
