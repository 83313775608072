import styled from 'styled-components';

export const Conteiner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  img {
    width: 147px;
    height: 105px;
  }
  p {
    font-size: 32px;
    text-align: center;
  }
`;
