import { takeLatest, call, put, all } from 'redux-saga/effects';
import { Types } from '../types';
import { requestBaseV3 } from '~/services/request';
import { carregarMotivosAusenciaResponse } from './actions';

export function* carregarMotivoAusencia({ payload }) {
  const params = {
    url: '/CarregarMotivosAusencia',
    method: 'GET',
  };

  try {
    const response = yield call(requestBaseV3, params);
    const { data } = response;
    yield put(carregarMotivosAusenciaResponse(data));
  } catch (error) {
    yield put(carregarMotivosAusenciaResponse({ success: false }));
  }
}

export default all([
  takeLatest(Types.CARREGAR_MOTIVOS_AUSENCIA_REQUEST, carregarMotivoAusencia),
]);
