import produce from 'immer';

import { Types } from '../../types';

// Reducers
const INITIAL_STATE = {
  payload: null,
  payloadPut: null,
  payloadGet: null,
  payloadCount: null,
  payloadGetById: null,
  payloadHistorico: null,

  loading: false,
  loadingHistorico: false,
}

export default function alocacaoRegraApuracao(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.ALOCACAO_REGRA_APURACAO_REQUEST: {
        draft.loading = true;
        break;
      }

      case Types.ALOCACAO_REGRA_APURACAO_REQUEST_PUT: {
        draft.loading = true;
        break;
      }

      case Types.ALOCACAO_REGRA_APURACAO_REQUEST_STATUS_PUT: {
        draft.loading = true;
        break;
      }

      case Types.ALOCACAO_REGRA_APURACAO_REQUEST_GET: {
        draft.loading = true;
        break;
      }

      case Types.ALOCACAO_REGRA_APURACAO_BY_ID_REQUEST_GET: {
        draft.loading = true;
        break;
      }

      case Types.ALOCACAO_REGRA_APURACAO_BY_ID_RESPONSE_GET: {
        draft.payloadGetById = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.ALOCACAO_REGRA_APURACAO_RESPONSE_GET: {
        draft.payloadGet = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.ALOCACAO_REGRA_APURACAO_RESPONSE: {
        draft.payload = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.ALOCACAO_REGRA_APURACAO_RESPONSE_PUT: {
        draft.payloadPut = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.ALOCACAO_REGRA_APURACAO_RESPONSE_STATUS_PUT: {
        draft.payloadPut = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.ALOCACAO_REGRA_APURACAO_COUNT_REQUEST: {
        draft.loading = true;
        break;
      }

      case Types.ALOCACAO_REGRA_APURACAO_HISTORICO_REQUEST: {
        draft.loadingHistorico = true;
        break;
      }

      case Types.ALOCACAO_REGRA_APURACAO_HISTORICO_RESPONSE: {
        draft.payloadHistorico = action.payload.data;
        draft.loadingHistorico = false;
        break;
      }

      case Types.ALOCACAO_REGRA_APURACAO_COUNT_RESPONSE: {
        draft.payloadCount = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.ALOCACAO_REGRA_APURACAO_CLEAR_DATA: {
        draft.payloadGetById = null;
        draft.payloadHistorico = null;
        draft.payloadCount = null;
        draft.payloadPut = null;
        draft.payloadGet = null;
        draft.payload = null;
        draft.loading = false;
        draft.loadingHistorico = false;
        break;
      }

      case Types.LOGOUT: {
        draft.payloadGetById = null;
        draft.payloadHistorico = null;
        draft.payloadCount = null;
        draft.payloadPut = null;
        draft.payloadGet = null;
        draft.payload = null;
        draft.loading = false;
        draft.loadingHistorico = false;
        break;
      }
      default:
    }
  });
}