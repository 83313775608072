import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

export const Container = styled(animated.div)`
  position: relative;
  width: 433px;
  min-height: 100%;
  background: var(--cinzaDisabledLight);
  padding-top: 20px;
  transform: translateY(60px);
  div {
    padding-right: 36px;
    padding-left: 36px;
    padding-bottom: 13px;
    padding-top: 11px;
    div {
      padding: 0;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  padding-top: 50px;
  &.destaque {
    background: var(--white);
    border-top: 1px solid var(--light-gray);
    div {
      display: flex;
      align-items: flex-end;
      h1 {
        margin-left: 16px;
        transform: translateY(-6px);
        text-transform: lowercase;
        color: var(--gray);
      }
    }
  }

  div {
    display: flex;
    align-items: center;
  }

  h1 {
    color: var(--darkBlue);
    font-size: 22px;
    ${({ titlelength }) =>
      titlelength > 24 &&
      css`
        font-size: 19px;
      `}
  }
  button {
    background: transparent;
    color: var(--darkBlue);
    text-transform: uppercase;

    &.simples {
      border: none;
      font-size: 11px;
      font-weight: bold;
    }
    &.aredondado {
      border: 1px solid var(--darkBlue);
      border-radius: 30px;
      font-size: 10px;
      width: 80px;
      padding-top: 8px;
      padding-bottom: 8px;
      line-height: 1;
    }
  }
  ${({ isOpen }) =>
    isOpen &&
    css`
      background: transparent !important;
    `}

  ${({ isOpenAcao }) =>
    isOpenAcao &&
    css`
      border-bottom: 1px solid var(--light-gray);
    `}
`;

export const RowEscala = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;
  }

  h1 {
    &.azul {
      font-size: 17px;
      color: var(--light-blue);
    }
  }

  button {
    background: transparent;
    color: var(--darkBlue);
    text-transform: uppercase;

    &.simples {
      border: none;
      font-size: 11px;
      font-weight: bold;
    }
    &.aredondado {
      border: 1px solid var(--darkBlue);
      border-radius: 30px;
      font-size: 10px;
      width: 80px;
      padding-top: 8px;
      padding-bottom: 8px;
      line-height: 1;
    }
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--white);
  h1 {
    font-size: 22px;
    color: var(--gray);
    + h1 {
      font-size: 17px;
      color: var(--light-blue);
    }
  }
  &.cards {
    padding: 0 !important;
  }
`;

export const MiniCalendario = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px 8px 5px 5px;
  border: 2px solid var(--darkGray);
  transform: translateY(-8px);
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 18px;
    width: 60px;
    background: var(--darkGray);
    margin: 0;

    span {
      text-align: center;
      font-size: 13px;
      text-transform: uppercase;
      color: var(--white);
    }
    + div {
      background: transparent;
      height: 36px;
      span {
        font-size: 26px;
        text-transform: uppercase;
        color: var(--gray);
      }
    }
  }
  &::before {
    content: '';
    border-style: solid;
    border-color: var(--darkGray);
    border-width: 0px 3px 0 3px;
    width: 40px;
    height: 5px;
    align-self: center;
    position: absolute;
    transform: translateY(-6px);
  }
`;

export const CartaoAlteracao = styled.div`
  border-left: ${({ cor }) => `11px solid ${cor}`};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-left: 20px !important;
  padding-top: 13px !important;
  padding-bottom: 13px !important;
  padding-right: 0px !important;
  + div {
    margin-top: 6px;
  }
  div {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    p {
      font-size: 19px;
      color: var(--gray);
      strong {
        font-weight: bold;
      }
    }
    &.tag {
      align-items: center;
      margin-left: 20px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
      background: var(--cinzaDisabledLight);
      border: 1px solid var(--light-gray);
      border-left: 0px;
      margin-left: 30px;
      transform: translateX(1px);
      padding: 4px;
      padding-left: 8px;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;

      span {
        font-size: 12px;
        color: var(--cinzaDisabledDark);
        text-transform: capitalize;
        margin-left: 12px;
      }
    }
  }
  button {
    background: none;
    border: none;
    color: var(--darkBlue);
    font-size: 11px;
    text-transform: uppercase;
    font-weight: bold;
    width: 108px;
    text-align: left;
    padding: 0;
  }
`;
export const Relogio = styled.div`
  border-radius: 50%;
  border: 1px solid var(--white);
  width: 17px;
  height: 17px;
  background: var(--amareloMostarda);
  &::before {
    content: '';
    border-left: 1px solid var(--white);
    height: 7px;
    position: absolute;
    transform: translate(7px, 2px);
  }
  &::after {
    content: '';
    border-left: 1px solid var(--white);
    height: 5px;
    position: absolute;
    transform: translate(9px, 7px) rotate(125deg);
  }
`;

export const ButtonArrowLeft = styled.button`
  border-radius: 50%;
  border: 2px solid var(--darkBlue);
  width: 34px;
  height: 34px;
  margin-right: 20px;
  position: relative;
  top: -8px;
  &::before {
    content: '';
    border-left: 2px solid var(--darkBlue);
    height: 15px;
    position: absolute;
    transform: translate(-3px, -12px) rotate(45deg);
  }
  &::after {
    content: '';
    border-left: 2px solid var(--darkBlue);
    height: 15px;
    position: absolute;
    transform: translate(-2px, -3px) rotate(125deg);
  }
`;
const posicaoAcoes = [
  {
    position: css`
      transform: translate(-31%, 180%);
    `,
    height: css`
      max-height: 0px;
    `,
  },
  {
    position: css`
      transform: translate(-31%, 165%);
    `,
    height: css`
      max-height: 111px;
    `,
  },
  {
    position: css`
      transform: translate(-31%, 141%);
    `,
    height: css`
      max-height: 150px;
    `,
  },
  {
    position: css`
      transform: translate(-31%, 131%);
    `,
    height: css`
      max-height: 189px;
    `,
  },
  {
    position: css`
      transform: translate(-31%, 124%);
    `,
    height: css`
      max-height: 228px;
    `,
  },
  {
    position: css`
      transform: translate(-31%, 120%);
    `,
    height: css`
      max-height: 287px;
    `,
  },
  {
    position: css`
      transform: translate(-31%, 116%);
    `,
    height: css`
      max-height: 306px;
    `,
  },
  {
    position: css`
      transform: translate(-31%, 113%);
    `,
    height: css`
      max-height: 345px;
    `,
  },
];
export const ListaDeAcoes = styled.div`
  position: relative;
  div {
    display: block;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
    padding-top: 25px !important;
    padding-right: 15px !important;
    padding-left: 15px !important;
    padding-bottom: 25px !important;

    button {
      ${({ open }) =>
        !open &&
        css`
          visibility: hidden;
        `}
      + button {
        margin-top: 10px;
      }
    }

    position: absolute;
    z-index: 1002;
    bottom: calc(100% + 12px);
    left: 50%;

    ${({ quantity, open }) => posicaoAcoes[open ? quantity : 0].position}
    border-radius: 15px;
    border: 1px solid var(--light-gray);

    max-height: 0;
    opacity: 0;
    transition-property: opacity, max-height;
    transition-duration: 0.5s;

    ${({ open }) =>
      open
        ? css`
            opacity: 1;
            display: block !important;
            ${({ quantity }) => posicaoAcoes[quantity].height}
          `
        : setTimeout(() => {
            return css`
              visibility: hidden;
              display: none !important;
            `;
          }, 600)}

    &::before {
      content: '';
      border-style: solid;
      border-color: var(--light-gray);
      border-width: 1px 1px 0 0;
      background: #fff;
      left: 70%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translate(-60%, -180%) rotate(-45deg);

      position: absolute;
      width: 20px;
      height: 20px;
    }
  }
`;

export const Info = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;

  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    display: flex;
    flex-direction: column;

    & > span {
      width: 300px;
    }
  }
`;

export const Photo = styled.img`
  width: 64px;
  height: 61px;
  margin-right: 20px;
`;

export const Name = styled.span`
  font-size: 21px;
  color: var(--primary);
`;

export const About = styled.span`
  font-size: 17px;
  color: var(--gray);
`;

export const Arrow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
  background: var(--white);

  background: var(--white);
  border: 1px solid var(--tableBorder);

  &::after,
  &::before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &::after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: var(--white);
    border-width: 10px;
    margin-left: -10px;
  }

  &::before {
    border-color: rgba(194, 225, 245, 0);
    border-bottom-color: var(--tableBorder);
    border-width: 11px;
    margin-left: -11px;
  }
`;
