import { Types } from '../types';

export function cargosGetRequest() {
  return {
    type: Types.CARGOS_GET_REQUEST
  };
}

export function cargosGetSuccess(data) {
  return {
    type: Types.CARGOS_GET_SUCCESS,
    payload: { data },
  };
}

export function cargosGetFailure() {
  return {
    type: Types.CARGOS_GET_FAILURE
  };
}
