/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { List } from 'react-virtualized';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Checkbox } from '@material-ui/core';

const Check = styled(Checkbox)`
  span {
    & .MuiCheckbox-colorPrimary.Mui-disabled {
      color: var(--primary);
    }
  }
`;
const Combo = styled(Autocomplete)`
  div {
    &.MuiAutocomplete-tag {
      border-radius: 5px;
      ${({ colorTag }) =>
        colorTag
          ? css`
              background-color: #00487e;
              color: #29d694;
            `
          : css`
              background-color: #29d694;
              color: white;
            `};
      text-transform: uppercase;
      font-size: 12px;
      border: none;
    }
    &.MuiAutocomplete-endAdornment {
      top: 0px !important;
    }
    &.MuiFormControl-fullWidth {
      overflow: hidden !important;
      overflow-y: auto !important;
      max-height: 85px !important;
    }
    &.MuiInput-underline:before {
      ${({ whiteVersion }) =>
        whiteVersion &&
        css`
          border-color: white !important;
        `}};
    }
    &.MuiInput-underline:hover:not(.Mui-disabled):before {
      ${({ whiteVersion }) =>
        whiteVersion &&
        css`
          border-color: white !important;
        `}};
    }
    svg {
      ${({ whiteVersion }) =>
        whiteVersion &&
        css`
          fill: white !important;
        `}};
    }
    input,
    label {
      ${({ whiteVersion }) =>
        whiteVersion &&
        css`
          color: white !important;
        `}};
    }
  }
`;
const ComboBox = ({
  defaultValue = [],
  data = [],
  arrayKey,
  onSelect,
  label,
  blue,
  multiple = true,
  fullWidth,
  disabled,
  keepValueAsSelected,
  value,
  whiteVersion,
  paged,
  isPaged,
  currentPage,
  gridColumns,
}) => {
  const [state, setState] = useState({
    currentValue: [],
    defaultValue: [],
    allSelected: false,
  });
  const [inputValue, setInputValue] = useState('');

  const handleStateValue = useCallback(
    (nome, valor) => {
      setState((sta) => ({ ...sta, [nome]: valor }));
    },
    [setState],
  );

  useEffect(() => {
    const aux =
      defaultValue &&
      defaultValue.filter((item) => {
        return data.filter((dataItem) => dataItem[arrayKey] === item[arrayKey]);
      });
    handleStateValue('defaultValue', aux);
    handleStateValue('allSelected', data.length === state.currentValue.length);
  }, []);

  useEffect(() => {
    if (!value) return;
    handleStateValue('currentValue', value);
  }, [value]);

  useEffect(() => {
    if (
      data.length !== state.currentValue.length &&
      state.allSelected &&
      true
    ) {
      handleStateValue('allSelected', false);
    }
  }, [state]);

  const handleChangeState = useCallback(
    (item) => {
      onSelect(item);
      handleStateValue('currentValue', item);
    },
    [onSelect, handleStateValue],
  );

  const handleScrollChange = useCallback(
    (search = null) => {
      if (currentPage) {
        const body = {
          pageSize: 25,
          search,
        };
        if (!search) {
          body.pageNumber = currentPage + 1;
        } else {
          body.pageSize = 5000;
        }
        paged({ ...body });
      }
    },
    [paged],
  );

  useEffect(() => {
    const list =
      data.length > 0
        ? data.filter((item) => {
            return (
              item[arrayKey] &&
              item[arrayKey].toLowerCase().includes(inputValue.toLowerCase())
            );
          })
        : [];
    if (inputValue.length === 3 || list.length === 0) {
      handleScrollChange(inputValue);
    }
  }, [inputValue]);

  const handleToggleSelectAll = () => {
    if (!state.allSelected) {
      handleChangeState(data);
    } else {
      handleChangeState([]);
    }
    handleStateValue('allSelected', !state.allSelected);
  };
  const handleChange = useCallback(
    (event, newValue, reason) => {
      if (multiple) {
        const isExist = state.currentValue.find(
          (item) => item[arrayKey] === event.target.textContent,
        );
        if (reason === 'clear') {
          handleChangeState([]);
        } else if (newValue.find((option) => option.value === 'select-all')) {
          handleToggleSelectAll();
        } else if (
          event.target.textContent.length === 0 &&
          reason === 'remove-option'
        ) {
          handleChangeState(newValue);
        } else if (!isExist) {
          const newObject = data.find(
            (item) => item[arrayKey] === event.target.textContent,
          );
          const newArray = [...state.currentValue];
          newArray.push(newObject);
          handleChangeState(newArray);
        } else {
          const filteredArray = state.currentValue.filter(
            (item) => item[arrayKey] !== event.target.textContent,
          );
          handleChangeState(filteredArray);
        }
      } else if (reason === 'select-option' || reason === 'remove-option') {
        handleChangeState(newValue);
      } else if (reason === 'clear') {
        handleChangeState({});
      }
    },
    [
      handleToggleSelectAll,
      handleChangeState,
      multiple,
      state,
      arrayKey,
      setState,
      data,
    ],
  );

  const inputRenderer = useCallback(
    (params) => {
      setInputValue(params.inputProps.value);
      return <TextField {...params} label={label} />;
    },
    [TextField],
  );
  const getOptionLabel = useCallback(
    (option) => {
      return arrayKey ? option[arrayKey] || '' : option;
    },
    [arrayKey],
  );

  const optionRenderer = useCallback(
    (option) => {
      if (multiple) {
        const selected = state.currentValue.find(
          (item) => item.id === option.id,
        );
        const selectAllProps =
          option.value === 'select-all' // To control the state of 'select-all' checkbox
            ? { checked: state.allSelected }
            : selected
            ? { checked: true }
            : { checked: false };
        return (
          <>
            <Check
              color="primary"
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              disabled
              style={{
                marginRight: 8,
              }}
              {...selectAllProps}
            />
            <div
              style={{
                width: '100%',
                height: '1.3rem',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {getOptionLabel(option)}
            </div>
          </>
        );
      }
      return (
        <div
          style={{
            width: '100%',
            height: '1.3rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {getOptionLabel(option)}
        </div>
      );
    },
    [Check, CheckBoxOutlineBlankIcon, CheckBoxIcon, state],
  );

  const getOptionSelected = useCallback((option, anotherOption) => {
    return option.value === anotherOption.value;
  }, []);
  const filter = createFilterOptions();

  const handleFilteredOptions = useCallback(
    (options, params) => {
      const filtered = filter(options, params);
      if (multiple && filtered.length !== 0 && !isPaged) {
        return [
          { [arrayKey]: 'Selecionar todos', value: 'select-all' },
          ...filtered,
        ];
      }
      return [...filtered];
    },
    [filter, multiple, arrayKey],
  );

  const ListboxComponent = React.forwardRef(function ListboxComponent(
    props,
    ref,
  ) {
    const { children, role, ...other } = props;
    const itemCount = Array.isArray(children) ? children.length : 0;
    const itemSize = 36;
    return (
      <div ref={ref}>
        <div {...other}>
          <List
            height={210}
            width={999}
            AutoSize
            autoWidth
            rowHeight={itemSize}
            overscanCount={5}
            rowCount={itemCount}
            rowRenderer={({ index, style }) => {
              if (index >= 24 && itemCount === index + 1) {
                handleScrollChange();
              }
              return React.cloneElement(children[index], {
                style: { backgroundColor: 'white', ...style },
              });
            }}
            role={role}
          />
        </div>
      </div>
    );
  });

  return (
    <Combo
      disableCloseOnSelect={multiple}
      multiple={multiple}
      size="small"
      options={data}
      fullWidth={!!fullWidth}
      className="p-0"
      disableListWrap
      ListboxComponent={ListboxComponent}
      value={keepValueAsSelected ? state.currentValue : value}
      defaultValue={defaultValue}
      disabled={disabled}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      filterOptions={handleFilteredOptions}
      onChange={handleChange}
      renderOption={optionRenderer}
      renderInput={inputRenderer}
      colorTag={blue}
      whiteVersion={whiteVersion}
      style={{ gridColumn: gridColumns || '' }}
    />
  );
};

ComboBox.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]),
  defaultValue: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  multiple: PropTypes.bool,
  arrayKey: PropTypes.string,
  keepValueAsSelected: PropTypes.bool,
  gridColumns: PropTypes.string,
  whiteVersion: PropTypes.bool,
  paged: PropTypes.bool,
  isPaged: PropTypes.bool,
};

ComboBox.defaultProps = {
  value: null,
  defaultValue: null,
  onChange: () => {},
  disabled: false,
  fullWidth: false,
  multiple: false,
  arrayKey: null,
  keepValueAsSelected: false,
  gridColumns: null,
  whiteVersion: false,
  paged: false,
  isPaged: false,
};

export default ComboBox;
