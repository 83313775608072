import { Types } from '../../types';

export function regraApuracaoRequest({ params, method, id }) {
  return {
    type: Types.REGRA_APURACAO_REQUEST,
    payload: params,
    method: method,
    id: id
  };
}

export function regraApuracaoDuplicateRequest({ params, callback }) {
  return {
    type: Types.REGRA_APURACAO_DUPLICATE_REQUEST,
    payload: params,
    callback,
  };
}

export function getRegraApuracaoResponse(data) {
  return {
    type: Types.GET_REGRA_APURACAO_RESPONSE,
    payload: { data },
  };
}

export function postRegraApuracaoResponse(data) {
  return {
    type: Types.POST_REGRA_APURACAO_RESPONSE,
    payload: { data },
  };
}

export function deleteRegraApuracaoResponse(data) {
  return {
    type: Types.DELETE_REGRA_APURACAO_RESPONSE,
    payload: { data },
  };
}

export function putRegraApuracaoResponse(data) {
  return {
    type: Types.PUT_REGRA_APURACAO_RESPONSE,
    payload: { data },
  };
}

export function regraApuracaoDuplicateResponse(data, callback) {
  if (callback) callback(data);
  return {
    type: Types.REGRA_APURACAO_DUPLICATE_RESPONSE,
    payload: { data },
  };
}

export function clearAllMotorNovo() {
  return {
    type: Types.CLEAR_ALL_MOTOR_NOVO,
  };
}
