import React, { useEffect } from 'react';

import { Conteiner } from './styles';
import Check from '~/assets/images/check.svg';
import { useModal } from '~/hooks/modal';

export default function Sucesso({ dados }) {
  const { ocultarModal } = useModal();
  useEffect(() => {
    setTimeout(() => ocultarModal(), 3000);
  }, []);
  return (
    <Conteiner>
      <div />
      <img src={Check} alt="Check" />
      <p>{dados.menssagem}</p>
    </Conteiner>
  );
}
