import { takeLatest, call, put, all } from 'redux-saga/effects';
import { Types } from '../../types';
import { requestMotorNovo } from '~/services/request';

import {
  getRegraApuracaoResponse,
  postRegraApuracaoResponse,
  putRegraApuracaoResponse,
  regraApuracaoDuplicateResponse,
} from './actions';

export function* regraApuracao({ payload, method, id }) {
  let params = {
    url: id ? '/api/v1/regra/' + id : '/api/v1/regra',
    method: method,
    body: payload
  };

  switch (method) {
    case "GET":
      try {
        const response = yield call(requestMotorNovo, params);
        let aux = {
          id: response.data[0].id_regra,
          data: response.data[0]
        }
        yield put(getRegraApuracaoResponse(aux));
        yield put(postRegraApuracaoResponse(aux));
      } catch (error) {
        yield put(getRegraApuracaoResponse({ success: false }));
      }
      break;
    case "POST":
      try {
        const response = yield call(requestMotorNovo, params);
        let aux = {};
        if (response.data.error) {
          aux = null
        } else {
          aux = {
            id: response.headers.location,
            data: response.data
          }
        }
        yield put(postRegraApuracaoResponse(aux));
      } catch (error) {
        yield put(postRegraApuracaoResponse({ success: false }));
      }
      break;
    case "PUT":
      try {
        const response = yield call(requestMotorNovo, params);
        yield put(putRegraApuracaoResponse({
          success: response.data === 'REGRA_UPDATED_SUCCESSFULLY'
        }));
        yield put(postRegraApuracaoResponse({ id: id }));
      } catch (error) {
        yield put(putRegraApuracaoResponse({ success: false }));
      }
      break;

    default:
      break;
  }
};

export function* regraApuracaoDuplicate({ payload, callback }) {
  const params = {
    url: '/api/v1/regra/duplicate',
    method: 'POST',
    body: payload,
  };

  try {
    const response = yield call(requestMotorNovo, params);

    if (response.isAxiosError) {
      yield put(regraApuracaoDuplicateResponse({ success: false }, callback));
      return;
    }

    yield put(regraApuracaoDuplicateResponse({ success: true, data: response.data }, callback));
  } catch (error) {
    yield put(regraApuracaoDuplicateResponse({ success: false }, callback));
  }
};

export default all([
  takeLatest(Types.REGRA_APURACAO_REQUEST, regraApuracao),
  takeLatest(Types.REGRA_APURACAO_DUPLICATE_REQUEST, regraApuracaoDuplicate),
]);