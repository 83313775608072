import { takeLatest, call, put, all } from 'redux-saga/effects';
import { Types } from '../../../types';
// eslint-disable-next-line import/no-cycle
import { requestMotorNovoBancoHoras } from '~/services/request';

import {
  getBancoHoraResponse,
  postBancoHoraResponse,
  putBancoHoraResponse,
} from './actions';

export function* bancoHora({ payload, method, id }) {
  const params = {
    url: id ? `/api/v1/bancoHora/${id}` : '/api/v1/bancoHora',
    method,
    body: payload,
  };

  switch (method) {
    case 'GET':
      try {
        const response = yield call(requestMotorNovoBancoHoras, params);
        yield put(getBancoHoraResponse(response.data));
      } catch (error) {
        yield put(getBancoHoraResponse({ success: false }));
      }
      break;
    case 'POST':
      try {
        const response = yield call(requestMotorNovoBancoHoras, params);

        const aux = {
          id: response.headers.location,
          data: response.data,
        };
        yield put(postBancoHoraResponse(aux));
      } catch (error) {
        yield put(postBancoHoraResponse({ success: false }));
      }
      break;
    case 'PUT':
      try {
        const response = yield call(requestMotorNovoBancoHoras, params);
        yield put(putBancoHoraResponse(response.data));
        yield put(postBancoHoraResponse({ id }));
      } catch (error) {
        yield put(putBancoHoraResponse({ success: false }));
      }
      break;

    default:
      break;
  }
}

export default all([takeLatest(Types.BANCO_HORA_REQUEST, bancoHora)]);
