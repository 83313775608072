import { Types } from '../types';

export function formaApuracaoRequest({ params, method }) {
  return {
    type: Types.FORMA_APURACAO_REQUEST,
    payload: params,
    method: method
  };
}

export function getFormaApuracaoResponse(data) {
  return {
    type: Types.GET_FORMA_APURACAO_RESPONSE,
    payload: { data },
  };
}

export function postFormaApuracaoResponse(data) {
  return {
    type: Types.POST_FORMA_APURACAO_RESPONSE,
    payload: { data },
  };
}

export function deleteFormaApuracaoResponse(data) {
  return {
    type: Types.DELETE_FORMA_APURACAO_RESPONSE,
    payload: { data },
  };
}

export function putFormaApuracaoResponse(data) {
  return {
    type: Types.PUT_FORMA_APURACAO_RESPONSE,
    payload: { data },
  };
}
