import { takeLatest, call, put, all, delay } from 'redux-saga/effects';
import { Types } from '../types';
import { requestApiRelatorio } from '~/services/request';

export function* requestMarcacoesRelatorio({ url, method, body, queryParams, onSuccess, onFailure }) {
  let params = { url, method, body, queryParams };

  try {
    const response = yield call(requestApiRelatorio, params);

    yield put(onSuccess(response.data));
    yield delay(2000);
  } catch (error) {
    yield put(onFailure({ erro: 'Erro', sucess: false }));
  }
}

export default all([
  takeLatest(Types.RELATORIO_MARCACAO_REQUEST, requestMarcacoesRelatorio)
]);