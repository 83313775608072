import { Types } from '../../types';

export function grupoAtivosRequest(params) {
  return {
    type: Types.GRUPO_ATIVOS_REQUEST,
    payload: params,
  };
}

export function grupoAtivosSuccess(data) {
  return {
    type: Types.GRUPO_ATIVOS_SUCCESS,
    payload: data,
  };
}

export function grupoAtivosFailure(error) {
  return {
    type: Types.GRUPO_ATIVOS_FAILURE,
    error: error,
  };
}
