import { Types } from '../types';

export function carregarAusenciasRequest() {
	return {
		type: Types.CARREGAR_AUSENCIAS_REQUEST,
	};
}

export function carregarAusenciasResponse(data) {
	return {
		type: Types.CARREGAR_AUSENCIAS_RESPONSE,
		payload: { data },
	};
}