import { Types } from '../types';

export function carregarMotivosMarcacaoRequest() {
	return {
		type: Types.CARRGAR_MOTIVOS_MARCACAO_REQUEST,
	};
}

export function carregarMotivosMarcacaoSuccess(data) {
	return {
		type: Types.CARRGAR_MOTIVOS_MARCACAO_SUCCESS,
		payload: { data },
	};
}

export function carregarMotivosMarcacaoFailure() {
	return {
		type: Types.CARRGAR_MOTIVOS_MARCACAO_FAILURE
	};
}
