import { Types } from '../types';

export function dashboardAprovacoesRequest() {
	return {
		type: Types.CARREGAR_DASHBOARD_APROVACOES_REQUEST,
	};
}

export function dashboardAprovacoesResponse(data) {
	return {
		type: Types.CARREGAR_DASHBOARD_APROVACOES_RESPONSE,
		payload: { data },
	};
}
