import styled from 'styled-components';

export const Conteiner = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  overflow: hidden;
  padding-top: 100;
  z-index: 1000;
  height: 100%;
`;
