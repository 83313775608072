import { Types } from '../../types';

export function alterarMarcacaoRequest(params, callback) {
  return {
    type: Types.ALTERAR_MARCACAO_REQUEST,
    params,
    callback,
  };
}

export function alterarMarcacaoSuccess(data, callback) {
  if (callback)
    callback(data);

  return {
    type: Types.ALTERAR_MARCACAO_SUCCESS,
    payload: { data },
  };
}

export function alterarMarcacaoFailure(data, callback) {
  if (callback)
    callback(data);

  return {
    type: Types.ALTERAR_MARCACAO_FAILURE,
  };
}

export function alterarDataMarcacaoRequest(params) {
  return {
    type: Types.ALTERAR_DATA_MARCACAO_REQUEST,
    params,
  };
}

export function alterarDataMarcacaoSuccess(data) {
  return {
    type: Types.ALTERAR_DATA_MARCACAO_SUCCESS,
    payload: { data },
  };
}

export function alterarDataMarcacaoFailure() {
  return {
    type: Types.ALTERAR_DATA_MARCACAO_FAILURE,
  };
}
