import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useAcao } from '~/hooks/escalas';

import MessageModalList from '~/components/_layouts/modals/MessageModalList';
import ButtonFolhaCerta from '~/components/_layouts/forms/ButtonFolhaCerta';
import ComboBox from '~/components/_layouts/forms/ComboBox';
import DateSelect from '~/components/_layouts/DateSelect';

import { requestEscalas } from '~/services/request';

import { Circle, Line, DivTransparente, DivBranca, Row as R } from './styles';

const AlterarEscala = ({ dados, onClose, callback }) => {
  const { alterarEscala } = useAcao();
  const [selectedEscala, setSelectedEscala] = useState(null);
  const [newEscalaDate, setNewEscalaDate] = useState(null);
  const [activeStep, setActiveStep] = useState(1);
  const [message, setMessage] = useState('');
  const [openMessage, setOpenMessage] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState(false);
  const [comboEscalas, setComboEscalas] = useState([]);
  const [gestaoRegra, setGestaoRegra] = useState({});

  const ref = useRef(null);

  const usuarioLogado = useSelector(
    (state) => state.loginPayload.loggedIn.Usuario,
  );

  const recuperaEscalasCadastradas = useCallback(async () => {
    const params = {
      url: '/escala/combo',
      method: 'GET',
      queryParams: {
        GestaoId: dados.gestaoId,
      },
    };

    const response = await requestEscalas(params);

    if (response.data) {
      if (ref.current) {
        setComboEscalas(response.data);
      }
    }

    const paramsGestao = {
      url: `/gestao/${dados.gestaoId}`,
      method: 'GET',
    };

    const responseGestao = await requestEscalas(paramsGestao);

    if (responseGestao.data) {
      if (ref.current) {
        setGestaoRegra(responseGestao.data.data);
      }
    }
  }, [requestEscalas, usuarioLogado]);

  const showMessageView = (data) => {
    setMessage(data.message);
    setOpenMessage(true);
    setMessageSuccess(data.success);
  };

  const buttonConfirm = () => {
    setOpenMessage(false);
    onClose();
    callback();
  };

  const sendRequest = useCallback(async () => {
    const { usuario, data } = dados;

    const params = {
      UsuarioId: usuario.Id || usuario.usuarioId,
      Data: data,
      NovaEscalaId: selectedEscala.id,
      NovaEscalaDataFim: newEscalaDate || '',
    };

    const response = await alterarEscala(params);
    showMessageView(response);
  }, [selectedEscala, newEscalaDate]);

  useEffect(() => {
    recuperaEscalasCadastradas();
  }, []);

  useEffect(() => {
    if (gestaoRegra.alocacaoDataFim === 'MesmoDia') {
      setNewEscalaDate(new Date(dados.data));
    }
  }, [gestaoRegra]);

  return (
    <>
      <DivBranca ref={ref}>
        <R style={{ paddingTop: 0 }}>
          <Circle active={activeStep === 1}>1</Circle>
          <Line />
          <Circle active={activeStep === 2}>2</Circle>
        </R>
        {activeStep === 1 ? (
          <>
            <h1>Nova escala para este dia?</h1>
            {comboEscalas &&
            comboEscalas.data &&
            comboEscalas.data.items.length > 0 ? (
              <ComboBox
                multiple={false}
                fullWidth
                data={comboEscalas.data.items}
                arrayKey="nome"
                value={selectedEscala}
                onSelect={(value) => setSelectedEscala(value)}
              />
            ) : (
              <p>Não há escalas disponíveis para alteração</p>
            )}
          </>
        ) : (
          <>
            <h1>Data fim?</h1>
            <DateSelect
              label="Data"
              modalLabel="Selecione o prazo final para resposta"
              onDaySelect={(value) => setNewEscalaDate(value)}
              selectedDay={newEscalaDate}
              disabled={gestaoRegra.alocacaoDataFim === 'MesmoDia'}
              min={new Date(dados.data)}
              validator={gestaoRegra.alocacaoDataFim === 'ObrigatorioDeterminarNaAlteracao'}
            />
            {gestaoRegra.alocacaoDataFim !== 'MesmoDia'   
              && gestaoRegra.alocacaoDataFim !== 'ObrigatorioDeterminarNaAlteracao'
              && (
              <div
                className="mt-1"
                style={{ fontSize: '11px', color: '#FF006B' }}
              >
                Deixe em branco para prazo indeterminado.
              </div>
            )}
          </>
        )}
      </DivBranca>
      <Row>
        {activeStep === 1 ? (
          <Col>
            <DivTransparente className="d-flex justify-content-center">
              <ButtonFolhaCerta
                buttonName="Cancelar"
                branco
                onClickButton={onClose}
                small
              />
              <ButtonFolhaCerta
                buttonName="Próximo"
                amarelo
                showIcon
                disabled={!selectedEscala}
                onClickButton={() => setActiveStep(2)}
                small
              />
            </DivTransparente>
          </Col>
        ) : (
          <Col>
            <DivTransparente className="d-flex justify-content-center">
              <ButtonFolhaCerta
                buttonName="Voltar"
                branco
                onClickButton={() => setActiveStep(1)}
                small
              />
              <ButtonFolhaCerta
                buttonName="Confirmar"
                amarelo
                showIcon
                disabled={gestaoRegra.alocacaoDataFim === 'ObrigatorioDeterminarNaAlteracao' && !newEscalaDate}
                onClickButton={() => {
                  if (dados && dados.alteracaoEscala) {
                    showMessageView({
                      message:
                        'Já existem alterações de escala para a data em questão. Para continuar, remova a ação existente.',
                      success: false,
                    });
                  } else {
                    sendRequest();
                  }
                }}
                small
              />
            </DivTransparente>
          </Col>
        )}
      </Row>
      <MessageModalList
        message={message}
        open={openMessage}
        messageSuccess={messageSuccess}
        onClick={() => {
          buttonConfirm();
        }}
      />
    </>
  );
};

AlterarEscala.propTypes = {};

export default AlterarEscala;
