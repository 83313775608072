import axios from 'axios';

const apiV3 = axios.create({
  baseURL: process.env.REACT_APP_APPV3,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    'X-XSS-Protection': '1; mode=block',
    'X-Content-Type-Options': 'nosniff',
    'X-Frame-Options': 'DENY',
    'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
    'Referrer-Policy': 'no-referrer',
    'Content-Security-Policy':
      "default-src https: wss: data: blob: 'unsafe-inline' 'unsafe-eval';",
    'Cross-Origin-Opener-Policy': 'same-origin',
    'Cross-Origin-Embedder-Policy': 'require-corp',
    'Cross-Origin-Resource-Policy': 'same-origin',
    Expires: '0',
  },
});

const apiIntermitentes = axios.create({
  baseURL: process.env.REACT_APP_INTERMITENTES,
});

const apiSobreaviso = axios.create({
  baseURL: process.env.REACT_APP_SOBREAVISO,
});

const apiRelatorio = axios.create({
  baseURL: process.env.REACT_APP_RELATORIO,
});

const apiAlocacaoRegra = axios.create({
  baseURL: process.env.REACT_APP_ALOCACAO_REGRA,
});

const apiReconhecimentoFacial = axios.create({
  baseURL: process.env.REACT_APP_KIOSK,
  // baseURL process.env.REACT_APP_ps ||: 'http://demo5879071.mockable.io/',
});

const apiLogin = axios.create({
  baseURL: process.env.REACT_APP_AUTH,
  // timeout: 3000,
  headers: {
    'Content-Type': 'application/json',
  },
});

const apiLoginV2 = axios.create({
  baseURL: process.env.REACT_APP_AUTH_V2,
  // timeout: 3000,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    'X-XSS-Protection': '1; mode=block',
    'X-Content-Type-Options': 'nosniff',
    'X-Frame-Options': 'DENY',
    'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
    'Referrer-Policy': 'no-referrer',
    'Content-Security-Policy':
      "default-src https: wss: data: blob: 'unsafe-inline' 'unsafe-eval';",
    'Cross-Origin-Opener-Policy': 'same-origin',
    'Cross-Origin-Embedder-Policy': 'require-corp',
    'Cross-Origin-Resource-Policy': 'same-origin',
    Expires: '0',
  },
});

const apiMotorNovo = axios.create({
  baseURL: process.env.REACT_APP_MOTOR_NOVO,
  headers: {
    'Content-Type': 'application/json',
  },
});

const apiMotorNovoBancoHoras = axios.create({
  baseURL: process.env.REACT_APP_MOTOR_BANCO_HORA,
  headers: {
    'Content-Type': 'application/json',
  },
});

const apiIndicadores = axios.create({
  baseURL: process.env.REACT_APP_INDICADORES,
  headers: {
    'Content-Type': 'application/json',
  },
});

const apiFiltrosHolerite = axios.create({
  baseURL: process.env.REACT_APP_FILTROS_HOLERITE,
  headers: {
    'Content-Type': 'application/json',
  },
});

const apiHolerite = axios.create({
  baseURL: process.env.REACT_APP_HOLERITE,
  headers: {
    'Content-Type': 'application/json',
  },
});

const apiFolhacertaBase = axios.create({
  baseURL: process.env.REACT_APP_FOLHA_CERTA_BASE,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    'X-XSS-Protection': '1; mode=block',
    'X-Content-Type-Options': 'nosniff',
    'X-Frame-Options': 'DENY',
    'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
    'Referrer-Policy': 'no-referrer',
    'Content-Security-Policy':
      "default-src https: wss: data: blob: 'unsafe-inline' 'unsafe-eval';",
    'Cross-Origin-Opener-Policy': 'same-origin',
    'Cross-Origin-Embedder-Policy': 'require-corp',
    'Cross-Origin-Resource-Policy': 'same-origin',
    Expires: '0',
  },
});

const apiImportacao = axios.create({
  baseURL: process.env.REACT_APP_IMPORTACAO,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

const apiRelatorioBancoHoras = axios.create({
  baseURL: process.env.REACT_APP_BANCO_HORA,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

const apiExportacao = axios.create({
  baseURL: process.env.REACT_APP_EXPORTACAO,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

const apiExportacaoZip = axios.create({
  baseURL: process.env.REACT_APP_EXPORTACAO,
  responseType: 'arraybuffer',
  headers: {
    'Content-Type': 'application/json',
  },
});

const apiIntegracao = axios.create({
  baseURL: process.env.REACT_APP_INTEGRACAO,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

const apiEscalas = axios.create({
  baseURL: process.env.REACT_APP_ESCALAS,
  headers: {
    'Content-Type': 'application/json',
  },
});

const apiDivergencia = axios.create({
  baseURL: process.env.REACT_APP_DIVERGENCIA,
});

const apiJornadaEvento = axios.create({
  baseURL: process.env.REACT_APP_JORNADA_EVENTO,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

const apiEspelhoPonto = axios.create({
  baseURL: process.env.REACT_APP_ESPELHO_PONTO,
  headers: {
    'Content-Type': 'application/json',
  },
});

const apiEventos = axios.create({
  baseURL: process.env.REACT_APP_EVENTOS,
});

const apiBancoFolgas = axios.create({
  baseURL: process.env.REACT_APP_BANCO_FOLGAS,
  headers: {
    'Content-Type': 'application/json',
  },
});

const apiBase = axios.create({
  baseURL: process.env.REACT_APP_BASE,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    'X-XSS-Protection': '1; mode=block',
    'X-Content-Type-Options': 'nosniff',
    'X-Frame-Options': 'DENY',
    'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
    'Referrer-Policy': 'no-referrer',
    'Content-Security-Policy':
      "default-src https: wss: data: blob: 'unsafe-inline' 'unsafe-eval';",
    'Cross-Origin-Opener-Policy': 'same-origin',
    'Cross-Origin-Embedder-Policy': 'require-corp',
    'Cross-Origin-Resource-Policy': 'same-origin',
    Expires: '0',
  },
});

const apiJornadaDeEventos = axios.create({
  baseURL: process.env.REACT_APP_JORNADA_DE_EVENTOS,
  headers: {
    'Content-Type': 'application/json',
  },
});

const apiJornadaDeEventosPostPut = axios.create({
  baseURL: process.env.REACT_APP_MOTOR_NOVO,
  headers: {
    'Content-Type': 'application/json',
  },
});

const apiVerba = axios.create({
  baseURL: process.env.REACT_APP_VERBA,
});

const apiFerias = axios.create({
  baseURL: process.env.REACT_APP_FERIAS,
});

const apiKiosk = axios.create({
  baseURL: process.env.REACT_APP_KIOSK,
});

export {
  apiAlocacaoRegra,
  apiBancoFolgas,
  apiBase,
  apiDivergencia,
  apiEscalas,
  apiEspelhoPonto,
  apiEventos,
  apiExportacao,
  apiExportacaoZip,
  apiFerias,
  apiFiltrosHolerite,
  apiFolhacertaBase,
  apiHolerite,
  apiImportacao,
  apiIndicadores,
  apiIntegracao,
  apiIntermitentes,
  apiJornadaDeEventos,
  apiJornadaDeEventosPostPut,
  apiJornadaEvento,
  apiKiosk,
  apiLogin,
  apiLoginV2,
  apiMotorNovo,
  apiMotorNovoBancoHoras,
  apiReconhecimentoFacial,
  apiRelatorio,
  apiRelatorioBancoHoras,
  apiSobreaviso,
  apiV3,
  apiVerba,
};
