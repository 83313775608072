import produce from 'immer';

import { Types } from '../types';

// Reducers
const INITIAL_STATE = {
  loading: false,

  listaDemonstrativo: null,
  listaFechamento: null,
  listaCiclos: null,
  listaBancoHoras: null,
  listaBancoHorasPorUsuario: null,
  listaPeriodo: null,
  responseRelatorio: null,
};

export default function relatorioApiBancoHoras(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.API_BANCO_HORAS_REQUEST: {
        draft.loading = true;
        draft.responseRelatorio = null;
        break;
      }

      case Types.RELATORIO_BANCO_HORAS_RESPONSE: {
        draft.responseRelatorio = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.LISTA_BANCO_HORAS_RESPONSE: {
        draft.listaBancoHoras = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.LISTA_BANCO_HORAS_POR_USUARIO_RESPONSE: {
        draft.listaBancoHorasPorUsuario = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.LISTA_CICLOS_RESPONSE: {
        draft.listaCiclos = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.LISTA_PERIODO_RESPONSE: {
        draft.listaPeriodo = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.LISTA_DEMONSTRATIVO_RESPONSE: {
        draft.listaDemonstrativo = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.LISTA_FECHAMENTO_RESPONSE: {
        draft.listaFechamento = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.CLEAR_RELATORIO: {
        draft.loading = false;
        draft.listaCiclos = null;
        draft.listaPeriodos = null;
        draft.listaBancoHoras = null;
        draft.listaDemonstrativo = null;
        draft.listaFechamento = null;
        draft.responseRelatorio = null;
        draft.listaBancoHorasPorUsuario = null;
        break;
      }

      case Types.LOGOUT: {
        draft.loading = false;
        draft.listaCiclos = null;
        draft.listaPeriodos = null;
        draft.listaBancoHoras = null;
        draft.listaDemonstrativo = null;
        draft.listaFechamento = null;
        draft.responseRelatorio = null;
        draft.listaBancoHorasPorUsuario = null;
        break;
      }
      default:
    }
  });
}
