import { takeLatest, call, put, all } from 'redux-saga/effects';
import { Types } from '../../../types';
// eslint-disable-next-line import/no-cycle
import { requestMotorNovoBancoHoras } from '~/services/request';

import {
  getBancoHorasConfiguracaoFaixaVerbaResponse,
  postBancoHorasConfiguracaoFaixaVerbaResponse,
  putBancoHorasConfiguracaoFaixaVerbaResponse,
} from './actions';

export function* bancoHorasConfiguracaoFaixaVerba({ payload, method, id }) {
  const params = {
    url: id
      ? `/api/v1/bancoHora/configuracao/faixa/verba/${id}`
      : '/api/v1/bancoHora/configuracao/faixa/verba',
    method,
    body: payload,
  };

  switch (method) {
    case 'GET':
      try {
        const response = yield call(requestMotorNovoBancoHoras, params);
        yield put(getBancoHorasConfiguracaoFaixaVerbaResponse(response.data));
      } catch (error) {
        yield put(
          getBancoHorasConfiguracaoFaixaVerbaResponse({ success: false }),
        );
      }
      break;
    case 'POST':
      try {
        const response = yield call(requestMotorNovoBancoHoras, params);

        const aux = {
          id: response.headers.location,
          data: response.data,
        };
        yield put(postBancoHorasConfiguracaoFaixaVerbaResponse(aux));
      } catch (error) {
        yield put(
          postBancoHorasConfiguracaoFaixaVerbaResponse({ success: false }),
        );
      }
      break;
    case 'PUT':
      try {
        const response = yield call(requestMotorNovoBancoHoras, params);
        yield put(putBancoHorasConfiguracaoFaixaVerbaResponse(response.data));
        yield put(postBancoHorasConfiguracaoFaixaVerbaResponse({ id }));
      } catch (error) {
        yield put(
          putBancoHorasConfiguracaoFaixaVerbaResponse({ success: false }),
        );
      }
      break;

    default:
      break;
  }
}

export default all([
  takeLatest(
    Types.BANCO_HORAS_CONFIGURACAO_FAIXA_VERBA_REQUEST,
    bancoHorasConfiguracaoFaixaVerba,
  ),
]);
