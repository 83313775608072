import produce from 'immer';

import { Types } from '../types';

// Reducers
const INITIAL_STATE = {
  payload: null,
  error: null,

  payloadArquivar: null,
  payloadDesarquivar: null,

  loading: false,
};

export default function grupo(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.GRUPO_GET_REQUEST: {
        draft.loading = true;
        break;
      }

      case Types.GRUPO_GET_SUCCESS: {
        draft.payload = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.GRUPO_GET_FAILURE: {
        draft.payload = null;
        draft.loading = false;
        break;
      }

      case Types.GRUPO_POST_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GRUPO_POST_SUCCESS: {
        draft.payload = action.payload.data;
        draft.loading = false;
        break;
      }
      case Types.GRUPO_POST_FAILURE: {
        draft.error = action.error;
        draft.loading = false;
        break;
      }

      case Types.GRUPO_ARQUIVAR_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GRUPO_ARQUIVAR_RESPONSE: {
        draft.payloadArquivar = action.payload;
        draft.loading = false;
        break;
      }

      case Types.GRUPO_DESARQUIVAR_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GRUPO_DESARQUIVAR_RESPONSE: {
        draft.payloadDesarquivar = action.payload;
        draft.loading = false;
        break;
      }

      case Types.GRUPO_EDITAR_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GRUPO_EDITAR_RESPONSE: {
        draft.payload = action.payload;
        draft.loading = false;
        break;
      }

      case Types.GRUPO_CLEAR_DATA: {
        draft.payload = null;
        draft.payloadDesarquivar = null;
        draft.payloadArquivar = null;
        draft.error = null;
        draft.loading = false;
        break;
      }

      case Types.LOGOUT: {
        draft.payload = null;
        draft.payloadDesarquivar = null;
        draft.payloadArquivar = null;
        draft.error = null;
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
