import { takeLatest, call, put, all, delay } from 'redux-saga/effects';
import { Types } from '../types';
import { toast } from 'react-toastify';
import { requestBaseV3 } from '~/services/request';
import { carregarMotivosMarcacaoSuccess, carregarMotivosMarcacaoFailure } from './actions';

export function* carregarMotivoMarcacao() {
	let params = {
		url: '/CarregarMotivosMarcacao',
		method: 'GET',
	};

	try {
		const response = yield call(requestBaseV3, params);

		if (response.data.Success) {
			const data = response.data;

			yield put(carregarMotivosMarcacaoSuccess(data));
			yield delay(2000);
		} else {
			toast.error(response.data.Error);
			yield put(carregarMotivosMarcacaoFailure());
		}
	} catch (error) {
		toast.error("Falha na autenticação");
		yield put(carregarMotivosMarcacaoFailure());
	}
}

export default all([
	takeLatest(Types.CARRGAR_MOTIVOS_MARCACAO_REQUEST, carregarMotivoMarcacao)
]);