/* eslint-disable react/prop-types */
/* eslint-disable no-return-assign */
/* eslint-disable consistent-return */
import React, { createContext, useContext, useCallback, useState } from 'react';

import { useSelector } from 'react-redux';

import { requestEscalas } from '~/services/request';

const Contexto = createContext();

export default function Provedor({ children }) {
  const usuario = useSelector((state) => state.loginPayload.loggedIn.Usuario);

  const [calendario, setCalendario] = useState(null);
  const [calendarioDia, setCalendarioDia] = useState(null);
  const [acoes, setAcoes] = useState(null);
  const [acao, setAcao] = useState(null);
  const [tags, setTags] = useState(null);
  const [statusAcao, setStatusAcao] = useState(null);
  const [saldo, setSaldo] = useState(null);

  const recuperaCalendario = useCallback(async (queryParams) => {
    let cleanUp = false;

    const params = {
      url: `/calendario/equipe`,
      method: 'GET',
      queryParams,
    };

    const response = await requestEscalas(params);

    if (cleanUp) return;

    if (response.data) {
      setCalendario(response.data);
    }

    return () => (cleanUp = true);
  }, []);

  const recuperaCalendarioDia = useCallback(async (queryParams) => {
    let cleanUp = false;

    const params = {
      url: `/calendario/dia`,
      method: 'GET',
      queryParams,
    };

    const response = await requestEscalas(params);

    if (cleanUp) return;

    if (response.data) {
      setCalendarioDia(response.data);
    }

    return () => (cleanUp = true);
  }, []);

  const recuperaAcao = useCallback(async (queryParams) => {
    let cleanUp = false;

    const params = {
      url: `/acao`,
      method: 'GET',
      queryParams,
    };

    const response = await requestEscalas(params);

    if (cleanUp) return;

    if (response.data) {
      setAcoes(response.data);
    }
    return () => (cleanUp = true);
  }, []);

  const recuperaAcaoPorId = useCallback(async (queryParams) => {
    let cleanUp = false;

    if (queryParams && queryParams.id) {
      const params = {
        url: `/acao/${queryParams.id}`,
        method: 'GET',
        queryParams,
      };

      const response = await requestEscalas(params);

      if (cleanUp) return;

      if (response.data) {
        setAcao(response.data);
      }
    }

    return () => (cleanUp = true);
  }, []);

  const alteraStatusAcao = useCallback(async (body) => {
    let cleanUp = false;
    const params = {
      url: `/acao/status`,
      method: 'PUT',
      body,
    };

    const response = await requestEscalas(params);

    if (cleanUp) return;

    if (response.data) {
      setStatusAcao(response.data);
    }

    return () => (cleanUp = true);
  }, []);

  const recuperaTags = useCallback(async (queryParams) => {
    let cleanUp = false;
    const params = {
      url: `/tag`,
      method: 'GET',
      queryParams,
    };

    const response = await requestEscalas(params);

    if (cleanUp) return;

    if (response.data) {
      setTags(response.data);
    }

    return () => (cleanUp = true);
  }, []);

  const recuperaSaldo = useCallback(async (queryParams) => {
    let cleanUp = false;
    const params = {
      url: `/tag/saldo`,
      method: 'GET',
      queryParams,
    };

    const response = await requestEscalas(params);

    if (cleanUp) return;

    if (response.data) {
      setSaldo(response.data);
    }

    return () => (cleanUp = true);
  }, []);

  return (
    <Contexto.Provider
      value={{
        recuperaCalendario,
        recuperaCalendarioDia,
        recuperaAcao,
        recuperaAcaoPorId,
        recuperaTags,
        alteraStatusAcao,
        recuperaSaldo,
        calendario,
        calendarioDia,
        acoes,
        acao,
        tags,
        statusAcao,
        usuario,
        saldo,
      }}
    >
      {children}
    </Contexto.Provider>
  );
}

export function useEscalasDaEquipe() {
  const contexto = useContext(Contexto);

  if (!contexto) {
    throw new Error('useEscalasDaEquipe deve ser usado dentro do Provedor');
  }

  return contexto;
}
