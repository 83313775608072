/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React from 'react';
import { useTransition } from 'react-spring';

import { Conteiner } from './styles';
import Detalhe from './Detalhe';

export default function ConteinerDetalheDoDia({
  dados,
  isEmployed,
  onClose = () => {},
  callback,
}) {
  const dadosComTransicao = useTransition(dados, (dados2) => dados2.id, {
    from: { right: '-120%' },
    enter: { right: '0' },
    leave: { right: '-120%' },
  });

  return (
    <Conteiner>
      {dadosComTransicao &&
        dadosComTransicao.map(({ item, key, props }) => (
          <Detalhe
            key={key}
            dados={item}
            isEmployed={isEmployed}
            style={props}
            onClose={onClose}
            callback={callback}
          />
        ))}
    </Conteiner>
  );
}
