import { takeLatest, call, put, all, delay } from 'redux-saga/effects';
import { Types } from '../../types';
import { requestAlocacaoRegra } from '~/services/request';

import {
  alocacaoRegraApuracaoResponse,
  alocacaoRegraApuracaoResponsePut,
  alocacaoRegraApuracaoResponseStatusPut,
  alocacaoRegraApuracaoResponseGet,
  alocacaoRegraApuracaoCountResponse,
  alocacaoRegraApuracaoGetByIDResponse,
  alocacaoRegraApuracaoHistoricoResponse,
} from './actions';

export function* alocacaoRegraApuracao({ payload, method }) {
  const params = {
    url: '/alocacao',
    method,
    body: payload,
  };
  try {
    const response = yield call(requestAlocacaoRegra, params);

    yield put(alocacaoRegraApuracaoResponse(response.data));
  } catch (error) {
    yield put(alocacaoRegraApuracaoResponse({ success: false }));
  }
}

export function* alocacaoRegraApuracaoCount() {
  const params = {
    url: '/alocacao/count/',
    method: 'GET',
  };

  try {
    const response = yield call(requestAlocacaoRegra, params);

    yield put(alocacaoRegraApuracaoCountResponse(response.data));
  } catch (error) {
    yield put(alocacaoRegraApuracaoCountResponse({ success: false }));
  }
}

export function* alocacaoRegraApuracaoPut({ payload, method }) {
  const params = {
    url: '/alocacao',
    method,
    body: payload,
  };

  try {
    const response = yield call(requestAlocacaoRegra, params);

    yield put(alocacaoRegraApuracaoResponsePut(response.data));
  } catch (error) {
    yield put(alocacaoRegraApuracaoResponsePut({ success: false }));
  }
}

export function* alocacaoRegraApuracaoStatusPut({ payload, method }) {
  const params = {
    url: '/alocacao/status',
    method,
    body: payload,
  };

  try {
    const response = yield call(requestAlocacaoRegra, params);

    yield put(alocacaoRegraApuracaoResponseStatusPut(response.data));
  } catch (error) {
    yield put(alocacaoRegraApuracaoResponseStatusPut({ success: false }));
  }
}

export function* alocacaoRegraApuracaoGet({ payload }) {
  const params = {
    url: '/alocacao',
    method: 'GET',
    queryParams: payload,
    body: {},
  };

  try {
    const response = yield call(requestAlocacaoRegra, params);

    yield put(alocacaoRegraApuracaoResponseGet(response.data));
  } catch (error) {
    yield put(alocacaoRegraApuracaoResponseGet({ success: false }));
  }
}

export function* alocacaoRegraApuracaoGetByID({ payload }) {
  const params = {
    url: `/alocacao/get-by-id/${payload}`,
    method: 'GET',
  };

  try {
    const response = yield call(requestAlocacaoRegra, params);

    yield put(alocacaoRegraApuracaoGetByIDResponse(response.data));
  } catch (error) {
    yield put(alocacaoRegraApuracaoGetByIDResponse({ success: false }));
  }
}

export function* alocacaoRegraApuracaoHistorico({ id }) {
  const params = {
    url: `/alocacao/historico/${id}`,
    method: 'GET',
  };

  try {
    const response = yield call(requestAlocacaoRegra, params);

    yield put(alocacaoRegraApuracaoHistoricoResponse(response.data));
  } catch (error) {
    yield put(alocacaoRegraApuracaoHistoricoResponse({ success: false }));
  }
}

export default all([
  takeLatest(Types.ALOCACAO_REGRA_APURACAO_REQUEST, alocacaoRegraApuracao),
  takeLatest(
    Types.ALOCACAO_REGRA_APURACAO_REQUEST_PUT,
    alocacaoRegraApuracaoPut,
  ),
  takeLatest(
    Types.ALOCACAO_REGRA_APURACAO_REQUEST_STATUS_PUT,
    alocacaoRegraApuracaoStatusPut,
  ),
  takeLatest(
    Types.ALOCACAO_REGRA_APURACAO_REQUEST_GET,
    alocacaoRegraApuracaoGet,
  ),
  takeLatest(
    Types.ALOCACAO_REGRA_APURACAO_COUNT_REQUEST,
    alocacaoRegraApuracaoCount,
  ),
  takeLatest(
    Types.ALOCACAO_REGRA_APURACAO_BY_ID_REQUEST_GET,
    alocacaoRegraApuracaoGetByID,
  ),
  takeLatest(
    Types.ALOCACAO_REGRA_APURACAO_HISTORICO_REQUEST,
    alocacaoRegraApuracaoHistorico,
  ),
]);
