import produce from 'immer';
import { Types } from '../types';

// Reducers
const INITIAL_STATE = {
  loading: false,
  lista: null,
  layout: null,
  exportacao: null,
  listaLayouts: null,
  listaVerbasByLayout: null,
};

export default function layoutExportacao(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.REQUEST_EXPORTACAO_VERBAS: {
        draft.loading = true;
        break;
      }

      case Types.REQUEST_TIPO_ASSINATURA_ELETRONICA: {
        draft.loading = true;
        break;
      }

      case Types.TIPO_ASSINATURA_ELETRONICA_RESPONSE: {
        draft.tipoAssinaturaEletronicaReponse = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.REQUEST_BY_ID_EXPORTACAO_VERBAS: {
        draft.loading = true;
        break;
      }

      case Types.LAYOUT_BY_ID_RESPONSE: {
        draft.layout = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.LISTA_LAYOUTS_RESPONSE: {
        draft.lista = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.VERBAS_BY_LAYOUT_TIPO_RESPONSE: {
        draft.listaVerbasByLayout = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.LISTA_LAYOUTS_BY_ID_RESPONSE: {
        draft.listaLayouts = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.NOVO_LAYOUT_RESPONSE: {
        draft.loading = false;
        break;
      }

      case Types.UPDATE_LAYOUT_RESPONSE: {
        draft.loading = false;
        break;
      }

      case Types.NOVA_EXPORTACAO_LAYOUT_RESPONSE: {
        draft.exportacao = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.CLEAR_EXPORTCACAO_VERBAS: {
        draft.loading = false;
        draft.lista = null;
        draft.layout = null;
        draft.exportacao = null;
        draft.listaLayouts = null;
        draft.listaVerbasByLayout = null;
        break;
      }

      case Types.LOGOUT: {
        draft.loading = false;
        draft.lista = null;
        draft.layout = null;
        draft.exportacao = null;
        draft.listaLayouts = null;
        draft.listaVerbasByLayout = null;
        break;
      }
      default:
    }
  });
}
