import { Types } from '~/store/modules/types';

const url = '/alocacao';

function responseListaAlocacaoEscalas(data, callback) {
  if (callback) {
    callback(data);
  }

  return {
    type: Types.RESPONSE_ALOCACAO_ESCALAS,
    payload: { data },
  };
}

export function requestAlocacaoEscalas(params, callback) {
  return {
    type: Types.REQUEST_ALOCACAO_ESCALAS,
    url,
    method: 'POST',
    body: params,
    responseAction: responseListaAlocacaoEscalas,
    callback,
  };
}

export function getListaAlocacaoEscalas(params, callback) {
  return {
    type: Types.REQUEST_ALOCACAO_ESCALAS,
    url,
    method: 'GET',
    queryParams: params,
    responseAction: responseListaAlocacaoEscalas,
    callback,
  };
}

function responseAlocacaoEscalasById(data, callback) {
  if (callback) {
    callback(data);
  }

  return {
    type: Types.RESPONSE_ALOCACAO_ESCALAS_BY_ID,
    payload: { data },
  };
}

export function getAlocacaoEscalasById(params, callback) {
  return {
    type: Types.REQUEST_ALOCACAO_ESCALAS_BY_ID,
    url: `/alocacao/${params.id}`,
    method: 'GET',
    responseAction: responseAlocacaoEscalasById,
    callback,
  };
}

function responseAlocacaoHistorico(data, callback) {
  if (callback) {
    callback(data);
  }

  return {
    type: Types.RESPONSE_ALOCACAO_HISTORICO,
    payload: { data },
  };
}

export function getAlocacaoHistorico(params, callback) {
  return {
    type: Types.REQUEST_ALOCACAO_HISTORICO,
    url: `/alocacao/historico`,
    method: 'GET',
    queryParams: params,
    responseAction: responseAlocacaoHistorico,
    callback,
  };
}

function responseAlocacaoHistoricoById(data, callback) {
  if (callback) {
    callback(data);
  }

  return {
    type: Types.RESPONSE_ALOCACAO_HISTORICO_BY_ID,
    payload: { data },
  };
}

export function getAlocacaoHistoricoById(params, callback) {
  return {
    type: Types.REQUEST_ALOCACAO_HISTORICO,
    url: `/alocacao/historico/${params.Id}`,
    method: 'GET',
    queryParams: { AlocacaoId: params.Id },
    responseAction: responseAlocacaoHistoricoById,
    callback,
  };
}

function responsePutAndPost(data, callback) {
  if (callback) {
    callback(data);
  }
  return {
    type: Types.CLEAR_ALOCACAO_ESCALAS,
  };
}

export function putAlocacaoEscalas(params, callback) {
  return {
    type: Types.REQUEST_PUT_POST_ALOCACAO_ESCALAS,
    url,
    method: 'PUT',
    body: params,
    responseAction: responsePutAndPost,
    callback,
  };
}

export function postAlocacaoEscalas(params, callback) {
  return {
    type: Types.REQUEST_PUT_POST_ALOCACAO_ESCALAS,
    url,
    method: 'POST',
    body: params,
    responseAction: responsePutAndPost,
    callback,
  };
}

export function requestChangeStatusAlocacao(params, callback) {
  return {
    type: Types.REQUEST_PUT_POST_ALOCACAO_ESCALAS,
    url: '/alocacao/status',
    method: 'PUT',
    body: params,
    responseAction: responsePutAndPost,
    callback,
  };
}

export function clearAlocacao() {
  return { type: Types.CLEAR_ALOCACAO_ESCALAS };
}
