import { takeLatest, call, put, all, delay } from 'redux-saga/effects';
import { Types } from '../types';
import { requestBaseV3 } from '~/services/request';
import { carregarAusenciasResponse } from './actions';

export function* carregarAusencias() {
	let params = {
		url: '/CarregarAusencias',
		method: 'GET',
	};

	try {
		const response = yield call(requestBaseV3, params);
		yield put(carregarAusenciasResponse(response.data));
	} catch (error) {
		yield put(carregarAusenciasResponse({ success: false }));
	}
}

export default all([
	takeLatest(Types.CARREGAR_AUSENCIAS_REQUEST, carregarAusencias)
]);