import React, { useState, useCallback } from 'react';

import { useAcao } from '~/hooks/escalas';

import Input from '~/components/_layouts/forms/Input';

import MessageModalList from '~/components/_layouts/modals/MessageModalList';

import { getStringForRequest } from '~/utils/dateFormat';

import { DivBranca, DivTransparente } from './styles';

export default function Acao({ dados, onClose, callback }) {
  const { usuario, data } = dados;

  const { alterarEntrada } = useAcao();

  const [novaEntrada, setNovaEntrada] = useState();

  const [message, setMessage] = useState('');
  const [openMessage, setOpenMessage] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState(false);

  const showMessageView = (data) => {
    setMessage(data.message);
    setOpenMessage(true);
    setMessageSuccess(data.success);
  };

  const buttonConfirm = () => {
    setOpenMessage(false);
    onClose();
    callback();
  };

  const salvarNovoHorario = useCallback(async () => {
    const horaMin = novaEntrada.split(':');
    const date = new Date();
    date.setHours(horaMin[0]);
    date.setMinutes(horaMin[1]);
    const params = {
      usuarioId: usuario.Id || usuario.usuarioId,
      data,
      novoHorario: getStringForRequest(
        `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`,
      ),
    };
    
    try {
      const response = await alterarEntrada(params);
      showMessageView(response);
    } catch (err) {
      showMessageView({
        success: false,
        message: err.response.data.message
          ? err.response.data.message
          : 'Erro ao Salvar Novo Horário',
      });
    }
  }, [novaEntrada]);

  return (
    <>
      <DivBranca>
        <h1>Novo horário de entrada?</h1>
        <Input
          type="time"
          value={novaEntrada}
          onChange={(value) => setNovaEntrada(value)}
        />
      </DivBranca>
      <DivTransparente>
        <button onClick={onClose}>cancelar</button>
        <button
          onClick={() => {
            if (dados && dados.alteracaoEscala) {
              showMessageView({
                message:
                  'Já existem alterações de escala para a data em questão. Para continuar, remova a ação existente.',
                success: false,
              });
            } else {
              salvarNovoHorario();
            }
          }}
        >
          confirmar
        </button>
      </DivTransparente>
      <MessageModalList
        message={message}
        open={openMessage}
        messageSuccess={messageSuccess}
        onClick={() => {
          buttonConfirm();
        }}
      />
    </>
  );
}
