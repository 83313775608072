import { takeLatest, call, put, all } from 'redux-saga/effects';
import { Types } from '../../../types';
// eslint-disable-next-line import/no-cycle
import { requestMotorNovoBancoHoras } from '~/services/request';

import {
  getBancoHorasEstagioResponse,
  putBancoHorasEstagioResponse,
} from './actions';

export function* bancoHorasEstagio({ payload, method, id, callback }) {
  const params = {
    url: id ? `/api/v1/bancoHora/estagio/${id}` : '/api/v1/bancoHora/estagio',
    method,
    body: payload,
  };

  switch (method) {
    case 'GET':
      try {
        const response = yield call(requestMotorNovoBancoHoras, params);
        yield put(getBancoHorasEstagioResponse(response.data, callback));
      } catch (error) {
        yield put(getBancoHorasEstagioResponse({ success: false }, callback));
      }
      break;
    case 'PUT':
      try {
        const response = yield call(requestMotorNovoBancoHoras, params);
        yield put(putBancoHorasEstagioResponse(response.data, callback));
      } catch (error) {
        yield put(putBancoHorasEstagioResponse({ success: false }, callback));
      }
      break;

    default:
      break;
  }
}

export default all([
  takeLatest(Types.BANCO_HORAS_ESTAGIO_REQUEST, bancoHorasEstagio),
]);
