import { Types } from '../../types';

export function alterarStatusLancamentoRequest(params, callback) {
  return {
    type: Types.ALTERAR_STATUS_LANCAMENTO_REQUEST,
    params,
    callback,
  };
}

export function alterarStatusLancamentoSuccess(data, callback) {
  if (callback)
    callback(data);

  return {
    type: Types.ALTERAR_STATUS_LANCAMENTO_SUCCESS,
    payload: { data },
  };
}

export function alterarStatusLancamentoFailure(data, callback) {
  if (callback)
    callback(data);

  return {
    type: Types.ALTERAR_STATUS_LANCAMENTO_FAILURE,
  };
}
