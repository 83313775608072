import produce from 'immer';

import { Types } from '../types';

// Reducers
const INITIAL_STATE = {
  payload: null,
  payloadPut: null,
  payloadGet: null,
  payloadCount: null,
  payloadAcoes: null,
  payloadGetById: null,
  payloadHistorico: null,
  payloadRelatorio: null,
  payloadRelatorioCsv: null,

  loading: false,
  loadingHistorico: false,
}

export default function lancamentoSobreaviso(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.LANCAMENTO_SOBREAVISO_REQUEST: {
        draft.loading = true;
        break;
      }

      case Types.LANCAMENTO_SOBREAVISO_REQUEST_PUT: {
        draft.loading = true;
        break;
      }

      case Types.LANCAMENTO_SOBREAVISO_REQUEST_GET: {
        draft.loading = true;
        break;
      }

      case Types.LANCAMENTO_SOBREAVISO_BY_ID_REQUEST_GET: {
        draft.loading = true;
        break;
      }

      case Types.LANCAMENTO_SOBREAVISO_ACAO_REQUEST: {
        draft.loading = true;
        break;
      }

      case Types.LANCAMENTO_SOBREAVISO_RELATORIO_REQUEST: {
        draft.loading = true;
        break;
      }

      case Types.LANCAMENTO_SOBREAVISO_RELATORIO_CSV_REQUEST: {
        draft.loading = true;
        break;
      }

      case Types.LANCAMENTO_SOBREAVISO_RELATORIO_CSV_RESPONSE: {
        draft.loading = false;
        draft.payloadRelatorioCsv = action.payload.data;
        break;
      }

      case Types.LANCAMENTO_SOBREAVISO_ACAO_REQUEST: {
        draft.loading = true;
        break;
      }

      case Types.LANCAMENTO_SOBREAVISO_ACAO_RESPONSE: {
        draft.loading = false;
        draft.payloadAcoes = action.payload.data;
        break;
      }

      case Types.LANCAMENTO_SOBREAVISO_RELATORIO_RESPONSE: {
        draft.payloadRelatorio = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.LANCAMENTO_SOBREAVISO_BY_ID_RESPONSE_GET: {
        draft.payloadGetById = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.LANCAMENTO_SOBREAVISO_RESPONSE_GET: {
        draft.payloadGet = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.LANCAMENTO_SOBREAVISO_RESPONSE: {
        draft.payload = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.LANCAMENTO_SOBREAVISO_RESPONSE_PUT: {
        draft.payloadPut = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.LANCAMENTO_SOBREAVISO_COUNT_REQUEST: {
        draft.loading = true;
        break;
      }

      case Types.LANCAMENTO_SOBREAVISO_COUNT_RESPONSE: {
        draft.payloadCount = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.LANCAMENTO_SOBREAVISO_HISTORICO_REQUEST: {
        draft.loadingHistorico = true;
        break;
      }

      case Types.LANCAMENTO_SOBREAVISO_HISTORICO_RESPONSE: {
        draft.payloadHistorico = action.payload.data;
        draft.loadingHistorico = false;
        break;
      }

      case Types.LANCAMENTO_SOBREAVISO_CLEAR_CSV: {
        draft.payloadRelatorioCsv = null;
        draft.loading = false;
        break;
      }

      case Types.LANCAMENTO_SOBREAVISO_CLEAR_DATA: {
        draft.payloadRelatorioCsv = null;
        draft.payloadRelatorio = null;
        draft.payloadHistorico = null;
        draft.payloadGetById = null;
        draft.payloadAcoes = null;
        draft.payloadCount = null;
        draft.payloadPut = null;
        draft.payloadGet = null;
        draft.payload = null;
        draft.loadingHistorico = false;
        draft.loading = false;
        break;
      }

      case Types.LOGOUT: {
        draft.payloadRelatorioCsv = null;
        draft.payloadRelatorio = null;
        draft.payloadHistorico = null;
        draft.payloadGetById = null;
        draft.payloadAcoes = null;
        draft.payloadCount = null;
        draft.payloadPut = null;
        draft.payloadGet = null;
        draft.payload = null;
        draft.loadingHistorico = false;
        draft.loading = false;
        break;
      }
      default:
    }
  });
}