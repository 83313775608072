import styled, { css } from 'styled-components';

export const Conteiner = styled.div`
  position: fixed;
  right: 0;
  top: 221px;
  overflow: hidden;
  padding-top: 100;
  height: 100%;
  z-index: 1001;

  ${({ isEmployed }) =>
    isEmployed &&
    css`
      transform: translateY(85px);
    `}
`;
