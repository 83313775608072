import { Types } from '../../types';

export function tratamentoRequest({ params, method, id }) {
  return {
    type: Types.TRATAMENTO_REQUEST,
    payload: params,
    method: method,
    id: id
  };
}

export function getTratamentoResponse(data) {
  return {
    type: Types.GET_TRATAMENTO_RESPONSE,
    payload: { data },
  };
}

export function postTratamentoResponse(data) {
  return {
    type: Types.POST_TRATAMENTO_RESPONSE,
    payload: { data },
  };
}

export function putTratamentoResponse(data) {
  return {
    type: Types.PUT_TRATAMENTO_RESPONSE,
    payload: { data },
  };
}
