import { Types } from '../../types';

export function filtrarPessoasRequest(params) {
  return {
    type: Types.FILTRAR_PESSOAS_REQUEST,
    payload: params,
  };
}

export function filtrarPessoasResponse(data) {
  return {
    type: Types.FILTRAR_PESSOAS_RESPONSE,
    payload: { data },
  };
}
