import { Types } from '../types';

export function salvarAvisoAusenciaLancamento({ params }, callback)  {
  return {
    type: Types.SALVAR_AVISO_AUSENCIA_LANCAMENTO_REQUEST,
    method: 'POST',
    payload: params,
    callback: callback
  };
}

export function salvarAvisoAusenciaLancamentoResponse(data, callback) {
  callback(data);
  return {
    type: Types.SALVAR_AVISO_AUSENCIA_LANCAMENTO_RESPONSE,
    payload: { data },
  };
}

// Alterar (Reprovar, Cancelar ou Aprovar)
export function alterarStatusLancamentoRequest({ body }, callback) {
  return {
    type: Types.ALTERAR_AUSENCIA_LANCAMENTO_REQUEST,
    method: 'POST',
    payload: body,
    callback: callback
  };
}

export function alterarStatusLancamentoResponse(data, callback) {
  callback(data);
  return {
    type: Types.ALTERAR_AUSENCIA_LANCAMENTO_RESPONSE,
    payload: { data },
  };
}