import { Types } from '../types';

export function regrasConvocacaoRequest(params) {
  return {
    type: Types.REGRAS_CONVOCACAO_REQUEST,
    payload: params
  };
}

export function regrasConvocacaoSuccess(data) {
  return {
    type: Types.REGRAS_CONVOCACAO_SUCCESS,
    payload: data,
  };
}

export function regrasConvocacaoFailure(data) {
  return {
    type: Types.REGRAS_CONVOCACAO_FAILURE,
    payload: data,
  };
}

export function regrasAtivasRequest() {
  return {
    type: Types.REGRAS_ATIVAS_REQUEST
  };
}

export function regrasAtivasSuccess(data) {
  return {
    type: Types.REGRAS_ATIVAS_SUCCESS,
    payload: { data },
  };
}

export function regrasAtivasFailure() {
  return {
    type: Types.REGRAS_ATIVAS_FAILURE
  };
}

export function regrasArquivadasRequest() {
  return {
    type: Types.REGRAS_ARQUIVADAS_REQUEST
  };
}

export function regrasArquivadasSuccess(data) {
  return {
    type: Types.REGRAS_ARQUIVADAS_SUCCESS,
    payload: { data },
  };
}

export function regrasArquivadasFailure() {
  return {
    type: Types.REGRAS_ARQUIVADAS_FAILURE
  };
}

export function regrasUtilizadasRequest() {
  return {
    type: Types.REGRAS_UTILIZADAS_REQUEST
  };
}

export function regrasUtilizadasSuccess(data) {
  return {
    type: Types.REGRAS_UTILIZADAS_SUCCESS,
    payload: { data },
  };
}

export function regrasUtilizadasFailure() {
  return {
    type: Types.REGRAS_UTILIZADAS_FAILURE
  };
}

export function regrasConvocacaoGetRequest(data) {
  return {
    type: Types.REGRAS_CONVOCACAO_GET_REQUEST,
    payload: data,
  };
}

export function regrasConvocacaoGetSuccess(data) {
  return {
    type: Types.REGRAS_CONVOCACAO_GET_SUCCESS,
    payload: data,
  };
}

export function regrasConvocacaoGetFailure() {
  return {
    type: Types.REGRAS_CONVOCACAO_GET_FAILURE
  };
}

export function regrasConvocacaoPutRequest(params) {
  return {
    type: Types.REGRAS_CONVOCACAO_PUT_REQUEST,
    payload: params,
  };
}

export function regrasConvocacaoPutResponse(data) {
  return {
    type: Types.REGRAS_CONVOCACAO_PUT_RESPONSE,
    payload: data,
  };
}

export function regrasClearData() {
  return {
    type: Types.REGRAS_CLEAR_DATA
  };
}
