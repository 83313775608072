import { Types } from '../../types';

export function filtrarGruposRequest(params) {
  return {
    type: Types.FILTRAR_GRUPOS_REQUEST,
    payload: params,
  };
}

export function filtrarGruposResponse(data) {
  return {
    type: Types.FILTRAR_GRUPOS_RESPONSE,
    payload: { data },
  };
}
