import { Types } from '../../types';

export function domingoDsrRequest({ params, method, id }) {
  return {
    type: Types.DOMINGO_DSR_REQUEST,
    payload: params,
    method: method,
    id: id
  };
}

export function getDomingoDsrResponse(data) {
  return {
    type: Types.GET_DOMINGO_DSR_RESPONSE,
    payload: { data },
  };
}

export function postDomingoDsrResponse(data) {
  return {
    type: Types.POST_DOMINGO_DSR_RESPONSE,
    payload: { data },
  };
}

export function putDomingoDsrResponse(data) {
  return {
    type: Types.PUT_DOMINGO_DSR_RESPONSE,
    payload: { data },
  };
}
