import { takeLatest, call, put, all } from 'redux-saga/effects';

import { Types } from '../../types';
import { requestHolerites } from '../../../../services/request';
import { responseListarHolerites } from './actions';

export function* listarTodosHolerites({ payload }) {
  const params = {
    url: '/importacao',
    method: 'GET',
    body: payload,
  };

  try {
    const response = yield call(requestHolerites, params);
    yield put(responseListarHolerites(response.data));
  } catch (error) {
    yield put(responseListarHolerites({ success: false }));
  }
}

export default all([
  takeLatest(Types.REQUEST_LISTAR_HOLERITES, listarTodosHolerites),
]);
