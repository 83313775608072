import produce from 'immer';
import { Types } from '~/store/modules/types';

const INITIAL_STATE = {
  loading: false,

  escalaPadrao: null,
  escala: null,
  lista: null,
};

export default function escalasTrabalho(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.REQUEST_ESCALA_TRABALHO: {
        draft.loading = true;
        break;
      }

      case Types.REQUEST_CRIAR_ESCALA_TRABALHO: {
        draft.loading = true;
        break;
      }

      case Types.GET_ESCALA_PADRAO: {
        draft.escalaPadrao = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.RESPONSE_ESCALA_TRABALHO: {
        draft.lista = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.GET_ESCALA_BY_ID: {
        draft.escala = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.CLEAR_ESCALAS_TRABALHO: {
        draft.loading = false;
        draft.escalaPadrao = null;
        draft.escala = null;
        draft.lista = null;
        break;
      }

      case Types.LOGOUT: {
        draft.loading = false;
        draft.escalaPadrao = null;
        draft.escala = null;
        draft.lista = null;
        break;
      }
      default:
    }
  });
}
