import produce from 'immer';

import { Types } from '../../types';

// Reducers
const INITIAL_STATE = {
  loading: false,
  get_payload: null,
  post_payload: null,
  put_payload: null,
}

export default function ausencias(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.AUSENCIAS_REQUEST: {
        draft.loading = true;
        draft.get_payload = null;
        draft.post_payload = null;
        draft.put_payload = null;
        break;
      }

      case Types.GET_AUSENCIAS_RESPONSE: {
        draft.get_payload = action.payload.data;
        draft.loading = false;
        break;
      }
      case Types.POST_AUSENCIAS_RESPONSE: {
        draft.post_payload = action.payload.data;
        draft.loading = false;
        break;
      }
      case Types.PUT_AUSENCIAS_RESPONSE: {
        draft.put_payload = action.payload.data;
        draft.loading = false;
        break;
      }
  
      case Types.CLEAR_ALL_MOTOR_NOVO: {
        draft.get_payload = null;
        draft.post_payload = null;
        draft.put_payload = null;
        draft.loading = false;
        break;
      }
  
      case Types.LOGOUT: {
        draft.get_payload = null;
        draft.post_payload = null;
        draft.put_payload = null;
        draft.loading = false;
        break;
      }
      default:
    }
  });
}