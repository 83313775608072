import { takeLatest, call, put, all } from 'redux-saga/effects';
import { Types } from '../types';
import { requestApiRelatorio } from '~/services/request';

const adpglobalUrl = '/adpglobal';

export function* relatorioRequest({
  url,
  queryParams,
  responseAction,
  callback,
}) {
  const params = {
    url,
    method: 'GET',
    queryParams,
  };

  try {
    const response = yield call(requestApiRelatorio, params);
    if (response.data && response.status === 200) {
      if (url === adpglobalUrl) {
        const urlApi = 'https://relatorio.folhacerta.com/api/v1/adpglobal';
        window.open(
          `${urlApi}?regraId=${queryParams.regraId}&periodoId=${queryParams.periodoId}`,
        );
      }
      yield put(
        responseAction(response.data, callback, response.request.responseURL),
      );
    } else {
      yield put(
        responseAction(
          {
            success: false,
            message: 'Não contém dados para esse relatório',
          },
          callback,
        ),
      );
    }
  } catch (error) {
    yield put(
      responseAction(
        {
          success: false,
          message: 'Não contém dados para esse relatório',
        },
        callback,
      ),
    );
  }
}

export default all([takeLatest(Types.RELATORIO_API_REQUEST, relatorioRequest)]);
