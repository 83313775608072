import { Types } from '../../types';

export function inconsistenciaRequest({ params, method, id }) {
  return {
    type: Types.INCONSISTENCIA_REQUEST,
    payload: params,
    method: method,
    id: id
  };
}

export function getInconsistenciaResponse(data) {
  return {
    type: Types.GET_INCONSISTENCIA_RESPONSE,
    payload: { data },
  };
}

export function postInconsistenciaResponse(data) {
  return {
    type: Types.POST_INCONSISTENCIA_RESPONSE,
    payload: { data },
  };
}

export function putInconsistenciaResponse(data) {
  return {
    type: Types.PUT_INCONSISTENCIA_RESPONSE,
    payload: { data },
  };
}
