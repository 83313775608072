import produce from 'immer';

import { Types } from '~/store/modules/types';

const INITIAL_STATE = {
  loading: false,

  totais: null,
};

export default function espelhoPontoBancoHoras(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.ESPELHO_PONTO_BANCO_HORA_REQUEST: {
        draft.loading = true;
        break;
      }

      case Types.TOTAIS_ESPELHO_BANCO_HORA: {
        draft.loading = false;
        draft.totais = action.payload.data;
        break;
      }

      case Types.CLEAR_RELATORIO: {
        draft.loading = false;
        draft.totais = null;
        break;
      }

      case Types.LOGOUT: {
        draft.loading = false;
        draft.totais = null;
        break;
      }
      default:
    }
  });
}
