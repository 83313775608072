import produce from 'immer';

import { Types } from '../types';

// Reducers
const INITIAL_STATE = {
  erro: null,
  response: null,

  loading: false,
}

export default function carregarUsuariosGestores(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.CARREGAR_USUARIOS_GESTORES_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.CARREGAR_USUARIOS_GESTORES_SUCCESS: {
        draft.response = action.payload;
        draft.loading = false;
        break;
      }
      case Types.CARREGAR_USUARIOS_GESTORES_FAILURE: {
        draft.loading = false;
        draft.erro = action.payload;
        break;
      }

      case Types.LOGOUT: {
        draft.response = null;
        draft.erro = null;
        draft.loading = false;
        break;
      }
      default:
    }
  });
}