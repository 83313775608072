import produce from 'immer';

import { Types } from '../types';

// Reducers
const INITIAL_STATE = {
  payload: null,
  payloadPut: null,
  payloadHistorico: null,
  payloadGet: null,
  payloadGetById: null,

  payloadCount: null,
  loadingHistorico: false,
  loading: false,
};

export default function motivoSobreaviso(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.MOTIVO_SOBREAVISO_REQUEST: {
        draft.loading = true;
        break;
      }

      case Types.MOTIVO_SOBREAVISO_REQUEST_PUT: {
        draft.loading = true;
        break;
      }

      case Types.MOTIVO_SOBREAVISO_REQUEST_GET: {
        draft.loading = true;
        break;
      }

      case Types.MOTIVO_SOBREAVISO_BY_ID_REQUEST_GET: {
        draft.loading = true;
        break;
      }

      case Types.MOTIVO_SOBREAVISO_BY_ID_RESPONSE_GET: {
        draft.payloadGetById = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.MOTIVO_SOBREAVISO_RESPONSE_GET: {
        draft.payloadGet = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.MOTIVO_SOBREAVISO_RESPONSE: {
        draft.payload = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.MOTIVO_SOBREAVISO_RESPONSE_PUT: {
        draft.payloadPut = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.MOTIVO_SOBREAVISO_HISTORICO_REQUEST: {
        draft.loadingHistorico = true;
        break;
      }

      case Types.MOTIVO_SOBREAVISO_HISTORICO_RESPONSE: {
        draft.payloadHistorico = action.payload.data;
        draft.loadingHistorico = false;
        break;
      }

      case Types.MOTIVO_SOBREAVISO_CLEAR_DATA: {
        draft.payloadHistorico = null;
        draft.payloadGetById = null;
        draft.payloadCount = null;
        draft.payloadPut = null;
        draft.payloadGet = null;
        draft.payload = null;
        draft.loadingHistorico = false;
        draft.loading = false;
        break;
      }

      case Types.LOGOUT: {
        draft.payloadHistorico = null;
        draft.payloadGetById = null;
        draft.payloadCount = null;
        draft.payloadPut = null;
        draft.payloadGet = null;
        draft.payload = null;
        draft.loadingHistorico = false;
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
