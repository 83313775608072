/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React from 'react';
import { useTransition } from 'react-spring';

import Acao from './Acao';

import { Conteiner } from './styles';

export default function ConteinerAcao({
  dados,
  option,
  isEmployed,
  onClose,
  callback,
}) {
  const dadosComTransicao = useTransition(dados, (dados2) => dados2.id, {
    from: { right: '-120%' },
    enter: { right: '0' },
    leave: { right: '-120%' },
  });

  return (
    <Conteiner isEmployed={isEmployed}>
      {dadosComTransicao &&
        dadosComTransicao.map(({ item, key, props }) => (
          <Acao
            key={key}
            dados={item}
            style={props}
            option={option}
            onClose={onClose}
            callback={callback}
          />
        ))}
    </Conteiner>
  );
}
