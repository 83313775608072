import { takeLatest, call, put, all } from 'redux-saga/effects';
import { Types } from '../../../types';
// eslint-disable-next-line import/no-cycle
import { requestMotorNovoBancoHoras } from '~/services/request';

import {
  getBancoHorasEstagioVerbaResponse,
  putBancoHorasEstagioVerbaResponse,
} from './actions';

export function* bancoHorasEstagioVerba({ payload, method, callback }) {
  const params = {
    url: '/api/v1/bancoHora/configuracao/estagio/verba',
    method,
    body: payload,
  };

  switch (method) {
    case 'GET':
      try {
        const response = yield call(requestMotorNovoBancoHoras, params);
        yield put(getBancoHorasEstagioVerbaResponse(response.data, callback));
      } catch (error) {
        yield put(
          getBancoHorasEstagioVerbaResponse({ success: false }, callback),
        );
      }
      break;
    case 'PUT':
      try {
        const response = yield call(requestMotorNovoBancoHoras, params);
        yield put(putBancoHorasEstagioVerbaResponse(response.data, callback));
      } catch (error) {
        yield put(
          putBancoHorasEstagioVerbaResponse({ success: false }, callback),
        );
      }
      break;

    default:
      break;
  }
}

export default all([
  takeLatest(Types.BANCO_HORAS_ESTAGIO_VERBA_REQUEST, bancoHorasEstagioVerba),
]);
