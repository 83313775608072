import { takeLatest, call, put, all, delay } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { Types } from '../types';
import { requestBaseV3 } from '~/services/request';

// Carregar Lancamentos Actions
import {
  carregarDayOffLancamentosSuccess,
  carregarDayOffLancamentosFailure,
  salvarDayOffLancamentoResponse,
} from './actions';
import {
  carregarFeriasLancamentosSuccess,
  carregarFeriasLancamentosFailure,
  salvarFeriasLancamentoResponse,
} from './actions';
import {
  carregarRecessoLancamentosSuccess,
  carregarRecessoLancamentosFailure,
  salvarRecessoLancamentoResponse,
} from './actions';
import {
  carregarLancamentosSuccess,
  carregarLancamentosFailure,
} from './actions';
import {
  totalPontosPorFuncionarioSuccess,
  totalPontosPorFuncionarioFailure,
} from './actions';
import {
  CarregarDayOffLancamentoResponse,
  CarregarRecessoPorIdResponse,
  CarregarFeriasPorIdResponse,
} from './actions';

const requestPOST = (body, url) => {
  const params = {
    url,
    method: 'POST',
    body,
  };

  return requestBaseV3(params);
};

export function* ferias() {
  try {
    const params = {
      url: '/CarregarFeriasLancamentos',
      method: 'GET',
    };

    const response = yield call(requestBaseV3, params);

    if (response.data.Success) {
      const { data } = response;

      yield put(carregarFeriasLancamentosSuccess(data));
      yield delay(2000);
    } else {
      toast.error(response.data.Error);
      yield put(carregarFeriasLancamentosFailure());
    }
  } catch (error) {
    toast.error('Falha na autenticação');
    yield put(carregarFeriasLancamentosFailure());
  }
}

export function* salvarFerias({ payload, method, callback }) {
  const params = {
    url: '/salvarFeriaslancamento',
    method,
    body: payload,
  };

  try {
    const response = yield call(requestBaseV3, params);
    yield put(salvarFeriasLancamentoResponse(response.data, callback));
  } catch (error) {
    yield put(salvarFeriasLancamentoResponse({ success: false }, callback));
  }
}

export function* dayOFF({ usuarioId }) {
  try {
    const response = yield call(
      requestPOST,
      { usuarioId },
      '/CarregarDayOffLancamentos',
    );

    if (response.data.Success) {
      const { data } = response;

      yield put(carregarDayOffLancamentosSuccess(data));
      yield delay(2000);
    } else {
      toast.error(response.data.Error);
      yield put(carregarDayOffLancamentosFailure());
    }
  } catch (error) {
    toast.error('Falha na autenticação');
    yield put(carregarDayOffLancamentosFailure());
  }
}

export function* salvarDayOff({ payload, method, callback }) {
  const formData = new FormData();
  for (const key in payload) {
    formData.append(key, payload[key]);
  }

  const params = {
    url: '/salvardayofflancamento',
    method,
    body: formData,
  };

  try {
    const response = yield call(requestBaseV3, params);

    yield put(salvarDayOffLancamentoResponse(response.data, callback));
  } catch (error) {
    yield put(salvarDayOffLancamentoResponse({ success: false }, callback));
  }
}

export function* recesso({ usuarioId }) {
  try {
    const response = yield call(
      requestPOST,
      { usuarioId },
      '/CarregarRecessoLancamentos',
    );

    if (response.data.Success) {
      const { data } = response;

      yield put(carregarRecessoLancamentosSuccess(data));
      yield delay(2000);
    } else {
      toast.error(response.data.Error);
      yield put(carregarRecessoLancamentosFailure());
    }
  } catch (error) {
    toast.error('Falha na autenticação');
    yield put(carregarRecessoLancamentosFailure());
  }
}

export function* salvarRecesso({ payload, method, callback }) {
  const formData = new FormData();
  for (const key in payload) {
    formData.append(key, payload[key]);
  }

  const params = {
    url: '/salvarrecessolancamento',
    method,
    body: formData,
  };

  try {
    const response = yield call(requestBaseV3, params);

    yield put(salvarRecessoLancamentoResponse(response.data, callback));
  } catch (error) {
    yield put(salvarRecessoLancamentoResponse({ success: false }, callback));
  }
}

export function* lancamentos({ payload, method }) {
  const params = {
    url: '/CarregarLancamentos',
    method,
    queryParams: payload,
  };

  try {
    const response = yield call(requestBaseV3, params);

    if (response.data.Success) {
      const { data } = response;

      yield put(carregarLancamentosSuccess(data));
      yield delay(2000);
    } else {
      toast.error(response.data.Error);
      yield put(carregarLancamentosFailure());
    }
  } catch (error) {
    toast.error('Falha na autenticação');
    yield put(carregarLancamentosFailure());
  }
}

export function* totalPontosPorFuncionario({ payload, method }) {
  const params = {
    url: '/TotalPontosPorFuncionario',
    method,
    queryParams: payload,
  };

  try {
    const response = yield call(requestBaseV3, params);

    if (response.data.Success) {
      const { data } = response;

      yield put(totalPontosPorFuncionarioSuccess(data));
      yield delay(2000);
    } else {
      toast.error(response.data.Error);
      yield put(totalPontosPorFuncionarioFailure());
    }
  } catch (error) {
    toast.error('Falha na autenticação');
    yield put(totalPontosPorFuncionarioFailure());
  }
}

export function* carregarDayOffPorID({ payload, method }) {
  const formData = new FormData();
  for (const key in payload) {
    formData.append(key, payload[key]);
  }

  const params = {
    url: '/CarregarDayOffLancamento',
    method,
    body: formData,
  };

  try {
    const response = yield call(requestBaseV3, params);
    const { data } = response;

    yield put(CarregarDayOffLancamentoResponse(data));
  } catch (error) {
    yield put(CarregarDayOffLancamentoResponse({ Success: false }));
  }
}

export function* carregarRecessoPorID({ payload, method }) {
  const formData = new FormData();
  for (const key in payload) {
    formData.append(key, payload[key]);
  }

  const params = {
    url: '/CarregarRecessoLancamento',
    method,
    body: formData,
  };

  try {
    const response = yield call(requestBaseV3, params);
    const { data } = response;

    yield put(CarregarRecessoPorIdResponse(data));
  } catch (error) {
    yield put(CarregarRecessoPorIdResponse({ Success: false }));
  }
}

export function* carregarFeriasPorID({ payload }) {
  const params = {
    url: '/CarregarFeriasLancamento',
    method: 'GET',
    queryParams: payload,
  };

  try {
    const response = yield call(requestBaseV3, params);
    const { data } = response;

    yield put(CarregarFeriasPorIdResponse(data));
  } catch (error) {
    yield put(CarregarFeriasPorIdResponse({ Success: false }));
  }
}

export default all([
  takeLatest(Types.CARREGAR_FERIAS_LANCAMENTOS_REQUEST, ferias),
  takeLatest(Types.CARREGAR_RECESSO_LANCAMENTOS_REQUEST, recesso),
  takeLatest(Types.CARREGAR_DAYOFF_LANCAMENTOS_REQUEST, dayOFF),
  takeLatest(Types.CARREGAR_LANCAMENTOS_REQUEST, lancamentos),
  takeLatest(
    Types.TOTAL_PONTOS_POR_FUNCIONARIO_REQUEST,
    totalPontosPorFuncionario,
  ),

  takeLatest(Types.SALVAR_DAYOFF_LANCAMENTO_REQUEST, salvarDayOff),
  takeLatest(Types.SALVAR_RECESSO_LANCAMENTO_REQUEST, salvarRecesso),
  takeLatest(Types.SALVAR_FERIAS_LANCAMENTO_REQUEST, salvarFerias),

  takeLatest(Types.DAYOFF_LANCAMENTO_POR_ID_REQUEST, carregarDayOffPorID),
  takeLatest(Types.RECESSO_POR_ID_REQUEST, carregarRecessoPorID),
  takeLatest(Types.FERIAS_POR_ID_REQUEST, carregarFeriasPorID),
]);
