import { Types } from '../../types';

export function requestImportacao({ payload, queryParams }, callback) {
  return {
    type: Types.REQUEST_IMPORTACAO,
    payload,
    queryParams,
    callback,
  };
}

export function responseImportacao(data, callback) {
  callback();
  return {
    type: Types.RESPONSE_IMPORTACAO,
    payload: { data },
  };
}

export function responseImportacaoClearData() {
  return {
    type: Types.RESPONSE_IMPORTACAO_CLEAR_DATA,
  };
}

export function requestImportacaoArquivo(importacaoId, queryParams, callback) {
  return {
    type: Types.REQUEST_IMPORTACAO_ARQUIVO,
    importacaoId,
    queryParams,
    callback,
  };
}

export function responseImportacaoArquivo(data, callback) {
  callback(data);
  return {
    type: Types.RESPONSE_IMPORTACAO_ARQUIVO,
    payload: { data },
  };
}