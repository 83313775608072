import { takeLatest, call, put, all } from 'redux-saga/effects';
import { Types } from '../types';
import { requestBaseV3 } from '~/services/request';

import {
  salvarAvisoAusenciaLancamentoResponse,
  alterarStatusLancamentoResponse
} from './actions';

export function* salvarAvisoAusenciaLancamento({ payload, callback }) {
  let params = {
    url: '/SalvarAvisoAusenciaLancamento',
    method: 'POST',
    body: payload
  };

  try {
    const response = yield call(requestBaseV3, params);
    yield put(salvarAvisoAusenciaLancamentoResponse(response.data, callback));
  } catch (error) {
    yield put(salvarAvisoAusenciaLancamentoResponse({ success: false }, callback));
  }
}

export function* alterarStatusLancamento({ payload, method, callback }) {
  let form_data = new FormData();
  for (let key in payload) {
    form_data.append(key, payload[key]);
  }

  let params = {
    url: '/AlterarStatusLancamento',
    method: method,
    body: form_data
  };

  try {
    const response = yield call(requestBaseV3, params);

    yield put(alterarStatusLancamentoResponse(response.data, callback));
  } catch (error) {
    yield put(alterarStatusLancamentoResponse({ success: false }, callback));
  }
}

export default all([
  takeLatest(Types.SALVAR_AVISO_AUSENCIA_LANCAMENTO_REQUEST, salvarAvisoAusenciaLancamento),
  takeLatest(Types.ALTERAR_AUSENCIA_LANCAMENTO_REQUEST, alterarStatusLancamento)
]);