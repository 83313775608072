import { Types } from '../types';

export function motivosInterrupcaoPostRequest(params) {
  return {
    type: Types.MOTIVOS_INTERRUPCAO_POST_REQUEST,
    payload: params
  };
}

export function motivosInterrupcaoPostSuccess(data) {
  return {
    type: Types.MOTIVOS_INTERRUPCAO_POST_SUCCESS,
    payload: { data },
  };
}

export function motivosInterrupcaoPostFailure(data) {
  return {
    type: Types.MOTIVOS_INTERRUPCAO_POST_FAILURE,
    payload: { data },
  };
}

export function motivosInterrupcaoGetRequest() {
  return {
    type: Types.MOTIVOS_INTERRUPCAO_GET_REQUEST
  };
}

export function motivosInterrupcaoGetSuccess(data) {
  return {
    type: Types.MOTIVOS_INTERRUPCAO_GET_SUCCESS,
    payload: { data },
  };
}

export function motivosInterrupcaoGetFailure(data) {
  return {
    type: Types.MOTIVOS_INTERRUPCAO_GET_FAILURE,
    payload: { data },
  };
}

export function motivosInterrupcaoPutRequest(data) {
  return {
    type: Types.MOTIVOS_INTERRUPCAO_PUT_REQUEST,
    payload: data,
  };
}

export function motivosInterrupcaoPutResponse(data) {
  return {
    type: Types.MOTIVOS_INTERRUPCAO_PUT_RESPONSE,
    payload: { data },
  };
}


export function motivosInterrupcaoClearData() {
  return {
    type: Types.MOTIVOS_INTERRUPCAO_CLEAR_DATA
  };
}


