import { takeLatest, call, put, all } from 'redux-saga/effects';
import { Types } from '../types';
import { requestSobreaviso } from '../../../services/request';

import {
  motivoSobreavisoResponse,
  motivoSobreavisoResponsePut,
  motivoSobreavisoResponseGet,
  motivoSobreavisoGetByIDResponse,
  motivoSobreavisoHistoricoResponse,
} from './actions';

export function* motivoSobreaviso({ payload, method }) {
  const params = {
    url: '/motivo',
    method,
    body: payload,
  };

  try {
    const response = yield call(requestSobreaviso, params);

    yield put(motivoSobreavisoResponse(response.data));
  } catch (error) {
    yield put(motivoSobreavisoResponse({ success: false }));
  }
}

export function* motivoSobreavisoPut({ payload, method }) {
  const params = {
    url: `/motivo/${payload.id}`,
    method,
    body: payload,
  };

  try {
    const response = yield call(requestSobreaviso, params);

    yield put(motivoSobreavisoResponsePut(response.data));
  } catch (error) {
    yield put(motivoSobreavisoResponsePut({ success: false }));
  }
}

export function* motivoSobreavisoGet({ payload }) {
  const params = {
    url: '/motivo',
    method: 'GET',
    queryParams: payload,
  };

  try {
    const response = yield call(requestSobreaviso, params);

    yield put(motivoSobreavisoResponseGet(response.data));
  } catch (error) {
    yield put(motivoSobreavisoResponseGet({ success: false }));
  }
}

export function* motivoSobreavisoGetByID({ payload }) {
  const params = {
    url: `/motivo/${payload}`,
    method: 'GET',
  };

  try {
    const response = yield call(requestSobreaviso, params);

    yield put(motivoSobreavisoGetByIDResponse(response.data));
  } catch (error) {
    yield put(motivoSobreavisoGetByIDResponse({ success: false }));
  }
}

export function* motivoSobreavisoHistorico({ id }) {
  const params = {
    url: `/motivo/historico/${id}`,
    method: 'GET',
  };

  try {
    const response = yield call(requestSobreaviso, params);

    yield put(motivoSobreavisoHistoricoResponse(response.data));
  } catch (error) {
    yield put(motivoSobreavisoHistoricoResponse({ success: false }));
  }
}

export default all([
  takeLatest(Types.MOTIVO_SOBREAVISO_REQUEST, motivoSobreaviso),
  takeLatest(Types.MOTIVO_SOBREAVISO_REQUEST_PUT, motivoSobreavisoPut),
  takeLatest(Types.MOTIVO_SOBREAVISO_REQUEST_GET, motivoSobreavisoGet),
  takeLatest(
    Types.MOTIVO_SOBREAVISO_BY_ID_REQUEST_GET,
    motivoSobreavisoGetByID,
  ),
  takeLatest(
    Types.MOTIVO_SOBREAVISO_HISTORICO_REQUEST,
    motivoSobreavisoHistorico,
  ),
]);
