import { Types } from '../../../types';

export function bancoHorasConfiguracaoCicloRequest({ params, method, id }) {
  return {
    type: Types.BANCO_HORAS_CONFIGURACAO_CICLO_REQUEST,
    payload: params,
    method: method,
    id: id
  };
}

export function getBancoHorasConfiguracaoCicloResponse(data) {
  return {
    type: Types.GET_BANCO_HORAS_CONFIGURACAO_CICLO_RESPONSE,
    payload: { data },
  };
}

export function postBancoHorasConfiguracaoCicloResponse(data) {
  return {
    type: Types.POST_BANCO_HORAS_CONFIGURACAO_CICLO_RESPONSE,
    payload: { data },
  };
}

export function putBancoHorasConfiguracaoCicloResponse(data) {
  return {
    type: Types.PUT_BANCO_HORAS_CONFIGURACAO_CICLO_RESPONSE,
    payload: { data },
  };
}
