import produce from 'immer';

import { Types } from '../../types';

const INITIAL_STATE = {
  payload: null,
  erro: null,
  loading: false,
};

export default function filtrarGrupos(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.FILTRAR_GRUPOS_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.FILTRAR_GRUPOS_RESPONSE: {
        draft.payload = action.payload.data;
        draft.loading = false;
        break;
      }
      case Types.LOGOUT: {
        draft.payload = null;
        draft.loading = false;
        draft.erro = false;
        break;
      }
      default:
    }
  });
}
