import { Types } from '../types';

export function carregarHistoricoMarcacaoRequest(usuarioId, id) {
  return {
    type: Types.CARREGAR_HISTORICO_MARCACAO_REQUEST,
    payload: {
      usuarioId,
      id,
    },
  };
}

export function carregarHistoricoMarcacaoSuccess(data) {
  return {
    type: Types.CARREGAR_HISTORICO_MARCACAO_SUCCESS,
    payload: { data },
  };
}

export function carregarHistoricoMarcacaoFailure() {
  return {
    type: Types.CARREGAR_HISTORICO_MARCACAO_FAILURE,
  };
}

export function carregarHistoricoMarcacaoClearData() {
  return {
    type: Types.CARREGAR_HISTORICO_MARCACAO_CLEAR_DATA,
  };
}
