import { Types } from '~/store/modules/types';

const baseUrl = '/gestao';

function responseListaRegrasGestaoEscalaCombo(data) {
  return {
    type: Types.RESPONSE_REGRAS_GESTAO_ESCALA_COMBO,
    payload: { data },
  };
}

export function requestRegrasGestaoEscalaCombo() {
  return {
    type: Types.REQUEST_REGRAS_GESTAO_ESCALA,
    url: `${baseUrl}/combo`,
    method: 'GET',
    responseAction: responseListaRegrasGestaoEscalaCombo,
  };
}

function responseListaRegrasGestaoEscala(data, callback) {
  if (callback) {
    callback(data);
  }

  return {
    type: Types.RESPONSE_REGRAS_GESTAO_ESCALA,
    payload: { data },
  };
}

export function requestRegrasGestaoEscala(params, callback) {
  return {
    type: Types.REQUEST_REGRAS_GESTAO_ESCALA,
    url: baseUrl,
    method: 'POST',
    body: params,
    responseAction: responseListaRegrasGestaoEscala,
    callback,
  };
}

export function requestAlteraRegrasGestaoEscala(params, callback) {
  return {
    type: Types.REQUEST_REGRAS_GESTAO_ESCALA,
    url: baseUrl,
    method: 'PUT',
    body: params,
    responseAction: responseListaRegrasGestaoEscala,
    callback,
  };
}

export function getListaRegrasGestaoEscala(params, callback) {
  return {
    type: Types.REQUEST_REGRAS_GESTAO_ESCALA,
    url: baseUrl,
    method: 'GET',
    queryParams: params,
    responseAction: responseListaRegrasGestaoEscala,
    callback,
  };
}

function responseAlteraStatusRegrasGestao(data, callback) {
  if (callback) {
    callback(data);
  }

  return {
    type: Types.RESPONSE_ALTERA_STATUS_REGRAS_GESTAO,
    payload: { data },
  };
}
export function alteraStatusRegrasGestao(params, callback) {
  return {
    type: Types.ALTERA_STATUS_REGRAS_GESTAO,
    url: `${baseUrl}/status`,
    method: 'PUT',
    body: params,
    responseAction: responseAlteraStatusRegrasGestao,
    callback,
  };
}

function responseRegrasGestaoPorId(data, callback) {
  if (callback) {
    callback(data);
  }

  return {
    type: Types.RESPONSE_REGRAS_GESTAO_POR_ID,
    payload: { data },
  };
}

export function getRegrasGestaoPorId(id, callback) {
  return {
    type: Types.REQUEST_REGRAS_GESTAO_ESCALA,
    url: `${baseUrl}/${id}`,
    method: 'GET',
    responseAction: responseRegrasGestaoPorId,
    callback,
  };
}

export function clearRegrasGestaoEscala() {
  return { type: Types.CLEAR_RELATORIO };
}
