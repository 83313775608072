import React, { useCallback } from 'react';
import { IoIosArrowForward } from 'react-icons/io';

import ButtonPadrao from '../../ButtonPadrao';
import { Container } from './styles';
import { useModal } from '~/hooks/modal';

export default function ListaSecoesPessoas({ dados }) {
  const { titulo = '', menssagem, confirmacao, acao } = dados;
  const { ocultarModal } = useModal();

  const resolverAcao = useCallback(() => {
    acao();
    ocultarModal();
  }, [acao, ocultarModal]);
  return (
    <Container>
      <header>
        <h1>Lista</h1>
        <button>Fechar</button>
      </header>
      <main>
        <ul>
          <li className="cabecario">
            <span>
              <strong>4</strong>departamentos
            </span>
            <span>
              <strong>48</strong>pessoas
            </span>
          </li>
          <li>
            <span>departamentos</span>
            <div>
              <span>12 pessoas</span>
              <button type="button">{'>'}</button>
            </div>
          </li>
        </ul>
      </main>
      <footer />
    </Container>
  );
}
