import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

export const Conteiner = styled.div`
  position: fixed;
  top: 0;
  overflow: hidden;
  left: 0;
  z-index: 4000;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const Background = styled(animated.div)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
`;
