/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'reactstrap';

class ButtonFolhaCerta extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.elementRef = React.createRef();
  }

  onClickButton = () => {
    this.timeOutMessage();
    this.props.onClickButton();
  };

  timeOutMessage = () => {
    setTimeout(() => {
      if (this.elementRef.current != null) {
        // avoiding updating when component was unmounted. can cause memory leak;
        this.setState({ disabled: false });
      }
    }, 1000);
  };

  render() {
    const {
      amarelo,
      branco,
      buttonName,
      brancoHeader,
      cinza,
      cinzaTrasparente,
      azulFolhaCerta,
      darkFolhaCerta,
      showIcon,
      disabled,
      disabledStatus,
      small,
      marginTop,
      transparent,
      withoutBackground,
      dergadeVerde,
      dergadeVermelho,
      id,
      width,
      grayWithoutBackground,
      showDownloadIcon,
      nowrap,
      verde,
      vermelho,
      roxo,
      isBoxShadow,
      styles,
    } = this.props;

    let className = 'pl-4 pr-4 pt-3 pb-3 mr-2 btn-sm btn-pill text-uppercase';

    if (showIcon) {
      className = 'pl-4 pr-3 pt-3 pb-3 mr-2 btn-sm btn-pill text-uppercase';
    }

    if (small) {
      className = 'pl-3 pr-3 pt-2 pb-2 mr-2 btn-sm btn-pill text-uppercase';
    }

    const style = {
      width,
      lineHeight: '13px',
      marginTop: marginTop || '2%',
      whiteSpace: nowrap ? 'nowrap' : 'normal',
      ...styles,
    };

    if (!disabled && !disabledStatus) {
      if (amarelo) {
        className += ' text-white';
        style.boxShadow = '0px 2px 0px #00000066';
      } else if (brancoHeader) {
        style.color = 'white';
        style.borderColor = 'white';
      } else if (cinza) {
        style.color = '#707070';
        style.borderColor = '#707070';
        style.backgroundColor = '#F1F4F6';
      } else if (cinzaTrasparente) {
        style.color = '#707070';
        style.borderColor = '#707070';
      } else if (branco) {
        style.color = '#003258';
        style.borderColor = '#003258';
      } else if (azulFolhaCerta) {
        style.color = '#00ABDE';
        style.borderColor = '#00ABDE';
        className = 'pl-3 pr-3 pt-2 pb-2 mr-2 btn-sm btn-pill text-uppercase';
      } else if (darkFolhaCerta) {
        style.color = 'var(--darkBlue)';
        style.borderColor = 'var(--darkBlue)';
        className = 'pl-3 pr-3 pt-2 pb-2 mr-2 btn-sm btn-pill text-uppercase';
      } else if (dergadeVerde) {
        className += ' bg-green-blue text-white';
      } else if (dergadeVermelho) {
        className += ' bg-pink-purple text-white';
      } else if (transparent) {
        style.color = 'white';
        style.borderColor = 'white';
        style.backgroundColor = 'transparent';
      } else if (withoutBackground) {
        style.color = '#003258';
        style.border = 'none';
        style.backgroundColor = 'transparent';
        className = 'pt-2 pb-2 ml-1 mr-1 mt-1 btn-sm text-uppercase';
      } else if (grayWithoutBackground) {
        style.color = '#707070';
        style.borderColor = 'white';
        style.backgroundColor = 'transparent';
      } else if (verde) {
        style.backgroundColor = '#29d694';
        style.border = 'none';
      } else if (vermelho) {
        style.backgroundColor = '#e75190';
        style.border = 'none';
      } else if (roxo) {
        style.backgroundColor = '#c189f9';
        style.border = 'none';
      }

      if (!isBoxShadow) {
        style.boxShadow = 'none';
      }
    }

    className += ` ${this.props.className}`;

    return (
      <>
        <Button
          ref={this.elementRef}
          className={className}
          color={
            disabled || disabledStatus
              ? 'cinzaDisabledLight'
              : dergadeVerde || dergadeVermelho
              ? ''
              : amarelo
              ? 'amareloFolha'
              : branco ||
                azulFolhaCerta ||
                cinza ||
                cinzaTrasparente ||
                darkFolhaCerta
              ? 'white'
              : 'alternate'
          }
          onClick={!disabled || disabledStatus ? this.onClickButton : () => {}}
          style={style}
          id={id}
        >
          {showDownloadIcon ? (
            <i
              className="lnr-download btn-icon-wrapper ml-4"
              style={{
                color: '#93AFB7',
                fontWeight: 'bold',
                fontSize: '20px',
                margin: '0 10px',
              }}
            />
          ) : (
            ''
          )}

          {buttonName}

          {showIcon ? (
            <i
              className="lnr-chevron-right btn-icon-wrapper ml-4"
              style={{
                // eslint-disable-next-line no-nested-ternary
                color:
                  disabled || disabledStatus
                    ? '#93AFB7'
                    : amarelo
                    ? 'white'
                    : '#29d694',
                fontWeight: 'bold',
                fontSize: 'xx-small',
              }}
            />
          ) : (
            ''
          )}
        </Button>
      </>
    );
  }
}

ButtonFolhaCerta.propTypes = {
  onClickButton: PropTypes.func.isRequired,
  buttonName: PropTypes.string.isRequired,
  id: PropTypes.string,

  disabled: PropTypes.bool,
  disabledStatus: PropTypes.bool,

  showIcon: PropTypes.bool,
  width: PropTypes.string,

  amarelo: PropTypes.bool,
  branco: PropTypes.bool,
  azulFolhaCerta: PropTypes.bool,
  brancoHeader: PropTypes.bool,
  cinza: PropTypes.bool,
  small: PropTypes.bool,
  dergadeVerde: PropTypes.bool,
  dergadeVermelho: PropTypes.bool,
  grayWithoutBackground: PropTypes.bool,
  withoutBackground: PropTypes.bool,
  transparent: PropTypes.bool,
  showDownloadIcon: PropTypes.bool,
  nowrap: PropTypes.bool,
};

ButtonFolhaCerta.defaultProps = {
  id: 'buttonFolhacerta',
  azulFolhaCerta: false,
  disabled: false,
  disabledStatus: false,
  amarelo: false,
  branco: false,
  showIcon: false,

  grayWithoutBackground: false,
  withoutBackground: false,
  transparent: false,

  brancoHeader: false,
  cinza: false,
  small: false,
  dergadeVerde: false,
  dergadeVermelho: false,

  width: null,
  showDownloadIcon: false,
  nowrap: true,
};

export default ButtonFolhaCerta;
