/* eslint-disable import/no-cycle */
import { takeLatest, call, put, all } from 'redux-saga/effects';

import { Types } from '../../types';
import { requestHolerites } from '../../../../services/request';
import { responseListaTiposHolerites } from './actions';

export function* listaTiposHolerites({ payload }) {
  const params = {
    url: '/holeritetipo',
    method: 'GET',
    body: payload,
  };

  try {
    const response = yield call(requestHolerites, params);
    yield put(responseListaTiposHolerites(response.data));
  } catch (error) {
    yield put(responseListaTiposHolerites({ success: false }));
  }
}

export default all([
  takeLatest(Types.REQUEST_LISTA_TIPOS_HOLERITES, listaTiposHolerites),
]);
