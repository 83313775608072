import { Types } from '../../../types';

export function bancoCicloRequest({ params, method, id, callback }) {
  return {
    type: Types.BANCO_CICLO_REQUEST,
    payload: params,
    method: method,
    id: id,
    callback,
  };
}

export function bancoCicloActivateRequest(ids, callback) {
  return {
    type: Types.BANCO_CICLO_ACTIVATE_REQUEST,
    ids: ids,
    callback,
  };
}

export function bancoCicloInactivateRequest(ids, callback) {
  return {
    type: Types.BANCO_CICLO_INACTIVATE_REQUEST,
    ids: ids,
    callback,
  };
}

export function bancoCicloBatchRequest({ params, callback }) {
  return {
    type: Types.BANCO_CICLO_BATCH_REQUEST,
    payload: params,
    callback,
  };
}

export function getBancoCicloResponse(data, callback) {
  if (callback) callback(data);
  return {
    type: Types.GET_BANCO_CICLO_RESPONSE,
    payload: { data },
  };
}

export function postBancoCicloResponse(data, callback) {
  if (callback) callback(data);
  return {
    type: Types.POST_BANCO_CICLO_RESPONSE,
    payload: { data },
  };
}

export function putBancoCicloResponse(data, callback) {
  if (callback) callback(data);
  return {
    type: Types.PUT_BANCO_CICLO_RESPONSE,
    payload: { data },
  };
}

export function bancoCicloActivateResponse(data, callback) {
  if (callback) callback(data);
  return {
    type: Types.BANCO_CICLO_ACTIVATE_RESPONSE,
    payload: { data },
  };
}

export function bancoCicloInactivateResponse(data, callback) {
  if (callback) callback(data);
  return {
    type: Types.BANCO_CICLO_INACTIVATE_RESPONSE,
    payload: { data },
  };
}

export function bancoCicloBatchResponse(data, callback) {
  if (callback) callback(data);
  return {
    type: Types.BANCO_CICLO_BATCH_RESPONSE,
    payload: { data },
  };
}