import produce from 'immer';

import { Types } from '../types';

// Reducers
const INITIAL_STATE = {
  payload: null,
  payloadProcessosInternosList: null,
  loading: false,
  loadingProcessosInternosList: false,
  erro: null,
};

export default function processosInternos(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.PROCESSOS_INTERNOS_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.PROCESSOS_INTERNOS_SUCCESS: {
        draft.payload = action.payload.data;
        draft.loading = false;
        break;
      }
      case Types.PROCESSOS_INTERNOS_FAILURE: {
        draft.payload = null;
        draft.erro = action.payload.data;
        draft.loading = false;
        break;
      }
      case Types.PROCESSOS_INTERNOS_FUNCIONARIO_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.PROCESSOS_INTERNOS_FUNCIONARIO_RESPONSE: {
        draft.payload = action.payload.data;
        draft.loading = false;
        break;
      }
      case Types.LISTAR_PROCESSOS_INTERNOS_REQUEST: {
        draft.loadingProcessosInternosList = true;
        break;
      }
      case Types.LISTAR_PROCESSOS_INTERNOS_RESPONSE: {
        draft.payloadProcessosInternosList = action.payload.data;
        draft.loadingProcessosInternosList = false;
        break;
      }
      case Types.SOLICITAR_PROCESSO_INTERNO_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.SOLICITAR_PROCESSO_INTERNO_RESPONSE: {
        draft.payload = action.payload.data;
        draft.loading = false;
        break;
      }
      case Types.CLEAR_PROCESSOS_INTERNOS: {
        draft.payload = null;
        draft.payloadProcessosInternosList = null;
        draft.loading = false;
        draft.loadingProcessosInternosList = false;
        break;
      }
      case Types.LOGOUT: {
        draft.payload = null;
        draft.payloadProcessosInternosList = null;
        draft.loading = false;
        draft.loadingProcessosInternosList = false;
        break;
      }
      default:
    }
  });
}
