import { Types } from '../../types';

export function intervaloRequest({ params, method, id }) {
  return {
    type: Types.INTERVALO_REQUEST,
    payload: params,
    method: method,
    id: id
  };
}

export function getIntervaloResponse(data) {
  return {
    type: Types.GET_INTERVALO_RESPONSE,
    payload: { data },
  };
}

export function postIntervaloResponse(data) {
  return {
    type: Types.POST_INTERVALO_RESPONSE,
    payload: { data },
  };
}

export function putIntervaloResponse(data) {
  return {
    type: Types.PUT_INTERVALO_RESPONSE,
    payload: { data },
  };
}
