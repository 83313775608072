import { Types } from '../types';

export function carregarDownloadsRequest(params) {
  return {
    type: Types.CARREGAR_DOWNLOADS_REQUEST,
    payload: { params },
  };
}

export function carregarDownloadResponse(data) {
  return {
    type: Types.CARREGAR_DOWNLOADS_RESPONSE,
    payload: { data },
  };
}

export function carregarDownloadArquivoRequest(downloadId) {
  return {
    type: Types.CARREGAR_DOWNLOADS_ARQUIVO_REQUEST,
    downloadId,
  };
}

export function carregarDownloadArquivoResponse() {
  return {
    type: Types.CARREGAR_DOWNLOADS_ARQUIVO_RESPONSE,
  };
}

export function carregarDownloadArquivosRequest(downloadsIds) {
  return {
    type: Types.CARREGAR_DOWNLOADS_ARQUIVOS_REQUEST,
    downloadsIds,
  };
}

export function carregarDownloadArquivosResponse() {
  return {
    type: Types.CARREGAR_DOWNLOADS_ARQUIVOS_RESPONSE,
  };
}

export function carregarDownloadParaQuemRequest(id) {
  return {
    type: Types.CARREGAR_DOWNLOADS_PARA_QUEM_REQUEST,
    id,
  };
}

export function carregarDownloadParaQuemResponse(data) {
  return {
    type: Types.CARREGAR_DOWNLOADS_PARA_QUEM_RESPONSE,
    payload: { data },
  };
}
