export const DIAS_SEMANA_ABREVIADO = [
  'DOM',
  'SEG',
  'TER',
  'QUA',
  'QUI',
  'SEX',
  'SAB',
];

export const MESES = [
  '',
  'janeiro',
  'fevereiro',
  'março',
  'abril',
  'maio',
  'junho',
  'julho',
  'agosto',
  'setembro',
  'outubro',
  'novembro',
  'dezembro',
];

export const MESES_UPPER = [
  'JANEIRO',
  'FEVEREIRO',
  'MARÇO',
  'ABRIL',
  'MAIO',
  'JUNHO',
  'JULHO',
  'AGOSTO',
  'SETEMBRO',
  'OUTUBRO',
  'NOVEMBRO',
  'DEZEMBRO',
];

export const DIAS_SEMANA = [
  'Segunda',
  'Terça',
  'Quarta',
  'Quinta',
  'Sexta',
  'Sábado',
  'Domingo',
];

export const DIAS_SEMANA_SEM_ACENTOS = {
  Segunda: 'Segunda',
  Terca: 'Terça',
  Quarta: 'Quarta',
  Quinta: 'Quinta',
  Sexta: 'Sexta',
  Sabado: 'Sábado',
  Domingo: 'Domingo',
};

export const ORDENADO_DIAS_SEMANA = [
  'Domingo',
  'Segunda',
  'Terça',
  'Quarta',
  'Quinta',
  'Sexta',
  'Sábado',
];

export const MONTHS_FOR_COMBOBOX = [
  { month: 'Janeiro', index: 0, enumPeriod: 1 },
  { month: 'Fevereiro', index: 1, enumPeriod: 2 },
  { month: 'Março', index: 2, enumPeriod: 3 },
  { month: 'Abril', index: 3, enumPeriod: 4 },
  { month: 'Maio', index: 4, enumPeriod: 5 },
  { month: 'Junho', index: 5, enumPeriod: 6 },
  { month: 'Julho', index: 6, enumPeriod: 7 },
  { month: 'Agosto', index: 7, enumPeriod: 8 },
  { month: 'Setembro', index: 8, enumPeriod: 9 },
  { month: 'Outubro', index: 9, enumPeriod: 10 },
  { month: 'Novembro', index: 10, enumPeriod: 11 },
  { month: 'Dezembro', index: 11, enumPeriod: 12 },
];

export const MONTH = (complete, marco) =>
  complete
    ? [
        'Janeiro',
        'Fevereiro',
        marco ? 'Marco' : 'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ]
    : [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez',
      ];

const isDate = (data) => {
  if (!(data instanceof Date)) {
    return new Date(data);
  }
  return data;
};

export const formatMonth = (month, complete) =>
  complete
    ? MONTH(complete)[month].toUpperCase()
    : MONTH(complete)[month].toUpperCase();

export const dateAndHours = (date) => {
  if (date) {
    const data = new Date(date);
    let minutes = data.getMinutes();
    if (minutes < 10) minutes = `0${minutes}`;

    let hours = data.getHours();
    if (hours < 10) hours = `0${hours}`;

    return `${data.getDate()} ${formatMonth(
      data.getMonth(),
    )} ${data.getFullYear()} ${hours}:${minutes}`;
  }

  return '';
};

export const dateHoursAndMinutes = (date) => {
  if (date) {
    const data = new Date(date);
    let minutes = data.getMinutes();
    if (minutes < 10) minutes = `0${minutes}`;

    let hours = data.getHours();
    if (hours < 10) hours = `0${hours}`;

    return `${data.getFullYear()}/${data.getMonth()}/${data.getDate()} ${hours}:${minutes}:00`;
  }

  return '';
};

export const monthAndYear = (date, complete = true) => {
  const data = new Date(date);

  return `${formatMonth(data.getMonth(), complete)} ${data.getFullYear()}`;
};

export const getFullDateAsString = (dateValue) => {
  return `${dateValue.getDate()} de ${formatMonth(
    dateValue.getMonth(),
    true,
  )} de ${dateValue.getFullYear()}`;
};

export const GET_YEARS = (earliestYear) => {
  const currentYear = new Date().getFullYear();
  const YEARS = [];

  for (earliestYear; currentYear + 2 >= earliestYear; earliestYear += 1) {
    YEARS.push({ year: `${earliestYear}` });
  }

  return YEARS.reverse();
};

export const getDateByMinutes = (min) => {
  const hours = min / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  const date = new Date();
  date.setHours(rhours);
  date.setMinutes(rminutes);
  return date;
};

export const getHourAndMinuteByMinutes = (min) => {
  if (min !== null && min !== undefined) {
    const hours = Math.abs(min) / 60;
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);
    const hour = rhours < 10 ? `0${rhours}` : rhours;
    const minute = rminutes < 10 ? `0${rminutes}` : rminutes;
    return `${hour}:${minute}`;
  }

  return '';
};

export const getMinutesByDateType = (date) => {
  if (date) {
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return hours * 60 + minutes;
  }

  return 0;
};

export const getDatebyDateTimeString = (stringDateTime) => {
  if (stringDateTime) {
    const dateTime = parseInt(stringDateTime.replace('/Date(', ''), 10);
    const date = new Date(dateTime);

    return date;
  }

  return '';
};

export const getDayMonthAndYear = (date) => {
  const auxData = isDate(date);
  const aux = '0';
  const month = formatMonth(auxData.getMonth());
  const day = auxData.getDate();
  const year = auxData.getFullYear();

  return `${
    day.toString().length === 1 ? aux.concat(day.toString()) : day.toString()
  } ${month} ${year}`;
};

export const getDayAndMonth = (date) => {
  const auxData = isDate(date);
  const aux = '0';
  const month = formatMonth(auxData.getMonth());
  const day = auxData.getDate();

  return `${
    day.toString().length === 1 ? aux.concat(day.toString()) : day.toString()
  } ${month}`;
};

export const hoursAndMinutesByDate = (date) => {
  if (date) {
    let data = date;
    if (typeof date === 'string') data = new Date(date);

    let minutes = data.getMinutes();
    if (minutes < 10) minutes = `0${minutes}`;

    let hours = data.getHours();
    if (hours < 10) hours = `0${hours}`;

    return `${hours}:${minutes}`;
  }

  return '';
};

export const dateByHoursAndMinutes = (hoursAndMinutes) => {
  if (!hoursAndMinutes) {
    return '';
  }

  const date = new Date();

  const hours = hoursAndMinutes.split(':')[0];
  const minutes = hoursAndMinutes.split(':')[1];

  date.setHours(hours);
  date.setMinutes(minutes);

  return date;
};

export const getStringForRequest = (date) => {
  const dateAux = isDate(date);

  const isoDateTime = new Date(
    dateAux.getTime() - dateAux.getTimezoneOffset() * 60000,
  ).toISOString();

  return isoDateTime;
};

export const getStringHourByMinute = (min) => {
  if (min < 0) min *= -1;
  const hours = min / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  const minute = rminutes < 10 ? `0${rminutes}` : rminutes;
  const hour = rhours < 10 ? `0${rhours}` : rhours;
  return `${hour}:${minute}`;
};

export const getHourAndMinute = (date) => {
  let hour = String(new Date(date).getHours());

  let minute = String(new Date(date).getMinutes());

  if (hour.length === 1) hour = `0${hour}`;
  if (minute.length === 1) minute = `0${minute}`;

  return `${hour}:${minute}`;
};

export const getDay = (date) => {
  const formattedDate = String(new Date(date).getDate());

  if (formattedDate.length === 1) {
    return `0${formattedDate}`;
  }

  return formattedDate;
};

export const getMonth = (date, complete) => {
  return MONTH(complete)[new Date(date).getMonth()];
};

export const getYear = (date) => {
  return new Date(date).getFullYear();
};

export const getWeekDay = (date) => {
  return ORDENADO_DIAS_SEMANA[new Date(date).getDay()];
};

export const getDataComDiaSemana = (date, complete = false) => {
  return `${getDay(date)} ${getMonth(date, complete)}, ${getWeekDay(date)}`;
};

export const getData = (date) => {
  const dateConvert = new Date(date);
  const mes = dateConvert.getMonth() + 1;
  return `${getDay(date)}/${mes > 9 ? mes : `0${mes}`}/${getYear(date)}`;
};

export const getDayDifferenceBewtweenTwoDates = (firstDate, lastDate) => {
  if (!firstDate || !lastDate) return null;
  const diffInTime = lastDate.getTime() - firstDate.getTime();
  const diffInDays = diffInTime / (1000 * 3600 * 24);

  return diffInDays;
};

export const getHowManyDaysSelected = (firstDate, lastDate) => {
  if (!firstDate || !lastDate) return null;
  const diffInTime = lastDate.getTime() - firstDate.getTime();
  const diffInDays = diffInTime / (1000 * 3600 * 24);

  return diffInDays + 1;
};

export const recuperaDataCompleta = (date) => {
  return `${getDay(date)}/${
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  }/${getYear(date)}`;
};

export const diaEntreDuasData = (dataInicial, dataFinal, dataComparar) => {
  dataInicial.setHours(0);
  dataInicial.setMinutes(0);
  dataInicial.setSeconds(0);
  dataFinal.setHours(0);
  dataFinal.setMinutes(0);
  dataFinal.setSeconds(0);
  return dataComparar <= dataFinal && dataComparar >= dataInicial;
};

export const isSameDate = (dataInicial, dataFinal) => {
  dataInicial.setHours(0);
  dataInicial.setMinutes(0);
  dataInicial.setSeconds(0);
  dataFinal.setHours(0);
  dataFinal.setMinutes(0);
  dataFinal.setSeconds(0);

  return dataInicial.getTime() === dataFinal.getTime();
};

export const getDiaDaSemana = (data, completo) => {
  const auxData = isDate(data);
  return auxData.toLocaleString('pt-br', {
    weekday: completo ? 'long' : 'short',
  });
};

export const getTimeStringFromDate = (date) => {
  let hora = date.getHours().toString();
  hora = hora.length === 1 ? `0${hora}` : hora;

  let minuto = date.getMinutes().toString();
  minuto = minuto.length === 1 ? `0${minuto}` : minuto;

  return `${hora}:${minuto}`;
};
