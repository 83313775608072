import produce from 'immer';

import { Types } from '../types';

// Reducers
const INITIAL_STATE = {
  loading: false,

  payload: null,
  payloadArquivar: null,
  payloadDesarquivar: null,

  ativas: null,
  arquivadas: null,
  todos: null,
  disponiveis: null,
}

export default function contratantes(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.CONTRATANTES_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.CONTRATANTES_SUCCESS: {
        draft.payload = action.payload.data;
        draft.loading = false;
        break;
      }
      case Types.CONTRATANTES_FAILURE: {
        draft.payload = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.CONTRATANTES_EDITAR_REQUEST: {
        draft.loading = true;
        break;
      }

      case Types.CONTRATANTES_EDITAR_RESPONSE: {
        draft.payload = action.payload;
        draft.loading = false;
        break;
      }

      case Types.CONTRATANTES_ATIVAS_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.CONTRATANTES_ATIVAS_SUCCESS: {
        draft.ativas = action.payload.data;
        draft.loading = false;
        break;
      }
      case Types.CONTRATANTES_ATIVAS_FAILURE: {
        draft.ativas = null;
        draft.loading = false;
        break;
      }

      case Types.CONTRATANTES_ARQUIVADAS_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.CONTRATANTES_ARQUIVADAS_SUCCESS: {
        draft.arquivadas = action.payload.data;
        draft.loading = false;
        break;
      }
      case Types.CONTRATANTES_ARQUIVADAS_FAILURE: {
        draft.arquivadas = null;
        draft.loading = false;
        break;
      }

      case Types.CONTRATANTES_TODOS_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.CONTRATANTES_TODOS_SUCCESS: {
        draft.todos = action.payload.data;
        draft.loading = false;
        break;
      }
      case Types.CONTRATANTES_TODOS_FAILURE: {
        draft.todos = null;
        draft.loading = false;
        break;
      }

      case Types.CONTRATANTES_DISPONIVEIS_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.CONTRATANTES_DISPONIVEIS_SUCCESS: {
        draft.disponiveis = action.payload.data;
        draft.loading = false;
        break;
      }
      case Types.CONTRATANTES_DISPONIVEIS_FAILURE: {
        draft.disponiveis = null;
        draft.loading = false;
        break;
      }

      case Types.CONTRATANTES_ARQUIVAR_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.CONTRATANTES_ARQUIVAR_RESPONSE: {
        draft.payloadArquivar = action.payload;
        draft.loading = false;
        break;
      }

      case Types.CONTRATANTES_DESARQUIVAR_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.CONTRATANTES_DESARQUIVAR_RESPONSE: {
        draft.payloadDesarquivar = action.payload;
        draft.loading = false;
        break;
      }

      case Types.CONTRATANTES_CLEAR_DATA: {
        draft.payload = null;
        draft.payloadArquivar = null;
        draft.payloadDesarquivar = null;
        draft.loading = false;
        break;
      }

      case Types.LOGOUT: {
        draft.payload = null;
        draft.payloadArquivar = null;
        draft.payloadDesarquivar = null;
        draft.ativas = null;
        draft.arquivadas = null;
        draft.todos = null;
        draft.disponiveis = null;
        draft.loading = false;
        break;
      }
      default:
    }
  });
}