import { takeLatest, call, put, all } from 'redux-saga/effects';
import { Types } from '../../../types';
// eslint-disable-next-line import/no-cycle
import { requestMotorNovoBancoHoras } from '~/services/request';

import {
  getBancoHorasConfiguracaoResponse,
  postBancoHorasConfiguracaoResponse,
  putBancoHorasConfiguracaoResponse,
} from './actions';

export function* bancoHorasConfiguracao({ payload, method, id }) {
  const params = {
    url: id
      ? `/api/v1/bancoHora/configuracao/${id}`
      : '/api/v1/bancoHora/configuracao',
    method,
    body: payload,
  };

  switch (method) {
    case 'GET':
      try {
        const response = yield call(requestMotorNovoBancoHoras, params);
        yield put(getBancoHorasConfiguracaoResponse(response.data));
      } catch (error) {
        yield put(getBancoHorasConfiguracaoResponse({ success: false }));
      }
      break;
    case 'POST':
      try {
        const response = yield call(requestMotorNovoBancoHoras, params);

        const aux = {
          id: response.headers.location,
          data: response.data,
        };
        yield put(postBancoHorasConfiguracaoResponse(aux));
      } catch (error) {
        yield put(postBancoHorasConfiguracaoResponse({ success: false }));
      }
      break;
    case 'PUT':
      try {
        const response = yield call(requestMotorNovoBancoHoras, params);
        yield put(putBancoHorasConfiguracaoResponse(response.data));
        yield put(postBancoHorasConfiguracaoResponse({ id }));
      } catch (error) {
        yield put(putBancoHorasConfiguracaoResponse({ success: false }));
      }
      break;

    default:
      break;
  }
}

export default all([
  takeLatest(Types.BANCO_HORAS_CONFIGURACAO_REQUEST, bancoHorasConfiguracao),
]);
