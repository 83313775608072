import { takeLatest, call, put, all, delay } from 'redux-saga/effects';
import { Types } from '../types';
import { toast } from 'react-toastify';
import { requestBaseV3 } from '~/services/request';
import { carregarPeriodoAtualSuccess, carregarPeriodoAtualFailure } from './actions';

export function* carregarPeriodoAtual({ payload }) {
	let body = {
		usuarioId: payload.usuarioId,
	};

	let params = {
		url: '/CarregarPeriodoAtual',
		method: 'POST',
		body: body
	};

	try {
		const response = yield call(requestBaseV3, params);
		if (response.data.Success) {
			const data = response.data;

			yield put(carregarPeriodoAtualSuccess(data));
			yield delay(2000);
		} else {
			toast.error(response.data.Error);
			yield put(carregarPeriodoAtualFailure());
		}
	} catch (error) {
		toast.error("Falha na autenticação");
		yield put(carregarPeriodoAtualFailure());
	}
}

export default all([
	takeLatest(Types.CARREGAR_PERIODO_ATUAL_REQUEST, carregarPeriodoAtual)
]);