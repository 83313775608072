import React, { createContext, useContext, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { requestEscalas } from '~/services/request';

const ContextoAcao = createContext();

export function ProvedorAcao({ children }) {
  const usuario = useSelector((state) => state.loginPayload.loggedIn.Usuario);

  const [acaoSelecinada, setAcaoSelecionada] = useState([]);
  const [listaDeAcaoDisponiveis, setListaDeAcaoDisponiveis] = useState([]);
  const usuarioLogado = useSelector(
    (state) => state.loginPayload.loggedIn.Usuario,
  );
  const buscarListaDeAcaoDisponiveis = useCallback(
    async (object) => {
      let cleanUp = false;
      const { usuarioId, data } = object;

      const params = {
        url: '/acao/disponivel',
        method: 'GET',
        queryParams: {
          UsuarioId: usuarioId,
          Data: data,
        },
      };
  
      const response = await requestEscalas(params);

      if (response.data) {
        if (!cleanUp) {
          setListaDeAcaoDisponiveis(response.data.data.items);
        }
      }
      return () => (cleanUp = true);
    },
    [setListaDeAcaoDisponiveis, requestEscalas, usuarioLogado],
  );

  const ocultarCriadorDeAcao = useCallback(async () => {
    await setAcaoSelecionada([]);
  }, []);

  const mostrarCriadorDeAcao = useCallback(
    async (dados) => {
      const acao = {
        id: dados ? dados.data : 0,
        ...dados,
        usuarioLogado,
      };
      await setAcaoSelecionada([acao]);
    },
    [usuarioLogado, setAcaoSelecionada],
  );
  const criarAcao = useCallback(
    async (body) => {
      try {
      const params = {
        url: '/acao',
        method: 'POST',
        body,
      };
  
      const response = await requestEscalas(params);

        if (response.data) {
          return response.data;
        }
      } catch (err) {
        if (err.response.data) {
          return err.response.data;
        }
      }

      return null;
    },
    [requestEscalas],
  );

  const alterarEntrada = useCallback(
    (params) => {
      const { usuarioId, data, novoHorario = '' } = params;
      const body = {
        usuarioId,
        data,
        tipoAcao: 'AlterarEntrada',
        novoHorarioEntrada: novoHorario,
      };
      return criarAcao(body);
    },
    [criarAcao, usuarioLogado],
  );

  const FolgaCompensarDuranteSemana = useCallback(
    (params) => {
      const { usuarioId, data } = params;
      const body = {
        usuarioId,
        data,
        tipoAcao: 'FolgaCompensarDuranteSemana',
      };
      return criarAcao(body);
    },
    [criarAcao, usuarioLogado],
  );

  const alterarEscala = useCallback(
    (params) => {
      const { UsuarioId, Data, NovaEscalaId, NovaEscalaDataFim } = params;
      const body = {
        UsuarioId,
        Data,
        tipoAcao: 'AlterarEscala',
        NovaEscalaId,
        NovaEscalaDataFim,
      };
      return criarAcao(body);
    },
    [criarAcao, usuarioLogado],
  );

  const mudarFolgaSemanal = useCallback(
    (params) => {
      const { usuarioId, data, novaFolgaSemanal = '' } = params;
      const body = {
        usuarioId,
        data,
        tipoAcao: 'MoverFolgaSemanal',
        novaFolgaSemanal,
      };
      return criarAcao(body);
    },
    [criarAcao, usuarioLogado],
  );

  const alterarStatus = useCallback(
    async (body) => {
      const params = {
        url: '/acao/status',
        method: 'PUT',
        body,
      };
  
      const response = await requestEscalas(params);
    },
    [requestEscalas],
  );
  const requestAcao = useCallback(
    (params) => {
      const body = {
        ...params,
      };
      return criarAcao(body);
    },
    [criarAcao, usuarioLogado],
  );

  return (
    <ContextoAcao.Provider
      value={{
        ocultarCriadorDeAcao,
        mostrarCriadorDeAcao,
        alterarEntrada,
        buscarListaDeAcaoDisponiveis,
        listaDeAcaoDisponiveis,
        mudarFolgaSemanal,
        requestAcao,
        FolgaCompensarDuranteSemana,
        alterarEscala,
        alterarStatus,
        acaoSelecinada,
        usuario,
      }}
    >
      {children}
    </ContextoAcao.Provider>
  );
}

export function useAcao() {
  const contexto = useContext(ContextoAcao);

  if (!contexto) {
    throw new Error('useAcao deve ser usado dentro do ProvedorAcao');
  }

  return contexto;
}
