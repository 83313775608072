import { Types } from '../../types';

export function grupoArquivadosRequest(params) {
  return {
    type: Types.GRUPO_ARQUIVADOS_REQUEST,
    payload: params,
  };
}

export function grupoArquivadosSuccess(data) {
  return {
    type: Types.GRUPO_ARQUIVADOS_SUCCESS,
    payload: { data },
  };
}

export function grupoArquivadosFailure(error) {
  return {
    type: Types.GRUPO_ARQUIVADOS_FAILURE,
    error: error,
  };
}
