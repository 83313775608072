import { takeLatest, call, put, all, delay } from 'redux-saga/effects';
import { requestBaseV3 } from '~/services/request';
import { Types } from '../types';
import { marcarPonto3Success, marcarPonto3Failure } from './actions';
import { carregarDia3Request } from '../carregarDia3/actions';

export function* marcarPonto3({ params }) {
  const parameters = {
    url: '/MarcarPonto3',
    method: 'POST',
    body: params,
  };

  try {
    const response = yield call(requestBaseV3, parameters);

    if (response.data.Success) {
      const { data } = response;

      yield put(marcarPonto3Success(data));
      yield put(carregarDia3Request());
      yield delay(2000);
    } else {
      yield put(marcarPonto3Failure(response.data));
    }
  } catch (error) {
    yield put(marcarPonto3Failure({ success: false }));
  }
}

export default all([takeLatest(Types.MARCAR_PONTO_3_REQUEST, marcarPonto3)]);
