import { Types } from '~/store/modules/types';

const baseUrl = '/acao';

function responseAcoes(data, callback) {
  if (callback) {
    callback(data);
  }

  return {
    type: Types.RESPONSE_ACOES,
    payload: { data },
  };
}

export function requestAcoes(params, callback) {
  return {
    type: Types.REQUEST_ACOES,
    url: baseUrl,
    method: 'POST',
    body: params,
    responseAction: responseAcoes,
    callback,
  };
}

export function getAcoes(params, callback) {
  return {
    type: Types.REQUEST_ACOES,
    url: baseUrl,
    method: 'GET',
    queryParams: params,
    responseAction: responseAcoes,
    callback,
  };
}

function responseAcaoPorId(data) {
  return {
    type: Types.RESPONSE_ACAO_POR_ID,
    payload: { data },
  };
}

export function getAcoesPorId(id) {
  return {
    type: Types.REQUEST_ACOES,
    url: `${baseUrl}/${id}`,
    method: 'GET',
    responseAction: responseAcaoPorId,
  };
}

export function alteraStatusAcao(params, callback) {
  return {
    type: Types.REQUEST_ACOES,
    url: `${baseUrl}/status`,
    method: 'PUT',
    body: params,
    responseAction: responseAcoes,
    callback,
  };
}

export function clearAcoes() {
  return { type: Types.CLEAR_RELATORIO };
}
