import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

export const Container = styled(animated.div)`
  position: relative;
  width: 433px;
  min-height: 100%;
  background: var(--cinzaDisabledLight);
`;
export const DivBranca = styled.div`
  border-top: 1px solid var(--tableBorder);
  border-bottom: 1px solid var(--tableBorder);
  background-color: #fff;
  padding: 36px;
  h1 {
    color: var(--light-blue);
    text-transform: capitalize;
    font-size: 28px;
  }
`;
export const DivTransparente = styled.div`
  display: flex;
  justify-content: center;
  background-color: transparent;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 55px;
  padding-right: 55px;
  button {
    background: transparent;
    color: var(--darkBlue);
    text-transform: uppercase;
    border: 1px solid var(--darkBlue);
    border-radius: 18px;
    font-size: 17px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    + button {
      margin-left: 20px;
      color: #ffffff;
      border-color: #f9c001;
      background: #f9c001;
    }
  }
`;

export const BotaoDiaCalendario = styled.button`
  border: none;
  background: none;
  height: 113px;
  width: 48px;
  padding: 0;
  span {
    color: var(--gray);
    font-size: 11px;
    text-transform: uppercase;
  }
  div {
    div {
      border: 1px solid var(--light-gray);
      height: 40px;
      background: var(--gray);

      display: flex;
      align-items: center;
      justify-content: center;
      span {
        color: #ffffff;
        font-size: 19px;
      }

      + div {
        border-top: none;
        height: 58px;
        flex-direction: column;

        p {
          color: var(--gray);
          margin: 0;
          ${(props) =>
            props.diatrabalhado
              ? css`
                  font-size: 11px;
                `
              : css`
                  font-size: 9px;
                `}
        }
        ${(props) =>
          props.diatrabalhado && props.selecionado
            ? css`
                background: var(--cinzaDisabledLight);
                border: 2px solid var(--warning);
              `
            : props.diatrabalhado
            ? css`
                background: var(--cinzaDisabledLight);
              `
            : css`
                background: var(--mediumGray);
              `}
      }
    }
  }
  + button {
    div {
      div {
        border-left: none;
      }
    }
  }
`;

export const UL = styled.ul`
  list-style: none;
  display: flex;
  padding: 15px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  padding-top: 50px;
  &.destaque {
    background: #fff;
    border-top: 1px solid #d9e5eb;
    div {
      display: flex;
      align-items: flex-end;
      h1 {
        margin-left: 16px;
        transform: translateY(-6px);
        text-transform: lowercase;
        color: #516267;
      }
    }
  }

  div {
    display: flex;
    align-items: center;
  }

  h1 {
    color: #00487e;
    font-size: 22px;
  }
  button {
    background: transparent;
    color: #00487e;
    text-transform: uppercase;

    &.simples {
      border: none;
      font-size: 11px;
      font-weight: bold;
    }
    &.aredondado {
      border: 1px solid #00487e;
      border-radius: 18px;
      font-size: 10px;
      height: 29px;
      width: 80px;
    }
  }

  &.suave {
    border-top: 1px solid #d9e5eb;

    background: var(--white);
    padding: 32px;
    h1 {
      color: var(--light-blue);
    }
  }
`;

export const Wrapper = styled.div`
  background: var(--white);
  width: 100%;
  span {
    font-size: 22px;
    color: var(--light-blue);
    strong {
      font-weight: bold;
    }
  }
`;

export const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 45px;
  min-width: 45px;

  color: ${({ active }) => (active ? 'white' : '#93AFB7')};
  font-family: 'Circular Book';
  font-size: 29px;

  border-radius: 50%;
  background-color: ${({ active }) => active && 'var(--primary)'};
  border: ${({ active }) => !active && '1px solid #93AFB7'};

  cursor: pointer;
`;

export const Line = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: auto;
  margin-bottom: auto;

  width: 100%;
  height: 1px;

  background-color: #93afb7;

  cursor: pointer;
`;
export const LabelCompensaSemana = styled.div`
  font-size: 28px;
  color: var(--primary);
`;

export const JourneySpan = styled.tspan`
  font-size: 25px;
  font-weight: bold;
`;

export const Disclaimer = styled.span`
  font-size: 14px;
  color: var(--gray);

  ${({ title }) =>
    title &&
    css`
      color: var(--primary);
      font-size: 17px;
      margin: 10px 0;
    `};
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  h1 {
    font-size: 22px;
    color: var(--gray);
    + h1 {
      font-size: 17px;
      color: var(--primary);
    }
  }
  &.cards {
    padding: 0 !important;
  }
`;

export const Switcher = styled.button`
  padding: 5px;
  padding-right: 9px;
  padding-left: 9px;
  border-radius: 30px;
  border: none;
  background: rgb(196, 103, 237) !important;
  background: linear-gradient(
    0deg,
    rgba(196, 103, 237, 1) 3%,
    rgba(247, 59, 98, 1) 100%
  ) !important;
  width: 71px;
  height: 29px;
  display: flex;
  align-items: center;
  flex-direction: column;

  span {
    color: var(--white) !important;
    font-size: 13px;
    align-self: flex-end;
    text-transform: uppercase;
  }
  div {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #edf3f5;
    background: rgb(217, 229, 235);
    background: linear-gradient(
      0deg,
      rgba(217, 229, 235, 1) 49%,
      rgba(255, 255, 255, 1) 100%
    );
    position: absolute;
    transform: translate(-21px, -2.5px);
    transition: transform 0.4s;
  }

  ${({ on }) =>
    on &&
    css`
      background: var(--light-blue) !important;
      justify-content: flex-start;

      div {
        transform: translate(21px, -2.5px);
      }
      span {
        align-self: flex-start;
      }
    `}
`;
