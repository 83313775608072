import { Types } from '../../../types';

export function bancoHorasConfiguracaoCicloVerbaRequest({ params, method, id }) {
  return {
    type: Types.BANCO_HORAS_CONFIGURACAO_CICLO_VERBA_REQUEST,
    payload: params,
    method: method,
    id: id
  };
}

export function getBancoHorasConfiguracaoCicloVerbaResponse(data) {
  return {
    type: Types.GET_BANCO_HORAS_CONFIGURACAO_CICLO_VERBA_RESPONSE,
    payload: { data },
  };
}

export function postBancoHorasConfiguracaoCicloVerbaResponse(data) {
  return {
    type: Types.POST_BANCO_HORAS_CONFIGURACAO_CICLO_VERBA_RESPONSE,
    payload: { data },
  };
}

export function putBancoHorasConfiguracaoCicloVerbaResponse(data) {
  return {
    type: Types.PUT_BANCO_HORAS_CONFIGURACAO_CICLO_VERBA_RESPONSE,
    payload: { data },
  };
}
