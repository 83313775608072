import { Types } from '../../types';

export function requestHolerites(params) {
  return {
    type: Types.REQUEST_LISTAR_HOLERITES,
    payload: params,
  };
}

export function responseListarHolerites(data) {
  return {
    type: Types.RESPONSE_LISTAR_HOLERITES,
    payload: { data },
  };
}
