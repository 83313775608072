import React, { createContext, useContext, useCallback } from 'react';

import { requestEscalas } from '~/services/request';

const ContextoTag = createContext();

export function ProvedorTag({ children }) {
  const resolverTags = useCallback(
    async (object) => {      
      const { usuariosIds, ano, mes } = object;
      const params = {
        url: '/tag',
        method: 'GET',
        queryParams: {
          Ano: ano,
          Mes: mes,
          UsuariosIds : usuariosIds,
        },
      };
  
      const response = await requestEscalas(params);

      if (response.data) {
        return response.data.data;
      }
    },
    [requestEscalas],
  );
  return (
    <ContextoTag.Provider
      value={{
        resolverTags,
      }}
    >
      {children}
    </ContextoTag.Provider>
  );
}

export function useTag() {
  const contexto = useContext(ContextoTag);

  if (!contexto) {
    throw new Error('useTag deve ser usado dentro do ProvedorTag');
  }

  return contexto;
}
