import {
  requestRegrasCondicoesTrabalho,
  getListaRegrasCondicoesTrabalho,
  getComboRegrasCondicoesTrabalho,
  getRegrasCondicoesTrabalhoPorId,
  alteraStatusRegrasCondicoesTrabalho,
  alterarRegrasCondicoesTrabalho,
  clearRegrasCondicoesTrabalho,
} from './configuracoes/regrasCondicoesTrabalho/actions';

import {
  requestRegrasGestaoEscala,
  getListaRegrasGestaoEscala,
  requestRegrasGestaoEscalaCombo,
  getRegrasGestaoPorId,
  alteraStatusRegrasGestao,
  requestAlteraRegrasGestaoEscala,
  clearRegrasGestaoEscala,
} from './configuracoes/regrasGestaoEscala/actions';

import { getCalendarioEquipe } from './equipe/actions';

import { getTags } from './tags/actions';

import {
  requestAlocacaoEscalas,
  getListaAlocacaoEscalas,
  getAlocacaoEscalasById,
  getAlocacaoHistorico,
  getAlocacaoHistoricoById,
  putAlocacaoEscalas,
  postAlocacaoEscalas,
  requestChangeStatusAlocacao,
  clearAlocacao,
} from './configuracoes/alocacao/actions';

import {
  requestEscalasDeTrabalho,
  requestChangeStatus,
  requestCombo,
  clearEscalasTrabalho,
  criarEscala,
  getEscalaById,
  validarEscala,
  getEscalaPadrao,
} from './configuracoes/escalaTrabalho/actions';

import {
  getTurno,
  PostTurno,
  PutTurno,
  ClearTurno,
} from './configuracoes/turno/actions';

import { getEscalasEmUso } from './escalasEmUso/actions';

import {
  getAcoes,
  requestAcoes,
  getAcoesPorId,
  alteraStatusAcao,
} from './acoes/actions';

export {
  requestRegrasCondicoesTrabalho,
  getListaRegrasCondicoesTrabalho,
  alterarRegrasCondicoesTrabalho,
  requestRegrasGestaoEscala,
  getListaRegrasGestaoEscala,
  requestRegrasGestaoEscalaCombo,
  requestAlteraRegrasGestaoEscala,
  clearRegrasGestaoEscala,
  getComboRegrasCondicoesTrabalho,
  getRegrasCondicoesTrabalhoPorId,
  alteraStatusRegrasCondicoesTrabalho,
  getRegrasGestaoPorId,
  alteraStatusRegrasGestao,
  getCalendarioEquipe,
  getTags,
  requestAlocacaoEscalas,
  getListaAlocacaoEscalas,
  getAlocacaoEscalasById,
  getAlocacaoHistorico,
  getAlocacaoHistoricoById,
  putAlocacaoEscalas,
  postAlocacaoEscalas,
  requestChangeStatusAlocacao,
  requestEscalasDeTrabalho,
  requestChangeStatus,
  requestCombo,
  clearAlocacao,
  clearEscalasTrabalho,
  criarEscala,
  getEscalaById,
  validarEscala,
  getTurno,
  PostTurno,
  PutTurno,
  ClearTurno,
  getEscalasEmUso,
  getEscalaPadrao,
  clearRegrasCondicoesTrabalho,
  getAcoes,
  requestAcoes,
  getAcoesPorId,
  alteraStatusAcao,
};
