import { Types } from '../types';

export function usuariosConvocacaoRequest(inicio, fim, folga) {
  return {
    type: Types.USUARIOS_CONVOCACAO_REQUEST,
    payload: {
      inicioTrabalho: inicio,
      fimTrabalho: fim,
      diasFolga: folga,
    },
  };
}

export function usuariosConvocacaoSuccess(data) {
  return {
    type: Types.USUARIOS_CONVOCACAO_SUCCESS,
    payload: { data },
  };
}

export function usuariosConvocacaoFailure() {
  return {
    type: Types.USUARIOS_CONVOCACAO_FAILURE,
  };
}

export function usuariosIntermitentesTodosRequest() {
  return {
    type: Types.USUARIOS_INTERMITENTES_TODOS_REQUEST,
  };
}

export function usuariosIntermitentesTodosSuccess(data) {
  return {
    type: Types.USUARIOS_INTERMITENTES_TODOS_SUCCESS,
    payload: { data },
  };
}

export function usuariosIntermitentesTodosFailure() {
  return {
    type: Types.USUARIOS_INTERMITENTES_TODOS_FAILURE,
  };
}
