import { Types } from '../types';

export function grupoGetRequest(params) {
  return {
    type: Types.GRUPO_GET_REQUEST,
    payload: params,
  };
}
export function grupoGetSuccess(data) {
  return {
    type: Types.GRUPO_GET_SUCCESS,
    payload: { data },
  };
}

export function grupoGetFailure(error) {
  return {
    type: Types.GRUPO_GET_FAILURE,
    error: error,
  };
}

export function grupoPostRequest(params) {
  return {
    type: Types.GRUPO_POST_REQUEST,
    payload: params,
  };
}

export function grupoPostSuccess(data) {
  return {
    type: Types.GRUPO_POST_SUCCESS,
    payload: { data },
  };
}

export function grupoPostFailure(error) {
  return {
    type: Types.GRUPO_POST_FAILURE,
    error: error,
  };
}

export function grupoArquivarRequest(params) {
  return {
    type: Types.GRUPO_ARQUIVAR_REQUEST,
    payload: params,
  };
}

export function grupoArquivarResponse(data) {
  return {
    type: Types.GRUPO_ARQUIVAR_RESPONSE,
    payload: { data },
  };
}

export function grupoEditarRequest(params) {
  return {
    type: Types.GRUPO_EDITAR_REQUEST,
    payload: params,
  };
}

export function grupoEditarResponse(data) {
  return {
    type: Types.GRUPO_EDITAR_RESPONSE,
    payload: data,
  };
}

export function grupoDesarquivarRequest(params) {
  return {
    type: Types.GRUPO_DESARQUIVAR_REQUEST,
    payload: params,
  };
}

export function grupoDesarquivarResponse(data) {
  return {
    type: Types.GRUPO_DESARQUIVAR_RESPONSE,
    payload: { data },
  };
}

export function grupoClearData() {
  return {
    type: Types.GRUPO_CLEAR_DATA,
  };
}
