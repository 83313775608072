import { takeLatest, call, put, all, delay } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import { Types } from '../types';

import { requestIntermitentes } from '~/services/request';

import {
  usuariosConvocacaoSuccess,
  usuariosConvocacaoFailure,
  usuariosIntermitentesTodosSuccess,
  usuariosIntermitentesTodosFailure,
} from './actions';

export function* usuariosConvocacao({ payload }) {
  const params = {
    url: '/Usuario/obter-usuarios-convocacao-alocacao',
    method: 'POST',
    body: payload,
  };

  try {
    const response = yield call(requestIntermitentes, params);
    if (response.data.success) {
      const { data } = response.data;

      yield put(usuariosConvocacaoSuccess(data));
      yield delay(2000);
    } else {
      toast.error(response.data.Error);
      yield put(usuariosConvocacaoFailure());
    }
  } catch (error) {
    toast.error('Falha na autenticação');
    yield put(usuariosConvocacaoFailure());
  }
}

export function* usuariosIntermitentesTodos() {
  const params = {
    url: '/Usuario',
    method: 'GET',
    body: null,
  };

  try {
    const response = yield call(requestIntermitentes, params);
    if (response.data.success) {
      const { data } = response.data;

      yield put(usuariosIntermitentesTodosSuccess(data));
      yield delay(2000);
    } else {
      toast.error(response.data.Error);
      yield put(usuariosIntermitentesTodosFailure());
    }
  } catch (error) {
    toast.error('Falha na autenticação');
    yield put(usuariosIntermitentesTodosFailure());
  }
}

export default all([
  takeLatest(Types.USUARIOS_CONVOCACAO_REQUEST, usuariosConvocacao),
  takeLatest(
    Types.USUARIOS_INTERMITENTES_TODOS_REQUEST,
    usuariosIntermitentesTodos,
  ),
]);
