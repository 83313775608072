import { Types } from '../../types';

export function horaNoturnaRequest({ params, method, id }) {
  return {
    type: Types.HORA_NOTURNA_REQUEST,
    payload: params,
    method: method,
    id: id
  };
}

export function getHoraNoturnaResponse(data) {
  return {
    type: Types.GET_HORA_NOTURNA_RESPONSE,
    payload: { data },
  };
}

export function postHoraNoturnaResponse(data) {
  return {
    type: Types.POST_HORA_NOTURNA_RESPONSE,
    payload: { data },
  };
}

export function putHoraNoturnaResponse(data) {
  return {
    type: Types.PUT_HORA_NOTURNA_RESPONSE,
    payload: { data },
  };
}
