import { Types } from '../types';

export function locaisDeTrabalhoRequest() {
  return {
    type: Types.LOCAIS_DE_TRABALHO_REQUEST
  };
}

export function locaisDeTrabalhoSuccess(data) {
  return {
    type: Types.LOCAIS_DE_TRABALHO_SUCCESS,
    payload: { data },
  };
}

export function locaisDeTrabalhoFailure() {
  return {
    type: Types.LOCAIS_DE_TRABALHO_FAILURE
  };
}
