import { Types } from '../../types';

export function requestListaTiposHolerites(params) {
  return {
    type: Types.REQUEST_LISTA_TIPOS_HOLERITES,
    payload: params,
  };
}

export function responseListaTiposHolerites(data) {
  return {
    type: Types.RESPONSE_LISTA_TIPOS_HOLERITES,
    payload: { data },
  };
}
