import styled, { css } from 'styled-components';

const colors = {
  incolor: {
    completo: css`
      background: transparent;
      color: var(--darkBlue);
      border-color: var(--darkBlue);
    `,
    color: css`
      color: var(--darkBlue);
    `,
  },
  azul: {
    completo: css`
      background: var(--darkBlue);
      color: var(--white);
      border-color: var(--darkBlue);
    `,
    color: css`
      color: var(--white);
    `,
  },
  amarelo: {
    completo: css`
      background: var(--amareloFolha);
      color: var(--white);
      border-color: var(--amareloFolha);
    `,
    color: css`
      color: var(--white);
    `,
  },
};

const ButtonParao = styled.button`
  text-transform: uppercase;
  border: 1px solid;
  border-radius: 18px;
  font-size: 10px;
  padding: 5px 20px;

  font-weight: bold;
  display: flex;

  ${({ color }) => (color ? colors[color].completo : colors.incolor.completo)}

  svg {
    margin-right: 8px;
    color: ${({ color }) =>
      color ? colors[color].color : colors.incolor.color};
    ${({ left }) =>
      left &&
      css`
        margin-right: 0;
        margin-left: 8px;
      `}
  }
`;
export default ButtonParao;
