import { takeLatest, call, put, all } from 'redux-saga/effects';

import { Types } from '../../types';
import { requestListagem, requestHolerites } from '~/services/request';
import {
  responseDeletaRegistroPorId,
  responseDeletaRegistroEmGrupo,
} from './actions';

export function* deletaRegistroPorId({ queryParams, callback }) {
  const params = {
    url: `/importacao/${queryParams.Id}`,
    method: 'DELETE',
    queryParams,
  };

  try {
    const response = yield call(requestListagem, params);
    yield put(responseDeletaRegistroPorId(response.data, callback));
  } catch (error) {
    yield put(responseDeletaRegistroPorId({ success: false, callback }));
  }
}

export function* deletaRegistroEmGrupo({ body, callback }) {
  const params = {
    url: `/importacao`,
    method: 'DELETE',
    body,
  };

  try {
    const response = yield call(requestHolerites, params);
    yield put(responseDeletaRegistroEmGrupo(response.data, callback));
  } catch (error) {
    yield put(responseDeletaRegistroEmGrupo({ success: false, callback }));
  }
}

export default all([
  takeLatest(Types.REQUEST_DELETE_OPTIONS, deletaRegistroPorId),
  takeLatest(Types.REQUEST_DELETE_OPTIONS_GROUP, deletaRegistroEmGrupo),
]);
