import { takeLatest, call, put, all } from 'redux-saga/effects';

import { Types } from '../../types';
import { requestFiltrarHolerites } from '~/services/request';
import { filtrarPessoasResponse } from './actions';

export function* filtrarHoleritesPorPessoas({ payload }) {
  const params = {
    url: '/pessoas',
    method: 'GET',
    body: payload,
  };

  try {
    const response = yield call(requestFiltrarHolerites, params);
    yield put(filtrarPessoasResponse(response.data));
  } catch (error) {
    yield put(filtrarPessoasResponse({ success: false }));
  }
}

export default all([
  takeLatest(Types.FILTRAR_PESSOAS_REQUEST, filtrarHoleritesPorPessoas),
]);
