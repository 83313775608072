import { takeLatest, call, put, all } from 'redux-saga/effects';
import { Types } from '../types';
import { requestMotorNovo } from '~/services/request';

import {
  getFormaApuracaoResponse,
  postFormaApuracaoResponse,
  putFormaApuracaoResponse,
  deleteFormaApuracaoResponse
} from './actions';

export function* formaApuracao({ payload, method }) {
  let params = {
    url: '/api/v1/formaApuracao',
    method: method,
    body: payload
  };

  switch (method) {
    case "GET":
      getRequest(params);
      break;
    case "POST":
      postRequest(params);
      break;
    case "PUT":
      putRequest(params);
      break;
    case "DELETE":
      deleteRequest(params);
      break;

    default:
      break;
  }
}

export function* getRequest(params) {
  try {
    const response = yield call(requestMotorNovo, params);
    yield put(getFormaApuracaoResponse(response.data));
  } catch (error) {
    yield put(getFormaApuracaoResponse({ success: false }));
  }
}

export function* postRequest(params) {
  try {
    const response = yield call(requestMotorNovo, params);
    yield put(postFormaApuracaoResponse(response.data));
  } catch (error) {
    yield put(postFormaApuracaoResponse({ success: false }));
  }
}

export function* putRequest(params) {
  try {
    const response = yield call(requestMotorNovo, params);
    yield put(putFormaApuracaoResponse(response.data));
  } catch (error) {
    yield put(putFormaApuracaoResponse({ success: false }));
  }
}

export function* deleteRequest(params) {
  try {
    const response = yield call(requestMotorNovo, params);
    yield put(deleteFormaApuracaoResponse(response.data));
  } catch (error) {
    yield put(deleteFormaApuracaoResponse({ success: false }));
  }
}

export default all([
  takeLatest(Types.FORMA_APURACAO_REQUEST, formaApuracao),
]);