import produce from 'immer';

import { Types } from '~/store/modules/types';

const INITIAL_STATE = {
  loading: false,
  porId: null,
  lista: null,
  listaCombo: null,
};

export default function regrasGestaoEscala(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.REQUEST_REGRAS_GESTAO_ESCALA: {
        draft.loading = true;
        break;
      }

      case Types.RESPONSE_REGRAS_GESTAO_ESCALA: {
        draft.lista = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.RESPONSE_REGRAS_GESTAO_ESCALA_COMBO: {
        draft.listaCombo = action.payload.data;
        draft.loading = true;
        break;
      }

      case Types.REQUEST_REGRAS_GESTAO_POR_ID: {
        draft.loading = true;
        break;
      }

      case Types.RESPONSE_REGRAS_GESTAO_POR_ID: {
        draft.porId = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.ALTERA_STATUS_REGRAS_GESTAO: {
        draft.loading = true;
        break;
      }

      case Types.RESPONSE_ALTERA_STATUS_REGRAS_GESTAO: {
        draft.lista = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.CLEAR_RELATORIO: {
        draft.loading = false;
        draft.listaCombo = null;
        draft.lista = null;
        draft.porId = null;
        break;
      }

      case Types.LOGOUT: {
        draft.loading = false;
        draft.listaCombo = null;
        draft.lista = null;
        draft.porId = null;
        break;
      }

      default:
    }
  });
}
