import { takeLatest, call, put, all, delay } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { Types } from '../../types';

import { requestBaseV3 } from '~/services/request';

export function* request({ url, body, responseAction, method, callback }) {
  const formData = new FormData();
  Object.keys(body).forEach((key) => formData.append(key, body[key]));

  const parameters = {
    url,
    method,
    body: formData,
  };

  try {
    const response = yield call(requestBaseV3, parameters);
    const { data } = response;

    if (data.Success) {
      yield put(responseAction(data, callback));
      toast.info('Solicitação de desconsideração realizada');
      yield delay(2000);
    } else {
      toast.error(data.Error);
      yield put(responseAction(data, callback));
    }
  } catch (error) {
    toast.error('Erro ao desconsiderar ponto');
    yield put(
      responseAction({ Success: false, Error: 'Erro ao desconsiderar ponto' }, callback),
    );
  }
}

export default all([takeLatest(Types.DESCONSIDERAR_MARCACAO_REQUEST, request)]);
