import React from 'react';

import { Container } from './styles';
import MoverFolgaSemanal from './MoverFolgaSemanal';
import AlterarEntrada from './AlterarEntrada';
import MudarPara from './MudarPara';
import AlterarEscala from './AlterarEscala';
import FolgaCompensarDuranteSemana from './FolgaCompensarDuranteSemana';
import FolgaCompensarBancoHoras from './FolgaCompensarBancoHoras';
import VisualizarAcao from './VisualizarAcao';

export default function Acao({ dados, style, option, onClose, callback }) {
  const acao = [
    <div />,
    <MudarPara dados={dados} onClose={onClose} callback={callback} />,
    <MudarPara dados={dados} onClose={onClose} callback={callback} />,
    <AlterarEntrada dados={dados} onClose={onClose} callback={callback} />,
    <MoverFolgaSemanal dados={dados} onClose={onClose} callback={callback} />,
    <FolgaCompensarDuranteSemana
      dados={dados}
      onClose={onClose}
      callback={callback}
    />,
    <FolgaCompensarBancoHoras
      dados={dados}
      onClose={onClose}
      callback={callback}
    />,
    <AlterarEscala dados={dados} onClose={onClose} callback={callback} />,
    <VisualizarAcao dados={dados} onClose={onClose} callback={callback} />,
  ];

  return <Container style={style}>{acao[option]}</Container>;
}
