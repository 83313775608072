import produce from 'immer';

import { Types } from '../types';

// Reducers
const INITIAL_STATE = {
  indicadoresGET: null,
  indicadoresPOST: null,
  indicadoresPUT: null,
  indicadoresGET_POR_ID: null,

  erro: null,
  loading: false,
}

export default function indicadores(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.INDICADORES_REQUEST: {
        draft.loading = true;
        break;
      }

      // GET PEDIDO
      case Types.INDICADORES_GET_SUCCESS: {
        draft.indicadoresGET = action.payload.data;
        draft.loading = false;
        break;
      }

      // GET PEDIDO POR ID
      case Types.INDICADORES_GET_ID_SUCCESS: {
        draft.indicadoresGET_POR_ID = action.payload.data;
        draft.loading = false;
        break;
      }

      // POST PEDIDO
      case Types.INDICADORES_POST_SUCCESS: {
        draft.indicadoresPOST = action.payload.data;
        draft.loading = false;
        break;
      }

      // PUT INDICADORES
      case Types.INDICADORES_PUT_SUCCESS: {
        draft.indicadoresPUT = action.payload.data;
        draft.loading = false;
        break;
      }

      // ERRO
      case Types.INDICADORES_FAILURE: {
        draft.erro = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.CLEAR_INDICADORES: {
        draft.indicadoresGET = null;
        draft.indicadoresPOST = null;
        draft.indicadoresPUT = null;
        draft.indicadoresGET_POR_ID = null;
        
        draft.loading = false;
        draft.erro = false;
        break;
      }
      
      case Types.LOGOUT: {
        draft.indicadoresGET = null;
        draft.indicadoresPOST = null;
        draft.indicadoresPUT = null;
        draft.indicadoresGET_POR_ID = null;

        draft.loading = false;
        draft.erro = false;
        break;
      }
      default:
    }
  });
}