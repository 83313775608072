import { takeLatest, call, put, all } from 'redux-saga/effects';

import { Types } from '../../types';
import { requestFiltrarHolerites } from '~/services/request';
import { filtrarDepartamentosResponse } from './actions';

export function* filtrarHoleritesPorDepartamentos({ payload }) {
  const params = {
    url: '/departamentos',
    method: 'GET',
    body: payload,
  };

  try {
    const response = yield call(requestFiltrarHolerites, params);
    yield put(filtrarDepartamentosResponse(response.data));
  } catch (error) {
    yield put(filtrarDepartamentosResponse({ success: false }));
  }
}

export default all([
  takeLatest(
    Types.FILTRAR_DEPARTAMENTOS_REQUEST,
    filtrarHoleritesPorDepartamentos,
  ),
]);
