import { Types } from '../../types';

export function faltasRequest({ params, method, id }) {
  return {
    type: Types.FALTAS_REQUEST,
    payload: params,
    method: method,
    id: id
  };
}

export function getFaltasResponse(data) {
  return {
    type: Types.GET_FALTAS_RESPONSE,
    payload: { data },
  };
}

export function postFaltasResponse(data) {
  return {
    type: Types.POST_FALTAS_RESPONSE,
    payload: { data },
  };
}

export function putFaltasResponse(data) {
  return {
    type: Types.PUT_FALTAS_RESPONSE,
    payload: { data },
  };
}
