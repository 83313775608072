import produce from 'immer';

import { Types } from '~/store/modules/types';

const INITIAL_STATE = {
  loading: false,
  saldo: null,
  lista: null,
};

export default function regrasCondicoesTrabalho(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.REQUEST_ALOCACAO_ESCALAS: {
        draft.loading = true;
        break;
      }

      case Types.REQUEST_ALOCACAO_HISTORICO: {
        draft.loading = true;
        break;
      }

      case Types.REQUEST_ALOCACAO_ESCALAS_BY_ID: {
        draft.loading = true;
        break;
      }

      case Types.REQUEST_PUT_POST_ALOCACAO_ESCALAS: {
        draft.loading = true;
        break;
      }

      case Types.RESPONSE_ALOCACAO_ESCALAS: {
        draft.payloadAlocacaoList = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.RESPONSE_ALOCACAO_ESCALAS_BY_ID: {
        draft.payloadAlocacao = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.RESPONSE_ALOCACAO_HISTORICO_BY_ID: {
        draft.payloadHistoricoDetalhe = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.RESPONSE_ALOCACAO_HISTORICO: {
        draft.payloadHistorico = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.CLEAR_ALOCACAO_ESCALAS: {
        draft.loading = false;
        draft.payloadAlocacao = null;
        draft.payloadAlocacaoList = null;
        break;
      }

      case Types.LOGOUT: {
        draft.loading = false;
        draft.lista = null;
        break;
      }

      default:
    }
  });
}
