import { Types } from '../../../types';

export function bancoHoraRequest({ params, method, id }) {
  return {
    type: Types.BANCO_HORA_REQUEST,
    payload: params,
    method: method,
    id: id
  };
}

export function getBancoHoraResponse(data) {
  return {
    type: Types.GET_BANCO_HORA_RESPONSE,
    payload: { data },
  };
}

export function postBancoHoraResponse(data) {
  return {
    type: Types.POST_BANCO_HORA_RESPONSE,
    payload: { data },
  };
}

export function putBancoHoraResponse(data) {
  return {
    type: Types.PUT_BANCO_HORA_RESPONSE,
    payload: { data },
  };
}