import { Types } from '../types';

export function gerenciarProcessosInternos({ params }, callback) {
  return {
    type: Types.GERENCIAR_PROCESSOS_INTERNOS_REQUEST,
    payload: params,
    callback,
  };
}

export function gerenciarProcessosInternosResponse(data, callback) {
  callback(data);
  return {
    type: Types.GERENCIAR_PROCESSOS_INTERNOS_RESPONSE,
    payload: { data },
  };
}
