import produce from 'immer';

import { Types } from '../types';

// Reducers
const INITIAL_STATE = {
  pedidoGET: null,
  pedidoPOST: null,
  pedidoGetPorId: null,

  erro: null,
  loading: false,
};

export default function reprocessamento(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.PEDIDO_REPROCESSAMENTO_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.PEDIDO_REPROCESSAMENTO_DETALHE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.PEDIDO_REPROCESSAMENTO_LOG_REQUEST: {
        draft.loading = true;
        break;
      }

      // GET PEDIDO
      case Types.PEDIDO_GET_SUCCESS: {
        draft.pedidoGET = action.payload.data;
        draft.loading = false;
        break;
      }

      // GET PEDIDO POR ID
      case Types.PEDIDO_GET_ID_SUCCESS: {
        draft.pedidoGetPorId = action.payload.data;
        draft.loading = false;
        break;
      }

      // GET PEDIDO LOG POR ID
      case Types.PEDIDO_LOG_SUCCESS: {
        draft.pedidoLOG = action.payload.data;
        draft.loading = false;
        break;
      }

      // POST PEDIDO
      case Types.PEDIDO_POST_SUCCESS: {
        draft.pedidoPOST = action.payload.data;
        draft.loading = false;
        break;
      }

      // ERRO DO PROCESSAMENTO
      case Types.PEDIDO_REPROCESSAMENTO_FAILURE: {
        draft.erro = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.CLEAR_REPROCESSAMENTO: {
        draft.pedidoGET = null;
        draft.pedidoPOST = null;
        draft.pedidoGetPorId = null;
        draft.pedidoLOG = null;

        draft.loading = false;
        draft.erro = false;
        break;
      }

      case Types.LOGOUT: {
        draft.pedidoGET = null;
        draft.pedidoPOST = null;
        draft.pedidoGetPorId = null;

        draft.loading = false;
        draft.erro = false;
        break;
      }
      default:
    }
  });
}
