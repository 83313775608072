import produce from 'immer';

import { Types } from '../types';

// Reducers
const INITIAL_STATE = {
  payload: null,
  loading: false,
};

export default function carregarDownloads(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.CARREGAR_DOWNLOADS_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.CARREGAR_DOWNLOADS_RESPONSE: {
        draft.payload = action.payload.data;
        draft.loading = false;
        break;
      }
      case Types.CARREGAR_DOWNLOADS_ARQUIVO_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.CARREGAR_DOWNLOADS_ARQUIVO_RESPONSE: {
        draft.loading = false;
        break;
      }
      case Types.CARREGAR_DOWNLOADS_ARQUIVOS_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.CARREGAR_DOWNLOADS_ARQUIVOS_RESPONSE: {
        draft.loading = false;
        break;
      }
      case Types.CARREGAR_DOWNLOADS_PARA_QUEM_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.CARREGAR_DOWNLOADS_PARA_QUEM_RESPONSE: {
        draft.payloadParaQuem = action.payload.data;
        draft.loading = false;
        break;
      }
      case Types.LOGOUT: {
        draft.payload = null;
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
