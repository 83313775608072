import produce from 'immer';

import { Types } from '../types';

// Reducers
const INITIAL_STATE = {
  payload: null,
  ausenciaDetalhe: null,

  loading: false,
}

export default function carregarAvisoAusenciaLancamentos(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.CARREGAR_AVISO_AUSENCIA_LANCAMENTOS_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.CARREGAR_AVISO_AUSENCIA_LANCAMENTOS_SUCCESS: {
        draft.payload = action.payload.data;
        draft.loading = false;
        break;
      }
      case Types.CARREGAR_AVISO_AUSENCIA_LANCAMENTOS_FAILURE: {
        draft.payload = null;
        draft.loading = false;
        break;
      }

      case Types.CARREGAR_AUSENCIA_POR_ID_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.CARREGAR_AUSENCIA_POR_ID_SUCCESS: {
        draft.ausenciaDetalhe = action.payload.data;
        draft.loading = false;
        break;
      }
      case Types.CARREGAR_AUSENCIA_POR_ID_FAILURE: {
        draft.ausenciaDetalhe = null;
        draft.erro = action.payload.data;
        draft.loading = false;
        break;
      }


      case Types.CLEAR_AVISO_AUSENCIA_LANCAMENTO: {
        draft.payload = null;
        draft.ausenciaDetalhe = null;
        
        draft.erro = false;
        draft.loading = false;
        break;
      }


      case Types.LOGOUT: {
        draft.payload = null;
        draft.ausenciaDetalhe = null;
        
        draft.erro = false;
        draft.loading = false;
        break;
      }
      default:
    }
  });
}