import { Types } from '../types';

export function carregarUsuariosGestoresRequest(usuarioId) {
  return {
    type: Types.CARREGAR_USUARIOS_GESTORES_REQUEST,
    payload: { usuarioId },
  };
}

export function carregarUsuariosGestoresSuccess(data) {
  return {
    type: Types.CARREGAR_USUARIOS_GESTORES_SUCCESS,
    payload: data,
  };
}

export function carregarUsuariosGestoresFailure() {
  return {
    type: Types.CARREGAR_USUARIOS_GESTORES_FAILURE,
  };
}
