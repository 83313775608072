import { takeLatest, call, put, all } from 'redux-saga/effects';
import { Types } from '../types';
import { requestBaseV3 } from '~/services/request';

import {
  carregarUsuariosGestoresSuccess,
  carregarUsuariosGestoresFailure
} from './actions';

export function* carregarUsuariosGestores({ payload }) {
  let params = {
    url: '/CarregarUsuariosGestores',
    method: 'GET',
    queryParams: payload
  };

  try {
    const response = yield call(requestBaseV3, params);

    if (response.data.Success) {
      yield put(carregarUsuariosGestoresSuccess(response.data));
    } else {
      yield put(carregarUsuariosGestoresFailure(response.data));
    }
  } catch (error) {
    yield put(carregarUsuariosGestoresFailure({ Success: false, Error: 'Falha' }));
  }
}

export default all([
  takeLatest(Types.CARREGAR_USUARIOS_GESTORES_REQUEST, carregarUsuariosGestores),
]);