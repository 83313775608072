import { Types } from '../types';

export function regraSobreavisoPost(params) {
  return {
    type: Types.REGRA_SOBREAVISO_REQUEST,
    method: 'POST',
    payload: params,
  };
}

export function regraSobreavisoPut(params) {
  return {
    type: Types.REGRA_SOBREAVISO_REQUEST_PUT,
    method: 'PUT',
    payload: params,
  };
}

export function regraSobreavisoGet(params) {
  return {
    type: Types.REGRA_SOBREAVISO_REQUEST_GET,
    payload: params,
  };
}

export function regraSobreavisoGetByID(id) {
  return {
    type: Types.REGRA_SOBREAVISO_BY_ID_REQUEST_GET,
    payload: id,
  };
}

export function regraSobreavisoHistorico(id) {
  return {
    type: Types.REGRA_SOBREAVISO_HISTORICO_REQUEST,
    id,
  };
}

export function regraSobreavisoByUsuarioLogado() {
  return {
    type: Types.REGRA_SOBREAVISO_BY_USUARIO_LOGADO_REQUEST
  };
}

export function regraSobreavisoResponsePut(data) {
  return {
    type: Types.REGRA_SOBREAVISO_RESPONSE_PUT,
    payload: { data },
  };
}

export function regraSobreavisoResponse(data) {
  return {
    type: Types.REGRA_SOBREAVISO_RESPONSE,
    payload: { data },
  };
}

export function regraSobreavisoResponseGet(data) {
  return {
    type: Types.REGRA_SOBREAVISO_RESPONSE_GET,
    payload: { data },
  };
}

export function regraSobreavisoGetByIDResponse(data) {
  return {
    type: Types.REGRA_SOBREAVISO_BY_ID_RESPONSE_GET,
    payload: { data },
  };
}

export function regraSobreavisoHistoricoResponse(data) {
  return {
    type: Types.REGRA_SOBREAVISO_HISTORICO_RESPONSE,
    payload: { data },
  };
}

export function regraSobreavisoByUsuarioLogadoResponse(data) {
  return {
    type: Types.REGRA_SOBREAVISO_BY_USUARIO_LOGADO_RESPONSE,
    payload: { data },
  };
}

export function regraSobreavisoClear() {
  return {
    type: Types.REGRA_SOBREAVISO_CLEAR_DATA,
  };
}
