import { takeLatest, call, put, all } from 'redux-saga/effects';

import { Types } from '../types';

import { requestBaseV3 } from '~/services/request';

import { processosInternosSuccess, processosInternosFailure } from './actions';
import { processosInternosFuncionarioResponse } from './actions';
import { SolicitarProcessoInternoResponse } from './actions';
import { listarProcessosInternosResponse } from './actions';

export function* processosInternos() {
  let params = {
    url: '/ListarSolicitacaoProcessosInternosGestor',
    method: 'GET',
  };

  try {
    const response = yield call(requestBaseV3, params);
    const data = response.data;
    yield put(processosInternosSuccess(data));
  } catch (error) {
    yield put(processosInternosFailure({ success: false }));
  }
}

export function* processosInternosFuncionario({ payload }) {
  let form_data = new FormData();
  for (let key in payload) {
    form_data.append(key, payload[key]);
  }

  let params = {
    url: '/ListarSolicitacaoProcessosInternos',
    method: 'GET',
    body: form_data,
  };

  const response = yield call(requestBaseV3, params);
  const data = response.data;
  yield put(processosInternosFuncionarioResponse(data));
}

export function* listarProcessosInternos() {
  let params = {
    url: '/ListarProcessosInternos',
    method: 'GET',
  };

  const response = yield call(requestBaseV3, params);
  const data = response.data;
  yield put(listarProcessosInternosResponse(data));
}

export function* solicitarProcessosInternos({ payload, callback }) {
  let params = {
    url: '/SolicitarProcessoInterno',
    method: 'POST',
    body: payload,
  };

  const response = yield call(requestBaseV3, params);
  const data = response.data;
  yield put(SolicitarProcessoInternoResponse(data, callback));
}

export default all([
  takeLatest(Types.LISTAR_PROCESSOS_INTERNOS_REQUEST, listarProcessosInternos),
  takeLatest(Types.PROCESSOS_INTERNOS_REQUEST, processosInternos),
  takeLatest(
    Types.PROCESSOS_INTERNOS_FUNCIONARIO_REQUEST,
    processosInternosFuncionario,
  ),
  takeLatest(
    Types.SOLICITAR_PROCESSO_INTERNO_REQUEST,
    solicitarProcessosInternos,
  ),
]);
