import React, { useCallback } from 'react';
import { IoIosArrowForward } from 'react-icons/io';

import ButtonPadrao from '../../ButtonPadrao';
import { Container } from './styles';
import { useModal } from '~/hooks/modal';

export default function AceitarOuNegar({ dados }) {
  const { titulo = '', menssagem, confirmacao, acao, cancelar = null } = dados;
  const { ocultarModal } = useModal();

  const resolverAcao = useCallback(() => {
    acao();
    ocultarModal();
  }, [acao, ocultarModal]);
  return (
    <Container>
      <h1>{titulo}</h1>
      <div>
        {menssagem.map((item) => (
          <p>{item}</p>
        ))}
      </div>
      <div>
        <ButtonPadrao onClick={() => ocultarModal()}>
          {cancelar || 'Cancelar'}
        </ButtonPadrao>
        <ButtonPadrao color="amarelo" left onClick={resolverAcao}>
          {confirmacao}
          <IoIosArrowForward size={16} />
        </ButtonPadrao>
      </div>
    </Container>
  );
}
