import React, { useState, useCallback } from 'react';

import { Conteiner } from './styles';
import { useModal } from '~/hooks/modal';
import ComboBox from '~/components/_layouts/forms/ComboBox';
import ButtonFolhaCerta from '~/components/_layouts/forms/ButtonFolhaCerta';

export default function Select({ dados }) {
  const { ocultarModal } = useModal();
  const [estado, alterarEstado] = useState({
    array: dados.array,
    value: {},
  });
  const resolverMudancaDeEstado = useCallback(
    (nome, valor) => {
      alterarEstado((est) => ({ ...est, [nome]: valor }));
    },
    [alterarEstado],
  );

  const resolverAdicaoDeEvento = useCallback(() => {
    dados.onAdd(estado.value);
    ocultarModal();
  }, [estado, dados]);
  return (
    <Conteiner>
      <header>
        <h1>{dados.titulo}</h1>
      </header>
      <main>
        <label>{dados.label}</label>
        <ComboBox
          arrayKey={dados.arrayKey}
          data={estado.array}
          value={estado.value}
          onSelect={(value) => resolverMudancaDeEstado('value', value)}
          multiple={dados.multiple}
        />
        <p>{estado.value && estado.value.descricao}</p>
        <div>
          <ButtonFolhaCerta
            onClickButton={ocultarModal}
            buttonName="Cancelar"
            showIcon
            small
            branco
          />
          <ButtonFolhaCerta
            onClickButton={resolverAdicaoDeEvento}
            buttonName="Adicionar"
            showIcon
            small
            amarelo
          />
        </div>
      </main>
    </Conteiner>
  );
}
