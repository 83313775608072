import { takeLatest, call, put, all } from 'redux-saga/effects';
import { Types } from '../types';
import { requestSobreaviso } from '../../../services/request';

import {
  regraSobreavisoResponse,
  regraSobreavisoResponseGet,
  regraSobreavisoGetByIDResponse,
  regraSobreavisoResponsePut,
  regraSobreavisoHistoricoResponse,
  regraSobreavisoByUsuarioLogadoResponse,
} from './actions';

export function* regraSobreavisoPut({ payload, method }) {
  const params = {
    url: `/regra/${payload.id}`,
    method,
    body: payload,
  };

  try {
    const response = yield call(requestSobreaviso, params);

    yield put(regraSobreavisoResponsePut(response.data));
  } catch (error) {
    yield put(regraSobreavisoResponsePut({ success: false }));
  }
}

export function* regraSobreaviso({ payload, method }) {
  const params = {
    url: '/regra',
    method,
    body: payload,
  };

  try {
    const response = yield call(requestSobreaviso, params);

    yield put(regraSobreavisoResponse(response.data));
  } catch (error) {
    yield put(regraSobreavisoResponse({ success: false }));
  }
}

export function* regraSobreavisoGet({ payload }) {
  const params = {
    url: '/regra',
    method: 'GET',
    queryParams: payload,
  };

  try {
    const response = yield call(requestSobreaviso, params);

    yield put(regraSobreavisoResponseGet(response.data));
  } catch (error) {
    yield put(regraSobreavisoResponseGet({ success: false }));
  }
}

export function* regraSobreavisoGetByID({ payload }) {
  const params = {
    url: `/regra/${payload}`,
    method: 'GET',
  };

  try {
    const response = yield call(requestSobreaviso, params);

    yield put(regraSobreavisoGetByIDResponse(response.data));
  } catch (error) {
    yield put(regraSobreavisoGetByIDResponse({ success: false }));
  }
}

export function* regraSobreavisoHistorico({ id }) {
  const params = {
    url: `/regra/historico/${id}`,
    method: 'GET',
  };

  try {
    const response = yield call(requestSobreaviso, params);

    yield put(regraSobreavisoHistoricoResponse(response.data));
  } catch (error) {
    yield put(regraSobreavisoHistoricoResponse({ success: false }));
  }
}

export function* regraSobreavisoByUsuarioLogado() {
  const params = {
    url: `/regra/usuario-logado`,
    method: 'GET',
  };

  try {
    const response = yield call(requestSobreaviso, params);

    yield put(regraSobreavisoByUsuarioLogadoResponse(response.data));
  } catch (error) {
    yield put(regraSobreavisoByUsuarioLogadoResponse({ success: false }));
  }
}

export default all([
  takeLatest(Types.REGRA_SOBREAVISO_REQUEST, regraSobreaviso),
  takeLatest(Types.REGRA_SOBREAVISO_REQUEST_GET, regraSobreavisoGet),
  takeLatest(Types.REGRA_SOBREAVISO_BY_ID_REQUEST_GET, regraSobreavisoGetByID),
  takeLatest(Types.REGRA_SOBREAVISO_REQUEST_PUT, regraSobreavisoPut),
  takeLatest(
    Types.REGRA_SOBREAVISO_HISTORICO_REQUEST,
    regraSobreavisoHistorico,
  ),
  takeLatest(
    Types.REGRA_SOBREAVISO_BY_USUARIO_LOGADO_REQUEST,
    regraSobreavisoByUsuarioLogado,
  ),
]);
