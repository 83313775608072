/* eslint-disable import/no-cycle */
import qs from 'qs';
import { toast } from 'react-toastify';
import { store } from '~/store';

import {
  apiV3,
  apiIntermitentes,
  apiMotorNovo,
  apiRelatorio,
  apiSobreaviso,
  apiAlocacaoRegra,
  apiMotorNovoBancoHoras,
  apiIndicadores,
  apiLogin,
  apiLoginV2,
  apiFiltrosHolerite,
  apiHolerite,
  apiFolhacertaBase,
  apiImportacao,
  apiRelatorioBancoHoras,
  apiExportacao,
  apiExportacaoZip,
  apiIntegracao,
  apiEscalas,
  apiDivergencia,
  apiJornadaEvento,
  apiJornadaDeEventosPostPut,
  apiBancoFolgas,
  apiEspelhoPonto,
  apiBase,
  apiReconhecimentoFacial,
  apiVerba,
  apiFerias,
  apiKiosk,
  apiEventos,
} from '~/services/api';

const getConfig = (url, method, body, queryParams, authorization = true) => {
  const accessToken =
    store.getState().auth.token && store.getState().auth.token.AccessToken;
  const id = localStorage.getItem('user/id');

  const header = {
    usuarioId: id,
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    Accept: '*/*',
  };

  if (accessToken && authorization) {
    header.Authorization = `Bearer ${accessToken}`;
  }

  const config = {
    url,
    method,
    headers: header,
    data: body,
    params: queryParams,
  };

  return config;
};

const getConfigBase = (
  url,
  method,
  body,
  queryParams,
  authorization = true,
  header = {},
) => {
  const accessToken =
    store.getState().auth.token && store.getState().auth.token.AccessToken;

  header['Content-Type'] = 'application/json;charset=utf-8';

  if (accessToken && authorization) {
    header.Authorization = `Bearer ${accessToken}`;
  }

  const config = {
    url,
    method,
    headers: header,
    data: body,
    params: queryParams,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  };

  return config;
};

const requestBaseV3 = ({ url, method, body, queryParams }) => {
  const config = getConfigBase(url, method, body, queryParams);
  return apiV3.request(config);
};

const requestIntermitentes = ({ url, method, body, queryParams }) => {
  const config = getConfig(url, method, body, queryParams);
  return apiIntermitentes
    .request(config)
    .then((response) => response)
    .catch((err) => {
      if (err.response) {
        if (err.response.status >= 500 && err.response.status <= 599)
          return toast.error('Erro do servidor');
        return err.response;
      }
      return err;
    });
};

const requestEventos = ({ url, method, body, queryParams }) => {
  const config = getConfig(url, method, body, queryParams);
  return apiEventos
    .request(config)
    .then((response) => response)
    .catch((err) => {
      if (err.response) {
        if (err.response.status >= 500 && err.response.status <= 599)
          return toast.error('Erro do servidor');
        return err.response;
      }
      return err;
    });
};

const requestMotorNovo = ({ url, method, body, queryParams }) => {
  const config = getConfigBase(url, method, body, queryParams);
  return apiMotorNovo
    .request(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.status >= 500 && err.response.status <= 599)
          return toast.error('Erro do servidor');
        return err.response;
      }
      return err;
    });
};

const requestMotorNovoBancoHoras = ({ url, method, body, queryParams }) => {
  const config = getConfigBase(url, method, body, queryParams);
  return apiMotorNovoBancoHoras
    .request(config)
    .then((response) => response)
    .catch((err) => {
      if (err.response) {
        if (err.response.status >= 500 && err.response.status <= 599)
          return toast.error('Erro do servidor');
        return err.response;
      }
      return err;
    });
};

const requestSobreaviso = ({ url, method, body, queryParams }) => {
  const config = getConfigBase(url, method, body, queryParams);

  return apiSobreaviso
    .request(config)
    .then((response) => response)
    .catch((err) => {
      if (err.response) {
        if (err.response.status >= 500 && err.response.status <= 599)
          return toast.error('Erro do servidor');
        return err.response;
      }
      return err;
    });
};

const requestAlocacaoRegra = ({ url, method, body, queryParams }) => {
  const config = getConfigBase(url, method, body, queryParams);

  return apiAlocacaoRegra
    .request(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.status >= 500 && err.response.status <= 599)
          return toast.error('Erro do servidor');
        return err.response;
      }
      return err;
    });
};

const requestApiRelatorio = ({ url, method, body, queryParams }) => {
  const config = getConfigBase(url, method, body, queryParams);

  return apiRelatorio
    .request(config)
    .then((response) => response)
    .catch((err) => {
      if (err.response) {
        if (err.response.status >= 500 && err.response.status <= 599)
          return toast.error('Erro do servidor');
        return err.response;
      }
      return err;
    });
};

const requestIndicadores = ({ url, method, body, queryParams }) => {
  const config = getConfigBase(url, method, body, queryParams);

  return apiIndicadores
    .request(config)
    .then((response) => response)
    .catch((err) => {
      if (err.response) {
        return err.response;
      }
      return err;
    });
};

const requestLogin = ({
  url,
  method,
  body,
  queryParams,
  authorization = true,
  header = {},
}) => {
  const config = getConfigBase(
    url,
    method,
    body,
    queryParams,
    authorization,
    header,
  );

  return apiLogin
    .request(config)
    .then((response) => response)
    .catch((err) => {
      if (err.response) {
        if (err.response.status >= 500 && err.response.status <= 599)
          return toast.error('Erro do servidor');
        return err.response;
      }
      return err;
    });
};

const requestLoginV2 = ({
  url,
  method,
  body,
  queryParams,
  authorization = true,
  header = {},
}) => {
  const config = getConfigBase(
    url,
    method,
    body,
    queryParams,
    authorization,
    header,
  );

  return apiLoginV2
    .request(config)
    .then((response) => response)
    .catch((err) => {
      if (err.response) {
        if (err.response.status >= 500 && err.response.status <= 599)
          return toast.error('Erro do servidor');
        return err.response;
      }
      return err;
    });
};

const requestRefreshToken = ({ url, method, body, queryParams }) => {
  const config = getConfigBase(url, method, body, queryParams);

  return apiLogin
    .request(config)
    .then((response) => response)
    .catch((err) => {
      if (err.response) {
        if (err.response.status >= 500 && err.response.status <= 599)
          return toast.error('Erro do servidor');
        return err.response;
      }
      return err;
    });
};

const requestCheckForUpdates = ({ url, method, body, queryParams }) => {
  const config = getConfigBase(url, method, body, queryParams);

  return apiLogin
    .request(config)
    .then((response) => response)
    .catch((err) => {
      if (err.response) {
        if (err.response.status >= 500 && err.response.status <= 599)
          return toast.error('Erro do servidor');
        return err.response;
      }
      return err;
    });
};

const requestFiltrarHolerites = ({ url, method, body }) => {
  const config = getConfigBase(url, method, body, body);

  return apiFiltrosHolerite
    .request(config)
    .then((response) => response)
    .catch((err) => {
      if (err.response) {
        if (err.response.status >= 500 && err.response.status <= 599)
          return toast.error('Erro do servidor');
        return err.response;
      }
      return err;
    });
};

const requestHolerites = ({ url, method, body }) => {
  const config = getConfigBase(url, method, body, body);

  return apiHolerite
    .request(config)
    .then((response) => response)
    .catch((err) => {
      if (err.response) {
        if (err.response.status >= 500 && err.response.status <= 599)
          return toast.error('Erro do servidor');
        return err.response;
      }
      return err;
    });
};

const requestListagem = ({ url, method, queryParams }) => {
  const config = getConfigBase(url, method, queryParams);

  return apiHolerite
    .request(config)
    .then((response) => response)
    .catch((err) => {
      if (err.response) {
        if (err.response.status >= 500 && err.response.status <= 599)
          return toast.error('Erro do servidor');
        return err.response;
      }
      return err;
    });
};

const requestFolhacertaBase = ({ url, method, body, queryParams }) => {
  const config = getConfigBase(url, method, body, queryParams);

  return apiFolhacertaBase
    .request(config)
    .then((response) => response)
    .catch((err) => {
      if (err.response) {
        if (err.response.status >= 500 && err.response.status <= 599)
          return toast.error('Erro do servidor');
        return err.response;
      }
      return err;
    });
};

const requestImportacao = ({ url, method, body, queryParams }) => {
  const config = getConfigBase(url, method, body, queryParams);

  return apiImportacao
    .request(config)
    .then((response) => response)
    .catch((err) => {
      if (err.response) {
        if (err.response.status >= 500 && err.response.status <= 599)
          return toast.error('Erro do servidor');
        return err.response;
      }
      return err;
    });
};

const requestRelatorioBancoHoras = ({ url, method, body, queryParams }) => {
  const config = getConfigBase(url, method, body, queryParams);

  return apiRelatorioBancoHoras
    .request(config)
    .then((response) => response)
    .catch((err) => {
      if (err.response) {
        if (err.response.status >= 500 && err.response.status <= 599)
          return toast.error('Erro do servidor');
        return err.response;
      }
      return err;
    });
};

const requestExportacao = ({ url, method, body, queryParams }) => {
  const config = getConfigBase(url, method, body, queryParams);

  return apiExportacao
    .request(config)
    .then((response) => response)
    .catch((err) => {
      if (err.response) {
        if (err.response.status >= 500 && err.response.status <= 599)
          return toast.error('Erro do servidor');
        return err.response;
      }
      return err;
    });
};

const requestExportacaoZip = ({ url, method, body, queryParams }) => {
  const config = getConfigBase(url, method, body, queryParams);

  return apiExportacaoZip
    .request(config)
    .then((response) => response)
    .catch((err) => {
      if (err.response) {
        if (err.response.status >= 500 && err.response.status <= 599)
          return toast.error('Erro do servidor');
        return err.response;
      }
      return err;
    });
};

const requestIntegracao = ({ url, method, body, queryParams }) => {
  const config = getConfigBase(url, method, body, queryParams);

  return apiIntegracao
    .request(config)
    .then((response) => response)
    .catch((err) => {
      if (err.response) {
        if (err.response.status >= 500 && err.response.status <= 599)
          return toast.error('Erro do servidor');
        return err.response;
      }
      return err;
    });
};

const requestEscalas = ({ url, method, body, queryParams }) => {
  const config = getConfigBase(url, method, body, queryParams);
  return apiEscalas
    .request(config)
    .then((response) => response)
    .catch((err) => {
      if (err.response) {
        if (err.response.status >= 500 && err.response.status <= 599)
          return toast.error('Erro do servidor');
        return err.response;
      }
      return err;
    });
};

const requestDivergencia = ({ url, method, body, queryParams }) => {
  const config = getConfigBase(url, method, body, queryParams);

  return apiDivergencia
    .request(config)
    .then((response) => response)
    .catch((err) => {
      if (err.response) {
        if (err.response.status >= 500 && err.response.status <= 599)
          return toast.error('Erro do servidor');
        return err.response;
      }
      return err;
    });
};

const requestJornadaEvento = ({ url, method, body, queryParams }) => {
  const config = getConfigBase(url, method, body, queryParams);

  return apiJornadaEvento
    .request(config)
    .then((response) => response)
    .catch((err) => {
      if (err.response) {
        if (err.response.status >= 500 && err.response.status <= 599)
          return toast.error('Erro do servidor');
        return err.response;
      }
      return err;
    });
};

const requestJornadaEventoPostPut = ({ url, method, body, queryParams }) => {
  const config = getConfigBase(url, method, body, queryParams);

  return apiJornadaDeEventosPostPut
    .request(config)
    .then((response) => response)
    .catch((err) => {
      if (err.response) {
        if (err.response.status >= 500 && err.response.status <= 599)
          return toast.error('Erro do servidor');
        return err.response;
      }
      return err;
    });
};

const requestBancoFolgas = ({ url, method, body, queryParams }) => {
  const config = getConfigBase(url, method, body, queryParams);

  return apiBancoFolgas
    .request(config)
    .then((response) => response)
    .catch((err) => {
      if (err.response) {
        if (err.response.status >= 500 && err.response.status <= 599)
          return toast.error('Erro do servidor');
        return err.response;
      }
      return err;
    });
};

const requestEspelhoPonto = ({ url, method, body, queryParams }) => {
  const config = getConfigBase(url, method, body, queryParams);

  return apiEspelhoPonto
    .request(config)
    .then((response) => response)
    .catch((err) => {
      if (err.response) {
        if (err.response.status >= 500 && err.response.status <= 599)
          return toast.error('Erro do servidor');
        return err.response;
      }
      return err;
    });
};

const requestReconhecimentoFacial = ({ url, method, body, queryParams }) => {
  const config = getConfigBase(url, method, body, queryParams);

  return apiReconhecimentoFacial
    .request(config)
    .then((response) => response)
    .catch((err) => {
      if (err.response) {
        if (err.response.status >= 500 && err.response.status <= 599)
          return toast.error('Erro do servidor');
        return err.response;
      }
      return err;
    });
};

const requestBaseApiV1 = ({ url, method, body, queryParams }) => {
  const config = getConfigBase(url, method, body, queryParams);

  return apiBase
    .request(config)
    .then((response) => response)
    .catch((err) => {
      if (err.response) {
        if (err.response.status >= 500 && err.response.status <= 599)
          return toast.error('Erro do servidor');
        return err.response;
      }
      return err;
    });
};

const requestVerba = ({ url, method, body, queryParams }) => {
  const config = getConfigBase(url, method, body, queryParams);

  return apiVerba
    .request(config)
    .then((response) => response)
    .catch((err) => {
      if (err.response) {
        if (err.response.status >= 500 && err.response.status <= 599)
          return toast.error('Erro do servidor');
        return err.response;
      }
      return err;
    });
};

const requestFerias = ({ url, method, body, queryParams }) => {
  const config = getConfigBase(url, method, body, queryParams);

  return apiFerias
    .request(config)
    .then((response) => response)
    .catch((err) => {
      if (err.response) {
        if (err.response.status >= 500 && err.response.status <= 599)
          return toast.error('Erro do servidor');
        return err.response;
      }
      return err;
    });
};

const requestKiosk = ({ url, method, body, queryParams }) => {
  const config = getConfigBase(url, method, body, queryParams);

  return apiKiosk
    .request(config)
    .then((response) => response)
    .catch((err) => {
      if (err.response) {
        if (err.response.status >= 500 && err.response.status <= 599)
          return toast.error('Erro do servidor');
        return err.response;
      }
      return err;
    });
};

export {
  requestBaseV3,
  requestIntermitentes,
  requestMotorNovo,
  requestApiRelatorio,
  requestSobreaviso,
  requestAlocacaoRegra,
  requestMotorNovoBancoHoras,
  requestIndicadores,
  requestLogin,
  requestLoginV2,
  requestFiltrarHolerites,
  requestHolerites,
  requestFolhacertaBase,
  requestListagem,
  requestImportacao,
  requestRelatorioBancoHoras,
  requestExportacao,
  requestExportacaoZip,
  requestIntegracao,
  requestEscalas,
  requestDivergencia,
  requestJornadaEvento,
  requestJornadaEventoPostPut,
  requestBancoFolgas,
  requestEspelhoPonto,
  requestBaseApiV1,
  requestReconhecimentoFacial,
  requestVerba,
  requestFerias,
  requestRefreshToken,
  requestKiosk,
  requestCheckForUpdates,
  requestEventos,
};
