import produce from 'immer';

import { Types } from '../types';

// Reducers
const INITIAL_STATE = {
  grupos: null,
  filiais: null,
  pessoas: null,
  sindicatos: null,
  departamentos: null,
  centrosDeCustos: null,
  pais: null,
  estados: null,
  cidades: null,

  servicosDeOrigem: null,
  mensageriaStatus: null,

  empresas: null,

  periodos: null,
  regrasApuracao: null,

  gruposPaged: null,
  filiaisPaged: null,
  pessoasPaged: null,
  sindicatosPaged: null,
  departamentosPaged: null,
  centrosDeCustosPaged: null,
  empresasPaged: null,

  loading: false,
};

export default function folhacertabase(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.GET_FILIAIS_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GET_DEPARTAMENTOS_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GET_CENTROS_DE_CUSTO_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GET_GRUPOS_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GET_PESSOAS_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GET_SINDICATOS_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GET_PERIODOS_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GET_REGRAS_APURACAO_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GET_STATUS_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GET_SERVICOS_ORIGEM_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GET_EMPRESAS_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GET_PAIS_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GET_ESTADO_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GET_CIDADE_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GET_EMPRESAS_RESPONSE: {
        const auxData = action.payload.data;
        draft.loading = false;
        draft.empresas = auxData;
        break;
      }
      case Types.GET_FILIAIS_RESPONSE: {
        const auxData = action.payload.data;
        draft.loading = false;
        draft.filiais = auxData;
        break;
      }
      case Types.GET_DEPARTAMENTOS_RESPONSE: {
        const auxData = action.payload.data;
        draft.loading = false;
        draft.departamentos = auxData;
        break;
      }
      case Types.GET_CENTROS_DE_CUSTO_RESPONSE: {
        const auxData = action.payload.data;
        draft.loading = false;
        draft.centrosDeCustos = auxData;
        break;
      }
      case Types.GET_GRUPOS_RESPONSE: {
        const auxData = action.payload.data;
        draft.loading = false;
        draft.grupos = auxData;
        break;
      }
      case Types.GET_PESSOAS_RESPONSE: {
        const auxData = action.payload.data;
        draft.loading = false;
        draft.pessoas = auxData;
        break;
      }
      case Types.GET_SINDICATOS_RESPONSE: {
        const auxData = action.payload.data;
        draft.loading = false;
        draft.sindicatos = auxData;
        break;
      }
      case Types.GET_PAIS_RESPONSE: {
        const auxData = action.payload.data;
        draft.loading = false;
        draft.pais = auxData;
        break;
      }
      case Types.GET_ESTADO_RESPONSE: {
        const auxData = action.payload.data;
        draft.loading = false;
        draft.estados = auxData;
        break;
      }
      case Types.GET_CIDADE_RESPONSE: {
        const auxData = action.payload.data;
        draft.loading = false;
        draft.cidades = auxData;
        break;
      }
      case Types.GET_PERIODOS_RESPONSE: {
        const auxData = action.payload.data;
        draft.loading = false;
        draft.periodos = auxData;
        break;
      }
      case Types.GET_REGRAS_APURACAO_RESPONSE: {
        const auxData = action.payload.data;
        draft.loading = false;
        draft.regrasApuracao = auxData;
        break;
      }
      case Types.GET_SERVICOS_ORIGEM_RESPONSE: {
        const auxData = action.payload.data;
        draft.loading = false;
        draft.servicosDeOrigem = auxData;
        break;
      }
      case Types.GET_STATUS_RESPONSE: {
        const auxData = action.payload.data;
        draft.loading = false;
        draft.mensageriaStatus = auxData;
        break;
      }

      case Types.GET_FILIAIS_PAGED_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GET_DEPARTAMENTOS_PAGED_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GET_CENTROS_DE_CUSTO_PAGED_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GET_GRUPOS_PAGED_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GET_PESSOAS_PAGED_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GET_SINDICATOS_PAGED_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GET_EMPRESAS_PAGED_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GET_EMPRESAS_PAGED_RESPONSE: {
        const auxData = action.payload.data;
        draft.loading = false;
        draft.empresasPaged = auxData;
        break;
      }
      case Types.GET_FILIAIS_PAGED_RESPONSE: {
        const auxData = action.payload.data;
        draft.loading = false;
        draft.filiaisPaged = auxData;
        break;
      }
      case Types.GET_DEPARTAMENTOS_PAGED_RESPONSE: {
        const auxData = action.payload.data;
        draft.loading = false;
        draft.departamentosPaged = auxData;
        break;
      }
      case Types.GET_CENTROS_DE_CUSTO_PAGED_RESPONSE: {
        const auxData = action.payload.data;
        draft.loading = false;
        draft.centrosDeCustosPaged = auxData;
        break;
      }
      case Types.GET_GRUPOS_PAGED_RESPONSE: {
        const auxData = action.payload.data;
        draft.loading = false;
        draft.gruposPaged = auxData;
        break;
      }
      case Types.GET_PESSOAS_PAGED_RESPONSE: {
        const auxData = action.payload.data;
        draft.loading = false;
        draft.pessoasPaged = auxData;
        break;
      }
      case Types.GET_SINDICATOS_PAGED_RESPONSE: {
        const auxData = action.payload.data;
        draft.loading = false;
        draft.sindicatosPaged = auxData;
        break;
      }
      case Types.LOGOUT: {
        draft.grupos = null;
        draft.filiais = null;
        draft.pessoas = null;
        draft.sindicatos = null;
        draft.departamentos = null;
        draft.centrosDeCustos = null;
        draft.pais = null;
        draft.estados = null;
        draft.cidades = null;

        draft.servicosDeOrigem = null;
        draft.mensageriaStatus = null;

        draft.empresas = null;

        draft.periodos = null;
        draft.regrasApuracao = null;

        draft.loading = false;
        break;
      }

      default:
    }
  });
}
