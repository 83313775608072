import { takeLatest, call, put, all } from 'redux-saga/effects';
import { Types } from '../types';
import { requestRelatorioBancoHoras } from '~/services/request';

export function* relatorioRequest({
  url,
  queryParams,
  responseAction,
  callback,
}) {
  const params = {
    url,
    method: 'GET',
    queryParams,
  };

  try {
    const response = yield call(requestRelatorioBancoHoras, params);

    yield put(
      responseAction(response.data, callback, response.request.responseURL),
    );
  } catch (error) {
    yield put(
      responseAction(
        {
          success: false,
          message: 'Não contém dados para esse relatório',
        },
        callback,
      ),
    );
  }
}

export default all([
  takeLatest(Types.API_BANCO_HORAS_REQUEST, relatorioRequest),
]);
