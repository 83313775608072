import React from 'react';
import { Container } from './styles';
import AceitarOuNegar from '../AceitarOuNegar';
import Sucesso from '../Sucesso';
import ListaSecoesPessoas from '../ListaSecoesPessoas';
import Insucesso from '../Insucesso';
import Select from '../Select';

export default function Modal({ style, dados }) {
  const { tipo } = dados;
  const modais = {
    aceitarOuNegar: <AceitarOuNegar dados={dados} />,
    sucesso: <Sucesso dados={dados} />,
    secoesPessoas: <ListaSecoesPessoas dados={dados} />,
    insucesso: <Insucesso dados={dados} />,
    select: <Select dados={dados} />,
  };
  return <Container style={style}>{modais[tipo]}</Container>;
}
