import { Types } from '../../../types';

export function bancoHorasConfiguracaoRequest({ params, method, id }) {
  return {
    type: Types.BANCO_HORAS_CONFIGURACAO_REQUEST,
    payload: params,
    method: method,
    id: id
  };
}

export function getBancoHorasConfiguracaoResponse(data) {
  return {
    type: Types.GET_BANCO_HORAS_CONFIGURACAO_RESPONSE,
    payload: { data },
  };
}

export function postBancoHorasConfiguracaoResponse(data) {
  return {
    type: Types.POST_BANCO_HORAS_CONFIGURACAO_RESPONSE,
    payload: { data },
  };
}

export function putBancoHorasConfiguracaoResponse(data) {
  return {
    type: Types.PUT_BANCO_HORAS_CONFIGURACAO_RESPONSE,
    payload: { data },
  };
}
