import produce from 'immer';

import { Types } from '../types';

// Reducers
const INITIAL_STATE = {
  signed: false,
  loading: false,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.LOGIN_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.LOGIN_EXTERNO_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.LOGIN_SUCCESS: {
        draft.signed = true;
        draft.token = action.payload.token;
        draft.loading = false;
        break;
      }
      case Types.FIRST_LOGIN: {
        draft.signed = false;
        draft.token = action.payload.token;
        draft.loading = false;
        break;
      }
      case Types.LOGIN_FAILURE: {
        draft.loading = false;
        break;
      }
      case Types.REFRESH_TOKEN: {
        draft.loading = true;
        break;
      }
      case Types.REFRESH_TOKEN_SUCCESS: {
        draft.token = action.payload.token;
        draft.loading = false;
        break;
      }
      case Types.REFRESH_TOKEN_FAILURE: {
        draft.signed = false;
        draft.loading = false;
        break;
      }
      case Types.VALIDATE_EMAIL_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.VALIDATE_EMAIL_RESPONSE: {
        draft.payloadValidateEmail = action.payload.data;
        draft.loading = false;
        break;
      }
      case Types.FORGOT_PASSWORD_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.FORGOT_PASSWORD_RESPONSE: {
        draft.payloadForgotPassword = action.payload.data;
        draft.loading = false;
        break;
      }
      case Types.RESET_PASSWORD_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.RESET_PASSWORD_RESPONSE: {
        draft.signed = true;
        draft.loading = false;
        break;
      }
      case Types.CHECK_FOR_UPDATES: {
        draft.loading = true;
        break;
      }
      case Types.RESET_LOGIN: {
        draft.loading = false;
        break;
      }
      case Types.REGISTER_FIREBASE_TOKEN: {
        draft.firebaseToken = action.payload.token;
        break;
      }
      case Types.LOGOUT: {
        draft.signed = false;
        break;
      }
      default:
    }
  });
}
