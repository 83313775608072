import produce from 'immer';
import { Types } from '../../types';

// Reducers
const INITIAL_STATE = {
  payload: null,
  error: null,

  loading: false,
}

export default function grupoTodos(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.GRUPO_TODOS_REQUEST: {
        draft.loading = true;
        break;
      }

      case Types.GRUPO_TODOS_SUCCESS: {
        draft.payload = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.GRUPO_TODOS_FAILURE: {
        draft.payload = null;
        draft.loading = false;
        break;
      }

      case Types.GRUPO_CLEAR_DATA: {
        draft.payload = null;
        draft.error = null;
        draft.loading = false;
        break;
      }
      case Types.LOGOUT: {
        draft.payload = null;
        draft.error = null;
        draft.loading = false;
        break;
      }
      default:
    }
  });
}