import { takeLatest, call, put, all } from 'redux-saga/effects';
import { Types } from '../types';
import { requestIndicadores } from '~/services/request';

export function* indicadores({ url, method, body, queryParams, onSuccess, onFailure, callback }) {
  let params = {
    url, method, queryParams, body
  };

  try {
    const response = yield call(requestIndicadores, params);
    yield put(onSuccess(response.data, callback));
  } catch (error) {
    yield put(onFailure({ success: false, message: 'Erro ao para realizar reprocessamento' }, callback));
  }
}

export default all([
  takeLatest(Types.INDICADORES_REQUEST, indicadores),
]);