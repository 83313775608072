/* eslint-disable import/no-cycle */
import { takeLatest, call, put, all } from 'redux-saga/effects';
import { requestApiRelatorio, requestFolhacertaBase } from '~/services/request';
import { Types } from '../types';

import {
  getGruposResponse,
  getPessoasResponse,
  getFiliaisResponse,
  getSindicatosResponse,
  getDepartamentosResponse,
  getCentrosDeCustosResponse,
  getPeriodosResponse,
  getRegrasApuracaoResponse,
  getServicosDeOrigemResponse,
  getStatusMensageriaResponse,
  getEmpresasResponse,
  getPaisesResponse,
  getEstadosResponse,
  getCidadesResponse,
  getGruposPagedResponse,
  getPessoasPagedResponse,
  getFiliaisPagedResponse,
  getSindicatosPagedResponse,
  getDepartamentosPagedResponse,
  getCentrosDeCustosPagedResponse,
  getEmpresasPagedResponse,
} from './actions';

export function* filiaisPaged({ body, callback }) {
  const params = {
    url: '/filterpaged/filiais',
    method: 'POST',
    body,
  };

  try {
    const response = yield call(requestFolhacertaBase, params);

    yield put(getFiliaisPagedResponse(response.data, callback));
  } catch (error) {
    yield put(getFiliaisPagedResponse({ success: false }, callback));
  }
}

export function* filiais({ queryParams }) {
  const params = {
    url: '/filter/filiais',
    method: 'GET',
    queryParams,
  };

  try {
    const response = yield call(requestFolhacertaBase, params);

    yield put(getFiliaisResponse(response.data));
  } catch (error) {
    yield put(getFiliaisResponse({ success: false }));
  }
}

export function* departamentosPaged({ body, callback }) {
  const params = {
    url: '/filterpaged/departamentos',
    method: 'POST',
    body,
  };

  try {
    const response = yield call(requestFolhacertaBase, params);

    yield put(getDepartamentosPagedResponse(response.data, callback));
  } catch (error) {
    yield put(getDepartamentosPagedResponse({ success: false }, callback));
  }
}

export function* departamentos({ queryParams }) {
  const params = {
    url: '/filter/departamentos',
    method: 'GET',
    queryParams,
  };

  try {
    const response = yield call(requestFolhacertaBase, params);

    yield put(getDepartamentosResponse(response.data));
  } catch (error) {
    yield put(getDepartamentosResponse({ success: false }));
  }
}

export function* gruposPaged({ body, callback }) {
  const params = {
    url: '/filterpaged/grupos',
    method: 'POST',
    body,
  };

  try {
    const response = yield call(requestFolhacertaBase, params);

    yield put(getGruposPagedResponse(response.data, callback));
  } catch (error) {
    yield put(getGruposPagedResponse({ success: false }, callback));
  }
}

export function* grupos({ queryParams }) {
  const params = {
    url: '/filter/grupos',
    method: 'GET',
    queryParams,
  };

  try {
    const response = yield call(requestFolhacertaBase, params);

    yield put(getGruposResponse(response.data));
  } catch (error) {
    yield put(getGruposResponse({ success: false }));
  }
}

export function* pessoasPaged({ body, callback }) {
  const params = {
    url: '/filterpaged/pessoas',
    method: 'POST',
    body,
  };

  try {
    const response = yield call(requestFolhacertaBase, params);

    yield put(getPessoasPagedResponse(response.data, callback));
  } catch (error) {
    yield put(getPessoasPagedResponse({ success: false }, callback));
  }
}

export function* pessoas({ queryParams }) {
  const params = {
    url: '/filter/pessoas',
    method: 'GET',
    queryParams,
  };

  try {
    const response = yield call(requestFolhacertaBase, params);

    yield put(getPessoasResponse(response.data));
  } catch (error) {
    yield put(getPessoasResponse({ success: false }));
  }
}

export function* sindicatosPaged({ body, callback }) {
  const params = {
    url: '/filterpaged/sindicatos',
    method: 'POST',
    body,
  };

  try {
    const response = yield call(requestFolhacertaBase, params);
    yield put(getSindicatosPagedResponse(response.data, callback));
  } catch (error) {
    yield put(getSindicatosPagedResponse({ success: false }, callback));
  }
}

export function* sindicatos({ queryParams }) {
  const params = {
    url: '/filter/sindicatos',
    method: 'GET',
    queryParams,
  };

  try {
    const response = yield call(requestFolhacertaBase, params);
    yield put(getSindicatosResponse(response.data));
  } catch (error) {
    yield put(getSindicatosResponse({ success: false }));
  }
}

export function* centrosDeCustosPaged({ body, callback }) {
  const params = {
    url: '/filterpaged/centros-custos',
    method: 'POST',
    body,
  };

  try {
    const response = yield call(requestFolhacertaBase, params);
    yield put(getCentrosDeCustosPagedResponse(response.data, callback));
  } catch (error) {
    yield put(getCentrosDeCustosPagedResponse({ success: false }, callback));
  }
}

export function* centrosDeCustos({ queryParams }) {
  const params = {
    url: '/filter/centros-custos',
    method: 'GET',
    queryParams,
  };

  try {
    const response = yield call(requestFolhacertaBase, params);
    yield put(getCentrosDeCustosResponse(response.data));
  } catch (error) {
    yield put(getCentrosDeCustosResponse({ success: false }));
  }
}

export function* paises({ queryParams }) {
  const params = {
    url: '/filter/pais',
    method: 'GET',
    queryParams,
  };

  try {
    const response = yield call(requestFolhacertaBase, params);
    yield put(getPaisesResponse(response.data));
  } catch (error) {
    yield put(getPaisesResponse({ success: false }));
  }
}

export function* estados({ queryParams }) {
  const params = {
    url: '/filter/estado',
    method: 'GET',
    queryParams,
  };

  try {
    const response = yield call(requestFolhacertaBase, params);
    yield put(getEstadosResponse(response.data));
  } catch (error) {
    yield put(getEstadosResponse({ success: false }));
  }
}

export function* cidades({ queryParams }) {
  const params = {
    url: '/filter/cidade',
    method: 'GET',
    queryParams,
  };

  try {
    const response = yield call(requestFolhacertaBase, params);
    yield put(getCidadesResponse(response.data));
  } catch (error) {
    yield put(getCidadesResponse({ success: false }));
  }
}

export function* periodos() {
  const params = {
    url: '/folhacertabase/periodos',
    method: 'GET',
  };

  try {
    const response = yield call(requestApiRelatorio, params);
    yield put(getPeriodosResponse(response.data));
  } catch (error) {
    yield put(getPeriodosResponse({ success: false }));
  }
}

export function* regrasApuracao() {
  const params = {
    url: '/folhacertabase/regras',
    method: 'GET',
  };

  try {
    const response = yield call(requestApiRelatorio, params);
    yield put(getRegrasApuracaoResponse(response.data));
  } catch (error) {
    yield put(getRegrasApuracaoResponse({ success: false }));
  }
}

export function* servicosDeOrigem() {
  const params = {
    url: '/folhacertabase/modulos-origem',
    method: 'GET',
  };

  try {
    const response = yield call(requestApiRelatorio, params);
    yield put(getServicosDeOrigemResponse(response.data));
  } catch (error) {
    yield put(getServicosDeOrigemResponse({ success: false }));
  }
}

export function* mensageriaStatus() {
  const params = {
    url: '/folhacertabase/messagebird-status',
    method: 'GET',
  };

  try {
    const response = yield call(requestApiRelatorio, params);
    yield put(getStatusMensageriaResponse(response.data));
  } catch (error) {
    yield put(getStatusMensageriaResponse({ success: false }));
  }
}

export function* empresasPaged() {
  const params = {
    url: '/filterpaged/empresas',
    method: 'POST',
    body: { pageNumber: 1, pageSize: 2000 },
  };

  try {
    const response = yield call(requestFolhacertaBase, params);
    yield put(getEmpresasPagedResponse(response.data));
  } catch (error) {
    yield put(getEmpresasPagedResponse({ success: false }));
  }
}

export function* empresas() {
  const params = {
    url: '/filter/empresas',
    method: 'GET',
  };

  try {
    const response = yield call(requestFolhacertaBase, params);
    yield put(getEmpresasResponse(response.data));
  } catch (error) {
    yield put(getEmpresasResponse({ success: false }));
  }
}

export default all([
  takeLatest(Types.GET_FILIAIS_REQUEST, filiais),
  takeLatest(Types.GET_DEPARTAMENTOS_REQUEST, departamentos),
  takeLatest(Types.GET_GRUPOS_REQUEST, grupos),
  takeLatest(Types.GET_PESSOAS_REQUEST, pessoas),
  takeLatest(Types.GET_SINDICATOS_REQUEST, sindicatos),
  takeLatest(Types.GET_CENTROS_DE_CUSTO_REQUEST, centrosDeCustos),
  takeLatest(Types.GET_FILIAIS_PAGED_REQUEST, filiaisPaged),
  takeLatest(Types.GET_DEPARTAMENTOS_PAGED_REQUEST, departamentosPaged),
  takeLatest(Types.GET_GRUPOS_PAGED_REQUEST, gruposPaged),
  takeLatest(Types.GET_PESSOAS_PAGED_REQUEST, pessoasPaged),
  takeLatest(Types.GET_SINDICATOS_PAGED_REQUEST, sindicatosPaged),
  takeLatest(Types.GET_CENTROS_DE_CUSTO_PAGED_REQUEST, centrosDeCustosPaged),
  takeLatest(Types.GET_EMPRESAS_PAGED_REQUEST, empresasPaged),
  takeLatest(Types.GET_PAIS_REQUEST, paises),
  takeLatest(Types.GET_ESTADO_REQUEST, estados),
  takeLatest(Types.GET_CIDADE_REQUEST, cidades),
  takeLatest(Types.GET_PERIODOS_REQUEST, periodos),
  takeLatest(Types.GET_REGRAS_APURACAO_REQUEST, regrasApuracao),
  takeLatest(Types.GET_SERVICOS_ORIGEM_REQUEST, servicosDeOrigem),
  takeLatest(Types.GET_STATUS_REQUEST, mensageriaStatus),
  takeLatest(Types.GET_EMPRESAS_REQUEST, empresas),
]);
