import React, { useEffect } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

// Componentes Bibliotecas
import { Modal, Col, Row } from 'reactstrap';

// Componentes
import CardBoxHeader from '~/components/_layouts/cards/CardBoxHeader';
import ButtonFolhaCerta from '~/components/_layouts/forms/ButtonFolhaCerta';

// Image
import sucessoMsg from '~/assets/utils/images/imagensFolhaCerta/sucessoMsg.svg';

function MessageModalList({ onClick, open, message, messageSuccess }) {
  const toggle = () => {
    onClick();
  };

  useEffect(() => {
    if (messageSuccess) {
      setTimeout(() => {
        toggle();
      }, 2000);
    }
  }, [messageSuccess]);

  const mountMessageView = () => {
    const aux = [];
    const messages = message.split('\n');
    let line = '';
    if (messages && messages.length > 0) {
      for (let i = 0; i < messages.length; i += 1) {
        if (i === 0) {
          line = 'first';
        } else if (i === messages.length - 1) {
          line = 'last';
        } else {
          line = 'other';
        }
        switch (line) {
          case 'first':
            aux.push(
              <Col>
                <div>
                  <Row style={{ display: 'flow-root' }}>
                    <label
                      style={{
                        fontSize: '18px',
                        color: '#F73B62',
                        fontWeight: 'bold',
                        marginBottom: '30px',
                      }}
                    >
                      {messages[i]}
                    </label>
                  </Row>
                </div>
              </Col>,
            );
            break;
          case 'last':
            aux.push(
              <Col>
                <div>
                  <Row style={{ display: 'flow-root' }}>
                    <label
                      style={{
                        fontSize: '14px',
                        color: 'darkgray',
                        fontWeight: 'bolder',
                      }}
                    >
                      {messages[i]}
                    </label>
                  </Row>
                </div>
              </Col>,
            );
            break;
          case 'other':
            aux.push(
              <Col>
                <div>
                  <Row
                    style={{
                      display: 'flow-root',
                      border: '1.8px solid #D9E5EB',
                    }}
                  >
                    <label
                      style={{
                        margin: '5px',
                        fontSize: '15px',
                        color: '#516267',
                        fontWeight: 'bold',
                      }}
                    >
                      {messages[i]}
                    </label>
                  </Row>
                </div>
              </Col>,
            );
            break;
          default:
            break;
        }
      }
    }

    return aux;
  };

  return (
    <>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        {open && (
          <>
            {messageSuccess ? (
              <Modal
                isOpen={open}
                toggle={() => toggle()}
                className={`text-center`}
              >
                <div className="p-5" style={{ placeSelf: 'center' }}>
                  {message && (
                    <>
                      <div
                        className="p-1"
                        style={{ color: '#425054', fontSize: '20px' }}
                      >
                        <img width="100px" height="100px" src={sucessoMsg} />
                      </div>
                      <div
                        className="p-1"
                        style={{ color: '#425054', fontSize: '20px' }}
                      >
                        {message}
                      </div>
                    </>
                  )}
                </div>
              </Modal>
            ) : (
              <Modal
                isOpen={open}
                toggle={() => toggle()}
                size="lg"
                style={{ maxWidth: '700px', width: '100%' }}
              >
                <CardBoxHeader
                  className="pl-4 pr-4"
                  title="ESCALAS COM PROBLEMAS"
                />
                <div
                  className="text-center pb-5 pl-5 pr-5 pt-3"
                  style={{ placeSelf: 'center' }}
                >
                  {message && (
                    <>
                      <div
                        className="p-1"
                        style={{ color: '#425054', fontSize: '20px' }}
                      >
                        {mountMessageView()}
                      </div>
                      <div
                        className="d-flex"
                        style={{ justifyContent: 'center', fontSize: '17px' }}
                      >
                        <ButtonFolhaCerta
                          onClickButton={() => toggle()}
                          buttonName="ENTENDI"
                        />
                      </div>
                    </>
                  )}
                </div>
              </Modal>
            )}
          </>
        )}
      </ReactCSSTransitionGroup>
    </>
  );
}

export default MessageModalList;
