import React, { useState, useCallback } from 'react';

import ButtonFolhaCerta from '~/components/_layouts/forms/ButtonFolhaCerta';
import Input from '~/components/_layouts/forms/Input';
import MessageModalList from '~/components/_layouts/modals/MessageModalList';

import { useAcao } from '~/hooks/escalas';

import {
  Row,
  Disclaimer,
  DivTransparente,
  DivBranca,
  Switcher,
} from './styles';

const FolgaCompensarBancoHoras = ({ dados, onClose, callback }) => {
  const [isDiaInteiro, setIsDiaInteiro] = useState(false);
  const [quantidadeHoras, setQuantidadeHoras] = useState('00:00');
  const [message, setMessage] = useState('');
  const [openMessage, setOpenMessage] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState(false);

  const { requestAcao } = useAcao();

  const { usuario, data } = dados;

  const showMessageView = (data) => {
    setMessage(data.message);
    setOpenMessage(true);
    setMessageSuccess(data.success);
  };

  const buttonConfirm = () => {
    setOpenMessage(false);
    onClose();
    callback();
  };

  const CompensarBanco = useCallback(async () => {
    let folgaCompensarBancoHorasValor;

    if (!isDiaInteiro) {
      const horasEmMinutos = Number(quantidadeHoras.split(':')[0]) * 60;
      const minutos = Number(quantidadeHoras.split(':')[1]);
      folgaCompensarBancoHorasValor = horasEmMinutos + minutos;
    } else {
      folgaCompensarBancoHorasValor = 0;
    }

    const params = {
      usuarioId: usuario.Id || usuario.usuarioId,
      data,
      tipoAcao: 'FolgaCompensarBancoHoras',
      folgaCompensarBancoHorasDiaTodo: isDiaInteiro,
      folgaCompensarBancoHorasValor,
    };
    try {
      const response = await requestAcao(params);
      showMessageView(response);
    } catch (err) {
      showMessageView({
        success: false,
        message: err.response.data.message
          ? err.response.data.message
          : 'Erro ao compensar folga',
      });
    }
  }, [usuario, isDiaInteiro, quantidadeHoras, requestAcao, data]);

  return (
    <>
      <DivBranca>
        <h1 styles={{ fontWeight: 'regular', fontFamily: 'Roboto Light ' }}>
          Quantas horas serão reduzidas da jornada de trabalho e lançadas para
          compensar banco?
        </h1>
        <Row
          style={{
            padding: 0,
            justifyContent: 'flex-start',
            width: '100%',
            marginBottom: 22,
          }}
        >
          <Disclaimer style={{ marginRight: 22 }}>Dia inteiro?</Disclaimer>
          <Switcher
            on={isDiaInteiro}
            onClick={() => setIsDiaInteiro(!isDiaInteiro)}
          >
            <div />
            <span>{isDiaInteiro ? 'Sim' : 'Não'}</span>
          </Switcher>
        </Row>
        {!isDiaInteiro && (
          <Input
            type="time"
            value={quantidadeHoras}
            onChange={(value) => setQuantidadeHoras(value)}
          />
        )}
      </DivBranca>

      <DivTransparente>
        <ButtonFolhaCerta
          buttonName="Cancelar"
          withoutBackground
          showIcon={false}
          onClickButton={onClose}
        />
        <ButtonFolhaCerta
          buttonName="Confirmar"
          amarelo
          showIcon={false}
          onClickButton={() => {
            if (dados && dados.alteracaoEscala) {
              showMessageView({
                message:
                  'Já existem alterações de escala para a data em questão. Para continuar, remova a ação existente.',
                success: false,
              });
            } else {
              CompensarBanco();
            }
          }}
        />
      </DivTransparente>
      <MessageModalList
        message={message}
        open={openMessage}
        messageSuccess={messageSuccess}
        onClick={() => {
          buttonConfirm();
        }}
      />
    </>
  );
};

export default FolgaCompensarBancoHoras;
