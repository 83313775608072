import produce from 'immer';

import { Types } from '../types';

// Reducers
const INITIAL_STATE = {
  dadosGerais: null,
  cadastroVerbas: null,
  detalhesVerbas: null,

  putConfig: null,

  dadosVerbas: null,
  alocacaoConfig: null,
  verbas: null,

  loading: false,
  erro: null,
};

export default function configDePara(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      // Dados Gerais da Configuração de Verbas
      case Types.CONFIG_VERBAS_REQUEST: {
        draft.erro = null;
        draft.loading = true;
        break;
      }

      case Types.CONFIG_VERBAS_SUCCESS: {
        draft.dadosGerais = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.CONFIG_VERBAS_FAILURE: {
        draft.erro = action.payload.data;
        draft.loading = false;
        break;
      }

      // Dados Gerais do PUT de Configuração de Verbas para o Portal
      case Types.PUT_CONFIG_PORTAL_REQUEST: {
        draft.erro = null;
        draft.loading = true;
        break;
      }

      case Types.PUT_CONFIG_PORTAL_SUCCESS: {
        draft.dadosGerais = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.PUT_CONFIG_PORTAL_FAILURE: {
        draft.erro = action.payload.data;
        draft.loading = false;
        break;
      }

      // Detalhes de Configuração de Verbas
      case Types.CONFIG_VERBAS_DETALHES_REQUEST: {
        draft.erro = null;
        draft.loading = true;
        break;
      }

      case Types.CONFIG_VERBAS_DETALHES_SUCCESS: {
        draft.detalhesVerbas = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.CONFIG_VERBAS_DETALHES_FAILURE: {
        draft.erro = action.payload.data;
        draft.detalhesVerbas = null;
        draft.loading = false;
        break;
      }

      // Cadastro de Verbas
      case Types.CADASTRO_VERBAS_REQUEST: {
        draft.erro = null;
        draft.loading = true;
        break;
      }

      case Types.CADASTRO_VERBAS_SUCCESS: {
        draft.cadastroVerbas = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.CADASTRO_VERBAS_FAILURE: {
        draft.erro = action.payload.data;
        draft.loading = false;
        break;
      }

      // Alocação de uma configuracao de verbas para uma regra de apuração
      case Types.ALOCACAO_CONFIG_VERBAS_REGRA_REQUEST: {
        draft.erro = null;
        draft.loading = true;
        break;
      }

      case Types.ALOCACAO_CONFIG_VERBAS_REGRA_SUCCESS: {
        draft.alocacaoConfig = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.ALOCACAO_CONFIG_VERBAS_REGRA_FAILURE: {
        draft.erro = action.payload.data;
        draft.loading = false;
        break;
      }

      // Cadastro de Verbas
      case Types.PUT_CONFIG_REQUEST: {
        draft.erro = null;
        draft.loading = true;
        break;
      }

      case Types.PUT_CONFIG_RESPONSE: {
        draft.putConfig = action.payload.data;
        draft.loading = false;
        break;
      }

      // Verbas do Folhacerta
      case Types.GET_VERBAS: {
        draft.loading = true;
        break;
      }

      case Types.GET_VERBAS_RESPONSE: {
        draft.dadosVerbas = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.CONFIG_VERBAS_CLEAR_ALL: {
        draft.dadosGerais = null;
        draft.dadosVerbas = null;
        draft.detalhesVerbas = null;
        draft.cadastroVerbas = null;
        draft.putConfig = null;
        draft.verbas = null;
        draft.alocacaoConfig = null;

        draft.loading = false;
        break;
      }

      case Types.LOGOUT: {
        draft.dadosGerais = null;
        draft.dadosVerbas = null;
        draft.detalhesVerbas = null;
        draft.cadastroVerbas = null;
        draft.putConfig = null;
        draft.verbas = null;
        draft.alocacaoConfig = null;

        draft.loading = false;
        break;
      }
      default:
    }
  });
}
