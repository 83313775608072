import { Types } from '../types';

export function marcarPonto3Request(params) {
  return {
    type: Types.MARCAR_PONTO_3_REQUEST,
    params,
  };
}

export function marcarPonto3Success(data) {
  return {
    type: Types.MARCAR_PONTO_3_SUCCESS,
    payload: { data },
  };
}

export function marcarPonto3ClearData() {
  return {
    type: Types.MARCAR_PONTO_3_CLEAR_DATA,
  };
}

export function marcarPonto3Failure(data) {
  return {
    type: Types.MARCAR_PONTO_3_FAILURE,
    payload: { data },
  };
}
