import { Types } from '../types';

export function contratantesRequest(params) {
  return {
    type: Types.CONTRATANTES_REQUEST,
    payload: params
  };
}

export function contratantesSuccess(data) {
  return {
    type: Types.CONTRATANTES_SUCCESS,
    payload: { data },
  };
}

export function contratantesFailure(data) {
  return {
    type: Types.CONTRATANTES_FAILURE,
    payload: { data },
  };
}

export function contratantesEditarRequest(params) {
  return {
    type: Types.CONTRATANTES_EDITAR_REQUEST,
    payload: params
  };
}

export function contratantesEditarResponse(data) {
  return {
    type: Types.CONTRATANTES_EDITAR_RESPONSE,
    payload: data,
  };
}


export function contratantesAtivasRequest() {
  return {
    type: Types.CONTRATANTES_ATIVAS_REQUEST
  };
}

export function contratantesAtivasSuccess(data) {
  return {
    type: Types.CONTRATANTES_ATIVAS_SUCCESS,
    payload: { data },
  };
}

export function contratantesAtivasFailure() {
  return {
    type: Types.CONTRATANTES_ATIVAS_FAILURE
  };
}

export function contratantesArquivadasRequest() {
  return {
    type: Types.CONTRATANTES_ARQUIVADAS_REQUEST
  };
}

export function contratantesArquivadasSuccess(data) {
  return {
    type: Types.CONTRATANTES_ARQUIVADAS_SUCCESS,
    payload: { data },
  };
}

export function contratantesArquivadasFailure() {
  return {
    type: Types.CONTRATANTES_ARQUIVADAS_FAILURE
  };
}

export function contratantesTodosRequest() {
  return {
    type: Types.CONTRATANTES_TODOS_REQUEST
  };
}

export function contratantesTodosSuccess(data) {
  return {
    type: Types.CONTRATANTES_TODOS_SUCCESS,
    payload: { data },
  };
}

export function contratantesTodosFailure() {
  return {
    type: Types.CONTRATANTES_TODOS_FAILURE
  };
}

export function contratantesDisponiveisRequest() {
  return {
    type: Types.CONTRATANTES_DISPONIVEIS_REQUEST
  };
}

export function contratantesDisponiveisSuccess(data) {
  return {
    type: Types.CONTRATANTES_DISPONIVEIS_SUCCESS,
    payload: { data },
  };
}

export function contratantesDisponiveisFailure() {
  return {
    type: Types.CONTRATANTES_DISPONIVEIS_FAILURE
  };
}

export function contratantesArquivarRequest(data) {
  return {
    type: Types.CONTRATANTES_ARQUIVAR_REQUEST,
    payload: data,
  };
}

export function contratantesArquivarResponse(data) {
  return {
    type: Types.CONTRATANTES_ARQUIVAR_RESPONSE,
    payload: data,
  };
}

export function contratantesDesarquivarRequest(data) {
  return {
    type: Types.CONTRATANTES_DESARQUIVAR_REQUEST,
    payload: data,
  };
}

export function contratantesDesarquivarResponse(data) {
  return {
    type: Types.CONTRATANTES_DESARQUIVAR_RESPONSE,
    payload: data,
  };
}

export function contratantesClearData() {
  return {
    type: Types.CONTRATANTES_CLEAR_DATA
  };
}
