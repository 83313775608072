import { Types } from '../../../types';

export function bancoHorasEstagioRequest({ params, method, id }, callback) {
  return {
    type: Types.BANCO_HORAS_ESTAGIO_REQUEST,
    payload: params,
    method,
    id,
    callback,
  };
}

export function getBancoHorasEstagioResponse(data, callback) {
  callback(data);
  return {
    type: Types.GET_BANCO_HORAS_ESTAGIO_RESPONSE,
    payload: { data },
  };
}

export function putBancoHorasEstagioResponse(data, callback) {
  callback(data);
  return {
    type: Types.PUT_BANCO_HORAS_ESTAGIO_RESPONSE,
    payload: { data },
  };
}
