import { takeLatest, call, put, all, delay } from 'redux-saga/effects';
import { Types } from '../types';
import { requestBaseV3 } from '~/services/request';
import { carregarTotaisLancamentosResponse } from './actions';

export function* carregarTotaisLancamentos({ payload }) {
	let params = {
		url: '/CarregarTotaisLancamentos',
		method: 'POST',
		body: {
			usuarioId: payload.usuarioId,
		}
	};

	try {
		const response = yield call(requestBaseV3, params);
		const data = response.data;
		yield put(carregarTotaisLancamentosResponse(data));
	} catch (error) {
		yield put(carregarTotaisLancamentosResponse({ success: false }));
	}
}

export default all([
	takeLatest(Types.CARREGAR_TOTAIS_LANCAMENTOS_REQUEST, carregarTotaisLancamentos)
]);