import { takeLatest, call, put, all, delay } from 'redux-saga/effects';
import { Types } from '../types';

import { requestIntermitentes } from '~/services/request';

import {
  regrasConvocacaoSuccess,
  regrasConvocacaoFailure,
  regrasAtivasSuccess,
  regrasAtivasFailure,
  regrasArquivadasSuccess,
  regrasArquivadasFailure,
  regrasUtilizadasSuccess,
  regrasUtilizadasFailure,
  regrasConvocacaoGetSuccess,
  regrasConvocacaoGetFailure,
  regrasConvocacaoPutResponse,
} from './actions';

export function* regrasConvocacao({ payload }) {
  const params = {
    url: '/RegrasConvocacao',
    method: 'POST',
    body: payload,
  };

  try {
    const response = yield call(requestIntermitentes, params);
    if (response.data.success) {
      const { data } = response;

      yield put(regrasConvocacaoSuccess(data));
      yield delay(2000);
    } else {
      yield put(regrasConvocacaoFailure(response.data));
    }
  } catch (error) {
    yield put(regrasConvocacaoFailure({ success: false }));
  }
}

export function* regrasConvocacaoPut({ payload }) {
  const params = {
    url: '/RegrasConvocacao',
    method: 'PUT',
    body: payload,
  };

  try {
    const response = yield call(requestIntermitentes, params);
    yield put(regrasConvocacaoPutResponse(response));
  } catch (error) {
    yield put(regrasConvocacaoPutResponse({ success: false }));
  }
}

export function* regrasConvocacaoById({ payload }) {
  const params = {
    url: `/RegrasConvocacao/${payload.id}`,
    method: 'GET',
    body: null,
    queryParams: null,
  };

  try {
    const response = yield call(requestIntermitentes, params);
    if (response.data.success) {
      const { data } = response;

      yield put(regrasConvocacaoGetSuccess(data));
      yield delay(2000);
    } else {
      yield put(regrasConvocacaoGetFailure(response.data));
    }
  } catch (error) {
    yield put(regrasConvocacaoGetFailure({ success: false }));
  }
}

export function* regrasAtivas() {
  const params = {
    url: '/RegrasConvocacao/por-status',
    method: 'GET',
    body: null,
    queryParams: { naoarquivadas: true },
  };

  try {
    const response = yield call(requestIntermitentes, params);
    if (response.data.success) {
      const { data } = response.data;

      yield put(regrasAtivasSuccess(data));
      yield delay(2000);
    } else {
      yield put(regrasAtivasFailure());
    }
  } catch (error) {
    yield put(regrasAtivasFailure());
  }
}

export function* regrasUtilizadas() {
  const params = {
    url: '/RegrasConvocacao/por-status',
    method: 'GET',
    body: null,
    queryParams: { utilizadas: true },
  };

  try {
    const response = yield call(requestIntermitentes, params);
    if (response.data.success) {
      const { data } = response.data;

      yield put(regrasUtilizadasSuccess(data));
      yield delay(2000);
    } else {
      yield put(regrasUtilizadasFailure());
    }
  } catch (error) {
    yield put(regrasUtilizadasFailure());
  }
}

export function* regrasArquivadas() {
  const params = {
    url: '/RegrasConvocacao/por-status',
    method: 'GET',
    body: null,
    queryParams: { arquivadas: true },
  };

  try {
    const response = yield call(requestIntermitentes, params);
    if (response.data.success) {
      const { data } = response.data;

      yield put(regrasArquivadasSuccess(data));
      yield delay(2000);
    } else {
      yield put(regrasArquivadasFailure());
    }
  } catch (error) {
    yield put(regrasArquivadasFailure());
  }
}

export default all([
  takeLatest(Types.REGRAS_CONVOCACAO_REQUEST, regrasConvocacao),
  takeLatest(Types.REGRAS_ATIVAS_REQUEST, regrasAtivas),
  takeLatest(Types.REGRAS_ARQUIVADAS_REQUEST, regrasArquivadas),
  takeLatest(Types.REGRAS_UTILIZADAS_REQUEST, regrasUtilizadas),
  takeLatest(Types.REGRAS_CONVOCACAO_GET_REQUEST, regrasConvocacaoById),
  takeLatest(Types.REGRAS_CONVOCACAO_PUT_REQUEST, regrasConvocacaoPut),
]);
