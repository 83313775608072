import { takeLatest, call, put, all } from "redux-saga/effects";

import { Types } from "../types";

import { requestBaseV3 } from "~/services/request";

import { gerenciarProcessosInternosResponse } from "./actions";

export function* gerenciarProcessosInternos({ payload, callback }) {
  let form_data = new FormData();

  for (let key in payload) {
    form_data.append(key, payload[key]);
  }

  let params = {
    url: "/GerenciarProcessoInterno",
    method: "POST",
    body: form_data,
  };

  try {
    const response = yield call(requestBaseV3, params);
    yield put(gerenciarProcessosInternosResponse(response.data, callback));
  } catch (error) {
    yield put(gerenciarProcessosInternosResponse({ success: false }, callback));
  }
}

export default all([
  takeLatest(
    Types.GERENCIAR_PROCESSOS_INTERNOS_REQUEST,
    gerenciarProcessosInternos
  ),
]);
