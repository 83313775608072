import { Types } from '../../types';

export function ausenciasRequest({ params, method, id }) {
  return {
    type: Types.AUSENCIAS_REQUEST,
    payload: params,
    method: method,
    id: id
  };
}

export function getAusenciasResponse(data) {
  return {
    type: Types.GET_AUSENCIAS_RESPONSE,
    payload: { data },
  };
}

export function postAusenciasResponse(data) {
  return {
    type: Types.POST_AUSENCIAS_RESPONSE,
    payload: { data },
  };
}

export function putAusenciasResponse(data) {
  return {
    type: Types.PUT_AUSENCIAS_RESPONSE,
    payload: { data },
  };
}
