import { Types } from '../types';

// LISTAS DE FILIAIS
export function getFiliaisRequest(queryParams) {
  return { type: Types.GET_FILIAIS_REQUEST, queryParams };
}

export function getFiliaisResponse(data) {
  return { type: Types.GET_FILIAIS_RESPONSE, payload: { data } };
}

// LISTAS DE DEPARTAMENTOS
export function getDepartamentosRequest(queryParams) {
  return { type: Types.GET_DEPARTAMENTOS_REQUEST, queryParams };
}

export function getDepartamentosResponse(data) {
  return { type: Types.GET_DEPARTAMENTOS_RESPONSE, payload: { data } };
}

// LISTAS DE GRUPOS
export function getGruposRequest() {
  return { type: Types.GET_GRUPOS_REQUEST };
}

export function getGruposResponse(data) {
  return { type: Types.GET_GRUPOS_RESPONSE, payload: { data } };
}

// LISTAS DE PESSOAS
export function getPessoasRequest(queryParams) {
  let params = {};
  if (typeof queryParams !== 'string') params = queryParams;

  return { type: Types.GET_PESSOAS_REQUEST, queryParams: params };
}

export function getPessoasResponse(data) {
  return { type: Types.GET_PESSOAS_RESPONSE, payload: { data } };
}

// LISTAS DE SINDICATOS
export function getSindicatosRequest(queryParams) {
  return { type: Types.GET_SINDICATOS_REQUEST, queryParams };
}

export function getSindicatosResponse(data) {
  return { type: Types.GET_SINDICATOS_RESPONSE, payload: { data } };
}

// LISTAS DE CENTROS DE CUSTOS
export function getCentrosDeCustosRequest(queryParams) {
  return { type: Types.GET_CENTROS_DE_CUSTO_REQUEST, queryParams };
}

export function getCentrosDeCustosResponse(data) {
  return { type: Types.GET_CENTROS_DE_CUSTO_RESPONSE, payload: { data } };
}

// LISTAS DE PAISES
export function getPaisesRequest(queryParams) {
  return { type: Types.GET_PAIS_REQUEST, queryParams };
}

export function getPaisesResponse(data) {
  return { type: Types.GET_PAIS_RESPONSE, payload: { data } };
}

// LISTAS DE ESTADOS
export function getEstadosRequest(queryParams) {
  return { type: Types.GET_ESTADO_REQUEST, queryParams };
}

export function getEstadosResponse(data) {
  return { type: Types.GET_ESTADO_RESPONSE, payload: { data } };
}

// LISTAS DE CIDADES
export function getCidadesRequest(queryParams) {
  return { type: Types.GET_CIDADE_REQUEST, queryParams };
}

export function getCidadesResponse(data) {
  return { type: Types.GET_CIDADE_RESPONSE, payload: { data } };
}

// LISTAS DE PERÍODOS
export function getPeriodosRequest() {
  return { type: Types.GET_PERIODOS_REQUEST };
}

export function getPeriodosResponse(data) {
  return { type: Types.GET_PERIODOS_RESPONSE, payload: { data } };
}

// LISTAS DE REGRA DE APURACAO
export function getRegrasApuracaoRequest() {
  return { type: Types.GET_REGRAS_APURACAO_REQUEST,  };
}

export function getRegrasApuracaoResponse(data) {
  return { type: Types.GET_REGRAS_APURACAO_RESPONSE, payload: { data } };
}

// LISTAS DE SERVICOS DE ORIGEM
export function getServicosDeOrigemRequest() {
  return { type: Types.GET_SERVICOS_ORIGEM_REQUEST,  };
}

export function getServicosDeOrigemResponse(data) {
  return { type: Types.GET_SERVICOS_ORIGEM_RESPONSE, payload: { data } };
}

// LISTAS DE STATUS MENSAGERIA
export function getStatusMensageriaRequest() {
  return { type: Types.GET_STATUS_REQUEST,  };
}

export function getStatusMensageriaResponse(data) {
  return { type: Types.GET_STATUS_RESPONSE, payload: { data } };
}

// LISTAS DE EMPRESAS (ADMIN MASTER)
export function getEmpresasRequest() {
  return { type: Types.GET_EMPRESAS_REQUEST,  };
}

export function getEmpresasResponse(data) {
  return { type: Types.GET_EMPRESAS_RESPONSE, payload: { data } };
}

export function getEmpresasPagedRequest(queryParams) {
  let params = {};

  if (queryParams && typeof queryParams !== 'string') {
    params = queryParams;
  } else {
    params = { pageNumber: 1, pageSize: 25,  };
  }

  return { type: Types.GET_EMPRESAS_PAGED_REQUEST, body: params };
}

export function getEmpresasPagedResponse(data) {
  return { type: Types.GET_EMPRESAS_PAGED_RESPONSE, payload: { data } };
}

export function getCentrosDeCustosPagedRequest(queryParams, callback) {
  let params = {};

  if (queryParams && typeof queryParams !== 'string') {
    params = queryParams;
  } else {
    params = { pageNumber: 1, pageSize: 25 };
  }

  return { type: Types.GET_CENTROS_DE_CUSTO_PAGED_REQUEST, body: params, callback };
}

export function getCentrosDeCustosPagedResponse(data, callback) {
  if(callback) callback(data);
  
  return { type: Types.GET_CENTROS_DE_CUSTO_PAGED_RESPONSE, payload: { data } };
}

export function getSindicatosPagedRequest(queryParams, callback) {
  let params = {};

  if (queryParams && typeof queryParams !== 'string') {
    params = queryParams;
  } else {
    params = { pageNumber: 1, pageSize: 25 };
  }

  return { type: Types.GET_SINDICATOS_PAGED_REQUEST, body: params, callback };
}

export function getSindicatosPagedResponse(data, callback) {
  if(callback) callback(data);
  
  return { type: Types.GET_SINDICATOS_PAGED_RESPONSE, payload: { data } };
}

export function getPessoasPagedRequest(queryParams, callback) {
  let params = {};

  if (queryParams && typeof queryParams !== 'string') {
    params = queryParams;
  } else {
    params = { pageNumber: 1, pageSize: 25 };
  }

  return { type: Types.GET_PESSOAS_PAGED_REQUEST, body: params, callback };
}

export function getPessoasPagedResponse(data, callback) {
  if(callback) callback(data);
  
  return { type: Types.GET_PESSOAS_PAGED_RESPONSE, payload: { data } };
}

export function getGruposPagedRequest(queryParams, callback) {
  let params = {};

  if (queryParams && typeof queryParams !== 'string') {
    params = queryParams;
  } else {
    params = { pageNumber: 1, pageSize: 25 };
  }

  return { type: Types.GET_GRUPOS_PAGED_REQUEST, body: params, callback };
}

export function getGruposPagedResponse(data, callback) {
  if(callback) callback(data);

  return { type: Types.GET_GRUPOS_PAGED_RESPONSE, payload: { data } };
}

export function getDepartamentosPagedRequest(queryParams, callback) {
  let params = {};

  if (queryParams && typeof queryParams !== 'string') {
    params = queryParams;
  } else {
    params = { pageNumber: 1, pageSize: 25 };
  }

  return { type: Types.GET_DEPARTAMENTOS_PAGED_REQUEST, body: params, callback };
}

export function getDepartamentosPagedResponse(data, callback) {
  if(callback) callback(data);

  return { type: Types.GET_DEPARTAMENTOS_PAGED_RESPONSE, payload: { data } };
}

export function getFiliaisPagedRequest(queryParams, callback) {
  let params = {};

  if (queryParams && typeof queryParams !== 'string') {
    params = queryParams;
  } else {
    params = { pageNumber: 1, pageSize: 25 };
  }

  return { type: Types.GET_FILIAIS_PAGED_REQUEST, body: params, callback };
}

export function getFiliaisPagedResponse(data, callback) {
  if(callback) callback(data);
  
  return { type: Types.GET_FILIAIS_PAGED_RESPONSE, payload: { data } };
}
