import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'babel-polyfill';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import ReactDOM from 'react-dom';
import './assets/base.scss';

import { CookiesProvider } from 'react-cookie';

import App from './app';

const rootElement = document.getElementById('root');
ReactDOM.render(
  <CookiesProvider>
    <App />
  </CookiesProvider>,
  rootElement,
);
