import { Types } from '../../types';

export function horaPositivaFaixasRequest({ params, method, id }) {
  return {
    type: Types.HORA_POSITIVA_FAIXAS_REQUEST,
    payload: params,
    method: method,
    id: id
  };
}

export function getHoraPositivaFaixasResponse(data) {
  return {
    type: Types.GET_HORA_POSITIVA_FAIXAS_RESPONSE,
    payload: { data },
  };
}

export function postHoraPositivaFaixasResponse(data) {
  return {
    type: Types.POST_HORA_POSITIVA_FAIXAS_RESPONSE,
    payload: { data },
  };
}

export function putHoraPositivaFaixasResponse(data) {
  return {
    type: Types.PUT_HORA_POSITIVA_FAIXAS_RESPONSE,
    payload: { data },
  };
}
