import { takeLatest, call, put, all } from 'redux-saga/effects';
import { Types } from '../types';
import { requestBaseV3 } from '~/services/request';
import { dashboardAprovacoesResponse } from './actions';

export function* dashboardAprovacoes() {
  const params = {
    url: '/dashboardAprovacoes',
    method: 'GET',
  };

  try {
    const response = yield call(requestBaseV3, params);
    const { data } = response;
    yield put(dashboardAprovacoesResponse(data));
  } catch (error) {
    yield put(dashboardAprovacoesResponse({ Success: false }));
  }
}

export default all([
  takeLatest(Types.CARREGAR_DASHBOARD_APROVACOES_REQUEST, dashboardAprovacoes),
]);
