import { Types } from '../../types';

export function toleranciaRequest({ params, method, id }) {
  return {
    type: Types.TOLERANCIA_REQUEST,
    payload: params,
    method: method,
    id: id
  };
}

export function getToleranciaResponse(data) {
  return {
    type: Types.GET_TOLERANCIA_RESPONSE,
    payload: { data },
  };
}

export function postToleranciaResponse(data) {
  return {
    type: Types.POST_TOLERANCIA_RESPONSE,
    payload: { data },
  };
}

export function putToleranciaResponse(data) {
  return {
    type: Types.PUT_TOLERANCIA_RESPONSE,
    payload: { data },
  };
}
