import { Types } from '../types';

export function carregarAvisoAusenciaLancamentosRequest() {
  return {
    type: Types.CARREGAR_AVISO_AUSENCIA_LANCAMENTOS_REQUEST
  };
}

export function carregarAvisoAusenciaLancamentosSuccess(data) {
  return {
    type: Types.CARREGAR_AVISO_AUSENCIA_LANCAMENTOS_SUCCESS,
    payload: { data },
  };
}

export function carregarAvisoAusenciaLancamentosFailure() {
  return {
    type: Types.CARREGAR_AVISO_AUSENCIA_LANCAMENTOS_FAILURE
  };
}
 

export function carregarAusenciaLancamentoPorIdRequest(avisoAusenciaLancamento_id) {
  return {
    type: Types.CARREGAR_AUSENCIA_POR_ID_REQUEST,
    payload: { avisoAusenciaLancamento_id }
  };
}

export function carregarAusenciaLancamentoPorIdSuccess(data) {
  return {
    type: Types.CARREGAR_AUSENCIA_POR_ID_SUCCESS,
    payload: { data },
  };
}

export function carregarAusenciaLancamentoPorIdFailure() {
  return {
    type: Types.CARREGAR_AUSENCIA_POR_ID_FAILURE
  };
}
 

export function clearAusenciaLancamento() {
  return {
    type: Types.CLEAR_AVISO_AUSENCIA_LANCAMENTO
  };
}
 