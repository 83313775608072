import { takeLatest, call, put, all, delay } from 'redux-saga/effects';

import { Types } from '../../types';

import { requestFolhacertaBase } from '~/services/request';

import { grupoArquivadosSuccess, grupoArquivadosFailure } from './actions';

export function* grupoArquivados({ payload }) {
  let params = {
    url: '/grupo',
    method: 'GET',
    queryParams: payload,
  };

  try {
    const response = yield call(requestFolhacertaBase, params);

    if (response.data.success) {
      const data = response.data.data;
      yield put(grupoArquivadosSuccess(data));
      yield delay(2000);
    } else {
      yield put(grupoArquivadosFailure());
    }
  } catch (err) {
    yield put(grupoArquivadosFailure());
  }
}

export default all([
  takeLatest(Types.GRUPO_ARQUIVADOS_REQUEST, grupoArquivados),
]);
