import { Types } from '../types';

export function departamentosRequest() {
  return {
    type: Types.DEPARTAMENTOS_REQUEST,
  };
}

export function departamentosSuccess(data) {
  return {
    type: Types.DEPARTAMENTOS_SUCCESS,
    payload: { data },
  };
}

export function departamentosFailure() {
  return {
    type: Types.DEPARTAMENTOS_FAILURE
  };
}
