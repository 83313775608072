import { takeLatest, call, put, all, delay } from 'redux-saga/effects';
import { requestBaseV3 } from '~/services/request';
import { Types } from '../../types';

import { alterarStatusLancamentoSuccess, alterarStatusLancamentoFailure } from './actions';

export function* alterarStatusLancamento({ params, callback }) {
  const parameters = {
    url: '/AlterarStatusLancamento',
    method: 'POST',
    body: params,
  };

  try {
    const response = yield call(requestBaseV3, parameters);
    const { data } = response;

    if (data.Success) {
      yield put(alterarStatusLancamentoSuccess(data, callback));
      yield delay(2000);
    } else {
      yield put(alterarStatusLancamentoFailure(data, callback));
    }
  } catch (error) {
    yield put(alterarStatusLancamentoFailure({ Success: false, Error: error }, callback));
  }
}

export default all([
  takeLatest(Types.ALTERAR_STATUS_LANCAMENTO_REQUEST, alterarStatusLancamento),
]);
