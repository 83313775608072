import { takeLatest, call, put, all } from 'redux-saga/effects';

import { Types } from '~/store/modules/types';
import { requestEscalas } from '~/services/request';

export function* request({
  url,
  method,
  queryParams,
  body,
  responseAction,
  callback,
}) {
  const params = {
    url,
    method,
    queryParams,
    body,
  };

  try {
    const response = yield call(requestEscalas, params);
    yield put(responseAction(response.data, callback));
  } catch (error) {
    yield put(responseAction({ success: false, message: 'erro' }, callback));
  }
}

export default all([
  takeLatest(Types.REQUEST_REGRAS_CONDICOES_TRABALHO, request),
  takeLatest(Types.REQUEST_COMBO_CONDICOES_TRABALHO, request),
  takeLatest(Types.REQUEST_REGRAS_CONDICOES_TRABALHO_POR_ID, request),
  takeLatest(Types.ALTERA_STATUS_REGRAS_CONDICOES_TRABALHO, request),
]);
