import { Types } from '../../types';

export function requestDeletaRegistroPorId({ queryParams }, callback) {
  return {
    type: Types.REQUEST_DELETE_OPTIONS,
    queryParams,
    callback,
  };
}

export function responseDeletaRegistroPorId(data, callback) {
  callback();
  return {
    type: Types.RESPONSE_DELETE_OPTIONS,
    payload: { data },
  };
}

export function requestDeletaRegistroEmGrupo({ body }, callback) {
  return {
    type: Types.REQUEST_DELETE_OPTIONS_GROUP,
    body,
    callback,
  };
}

export function responseDeletaRegistroEmGrupo(data, callback) {
  callback(data);
  return {
    type: Types.RESPONSE_DELETE_OPTIONS_GROUP,
    payload: { data },
  };
}
