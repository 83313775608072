import produce from 'immer';

import { Types } from '~/store/modules/types';

const INITIAL_STATE = {
  loading: false,
  payloadTurno: null,
};

export default function regrasCondicoesTrabalho(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.REQUEST_TURNO: {
        draft.loading = true;
        break;
      }

      case Types.REQUEST_POST_AND_PUT_TURNO: {
        draft.loading = true;
        break;
      }

      case Types.RESPONSE_POST_AND_PUT_TURNO: {
        draft.loading = false;
        break;
      }

      case Types.RESPONSE_TURNO: {
        draft.payloadTurno = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.CLEAR_TURNO: {
        draft.loading = false;
        draft.payloadTurno = null;
        break;
      }

      case Types.LOGOUT: {
        draft.loading = false;
        draft.payloadTurno = null;
        break;
      }

      default:
    }
  });
}
