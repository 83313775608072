import { takeLatest, call, put, all } from 'redux-saga/effects';
import { Types } from '../../types';
import { requestImportacao } from '~/services/request';
import { responseImportacao, responseImportacaoArquivo } from './actions';

export function* importacao({ payload, queryParams, callback }) {
  const formData = new FormData();
  formData.append('arquivos', payload.files[0]);
  const params = {
    url: '/importacao',
    method: 'POST',
    body: formData,
    queryParams,
  };

  try {
    const response = yield call(requestImportacao, params);
    yield put(responseImportacao(response.data, callback));
  } catch (error) {
    yield put(responseImportacao({ success: false }, callback));
  }
}

export function* importacaoArquivo({ importacaoId, queryParams, callback }) {
  const params = {
    url: `/importacao/${importacaoId}/arquivo`,
    method: 'GET',
    queryParams,
  };

  try {
    const response = yield call(requestImportacao, params);
    yield put(responseImportacaoArquivo(response.data, callback));
  } catch (error) {
    yield put(responseImportacaoArquivo({ success: false }, callback));
  }
}

export default all([
  takeLatest(Types.REQUEST_IMPORTACAO, importacao),
  takeLatest(Types.REQUEST_IMPORTACAO_ARQUIVO, importacaoArquivo),
]);
