import { takeLatest, call, put, all, delay } from 'redux-saga/effects';
import { Types } from '../../types';

import { requestFolhacertaBase } from '~/services/request';

import { grupoTodosSuccess, grupoTodosFailure } from './actions';

export function* grupoTodos({ payload }) {
  let params = {
    url: '/grupo',
    method: 'GET',
    queryParams: payload,
  };

  try {
    const response = yield call(requestFolhacertaBase, params);

    if (response.data.success) {
      const data = response.data.data;

      yield put(grupoTodosSuccess(data));
      yield delay(2000);
    } else {
      yield put(grupoTodosFailure());
    }
  } catch (err) {
    yield put(grupoTodosFailure());
  }
}

export default all([takeLatest(Types.GRUPO_TODOS_REQUEST, grupoTodos)]);
