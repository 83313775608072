/* eslint-disable react/prop-types */
import React, { useState, useCallback } from 'react';

import { useAcao } from '~/hooks/escalas';

import MessageModalList from '~/components/_layouts/modals/MessageModalList';

import { DIAS_SEMANA_ABREVIADO, getDay } from '~/utils/dateFormat';
import { DivBranca, DivTransparente, BotaoDiaCalendario, UL } from './styles';

export default function MoverFolgaSemanal({ dados, onClose, callback }) {
  const { mudarFolgaSemanal } = useAcao();
  const [diaSelecionado, setDiaSelecionado] = useState('');
  const { usuario, semana, data } = dados;
  const [message, setMessage] = useState('');
  const [openMessage, setOpenMessage] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState(false);

  const showMessageView = (data) => {
    setMessage(data.message);
    setOpenMessage(true);
    setMessageSuccess(data.success);
  };

  const buttonConfirm = () => {
    setOpenMessage(false);
    onClose();
    callback();
  };

  const alterarDia = useCallback(async () => {
    if (diaSelecionado === '') return;
    const params = {
      usuarioId: usuario.Id || usuario.usuarioId,
      data,
      novaFolgaSemanal: diaSelecionado,
    };

    try {
      const response = await mudarFolgaSemanal(params);

      showMessageView(response);
    } catch (err) {
      showMessageView({
        success: false,
        message: err.response.data.message
          ? err.response.data.message
          : 'Erro ao mover folga',
      });
    }
  }, [usuario, diaSelecionado, data]);

  return (
    <>
      <DivBranca>
        <h1>Qual dia será a folga desta semana?</h1>
        <div>
          <UL>
            {semana &&
              semana.map((dia) => {
                const { data, naturezaDia, entrada, saida } = dia;
                const diaTrabalhado = naturezaDia === 'Trabalho';
                const selecionado = diaSelecionado === data;
                return (
                  <li key={dia.data}>
                    <BotaoDiaCalendario
                      diatrabalhado={diaTrabalhado}
                      selecionado={selecionado}
                      onClick={() => setDiaSelecionado(data)}
                      disabled={!diaTrabalhado}
                    >
                      <span>
                        {DIAS_SEMANA_ABREVIADO[new Date(data).getDay()]}
                      </span>
                      <div>
                        <div>
                          <span>{getDay(data)}</span>
                        </div>
                        <div>
                          {diaTrabalhado ? (
                            <>
                              <p>{entrada}</p>

                              <p>{saida}</p>
                            </>
                          ) : (
                            <p>{naturezaDia}</p>
                          )}
                          <p />
                        </div>
                      </div>
                    </BotaoDiaCalendario>
                  </li>
                );
              })}
          </UL>
        </div>
      </DivBranca>
      <DivTransparente>
        <button onClick={onClose}>cancelar</button>
        <button
          onClick={() => {
            if (dados && dados.alteracaoEscala) {
              showMessageView({
                message:
                  'Já existem alterações de escala para a data em questão. Para continuar, remova a ação existente.',
                success: false,
              });
            } else {
              alterarDia();
            }
          }}
        >
          confirmar
        </button>
      </DivTransparente>
      <MessageModalList
        message={message}
        open={openMessage}
        messageSuccess={messageSuccess}
        onClick={() => {
          buttonConfirm();
        }}
      />
    </>
  );
}
