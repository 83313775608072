import { takeLatest, call, put, all, delay } from 'redux-saga/effects';

import { Types } from '../types';

import { requestIntermitentes } from '~/services/request';

import {
  novaConvocacaoSuccess,
  novaConvocacaoFailure,
  convocacaoListaSuccess,
  convocacaoListaFailure,
  convocacaoResumoByIdSuccess,
  convocacaoResumoByIdFailure,
  convocacaoInterromperSuccess,
  convocacaoInterromperFailure,
  convocacaoVencendoResponse,
} from './actions';

export function* convocacaoResumoById({ payload }) {
  const params = {
    url: `/Convocacao/resumo/${payload}`,
    method: 'GET',
    body: null,
  };

  try {
    const response = yield call(requestIntermitentes, params);
    if (response.data.success) {
      const { data } = response;

      yield put(convocacaoResumoByIdSuccess(data));
      yield delay(2000);
    } else {
      yield put(convocacaoResumoByIdFailure(response.data));
    }
  } catch (error) {
    yield put(convocacaoResumoByIdFailure({ success: false }));
  }
}

export function* novaConvocacao({ payload, callback }) {
  const params = {
    url: '/Convocacao',
    method: 'POST',
    body: payload,
  };

  try {
    const response = yield call(requestIntermitentes, params);
    if (response.data.success) {
      const { data } = response;
      yield put(novaConvocacaoSuccess(response.data, callback));
      yield delay(2000);
    } else {
      yield put(novaConvocacaoFailure(response.data, callback));
    }
  } catch (error) {
    yield put(novaConvocacaoFailure({ success: false, callback }));
  }
}

export function* convocacaoLista({ payload }) {
  const params = {
    url: 'Convocacao/listaRH',
    method: 'POST',
    body: payload,
  };

  try {
    const response = yield call(requestIntermitentes, params);
    if (response.data.success) {
      const { data } = response.data;

      yield put(convocacaoListaSuccess(data));
      yield delay(2000);
    } else {
      yield put(convocacaoListaFailure());
    }
  } catch (error) {
    yield put(convocacaoListaFailure());
  }
}

export function* convocacaoInterromper({ payload }) {
  const params = {
    url: '/Convocacao/interromper',
    method: 'POST',
    body: payload,
    queryParams: null,
  };

  try {
    const response = yield call(requestIntermitentes, params);
    if (response.data.success) {
      yield put(convocacaoInterromperSuccess(response.data));
      yield delay(2000);
    } else {
      yield put(convocacaoInterromperFailure(response.data));
    }
  } catch (error) {
    yield put(convocacaoInterromperFailure());
  }
}

export function* convocacaoVencendo({ payload, status }) {
  const params = {
    url: `/Convocacao/vencendo-em/${payload.inicio}/${payload.fim}`,
    method: 'GET',
    body: null,
    queryParams: { [status]: true },
  };

  try {
    const response = yield call(requestIntermitentes, params);
    yield put(convocacaoVencendoResponse(response.data));
  } catch (error) {
    yield put(convocacaoVencendoResponse({ success: false }));
  }
}

export default all([
  takeLatest(Types.NOVA_CONVOCACAO_REQUEST, novaConvocacao),
  takeLatest(Types.CONVOCACAO_LISTA_REQUEST, convocacaoLista),
  takeLatest(Types.CONTRATANTES_RESUMO_ID_REQUEST, convocacaoResumoById),
  takeLatest(Types.CONVOCACAO_INTERROMPER_REQUEST, convocacaoInterromper),
  takeLatest(Types.CONVOCACAO_VENCENDO_REQUEST, convocacaoVencendo),
]);
