import { Types } from '../types';

export function carregarMotivosAusenciaRequest() {
  return {
    type: Types.CARREGAR_MOTIVOS_AUSENCIA_REQUEST
  };
}

export function carregarMotivosAusenciaResponse(data) {
  return {
    type: Types.CARREGAR_MOTIVOS_AUSENCIA_RESPONSE,
    payload: { data },
  };
}
