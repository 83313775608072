import { takeLatest, call, put, all, delay } from 'redux-saga/effects';
import { Types } from '../types';
import { toast } from 'react-toastify';
import { requestBaseV3 } from '~/services/request';
import { carregarMesSuccess, carregarMesFailure } from './actions';

export function* carregarMes({ payload }) {
  let params = {
    url: '/CarregarMes',
    method: 'POST',
    body: payload,
  };

  try {
    const response = yield call(requestBaseV3, params);

    if (response.data.Success) {
      const data = response.data;
      yield put(carregarMesSuccess(data, payload.callback));
      yield delay(2000);
    } else {
      toast.error(response.data.Error);
      yield put(carregarMesFailure());
    }
  } catch (error) {
    toast.error('Falha na autenticação');
    yield put(carregarMesFailure());
  }
}

export default all([takeLatest(Types.CARREGAR_MES_REQUEST, carregarMes)]);
