import produce from 'immer';

import { Types } from '../types';

// Reducers
const INITIAL_STATE = {
  payloadPost: null,
  loadingPost: false,

  payloadGet: null,
  loadingGet: false,

}

export default function motivosInterrupcao(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.MOTIVOS_INTERRUPCAO_GET_REQUEST: {
        draft.loadingGet = true;
        break;
      }
      case Types.MOTIVOS_INTERRUPCAO_GET_SUCCESS: {
        draft.payloadGet = action.payload.data;
        draft.loadingGet = false;
        break;
      }
      case Types.MOTIVOS_INTERRUPCAO_GET_FAILURE: {
        draft.payloadGet = action.payload.data;
        draft.loadingGet = false;
        break;
      }

      case Types.MOTIVOS_INTERRUPCAO_POST_REQUEST: {
        draft.loadingPost = true;
        break;
      }
      case Types.MOTIVOS_INTERRUPCAO_POST_SUCCESS: {
        draft.payloadPost = action.payload.data;
        draft.loadingPost = false;
        break;
      }
      case Types.MOTIVOS_INTERRUPCAO_POST_FAILURE: {
        draft.payloadPost = action.payload.data;
        draft.loadingPost = false;
        break;
      }

      case Types.MOTIVOS_INTERRUPCAO_PUT_REQUEST: {
        draft.loadingPost = false;
        break;
      }
      case Types.MOTIVOS_INTERRUPCAO_PUT_RESPONSE: {
        draft.payloadPost = action.payload.data;
        draft.loadingPost = false;
        break;
      }

      case Types.MOTIVOS_INTERRUPCAO_CLEAR_DATA: {
        draft.payloadPost = null;
        draft.loadingPost = false;
        break;
      }

      case Types.LOGOUT: {
        draft.payloadPost = null;
        draft.loadingPost = false;
        draft.payloadGet = null;
        draft.loadingGet = false;
        break;
      }
      default:
    }
  });
}