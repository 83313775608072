import { Types } from '../types';

export function carregarBancoHorasAtualRequest(usuarioId) {
  return {
    type: Types.CARREGAR_BANCO_HORAS_ATUAL_REQUEST,
    payload: { usuarioId },
  };
}

export function carregarBancoHorasAtualSuccess(data) {
  return {
    type: Types.CARREGAR_BANCO_HORAS_ATUAL_SUCCESS,
    payload: { data },
  };
}

export function carregarBancoHorasAtualFailure() {
  return {
    type: Types.CARREGAR_BANCO_HORAS_ATUAL_FAILURE
  };
}
 