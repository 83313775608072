import { Types } from '../../types';

export function filtrarSindicatosRequest(params) {
  return {
    type: Types.FILTRAR_SINDICATOS_REQUEST,
    payload: params,
  };
}

export function filtrarSindicatosResponse(data) {
  return {
    type: Types.FILTRAR_SINDICATOS_RESPONSE,
    payload: { data },
  };
}
