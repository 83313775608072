import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  background: var(--white);
  width: 100%;
  ${({ isOpen }) =>
    isOpen &&
    css`
      background: transparent;
    `}
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0 auto;
`;

export const Photo = styled.img`
  width: 64px
  height: 61px;
  margin: 0 15px;
`;

export const About = styled.span`
  font-size: 11px;
  color: var(--gray);

  ${({ name }) =>
    name &&
    css`
      font-size: 15px;
      color: var(--primary);
    `}

  margin-right: 10px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  h1 {
    font-size: 22px;
    color: #516267;
    + h1 {
      font-size: 17px;
      color: #00abde;
    }
  }
  &.cards {
    padding: 0 !important;
  }
  ${({ isOpen }) =>
    isOpen &&
    css`
      background: transparent;
    `}
`;
export const Name = styled.h2`
  color: var(--light-blue);
  font-size: 22px;
`;
