import { takeLatest, call, put, all } from 'redux-saga/effects';

import { Types } from '../../types';
import { requestFiltrarHolerites } from '~/services/request';
import { filtrarFiliaisResponse } from './actions';

export function* filtrarHoleritesPorFiliais({ payload }) {
  const params = {
    url: '/filiais',
    method: 'GET',
    body: payload,
  };

  try {
    const response = yield call(requestFiltrarHolerites, params);
    yield put(filtrarFiliaisResponse(response.data));
  } catch (error) {
    yield put(filtrarFiliaisResponse({ success: false }));
  }
}

export default all([
  takeLatest(Types.FILTRAR_FILIAIS_REQUEST, filtrarHoleritesPorFiliais),
]);
