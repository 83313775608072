import produce from 'immer';

import { Types } from '~/store/modules/types';

const INITIAL_STATE = {
  loading: false,
  lista: null,
  porId: null,
  combo: null,
};

export default function regrasCondicoesTrabalho(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.REQUEST_REGRAS_CONDICOES_TRABALHO: {
        draft.loading = true;
        break;
      }

      case Types.REQUEST_REGRAS_CONDICOES_TRABALHO_POR_ID: {
        draft.loading = true;
        break;
      }

      case Types.REQUEST_COMBO_CONDICOES_TRABALHO: {
        draft.loading = true;
        break;
      }

      case Types.ALTERA_STATUS_REGRAS_CONDICOES_TRABALHO: {
        draft.loading = true;
        break;
      }

      case Types.RESPONSE_REGRAS_CONDICOES_TRABALHO: {
        draft.lista = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.RESPONSE_REGRAS_CONDICOES_TRABALHO_POR_ID: {
        draft.porId = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.RESPONSE_COMBO_CONDICOES_TRABALHO: {
        draft.combo = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.RESPONSE_ALTERA_STATUS_REGRAS_CONDICOES_TRABALHO: {
        draft.combo = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.CLEAR_RELATORIO: {
        draft.loading = false;
        draft.lista = null;
        draft.combo = null;
        draft.porId = null;
        break;
      }

      case Types.LOGOUT: {
        draft.loading = false;
        draft.lista = null;
        draft.combo = null;
        draft.porId = null;
        break;
      }

      default:
    }
  });
}
