import produce from 'immer';

import { Types } from '../../types';

// Reducers
const INITIAL_STATE = {
  payload: null,
  loading: false,
}

export default function grupoAtivos(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.GRUPO_ATIVOS_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.GRUPO_ATIVOS_SUCCESS: {
        draft.payload = action.payload;
        draft.loading = false;
        break;
      }
      case Types.GRUPO_ATIVOS_FAILURE: {
        draft.payload = null;
        draft.loading = false;
        break;
      }

      case Types.GRUPO_CLEAR_DATA: {
        draft.payload = null;
        draft.loading = false;
        break;
      }
      case Types.LOGOUT: {
        draft.payload = null;
        draft.loading = false;
        break;
      }
      default:
    }
  });
}