/* eslint-disable no-shadow */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import ButtonFolhaCerta from '~/components/_layouts/forms/ButtonFolhaCerta';
import ConteinerAcao from '~/components/ConteinerAcao';
import MessageView from '~/components/_layouts/modals/MessageView';

import { useCalendario, useAcao } from '~/hooks/escalas';
import Provedor, { useEscalasDaEquipe } from '~/hooks/escalasDaEquipe';
import { requestChangeStatusAlocacao } from '~/store/modules/escalas';
import { getMonth, getDay, getWeekDay } from '~/utils/dateFormat';

import aprovado from '~/assets/utils/images/imagensFolhaCerta/iconsStatus/aprovado.svg';
import cancelado from '~/assets/utils/images/imagensFolhaCerta/iconsStatus/cancelado.svg';
import paraAprovar from '~/assets/utils/images/imagensFolhaCerta/iconsStatus/paraAprovar.svg';
import paraCancelar from '~/assets/utils/images/imagensFolhaCerta/iconsStatus/paraCancelar.svg';
import reprovado from '~/assets/utils/images/imagensFolhaCerta/iconsStatus/reprovado.svg';
import User from './components/User';

import {
  Container,
  Row,
  RowEscala,
  Column,
  Relogio,
  CartaoAlteracao,
  ButtonArrowLeft,
  ListaDeAcoes,
  MiniCalendario,
} from './styles';

const getStatusColor = (status) => {
  switch (status) {
    case 'ParaAprovar':
      return 'var(--warning)';
    case 'Aprovada':
      return 'var(--success)';
    case 'Reprovada':
      return 'var(--danger)';
    case 'Removida':
      return 'var(--gray)';
    case 'ParaRemover':
      return 'var(--light-purple)';

    default:
      return null;
  }
};

const getStatus = (status) => {
  switch (status) {
    case 'ParaAprovar':
      return paraAprovar;
    case 'Aprovada':
      return aprovado;
    case 'Reprovada':
      return reprovado;
    case 'Removida':
      return cancelado;
    case 'ParaRemover':
      return paraCancelar;

    default:
      return null;
  }
};

const DescAcoes = {
  MudarParaFolga: 'Mudar escala de Trabalho para Folga',
  MudarParaTrabalho: 'Mudar escala de Folga para Trabalho',
  AlterarEntrada: 'Horário de entrada alterado',
  MoverFolgaSemanal: 'Mudar Folga semanal',
  FolgaCompensarDuranteSemana: 'Compensar folga durante a semana ',
  FolgaCompensarBancoHoras: 'Compensar folga no banco de horas',
  AlterarEscala: 'Alterar escala',
};

const labelAcoes = [
  '',
  'Mudar Para Folga',
  'Mudar Para Trabalho',
  'Alterar Entrada',
  'Mover Folga Semanal',
  'Compensação na Semana',
  'Compensação de Banco',
  'Alterar Escala',
  'Visualizar Alteração',
];

const Component = ({ dados, isEmployed, style, onClose, callback }) => {
  const { data, naturezaDia, usuario, index } = dados;

  const dispatch = useDispatch();
  const { ocultarDetalheDoDia, calendarioAtual } = useCalendario();
  const [oldIndex, setOldIndex] = useState(index);
  const [acaoAberto, setAcaoAberto] = useState(false);
  const [openOptions, setOpenOptions] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState(null);
  const [messageSuccess, setMessageSuccess] = useState(null);

  const [opcaoAcao, setOpcaoAcao] = useState(0);
  const {
    mostrarCriadorDeAcao,
    acaoSelecinada,
    buscarListaDeAcaoDisponiveis,
    listaDeAcaoDisponiveis,
    ocultarCriadorDeAcao,
  } = useAcao();

  const { recuperaCalendarioDia, calendarioDia } = useEscalasDaEquipe();

  const abrirAcao = useCallback(
    (option, IdAcaoVisualizar) => {
      setOpenOptions(false);
      function moverFolgaSemanal() {
        let diaDaSemana = new Date(data);
        diaDaSemana = diaDaSemana.getDay();
        const limite =
          diaDaSemana === 0
            ? index + 1
            : diaDaSemana === 1
            ? index + 7
            : 8 - diaDaSemana + index;
        const inicio =
          diaDaSemana === 1
            ? index
            : diaDaSemana === 0
            ? index - 6
            : index - diaDaSemana + 1;
        const semana = calendarioAtual.dias.filter(
          (dia) => dia.index >= inicio && dia.index < limite,
        );
        return {
          semana,
        };
      }
      function mudarPara() {
        if (option === 1) {
          return { changeTo: 'Folga' };
        }
        return { changeTo: 'Trabalho' };
      }
      function naoAlterar() {}

      let dadosAcao;
      switch (option) {
        case 1:
          dadosAcao = mudarPara();
          break;
        case 2:
          dadosAcao = mudarPara();
          break;
        case 3:
          dadosAcao = naoAlterar();
          break;
        case 4:
          dadosAcao = moverFolgaSemanal();
          break;
        case 5:
          dadosAcao = naoAlterar();
          break;
        case 6:
          dadosAcao = naoAlterar();
          break;
        case 7:
          dadosAcao = naoAlterar();
          break;
        case 8: // Id 8 é o id de visualizar uma ação
          dadosAcao = naoAlterar();
          break;
        default:
          break;
      }

      if (option !== 8) {
        const acao = listaDeAcaoDisponiveis.find((a) => a.acaoId === option);
        dadosAcao = {
          ...dadosAcao,
          acao,
          usuario,
          ...dados,
        };
      } else {
        dadosAcao = {
          dados,
          IdAcaoVisualizar,
        };
      }

      setOpcaoAcao(option);
      setAcaoAberto(true);
      mostrarCriadorDeAcao(dadosAcao);
      callback(true);
    },
    [mostrarCriadorDeAcao, dados, setAcaoAberto, data, setOpenOptions, usuario, callback],
  );

  const atualizarCard = () => {
    ocultarCriadorDeAcao();
    const id = isEmployed ? usuario.usuarioId : usuario.Id;
    const params = { usuarioId: id, data };
    buscarListaDeAcaoDisponiveis(params);
    recuperaCalendarioDia(params);
  };

  useEffect(() => {
    atualizarCard();
  }, []);

  useEffect(() => {
    if (index !== oldIndex) {
      setAcaoAberto(false);
      atualizarCard();
    }
  }, [dados]);

  const fecharCriadorDeAcao = useCallback(() => {
    ocultarCriadorDeAcao();
    setAcaoAberto(false);
  }, [ocultarCriadorDeAcao, setAcaoAberto]);

  const fecharContainer = useCallback(() => {
    ocultarDetalheDoDia();

    onClose();
  }, [onClose, ocultarCriadorDeAcao]);

  const callbackMessage = (data) => {
    setOpenMessage(true);
    setMessage(data.message);
    setMessageSuccess(data.success);
    setTimeout(() => {      
      callback();
      fecharContainer();
      setOpenMessage(false);
    }, 2000);
  };

  const arquivarAlocaçãoEscala = useCallback(
    (id) => {
      dispatch(
        requestChangeStatusAlocacao(
          {
            id,
            ativo: false,
          },
          (data) => callbackMessage(data),
        ),
      );
    },
    [usuario, callbackMessage, requestChangeStatusAlocacao],
  );

  const checkAcoes = () => {
    if (dados.alteracaoEscala || (dados.acoes && dados.acoes.length !== 0))
      return true;
    return false;
  };

  const getHorarios = (escala) => {
    const { horaEntrada, horaSaidaAlmoco, horaVoltaAlmoco, horaSaida } = escala;
    return `${horaEntrada.substring(11, 16)} ${
      horaSaidaAlmoco ? `- ${horaSaidaAlmoco.substring(11, 16)} ` : ''
    }- ${
      horaVoltaAlmoco ? `${horaVoltaAlmoco.substring(11, 16)} -` : ''
    } ${horaSaida.substring(11, 16)}`;
  };

  return (
    <>
      <Container style={style}>
        <Row titlelength={labelAcoes[opcaoAcao].length} isOpenAcao={acaoAberto}>
          {acaoAberto ? (
            <div>
              <ButtonArrowLeft onClick={fecharCriadorDeAcao} />
              <h1>{labelAcoes[opcaoAcao]}</h1>
            </div>
          ) : (
            <>
              <h1>Escala no Dia</h1>
              <button onClick={fecharContainer} className="simples">
                Fechar
              </button>
            </>
          )}
        </Row>
        <User usuario={usuario} isEmployed={isEmployed} isOpen={acaoAberto} />
        <Row className="destaque" isOpen={acaoAberto}>
          <div>
            <MiniCalendario>
              <div>
                <span>{getMonth(data, false)}</span>
              </div>
              <div>
                <span>{getDay(data)}</span>
              </div>
            </MiniCalendario>
            <h1>{getWeekDay(data)}</h1>
          </div>
          <div />
          {listaDeAcaoDisponiveis && (
            <ListaDeAcoes
              open={openOptions}
              quantity={
                listaDeAcaoDisponiveis.length > 0
                  ? listaDeAcaoDisponiveis.length
                  : 1
              }
            >
              <div>
                {listaDeAcaoDisponiveis.length === 0 && (
                  <span>Não há ações disponíveis</span>
                )}
                {listaDeAcaoDisponiveis.length > 0 &&
                  listaDeAcaoDisponiveis.map((item) => (
                    <button
                      key={item.acaoId}
                      onClick={() => abrirAcao(item.acaoId)}
                      className="aredondado"
                      style={{ fontSize: 14, width: 209 }}
                    >
                      {labelAcoes[item.acaoId]}
                    </button>
                  ))}
              </div>
            </ListaDeAcoes>
          )}
          <button
            onClick={() => setOpenOptions(!openOptions)}
            className="aredondado"
            disabled={acaoAberto}
          >
            ações
          </button>
        </Row>
        {dados.escalaEmVigor && (
          <>
            <div>
              <span>ESCALA EM VIGOR</span>
            </div>
            <Column>
              <h1>
                {dados.escalaEmVigor.codigoEscala &&
                  `${dados.escalaEmVigor.codigoEscala} - `}
                {dados.escalaEmVigor.nomeEscala}
              </h1>
              {naturezaDia !== 'Trabalho' ? (
                <RowEscala>
                  <h1 className="azul">{naturezaDia}</h1>
                  <div />
                  {calendarioDia &&
                    calendarioDia.data &&
                    calendarioDia.data.permitirArquivarAlocacao && (
                      <button
                        onClick={() => arquivarAlocaçãoEscala(dados.alocacaoId)}
                        className="aredondado"
                        disabled={false}
                      >
                        ARQUIVAR ALOCAÇÃO
                      </button>
                    )}
                </RowEscala>
              ) : (
                <RowEscala>
                  <h1 className="azul">{getHorarios(dados.escalaEmVigor)}</h1>
                  <div />
                  {calendarioDia &&
                    calendarioDia.data &&
                    calendarioDia.data.permitirArquivarAlocacao && (
                      <button
                        onClick={() => arquivarAlocaçãoEscala(dados.alocacaoId)}
                        className="aredondado"
                        disabled={false}
                      >
                        ARQUIVAR ALOCAÇÃO
                      </button>
                    )}
                </RowEscala>
              )}
            </Column>
          </>
        )}

        {checkAcoes() && (
          <>
            <div>
              <span>ALTERAÇÕES DE ESCALA</span>
            </div>
            {dados.alteracaoEscala && (
              <Column className="cards">
                <CartaoAlteracao cor={getStatusColor('Aprovada')}>
                  <div>
                    {DescAcoes[dados.alteracaoEscala.tipoAcao]}
                    <div className="tag">
                      <img
                        src={getStatus('Aprovada')}
                        height="20px"
                        width="auto"
                        alt=""
                        style={{ paddingRight: '4px' }}
                      />
                      <span>Aprovada</span>
                    </div>
                  </div>
                  <button
                    onClick={() => abrirAcao(8, dados.alteracaoEscala.acaoId)}
                  >
                    ver detalhes
                  </button>
                </CartaoAlteracao>
              </Column>
            )}
          </>
        )}
      </Container>
      <ConteinerAcao
        dados={acaoSelecinada}
        option={opcaoAcao}
        isEmployed={isEmployed}
        onClose={fecharCriadorDeAcao}
        callback={callback}
      />
      <MessageView
        message={message}
        open={openMessage}
        onClick={() => {
          return null;
        }}
        success={messageSuccess}
      />
    </>
  );
};

export default function Detalhe({
  dados,
  isEmployed,
  style,
  onClose,
  callback,
}) {
  return (
    <Provedor>
      <Component
        dados={dados}
        isEmployed={isEmployed}
        style={style}
        onClose={onClose}
        callback={callback}
      />
    </Provedor>
  );
}
