import { Types } from '~/store/modules/types';

const baseUrl = '/condicaotrabalho';

function responseListaRegrasCondicoesTrabalho(data, callback) {
  if (callback) {
    callback(data);
  }

  return {
    type: Types.RESPONSE_REGRAS_CONDICOES_TRABALHO,
    payload: { data },
  };
}

export function requestRegrasCondicoesTrabalho(params, callback) {
  return {
    type: Types.REQUEST_REGRAS_CONDICOES_TRABALHO,
    url: baseUrl,
    method: 'POST',
    body: params,
    responseAction: responseListaRegrasCondicoesTrabalho,
    callback,
  };
}

export function getListaRegrasCondicoesTrabalho(params, callback) {
  return {
    type: Types.REQUEST_REGRAS_CONDICOES_TRABALHO,
    url: baseUrl,
    method: 'GET',
    queryParams: params,
    responseAction: responseListaRegrasCondicoesTrabalho,
    callback,
  };
}

function responseComboRegrasCondicoesTrabalho(data, callback) {
  if (callback) {
    callback(data);
  }

  return {
    type: Types.RESPONSE_COMBO_CONDICOES_TRABALHO,
    payload: { data },
  };
}

export function getComboRegrasCondicoesTrabalho(params, callback) {
  return {
    type: Types.REQUEST_COMBO_CONDICOES_TRABALHO,
    url: `${baseUrl}/combo`,
    method: 'GET',
    queryParams: params,
    responseAction: responseComboRegrasCondicoesTrabalho,
    callback,
  };
}

function responseAlteraStatusRegrasCondicoesTrabalho(data, callback) {
  if (callback) {
    callback(data);
  }

  return {
    type: Types.RESPONSE_ALTERA_STATUS_REGRAS_CONDICOES_TRABALHO,
    payload: { data },
  };
}

export function alteraStatusRegrasCondicoesTrabalho(params, callback) {
  return {
    type: Types.ALTERA_STATUS_REGRAS_CONDICOES_TRABALHO,
    url: `${baseUrl}/status`,
    method: 'PUT',
    body: params,
    responseAction: responseAlteraStatusRegrasCondicoesTrabalho,
    callback,
  };
}

function responseRegrasCondicoesTrabalhoPorId(data, callback) {
  if (callback) {
    callback(data);
  }

  return {
    type: Types.RESPONSE_REGRAS_CONDICOES_TRABALHO_POR_ID,
    payload: { data },
  };
}

export function getRegrasCondicoesTrabalhoPorId(params, callback) {
  return {
    type: Types.REQUEST_REGRAS_CONDICOES_TRABALHO_POR_ID,
    url: `${baseUrl}/${params.id}`,
    method: 'GET',
    queryParams: params,
    responseAction: responseRegrasCondicoesTrabalhoPorId,
    callback,
  };
}

export function alterarRegrasCondicoesTrabalho(params, callback) {
  return {
    type: Types.REQUEST_REGRAS_CONDICOES_TRABALHO,
    url: baseUrl,
    method: 'PUT',
    body: params,
    responseAction: responseListaRegrasCondicoesTrabalho,
    callback,
  };
}

export function clearRegrasCondicoesTrabalho() {
  return { type: Types.CLEAR_RELATORIO };
}
