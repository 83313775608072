import { takeLatest, call, put, all } from 'redux-saga/effects';
import { Types } from '../../types';
import { requestMotorNovo } from '~/services/request';

import {
  getHoraNegativaResponse,
  postHoraNegativaResponse,
  putHoraNegativaResponse
} from './actions';

export function* horaNegativa({ payload, method, id }) {
  let params = {
    url: id ? '/api/v1/horaNegativa/' + id : '/api/v1/horaNegativa',
    method: method,
    body: payload
  };

  switch (method) {
    case "GET":
      try {
        const response = yield call(requestMotorNovo, params);
        yield put(getHoraNegativaResponse(response.data));
      } catch (error) {
        yield put(getHoraNegativaResponse({ success: false }));
      }
      break;
    case "POST":
      try {
        const response = yield call(requestMotorNovo, params);
        let aux = {
          id: response.headers.location,
          data: response.data
        }
        yield put(postHoraNegativaResponse(aux));
      } catch (error) {
        yield put(postHoraNegativaResponse({ success: false }));
      }
      break;
    case "PUT":
      try {
        const response = yield call(requestMotorNovo, params);
        yield put(putHoraNegativaResponse(response.data));
        yield put(postHoraNegativaResponse({ id: id }));
      } catch (error) {
        yield put(putHoraNegativaResponse({ success: false }));
      }
      break;

    default:
      break;
  }
}


export default all([
  takeLatest(Types.HORA_NEGATIVA_REQUEST, horaNegativa),
]);