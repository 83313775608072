import produce from 'immer';

import { Types } from '~/store/modules/types';

const INITIAL_STATE = {
  loading: false,
  lista: null,
};

export default function calendarioEquipe(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.REQUEST_CALENDARIO_EQUIPE: {
        draft.loading = true;
        break;
      }

      case Types.RESPONSE_CALENDARIO_EQUIPE: {
        draft.lista = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.CLEAR_RELATORIO: {
        draft.loading = false;
        draft.lista = null;
        break;
      }

      case Types.LOGOUT: {
        draft.loading = false;
        draft.lista = null;
        break;
      }
      default:
    }
  });
}
