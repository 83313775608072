import { takeLatest, call, put, all } from 'redux-saga/effects';
import { Types } from '../types';
import { requestSobreaviso } from '../../../services/request';

import {
  alocacaoSobreavisoResponse,
  alocacaoSobreavisoResponsePut,
  alocacaoSobreavisoResponseGet,
  alocacaoSobreavisoGetByIDResponse,
  alocacaoSobreavisoHistoricoResponse,
} from './actions';

export function* alocacaoSobreaviso({ payload, method }) {
  const params = {
    url: '/alocacao',
    method,
    body: payload,
  };

  try {
    const response = yield call(requestSobreaviso, params);

    yield put(alocacaoSobreavisoResponse(response.data));
  } catch (error) {
    yield put(alocacaoSobreavisoResponse({ success: false }));
  }
}

export function* alocacaoSobreavisoPut({ payload, method }) {
  const params = {
    url: `/alocacao/${payload.id}`,
    method,
    body: payload,
  };

  try {
    const response = yield call(requestSobreaviso, params);

    yield put(alocacaoSobreavisoResponsePut(response.data));
  } catch (error) {
    yield put(alocacaoSobreavisoResponsePut({ success: false }));
  }
}

export function* alocacaoSobreavisoGet({ payload }) {
  const params = {
    url: '/alocacao',
    method: 'GET',
    queryParams: payload,
    body: {},
  };

  try {
    const response = yield call(requestSobreaviso, params);

    yield put(alocacaoSobreavisoResponseGet(response.data));
  } catch (error) {
    yield put(alocacaoSobreavisoResponseGet({ success: false }));
  }
}

export function* alocacaoSobreavisoGetByID({ payload }) {
  const params = {
    url: `/alocacao/${payload}`,
    method: 'GET',
  };

  try {
    const response = yield call(requestSobreaviso, params);

    yield put(alocacaoSobreavisoGetByIDResponse(response.data));
  } catch (error) {
    yield put(alocacaoSobreavisoGetByIDResponse({ success: false }));
  }
}

export function* alocacaoSobreavisoHistorico({ id }) {
  const params = {
    url: `/alocacao/historico/${id}`,
    method: 'GET',
  };

  try {
    const response = yield call(requestSobreaviso, params);

    yield put(alocacaoSobreavisoHistoricoResponse(response.data));
  } catch (error) {
    yield put(alocacaoSobreavisoHistoricoResponse({ success: false }));
  }
}

export default all([
  takeLatest(Types.ALOCACAO_SOBREAVISO_REQUEST, alocacaoSobreaviso),
  takeLatest(Types.ALOCACAO_SOBREAVISO_REQUEST_PUT, alocacaoSobreavisoPut),
  takeLatest(Types.ALOCACAO_SOBREAVISO_REQUEST_GET, alocacaoSobreavisoGet),
  takeLatest(
    Types.ALOCACAO_SOBREAVISO_BY_ID_REQUEST_GET,
    alocacaoSobreavisoGetByID,
  ),
  takeLatest(
    Types.ALOCACAO_SOBREAVISO_HISTORICO_REQUEST,
    alocacaoSobreavisoHistorico,
  ),
]);
