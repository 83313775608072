import { Types } from '../../types';

export function descansoObrigatorioRequest({ params, method, id }) {
  return {
    type: Types.DESCANSO_OBRIGATORIO_REQUEST,
    payload: params,
    method: method,
    id: id
  };
}

export function getDescansoObrigatorioResponse(data) {
  return {
    type: Types.GET_DESCANSO_OBRIGATORIO_RESPONSE,
    payload: { data },
  };
}

export function postDescansoObrigatorioResponse(data) {
  return {
    type: Types.POST_DESCANSO_OBRIGATORIO_RESPONSE,
    payload: { data },
  };
}

export function putDescansoObrigatorioResponse(data) {
  return {
    type: Types.PUT_DESCANSO_OBRIGATORIO_RESPONSE,
    payload: { data },
  };
}
