import { takeLatest, call, put, all } from 'redux-saga/effects';
import { Types } from '../../types';
import { requestMotorNovo } from '~/services/request';

import {
  getIntervaloResponse,
  postIntervaloResponse,
  putIntervaloResponse
} from './actions';

export function* intervalo({ payload, method, id }) {
  let params = {
    url: id ? '/api/v1/intervalo/' + id : '/api/v1/intervalo',
    method: method,
    body: payload
  };

  switch (method) {
    case "GET":
      try {
        const response = yield call(requestMotorNovo, params);
        yield put(getIntervaloResponse(response.data));
      } catch (error) {
        yield put(getIntervaloResponse({ success: false }));
      }
      break;
    case "POST":
      try {
        const response = yield call(requestMotorNovo, params);
        let aux = {
          id: response.headers.location,
          data: response.data
        }
        yield put(postIntervaloResponse(aux));
      } catch (error) {
        yield put(postIntervaloResponse({ success: false }));
      }
      break;
    case "PUT":
      try {
        const response = yield call(requestMotorNovo, params);
        yield put(putIntervaloResponse(response.data));
        yield put(postIntervaloResponse({ id: id }));
      } catch (error) {
        yield put(putIntervaloResponse({ success: false }));
      }
      break;

    default:
      break;
  }
}


export default all([
  takeLatest(Types.INTERVALO_REQUEST, intervalo),
]);