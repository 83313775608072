import { Types } from '../types';

export function carregarMesRequest(usuarioId, mes, ano, callback) {
  return {
    type: Types.CARREGAR_MES_REQUEST,
    payload: {
      usuarioId: usuarioId,
      mes: mes,
      ano: ano,
      callback,
    },
  };
}

export function carregarMesSuccess(data, callback) {
  if (callback) {
    callback();
  }
  return {
    type: Types.CARREGAR_MES_SUCCESS,
    payload: { data },
  };
}

export function carregarMesFailure() {
  return {
    type: Types.CARREGAR_MES_FAILURE,
  };
}

export function carregarMesClearData() {
  return {
    type: Types.CARREGAR_MES_CLEAR_DATA,
  };
}
