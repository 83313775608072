import { Types } from '~/store/modules/types';

function responseListaEscalasDeTrabalho(data, callback) {
  if (callback) {
    callback(data);
  }
  return {
    type: Types.RESPONSE_ESCALA_TRABALHO,
    payload: { data },
  };
}

export function requestEscalasDeTrabalho(params, callback) {
  return {
    type: Types.REQUEST_ESCALA_TRABALHO,
    url: '/escala',
    method: 'GET',
    queryParams: params,
    responseAction: responseListaEscalasDeTrabalho,
    callback,
  };
}

function responseChangeStatus(data, callback) {
  if (callback) {
    callback(data);
  }
  return {
    type: Types.CLEAR_ESCALAS_TRABALHO,
    payload: { data },
  };
}

export function requestChangeStatus(params, callback) {
  return {
    type: Types.REQUEST_ESCALA_TRABALHO,
    url: '/escala/status',
    method: 'PUT',
    body: params,
    responseAction: responseChangeStatus,
    callback,
  };
}

export function requestCombo(params, callback) {
  return {
    type: Types.REQUEST_ESCALA_TRABALHO,
    url: '/escala/combo',
    method: 'GET',
    queryParams: params,
    responseAction: responseListaEscalasDeTrabalho,
    callback,
  };
}

function responseValidarEscala(data, callback) {
  if (callback) {
    callback(data);
  }
  return {
    type: Types.RESPONSE_ESCALA_TRABALHO,
    payload: { data },
  };
}

export function validarEscala(params, callback) {
  return {
    type: Types.REQUEST_ESCALA_TRABALHO,
    url: '/escala/valida',
    method: 'POST',
    body: params,
    responseAction: responseValidarEscala,
    callback,
  };
}

function responseCriarEscala(data, callback) {
  if (callback) {
    callback(data);
  }
  return {
    type: Types.CLEAR_ESCALAS_TRABALHO,
    payload: { data },
  };
}

export function criarEscala(params, method, callback) {
  return {
    type: Types.REQUEST_CRIAR_ESCALA_TRABALHO,
    url: '/escala',
    method,
    body: params,
    responseAction: responseCriarEscala,
    callback,
  };
}

function responseEscalaById(data, callback) {
  if (callback) callback(data);

  return {
    type: Types.GET_ESCALA_BY_ID,
    payload: { data },
  };
}

export function getEscalaById(params, callback) {
  return {
    type: Types.REQUEST_CRIAR_ESCALA_TRABALHO,
    url: `/escala/${params.id}`,
    method: 'GET',
    queryParams: params,
    responseAction: responseEscalaById,
    callback,
  };
}

function responseGetPadrao(data, callback) {
  if (callback) callback(data);

  return {
    type: Types.GET_ESCALA_PADRAO,
    payload: { data },
  };
}

export function getEscalaPadrao(callback) {
  return {
    type: Types.REQUEST_ESCALA_TRABALHO,
    url: `/escala/padrao`,
    method: 'GET',
    responseAction: responseGetPadrao,
    callback,
  };
}

export function clearEscalasTrabalho() {
  return { type: Types.CLEAR_ESCALAS_TRABALHO };
}
