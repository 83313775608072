import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/pt-br';

import { Modal, Col, ModalBody, Row } from 'reactstrap';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ButtonFolhaCerta from '~/components/_layouts/forms/ButtonFolhaCerta';

const modifiersStyles = {
  selected: {
    color: 'white',
    backgroundColor: '#00AAE0',
  },
};

class DateSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      daySelect: props.selectedDay || null,
      open: props.open,
    };
  }

  componentDidUpdate() {
    if (this.props.bancoFolga || this.props.selectedDay) {
      if (this.state.daySelect !== this.props.selectedDay) {
        this.setState({ daySelect: this.props.selectedDay });
      }
    }
  }

  handleDayClick = (day) => {
    this.setState({ daySelect: day });
    this.props.onDaySelect(day);
  };

  formatDate = (day) => {
    const { format } = this.props;
    if (day) {
      const day1 = moment(day).format(format);
      return day1;
    }
    return '';
  };

  toogle = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  onBlur = () => {
    const { selectedDay } = this.props;
    this.toogle();
    this.setState({ daySelect: selectedDay });
  };

  onSelect = () => {
    const { onDaySelect } = this.props;
    const { daySelect } = this.state;
    this.toogle();
    onDaySelect(daySelect);
  };

  render() {
    const {
      openInDate,
      max,
      min,
      id,
      label,
      disabled,
      validator,
      className,
      onDaySelect,
    } = this.props;
    const { open, daySelect } = this.state;
    const disabledDays = {
      after: max || null,
      before: min || null,
    };

    return (
      <>
        <div className="RangeExample">
          <TextField
            fullWidth
            className="text-uppercase"
            id={id}
            label={label}
            value={this.formatDate(daySelect)}
            color="primary"
            onClick={!disabled ? this.toogle : () => {}}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <CalendarTodayIcon htmlColor="var(--cinzaDisabledDark)" />
                </InputAdornment>
              ),
            }}
            error={daySelect == null && validator}
            disabled={disabled}
          />
          <Modal isOpen={open} toggle={this.onBlur} className={className}>
            <ModalBody className="align-self-center">
              <DayPicker
                localeUtils={MomentLocaleUtils}
                locale="pt-br"
                numberOfMonths={1}
                disabledDays={disabledDays}
                selectedDays={daySelect}
                month={openInDate || daySelect ? daySelect : new Date()}
                onDayClick={this.handleDayClick}
                modifiersStyles={modifiersStyles}
              />
            </ModalBody>

            <Row className="mt-4 mb-4 ml-0 mr-0">
              <Col md={4} xs={4} className="d-flex">
                <ButtonFolhaCerta
                  onClickButton={() => {
                    onDaySelect(null);
                    this.setState({ daySelect: null });
                  }}
                  buttonName="Limpar"
                  small
                  branco
                />
              </Col>

              <Col md={8} xs={8} className="d-flex">
                <ButtonFolhaCerta
                  onClickButton={this.onBlur}
                  buttonName="Cancelar"
                  className="ml-auto"
                  branco
                  small
                />

                <ButtonFolhaCerta
                  onClickButton={this.onSelect}
                  buttonName="Selecionar"
                  showIcon
                  small
                />
              </Col>
            </Row>
          </Modal>
        </div>
      </>
    );
  }
}

DateSelect.propTypes = {
  open: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  onDaySelect: PropTypes.func.isRequired,

  openInDate: PropTypes.objectOf(Date),
  max: PropTypes.objectOf(Date),
  min: PropTypes.objectOf(Date),
  format: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  validator: PropTypes.bool,
  className: PropTypes.string,

  selectedDay: PropTypes.objectOf(Date),
};

DateSelect.defaultProps = {
  openInDate: null,
  max: null,
  min: null,
  label: null,
  disabled: false,
  validator: false,
  className: '',
  selectedDay: null,
  format: 'DD MMM dddd',
};

export default DateSelect;
