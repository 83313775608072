import { takeLatest, call, put, all, delay } from 'redux-saga/effects';
import { Types } from '../../types';

import { toast } from 'react-toastify';

import { requestFolhacertaBase } from '~/services/request';

import { grupoAtivosSuccess, grupoAtivosFailure } from './actions';

export function* grupoAtivos({ payload }) {
  let params = {
    url: '/grupo',
    method: 'GET',
    queryParams: payload,
  };

  try {
    const response = yield call(requestFolhacertaBase, params);

    if (response.data.success) {
      const data = response.data.data;

      yield put(grupoAtivosSuccess(data));
      yield delay(2000);
    } else {
      yield put(grupoAtivosFailure());
    }
  } catch (err) {
    yield put(grupoAtivosFailure());
  }
}

export default all([takeLatest(Types.GRUPO_ATIVOS_REQUEST, grupoAtivos)]);
